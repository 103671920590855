import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap'
import ReactApexChart from 'react-apexcharts'
import moment from 'moment'
// import "./dashboard.scss"

const AnalyticsComponets = (props) => {
    const [view, setView] = useState('monthly')
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState(getChartOptions('monthly'))
    const [totals, setTotals] = useState({
        thisMonthTotal: props.data?.thisMonthTotal,
        prevMonthTotal: props.data?.prevMonthTotal,
        thisYearTotal: props.data?.thisYearTotal,
        prevYearTotal: props.data?.prevYearTotal,
    })
    const [percentages, setPercentages] = useState(calculatePercentages(props.data))

    useEffect(() => {
        updateChart(view)
    }, [view])

    function calculatePercentages(data) {
        const calculateChange = (current, previous) => {
            if (previous === 0) return 0
            return (((current - previous) / previous) * 100).toFixed(1)
        }

        return {
            monthChange: calculateChange(data?.thisMonthTotal, data?.prevMonthTotal),
            yearChange: calculateChange(data?.thisYearTotal, data?.prevYearTotal),
        }
    }

    function getChartOptions(view) {
        const currentMonth = moment().month()

        const categories = view === 'monthly'
            ? Array.from({ length: 30 }, (_, i) => moment().month(currentMonth).date(i + 1).format('DD MMM'))
            : moment.months()

        return {
            chart: {
                type: 'line', // Use 'line' to handle both bar and line series
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: view === 'monthly' ? [0, 3] : [0, 3], // Width for bar and line
                curve: 'smooth',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            colors: ['#5664d2', '#3DDC84'],
            labels: categories,
        }
    }

    function updateChart(view) {
        const data = props.data
        const series = view === 'monthly'
            ? [
                { name: 'Current Month', data: data?.currentMonth.map(item => item.value), type: 'bar' },
                { name: 'Previous Month', data: data?.prevMonth.map(item => item.value), type: 'line' }
              ]
            : [
                { name: 'Current Year', data: data?.currentYear.map(item => item.value), type: 'bar' },
                { name: 'Previous Year', data: data?.prevYear.map(item => item.value), type: 'line' }
              ]

        setView(view)
        setSeries(series)
        setOptions(getChartOptions(view))
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="float-end d-none d-md-inline-block">
                        <ButtonGroup className="mb-2">
                            <Button size="sm" color="light" active={view === 'monthly'} onClick={() => setView('monthly')}>Monthly</Button>
                            <Button size="sm" color="light" active={view === 'annual'} onClick={() => setView('annual')}>Annual</Button>
                        </ButtonGroup>
                    </div>
                    <h4 className="card-title mb-2">Venue Analytics</h4>
                    <div id="line-column-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart options={options} series={series} type="line" height="350" />
                    </div>
                </CardBody>

                <CardBody className="border-top text-center">
                    <Row>
                        <Col sm={4}>
                            <div className="d-inline-flex">
                                <h5 className="me-2">{totals.thisMonthTotal}</h5>
                                <div className={percentages.monthChange >= 0 ? "text-success" : "text-danger"}>
                                    <i className={`mdi mdi-menu-${percentages.monthChange >= 0 ? "up" : "down"} font-size-14`}> </i>
                                    {percentages.monthChange} %
                                </div>
                            </div>
                            <p className="text-muted text-truncate mb-0">This month</p>
                        </Col>

                        <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Year :</p>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{totals.thisYearTotal}</h5>
                                    <div className={percentages.yearChange >= 0 ? "text-success" : "text-danger"}>
                                        <i className={`mdi mdi-menu-${percentages.yearChange >= 0 ? "up" : "down"} font-size-14`}> </i>
                                        {percentages.yearChange} %
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="mt-4 mt-sm-0">
                                <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Year :</p>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0">{totals.prevYearTotal}</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default AnalyticsComponets

