import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import CustomAvatar from "../../../components/Common/Avatar.js";
//import filter modal
import FilterModal from "../../../components/Modal/FilterModal";

import cryptLib from "@skavinvarnan/cryptlib";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  putRequestForm,
  postRequestForm,
} from "../../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  venueName: "", 
  userName: "",
  title: "",
  stars: "",
  review: "",
  reply: "",
  status: "",
  createdAt: "",
};

function base64Decode(str, channel = "") {
  try {

    return cryptLib.decryptCipherTextWithRandomIV(str, channel);
  } catch (error) {
      //console.log("error",error, channel, str)
    return "";
  }
}

const RatingManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [ratingdata, setratingdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");


  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [canExport, setCanExport] = useState(true);
  const [canReply, setCanReply] = useState(true);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("ratingId", actId);
    formData.append("status", status);

    const res = await putRequestForm("rating/update", formData);

    if (res.status == 1) {
      toast.success("Rating Status updated Successfully");
      ratingManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  useEffect(() => {
    ratingManagment();
  }, [currentPage]);

  useEffect(() => {
    ratingManagment();
  }, [sizePerPageOrg, sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    //console.log("toggleModel", model);
    setModel(!model);
  };


  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        //console.log("page", page);
      }
    }

    //console.log("type", type);

    if (type === "search") {
      //console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {

      
        var filterObject = {
            [key]: filter[key],
        };   
       

        if (key === "venueName" || key === "userName" || key === "review" || key === "reply") {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars" ) {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    //console.log("filterArgs", filterArgs);
    toggleModel();
    ratingManagment(filterArgs);
  };

  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {

      
        var filterObject = {
            [key]: filter[key],
        };   
       

        if (key === "venueName" || key === "userName" || key === "review" || key === "reply") {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars" ) {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    //console.log("filterArgs", filterArgs);
    toggleModel();
    ratingManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    ratingManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  
 
  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Venue</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.venueName }
          onChange={(e) => updateFilter("venueName", e.target.value)}
          name="venue"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">User</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.userName }
          onChange={(e) => updateFilter("userName", e.target.value)}
          name="user"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.title }
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
    <label className="col-sm-3 col-form-label">Stars</label>
    <div className="col-sm-6">
      <input
        type="text"
        value={ filter.stars }
        onChange={(e) => updateFilter("stars", e.target.value)}
        name="stars"
        className="form-control"
      />
    </div>
  </div>,
   <div className="row mb-4" key="title">
   <label className="col-sm-3 col-form-label">Review</label>
   <div className="col-sm-6">
     <input
       type="text"
       value={ filter.review }
       onChange={(e) => updateFilter("review", e.target.value)}
       name="review"
       className="form-control"
     />
   </div>
 </div>,
 <div className="row mb-4" key="title">
 <label className="col-sm-3 col-form-label">Reply</label>
 <div className="col-sm-6">
   <input
     type="text"
     value={ filter.reply }
     onChange={(e) => updateFilter("reply", e.target.value)}
     name="reply"
     className="form-control"
   />
 </div>
</div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={ filter.createdAt }
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];
  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },   
    {
      dataField: "member?.title",
      text: "User",
      sort: false,
      formatter: userFormatter,
    }, 

    {
      text: "New Conversation",
      dataField: "newMessage",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <>
            {row.newMessage ? (
              <Badge color="danger" className="badge-soft-danger">
                Yes
              </Badge>
            ) : (
              <Badge color="success" className="badge-soft-success">
                No
              </Badge>
            )}
          </>
        );
      },
    },  
    
    
    {
      dataField: "totalMessage",
      text: "Total Messages",
      sort: false,
    },


    {
      dataField: "lastMessage",
      text: "Last Message",
      sort: false,
      formatter: (cellContent, row) => {
        const msg = base64Decode(row.msg, row.chatId);

        if(row.type == 'image'){
          return (
            <>
              <img src={msg} width="50" height="50" />
            </>
          );
        }

        if(row.type == 'audio'){
          return (
            <>
              <audio controls>

                <source src
                ={msg} type="audio/mpeg" />
              </audio>
            </>
          );
        }

        return (
          <>
            {msg.length > 50 ? msg.substring(0, 50) + "..." : msg}
          </>
        );



      },
    },   

    {
      dataField: "createdAt",
      text: "Last Message Time",
      sort: false,
      formatter: (cellContent, row) => {
        return (
          <>
            {new Date(row.createdAt).toLocaleString()}
          </>
        );
      },
    },  

    
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {    
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {    
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {   
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >   
          { canReply && (      
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/venue-account/chat-window`,
                state: { row },
              }}
            >
              {" "}
              <i style={{color: "white"}} class="fas fa-comment"></i>
            </Link>{" "}

          </button>    
          )}     
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  function userFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
          <>
              {row.userImage ? (
                <img
                  src={row.userImage}
                  onClick={() => showLightBox(row.userImage)}
                  alt="img"
                  width={"30px"}
                />
              ) : (
                <CustomAvatar
                  iconSize={30}
                  name={
                    row?.member?.title
                  }
                />
              )}
            </>
            &nbsp;&nbsp;
            { row?.member?.title}
          </p>
        </div>
      </React.Fragment>
    );
  }

  
  const exportCSV = async () => {   
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {     
     
    };

    // if (filterArgs?.length) {
    //   payload.filterArgs = filterArgs;
    // } else {
    //   if (searchText) {
    //     payload.search = searchText;
    //   }
    // }
    
    try {
    const response = await postRequest(`chat/fetch-conversation/export-csv`, payload);
    const data = response.data;
    window.location.href = data.url;
    //console.log("data", data);
    }catch(error){
      //console.log("Failed to fetch data:", error);
    }finally{
      setLoader(false);
    }


  }

  const ratingManagment = async (filterArgs = []) => {
    //console.log(currentPage);
    const vid = localStorage.getItem("stored_venueId");
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      venueId: vid,
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try{
    const response = await postRequest(
      `chat/venue/chat-list`,payload);
    const data = response.data;

    //console.log("data", data);

    setTotalPage(data?.length);
    //setCurrentFPage(currentPage); 
    setratingdata(data);
    }catch(error){
      //console.log("Failed to fetch data:", error);
    }finally{
    setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`rating/update`, { ratingId: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          ratingManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>All Conversation</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Conversation</li>:{" "}
                <li className="breadcrumb-item">Conversation List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-4 text-end">
            {/* { canExport && (
          <button onClick={ () => { exportCSV() }  } type='button' className='btn btn-primary mx-2'><i class="fas fa-upload"></i> Export CSV</button>
            )} */}
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={ratingdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                {/* <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="venue">
                                      {" "}
                                      Venue	
                                    </option>
                                    <option value="user">
                                      {" "}
                                      User
                                    </option>
                                    <option value="title">Title	</option>
                                    <option value="star">Stars</option>
                                    <option value="review">Review</option>
                                    <option value="reply">Reply</option>
                                    <option value="createdAt">Created At</option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                    
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        ratingManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reload
                                    </button>
                                  </div>
                                </Col> */}

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    // onTableChange={handleTableChange}
                                    remote={false}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default RatingManagementlist;
