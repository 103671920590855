import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload"; 

import ActivityModal from "../../components/Modal/ActivityModal";
import OfferModal from "../../components/Modal/OfferModalOrg.js";
import Lightbox from "react-image-lightbox";
export default function CreateActivityBanner() {
  const location = useLocation();
  const state = location.state?.row;

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [bannerTypeList, setBannerTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);  
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    // fetchTypeList();
    fetchCategoryList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("activity/list", { limit: 10000000 });
    if (response.status == 1) {
      setActivityList(response.data.list);
    }
    setLoading(false);
  };

  const fetchTypeList = async () => {
    setLoading(true);
    const response = await postRequest("banner/type/list", { limit: 10000000 });
    if (response.status == 1) {
      setBannerTypeList(response.data.list);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const selectRow = (id, name) => {
    setActivityId(id);
    setActivityText(name);
    closeModal();
  };

  const selectOfferRow = (id, name) => {
    setOfferId(id);
    setOfferText(name);
    closeOfferModal();
  };

  const Createbanner = async (e) => {
    e.preventDefault();
   

   

    if (!bannerData?.type) {
      toast.error("Please select banner type");
      return;
    }

    if (!bannerData?.title) {
      toast.error("Title is required");
      return;
    }
    if (!bannerData?.type) {
      toast.error("Please select banner type");
      return;
    }

    if (bannerData?.type == "activity" && !activityId) {
      toast.error("Please select activity");
      return;
    }

  

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
    setStartLoader(false);
    formData.append("activityId", activityId);   
    formData.append("type", bannerData.type);  
    formData.append("title", bannerData.title);
    // formData.append("image", selectedImage);
    formData.append("link", bannerData.link || "");
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    } 


    setLoading(true);
    const response = await postRequestForm("activity/admin/banner/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/activityBanner");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Activity Banner Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Activity Banner Management</li>:{" "}
              <li className="breadcrumb-item">Create Activity Banner</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Activity Banner</h4>
                <Form onSubmit={Createbanner}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                     

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="link">Link</option>
                              <option value="activity">Activity</option>
                              {/* <option value="offer">Offer</option> */}

                              {/* <option value="venue">Venue</option> */}
                            </select>
                          </div>
                        </div>

                        {bannerData?.type == "activity" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>
                            <div className="col-sm-6"
                            onClick={() => toggleModel()}
                            >
                              {activityText ? (
                              //    <div
                              //    style={{
                              //      display: "flex",
                              //      border: "1px solid #ced4da",
                              //      padding:
                              //        " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //      borderRadius: "0.25rem",
                              //      lineHeight: 1.5,
                              //    }}
                              //  >
                              //    <div style={{ minWidth: "100%" }}>
                              //      <a
                              //        href="javascript:void(0)"
                              //        style={{
                              //          textDecoration: "none",
                              //          color: "#5b626b",
                              //          fontSize: "0.8125rem",
                              //          fontWeight: 400,
                              //        }}
                              //      >
                              //       {activityText}
                              //       </a>
                              //     </div>
                              //     <div>
                              //   <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //      <i
                              //           style={{ color: "#5b626b" }}
                              //           class="fa fa-plus"
                              //         ></i>
                              //   </a>
                              // </div>
                              //   </div>
                              <div class="input-group">
                                <button class="btn" style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff"   }} type="button" id="chooseActivityBtn" onclick={() => toggleModel()}>Choose Activity</button>
                                <input type="text" style={{borderLeft:"none"}} class="form-control" readonly value={activityText} id="selectedActivity" placeholder="No Activity chosen"/>                              
                              </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Activity
                                //     </a>
                                //   </div>
                                //   <div>
                                // <a
                                //   onClick={() => toggleModel()}
                                //   href="javascript:void(0)"
                                // >
                                //  <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                // </a>
                                // </div>
                                // </div>
                                <div class="input-group">
                                <button class="btn" style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff"  }} type="button" id="chooseActivityBtn" onclick={() => toggleModel()}>Choose Activity</button>
                                <input type="text" class="form-control" readonly id="selectedActivity" placeholder="No Activity chosen"/>  
                              </div>
                              )}
                            </div>
                          </div>
                        )}

                       

                        {bannerData?.type == "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value || " ")
                                }
                                value={bannerData?.link || " "}
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                            <br />
                            {bannerData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">Start Date</label>
                              <div className="col-sm-6">
                              <input type="date" onChange={ (e) => updateValue('startDate', e.target.value) } name='startDate'  className="form-control"  />

                                                            </div>
                          </div>           

                          <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">End Date</label>
                              <div className="col-sm-6">
                                  <input type="date" onChange={ (e) => updateValue('endDate', e.target.value) } name='endDate'  className="form-control"  />
                              </div>
                          </div>     */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

      {/* modal */}

      <ActivityModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
      {/* modal */}
     
    </React.Fragment>
  );
}
