import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { useParams, useLocation } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  postRequest,
  deleteRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const VenueStoryManagementlist = (props) => {
  const location = useLocation();
  const state = location.state?.row;

  const [modal_center, setmodal_center] = useState(false);
  const [storydata, setstorydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  useEffect(() => {
    storyManagment();
  }, [currentPage]);

  const { id } = useParams();

  useEffect(() => {
    setstorydata(state);
  }, [state]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function MediaFormatter(cellContent, row) {
    if (row.mediaType == "video") {
      return (
        <>
          <video width="50" height="50" controls>
            <source src={row.mediaUrl} type="video/mp4" />
          </video>
        </>
      );
    }

    return (
      <>
        <img
          src={row.mediaUrl}
          onClick={() => showLightBox(row.mediaUrl)}
          alt="image"
          width={"50px"}
        />
      </>
    );
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.thumbnail}
          onClick={() => showLightBox(row.thumbnail)}
          alt="thumbnail img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "contentType",
      text: "Content Type",
      sort: false,
    },
    {
      dataField: "mediaType",
      text: "Media Type",
      sort: false,
    },
    {
      dataField: "mediaUrl",
      text: "File",
      formatter: MediaFormatter,
      sort: false,
    },
    {
      dataField: "thumbnail",
      text: "Thumbnail",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "buttonText",
      text: "Button Text",
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];  
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const storyManagment = async () => {
    setLoader(true);
    const response = await postRequest(`venue/story/list`, { venueId: id });
    const data = response.data;

    setTotalPage(data.length);
    setstorydata(data);
    setLoader(false);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`story/delete`, { storyId: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          storyManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const [itemsdata, setItems] = useState([]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const themes = await postRequestForm(
      "venue/theme/list?page=0&limit=1000&sortBy=first_name&sortOrder=desc&q="
    );

    setItems(themes.data.list);
    setLoading(false);
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Venue Story Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Venue Story Management
                </li>
                : <li className="breadcrumb-item">Story List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link
              to={{
                pathname: `/createStory/${id}`,
              }}
            >
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={storydata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    remote={false}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default VenueStoryManagementlist;
