import React , {useEffect} from "react"

import { CountUp } from 'countup.js';


import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Button
} from "reactstrap"

import { 
  postRequest
  
} from "components/Common/Utils";

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import MonthlyEarnings3 from "./montly-earnings3";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ChartComponets from "./ChartData.js";
// import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import UniqueAnalytics from "./UniqueAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RecentlyActivity from "./RecentlyActivity";
import RevenueByLocations from "./RevenueByLocations";
import DeviceAnalytics from "./DeviceAnalytics";
import OnliUserByLoctions from "./OnliUserByLoctions";
import TopVenue from "./TopVenue";
import DashCard from "./DashCard";
import TopUser from "./TopUsers";
import Calendar from "./Calendar";



//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";

const temporaryData = {
  currentMonth: [
    { date: "2024-05-01", value: 100 },
    { date: "2024-05-02", value: 150 },
    { date: "2024-05-03", value: 200 },
    // Add more daily data as needed
  ],
  prevMonth: [
    { date: "2024-04-01", value: 80 },
    { date: "2024-04-02", value: 120 },
    { date: "2024-04-03", value: 160 },
    // Add more daily data as needed
  ],
  currentYear: [
    { month: "January", value: 2000 },
    { month: "February", value: 2500 },
    { month: "March", value: 3000 },
    // Add more monthly data as needed
  ],
  prevYear: [
    { month: "January", value: 1800 },
    { month: "February", value: 2200 },
    { month: "March", value: 2700 },
    // Add more monthly data as needed
  ],
  thisMonthTotal: 4500,
  prevMonthTotal: 3600,
  thisYearTotal: 30000,
  prevYearTotal: 29000
};

const defaultSalesData = [
  {
    "data": [10, 8, 6, 9, 11, 14, 8, 5, 10, 13, 14, 12],
    "title": "User Registration",
    "description": "user registered in last 12 months",
    "link": "/more-details",
    "count": "8,542"
  },
  {
    "data": [8, 6, 4, 7, 10, 12, 7, 4, 9, 12, 13, 11],
    "title": "Venue Added",
    "description": "venue added in last 12 months",
    "link": "/more-details",
    "count": "315"
  },
  {
    "data": [6, 4, 3, 6, 8, 10, 6, 3, 7, 20, 11, 9],
    "title": "Offer Created",
    "description": "offer created in last 12 months",
    "link": "/more-details",
    "count": "1,245"
  },
  {
    "data": [4, 3, 2, 4, 6, 8, 4, 2, 5, 8, 9, 12],
    "title": "Discount Claimed",
    "description": "discount claimed in last 12 months",
    "link": "/more-details",
    "count": "3,245"
  }
]


const Dashboard = (props) => {
   
    const location = useLocation();
    const state = location.state?.row;

    //console.log("state", state);

    const [dashCardStat, setDashCardStat] = React.useState([]);

    const [action, setAction] = React.useState("");

    const [salesData, setSalesData] = React.useState([]);

    const [countryWiseData, setCountryWiseData] = React.useState([]);
    const [apiRunning, setApiRunning] = React.useState(false);
    const [ calendarEvents, setCalendarEvents ] = React.useState([]);

    const [pieChartData, setPieChartData] = React.useState({});

    const [claimedAmount, setClaimedAmount] = React.useState(null);
    const [commisionEarned, setCommisionEarned] = React.useState(null);

    const [analyticsData, setAnalyticsData] = React.useState(null);  
    const [params , setParams] = React.useState({
      logstat: {
        monthChange: -1,
        yearChange: -1
      }
    });

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  const DashboardStat = async () => {    
    const selectedBusiness = localStorage.getItem("stored_venueId"); 

    const offerId = state?._id;

    setApiRunning(true);
    postRequest(`user/admin/dashboard-data`, {offerId}).then((data) => {
      let Counts = data?.data;  
      setDashCardStat(Counts.dashCard);
      let tempSales = [...salesData];
      tempSales = Counts.salesData;
      setSalesData(tempSales);
      setCountryWiseData(Counts.countryWiseData);
      setCalendarEvents(Counts.calendarData);
      setPieChartData(Counts.pieChartData);


      let tempClaimedAmount = {...claimedAmount};
      tempClaimedAmount = Counts.claimedAmount;

      ////console.log('tempClaimedAmount', tempClaimedAmount);
      setClaimedAmount(tempClaimedAmount)

      let tempCommissionAmount = {...commisionEarned};
      tempCommissionAmount = Counts.commisionEarned;
   
      setCommisionEarned(tempCommissionAmount);
      let tempStat = {...analyticsData};
      tempStat = Counts.logstat;
      setAnalyticsData(tempStat);  
      
      
    });
  };

  useEffect(() => {
    DashboardStat();
  }, [action],[params]);

  

  const reports = [
    { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" },
    { title: "Revenue", iconClass: "buffer", total: "$46,782", average: "-29%", badgecolor: "danger" },
    { title: "Average Price", iconClass: "tag-text-outline", total: "$15.9", average: "0%", badgecolor: "warning" },
    { title: "Product Sold", iconClass: "briefcase-check", total: "1890", average: "+89%", badgecolor: "info" },
  ]

  return (
    <React.Fragment>

    
<Row>
<Col className='12'>
            <div className="page-title-box">
              <h4>Analytics Data for { state?.title }</h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Offer Analytics</li>
                : <li className="breadcrumb-item">Analytic Counter</li>
              </ol>
            </div>
          </Col>

          {/* <div className="col-md-2 text-end">
            <Link to="/graph">
              {" "}
              <Button>
                
                {" "}
                <i class="fal fa-chart-line fs-5 text-light"></i> View Graph
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> */}


<Row>

  <DashCard offerId={state?._id} />

  {/* {salesData.map((item, index) => (
                <Col key={index} className='3' xl="3">
                    <YearlySales
                        data={item.data}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        count={item.count}
                    />
                </Col>
            ))} */}

        <Col xl="12">          
          <OnliUserByLoctions offerId={state?._id} />
        </Col>        

  {/* <Col xl="12">          
          <EmailSent />
        </Col>  */}

{/* <Row>
                                  <TopVenue /> 
                                </Row> */}
 <Row>

      <RevenueAnalytics offerId={state?._id} />

      <UniqueAnalytics offerId={state?._id} />


      <DeviceAnalytics offerId={state?._id} />

      <TopUser offerId={state?._id} />

</Row>





</Row>

  

      <Row>
                            <Col xl={12}>
                                {/* <Row>
                                    <MiniWidgets reports={this.state.reports} />
                                    
                                </Row> */}
                                {/* revenue Analytics */}
                                <Row>

                                  {/* { analyticsData && ( <RevenueAnalytics data={analyticsData} /> ) } */}

                                  {/* <RevenueAnalytics data={analyticsData} /> */}

                                </Row>

                                {/* <Row>
                                { commisionEarned && <MonthlyEarnings3 data={commisionEarned} /> }
                                </Row> */}

                            </Col>       

                             {/* <Col xl="12">

          { claimedAmount && <MonthlyEarnings2 data={claimedAmount} /> }

         
        </Col> */}
                     
                        </Row>
                        
                        <Row>
                            {/* sources */}
                            {/* <Sources/> */}

                            {/* recent activity */}
                            {/* <RecentlyActivity/> */}

                            {/* revenue by locations */}

                            {/* <Col xl={12}>
                              <RevenueByLocations countryData={countryWiseData} setAction={setAction}/>
                            </Col> */}

                            
                        </Row> 

                        <Row>
                                {/* <Col xl={9}>
                                    <Calendar events={calendarEvents} />                                    
                                </Col> */}

                                <Col xl={12}>
                                  <SalesAnalytics data={pieChartData} offerId={state?._id} />
                                </Col>
                          </Row>  
        


         <Col xl="12">

          {/* { claimedAmount && <MonthlyEarnings2 data={claimedAmount} /> } */}

         
        </Col>

      </Row>
      {/* <Row>

        <Col xl="4" lg="6">
          
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
         
          <RecentActivity />

        </Col>
        <Col xl="4">
          
          <WidgetUser />

         
          <YearlySales />
        </Col>
      </Row> */}

      

      <Row>
        {/* <Col xl="6">
         
          <LatestTransactions />
        </Col> */}

        {/* <Col xl="6">
         
          <LatestOrders />
        </Col> */}
      </Row>
      {/* <h3 style={{ textAlign: "center" }}>Custom Chart</h3> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
      <ChartComponets /> */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);