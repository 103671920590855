import React from "react";
import { FormGroup } from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import "./multi-select.scss";

function MultiselectCommon2({
  options,
  onSelect,
  onRemove,
  selectedValues,
  placeholder = "Search",
}) {
  return (
    <div>
      <FormGroup className="mb-3">
        <div className="">
          <Multiselect
            options={options} // Options to display in the dropdown
            displayValue="name" // Property name to display in the dropdown options
            onSelect={onSelect}
            onRemove={onRemove}
            selectedValues={selectedValues}
            placeholder={placeholder}
          />
        </div>
      </FormGroup>
    </div>
  );
}

export default MultiselectCommon2;
