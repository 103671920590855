import React from 'react';
import './EventCard.css'; // A CSS file to style the component

const EventCard = ({ event }) => {
  const {
    imageSrc,
    logoSrc,
    venueName,
    venueLocation,
    date,
    time,
    title,
    createdDate,
    usersInvited,
    extraGuests,
    status,
  } = event;

  const getStatusColor = () => {
    switch (status) {
      case 'upcoming':
        return '#E5007E';
      case 'completed':
        return 'green';
      case 'cancelled':
        return '#D8B735';
      default:
        return 'grey';
    }
  };

  const displayedUsers = usersInvited.slice(0, 5);
  const moreUsersCount = usersInvited.length - displayedUsers.length;

  return (
    <div className="event-card" style={{ borderColor: getStatusColor() }}>
        <div class="event-status" style={{ backgroundColor: getStatusColor() }}>{status}</div>
        <div class="top-half">
            <img className="event-image" src={imageSrc} alt={venueName} />
            <div className="text-container">
                <div className="venue-info">
                    <div className="event-logo"><img src={logoSrc} alt="Logo" /></div> 
                    <div className="text-info">
                         <h1 className="venue-name">{venueName}</h1>
                        <p className="venue-location">{venueLocation}</p> 
                    </div>                    
                </div>                

                <p className="event-date-time" style={{color: "#fff", fontSize: "18px"}}>
                    <span class="fas fa-calendar-alt" style={{marginRight: "12px", color: "#E5007E" }}></span>{date}
                </p> 

                <p className="event-date-time" style={{color: "#fff", fontSize: "18px"}}>
                    <span class="fas fa-clock" style={{marginRight: "12px", color: "#E5007E" }}></span>{time}
                </p> 
            </div>  
        </div> 
      <div style={{padding: "18px 6px", color: "#fff"}}>
        <h2 className="event-title">{title}</h2>
        <p className="created-date" style={{ fontSize: "1rem" }}>Created by: Demo</p>
        <p className="created-date" style={{ fontSize: "1rem" }}>Created date: {createdDate}</p>
        <hr style={{height: "2px", "backgroundColor": "#636363"}}/>
    </div>  

      <p style={{fontSize: "16px", color: "#636363"}}>Whos'In ?</p>

      <div className="event-users">
        <div className="invited-users">
            {displayedUsers.map((user, index) => (
            <div key={index} className="user-avatar">
                <img src={user.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}`} alt={user.name} />
                <span className="user-name">{user.name}</span>
                <div className="user-status-icon">
                {user.isIn ? (
                    <span className="fas fa-check-circle" style={{ color: "green" }}></span>
                ) : (
                    <span className="fas fa-question-circle" style={{ color: "yellow" }}></span>
                )}
                </div>
            </div>
            ))}
            {moreUsersCount > 0 && (
            <div className="more-users">
                +{moreUsersCount} more
            </div>
            )}
        </div>
        {extraGuests > 0 && (
            <div style={{display: "flex", flexDirection: "column"}}>
            <div className="extra-guests">
            <span className="extra-guests-count">{extraGuests}</span>           
            </div>
             <span className="extra-guests-label">Extra Guests</span>
             </div>
        )}
        </div>
     
      
    </div>
  );
};

export default EventCard;