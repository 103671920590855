import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './styles.css'; // Import your custom styles if any

// SVG Icons as React components (you can replace these with actual SVGs or import SVG files)
const UserIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
    </svg>
);

const BusinessIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8V4H8v4H2v14h20V8h-6zM10 6h4v2h-4V6zM4 10h16v10H4V10z"/>
    </svg>
);

const VenueIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 7c-1.1 0-2 .9-2 2v2H9v2h2v4H7v2h10v-2h-4v-4h2v-2h-1V9c0-1.1-.9-2-2-2z"/>
    </svg>
);

const OfferIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13v-2h-4V9h-2v2H9v2h4v2h2v-2h4z"/>
    </svg>
);

const DiscountIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 3h-17C2.67 3 2 3.67 2 4.5v15c0 .83.67 1.5 1.5 1.5h17c.83 0 1.5-.67 1.5-1.5v-15C22 3.67 21.33 3 20.5 3zm-1.5 15H5V5h14v13z"/>
    </svg>
);

const DealsIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2l-4 8h8l-4-8zm0 18l4-8H8l4 8z"/>
    </svg>
);
const Dashboard = ({ values }) => {
  const data = [
   
    // {
    //     color: '#FFB74D', // Darker shade for Total Offer
    //     icon: <OfferIcon />, // Total Offer
    //     label: 'Maximum Offer',
    //     value: values.maxOffers
    // },
   
    // {
    //     color: '#81C784', // Darker shade for Total Deals
    //     icon: <DealsIcon />, // Total Deals
    //     label: 'Venue',
    //     value: values?.totalVenue || 0
    // },

    {
        color: '#F48FB1', // Darker shade for Total Discount
        icon: <DiscountIcon />, // Total Discount
        label: 'Total Amount',
         value: (values?.totalAmount || 0).toFixed(2)
    },

     {
        color: '#FFF176', // Darker shade for Total Venue
        icon: <VenueIcon />, // Total Venue
        label: 'Commissions to 7NIGHTS',
        value: (values?.totalCommision || 0).toFixed(2)
    },

    {
        color: '#81C784', // Darker shade for Total Deals
        icon: <DealsIcon />, // Total Deals
        label: 'My Share',
        value: (values?.totalAmount || 0).toFixed(2) - (values.totalCommision || 0).toFixed(2)
    },


];

return (
    <Row>
        {data.map((item, index) => (
            <DashboardCard
                key={index}
                color={item.color}
                icon={item.icon}
                value={item.value}
                label={item.label}
            />
        ))}
    </Row>
);
};

const DashboardCard = ({ color, icon, value, label }) => {
    return (
        <Col md="3">
            <Card style={{ backgroundColor: color, borderRadius: '10px', textAlign: 'center' }}>
                <CardBody>
                    <div style={{ fontSize: '30px', marginBottom: '10px' }}>
                        {icon}
                    </div>
                    <h3 style={{ fontSize: '24px', marginBottom: '10px' }}>{value}</h3>
                    <p style={{ fontSize: '14px', color: '#6c757d' }}>{label}</p>
                </CardBody>
            </Card>
        </Col>
    );
};


export default Dashboard;
