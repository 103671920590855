import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequest } from "../../components/Common/Utils.js";

export default function BusinessUpdateRating({ ratingObj, onFormSubmit }) {
  const state = ratingObj;

  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [ratingData, setRatingData] = useState();

  const [loading, setLoading] = useState(false);

  const [selectedCover, setSelectedCover] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setRatingData(state);
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const Updaterating = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await putRequest("review/admin/update", {
      reviewId: state?._id,
      stars: ratingData.stars,
      review: ratingData.review,
      status: ratingData.status == "true" ? 1 : 0,
      type: "venues",
    });
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        onFormSubmit();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...ratingData };
    list[index] = value;
    setRatingData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Rating </h4>
                <Form onSubmit={Updaterating}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Stars
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("stars", e.target.value)
                              }
                              value={ratingData?.stars}
                              name="stars"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Review
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("review", e.target.value)
                              }
                              name="review"
                              value={ratingData?.review}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={ratingData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
