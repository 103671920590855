import { Modal } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import DraggableList from 'react-draggable-list';

const Item = ({ item, dragHandleProps, index }) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      <div
        className={`disable-select ${isDragging ? 'dragging' : ''}`}
        style={{
          border: "1px solid #e4dfdf",
          borderRadius: '5px',
          margin: "4px",
          padding: "15px",
          display: "flex",
          justifyContent: "space-around",
          background: "white",
          alignItems: "center",
          userSelect: "none"
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          //console.log("touchStart");
          // Change background color when touched
          e.target.style.backgroundColor = "blue";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          //console.log("mouseDown");
          setIsDragging(true); // Set dragging state to true
          onMouseDown(e);
        }}
        onTouchEnd={(e) => {
          // Reset background color when touch ends
          e.target.style.backgroundColor = "black";
          setIsDragging(false); // Set dragging state to false
        }}
        onMouseUp={() => {
          // Reset background color when mouse up
          setIsDragging(false); // Set dragging state to false
        }}
      >
        <span class="fas fa-grip-vertical" style={{ fontSize: "1.5rem", width: '20%' }}></span>
        <div style={{ width: '50%' }}>
          {item.title || item.name || item.label}
        </div>
        <div style={{ width: '30%' }}>
          <span className='text-center align-middle mb-0'>{item.type} - {item?.container}</span>           
        </div>
      </div>
    </>
  );
};

const DragDropHomeblockWebsite = ({ data, show, closeModal, setData }) => {
  // State for the list
  const [finalData, setFinalData] = useState(data);
  const [serverData, setServerData] = useState(data); // Assuming data is coming from the server
  const [list, setList] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      const dt = data[0]; // Assuming there's only one relevant document.

      setServerData({
        container_1 : dt.container_1,
        container_2 : dt.container_2,
        container_3 : dt.container_3,
        container_4 : dt.container_4,
      }); // Set the server data to state.
  
      // Initialize an empty array for the final data
      let fData = [];
  
      // Function to add items with their respective container name
      const addItemsWithContainerName = (items, containerName) => items.map(item => ({ ...item, container: containerName }));
  
      // Assuming dt.container_x_data already contains the items in the correct order
      // Append items from each container with their container name
      if (dt.container_1_data) {
        fData = [...fData, ...addItemsWithContainerName(dt.container_1_data, 'container-1')];
      }
      if (dt.container_2_data) {
        fData = [...fData, ...addItemsWithContainerName(dt.container_2_data, 'container-2')];
      }
      if (dt.container_3_data) {
        fData = [...fData, ...addItemsWithContainerName(dt.container_3_data, 'container-3')];
      }
      if (dt.container_4_data) {
        fData = [...fData, ...addItemsWithContainerName(dt.container_4_data, 'container-4')];
      }
  
      setFinalData(fData); // Set the final data to state.

  
      // If you need to maintain a separate list state with indexes, though it might be redundant
      const listDt = fData.map((item, index) => ({ ...item, index: index }));
      setList(listDt);
    }
  }, [data]);


  

  // Ref for the container
  const containerRef = useRef();

  // Handle list change
  const handleListChange = (newList) => {
    setList(newList);
  };

  const updateContainerNumbers = (updatedList) => {
    return updatedList.map((item, index) => {
      // Assuming every 3 items belong to a new container
      const containerNumber = Math.floor(index / 3) + 1;
      return { ...item, container: `container-${containerNumber}` };
    });
  };


  const handleDragEnd = (newList) => {
    // Update container numbers based on new list order
    const updatedListWithContainers = updateContainerNumbers(newList);
    setList(updatedListWithContainers);
  
    // Initialize empty arrays for each container's data
    let container_1_data = [], container_2_data = [], container_3_data = [], container_4_data = [];
  
    // Group items by their new container assignment
    updatedListWithContainers.forEach((item, index) => {
      const containerIndex = Math.floor(index / 3);
      switch (containerIndex) {
        case 0:
          container_1_data.push(item);
          break;
        case 1:
          container_2_data.push(item);
          break;
        case 2:
          container_3_data.push(item);
          break;
        case 3:
          container_4_data.push(item);
          break;
        // Add more cases if there are more than 4 containers
      }
    });
  
    // Update serverData with the new container assignments
    const newServerData = {
      ...serverData, // Spread existing serverData to maintain other properties
      container_1: container_1_data.map(item => item._id), // Assuming you need to save the IDs back to serverData
      container_2: container_2_data.map(item => item._id),
      container_3: container_3_data.map(item => item._id),
      container_4: container_4_data.map(item => item._id), 
    };
  
    setServerData(newServerData); // Update the serverData state
  
    // Optionally, if you need to propagate these changes back to the parent component or perform further actions
    // setData(newServerData); // This would be where you actually sync with the server or external state
  };
  

  return (
    <>
      <div className="" style={{ maxHeight: '500px', overflowY: 'auto' }} ref={containerRef}>
        <DraggableList
          itemKey="index"
          template={Item}
          list={list}
          onMoveEnd={handleDragEnd} // Use handleDragEnd for onMoveEnd callback
          container={() => containerRef.current}
        />
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <button
          onClick={() =>
            setData(serverData)
           ////console.log(serverData)
          } /* Set onClick to call setData(items) */
          className="btn btn-primary"
        >
          Save Order
        </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button
        onClick={() => window.open("https://www.whosin.me/", "_blank")}
        className="btn btn-primary"
      >
        Visit Website
      </button>


      </div>
    </>
  );
};

export default DragDropHomeblockWebsite;