import React, { useState, useEffect } from "react";
import { postRequestForm, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

import VenueModal from "./VenueModalOrg.js";
import OfferModal from "./OfferModalOrg.js";
import CategoryModal from "./CategoryModal.js";
import FileUpload from "components/Common/FileUpload.js";

import Swal from "sweetalert2";


const FormComponent = ({ addData, currentPackage, currentIndex }) => {
  const [packageData, setPackageData] = useState(currentPackage);
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [show, setShow] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [venueData, setVenueData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [bannerData, setBannerData] = useState(currentPackage);
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [venueId, setVenueId] = useState("");
  const [offerId, setOfferId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryText, setCategoryText] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryModel, setCategoryModel] = useState(false);
  const [categoryId, setCategoryId] = useState("");


  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const fetchVenueData = async (id) => {    
      const payload = {
        limit: 1,
        ids: [id],
      };        
      try {
        const response = await postRequest(`venue/list`, payload);
        const data = response.data;         
        const venue = data?.list[0];
        setVenueText(venue?.name);
        setVenueImage(venue?.logo);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } 
  };

  const fetchCategoryData = async (id) => {
    const payload = {
      limit: 1,
      ids: [id],
    };
    try {
      const response = await postRequest(`venue/category/list/admin`, payload);
      const data = response.data;
      const category = data?.list[0];

      setCategoryText(category?.title);
      setCategoryImage(category?.image);
      
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchOfferData = async (id) => {
    const payload = {
      limit: 1,
      ids: [id],
    };
    try {
      const response = await postRequest(`venue/offer/list/admin`, payload);
      const data = response.data;
      const offer = data?.list[0];
      setOfferText(offer?.title);
      setOfferImage(offer?.image);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  }

  useEffect(() => {
      if (currentPackage?.type == "venue") {
        setVenueId(currentPackage?.item);
        fetchVenueData(currentPackage?.item);
      } else if (currentPackage?.type == "offer") {
        setOfferId(currentPackage?.item);
        fetchOfferData(currentPackage?.item);       
      } else if (currentPackage?.type == "category") {
        setCategoryId(currentPackage?.item);
        fetchCategoryData(currentPackage?.item);
      }
      setPreviewImage(currentPackage?.image);

  }, [currentPackage]);

  const toggleModel = () => {    
    setModel(!model);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectOfferRow = (id, name, offer) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(offer?.image);
    closeOfferModal();
  };

  const closeCategoryModal = () => {
    setCategoryModel(false);
  };

  const selectCategoryRow = (id, name, category) => {
    setCategoryId(id);
    setCategoryText(name);
    setCategoryImage(category?.image);
    closeCategoryModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };  

  const updateValue = (index, value) => {
    
    if(index == "type"){
      setVenueText("");
      setVenueImage("");
      setOfferText("");
      setOfferImage("");
      setCategoryText("");
      setCategoryImage("");
      setVenueId("");
      setOfferId("");
      setCategoryId("");
    }

    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
    
  };

  const addDataPackage = async (e) => {
    e.preventDefault();

    // if(!bannerData?.title){
    //   alert("Please enter title");
    //   return;
    // }

    if(!bannerData?.type || bannerData?.type == "Select Banner Content Type"){      

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select banner content type",
      });


      return;
    }    

    if (bannerData?.type == "venue" && !venueId) {      

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select venue",
      });


      return;
    }

    if (bannerData?.type == "offer" && !offerId) {    

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select offer",
      });


      return;
    }

    if (bannerData?.type == "category" && !categoryId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select category",
      });

      return;
    }


    if(bannerData?.type == "link" && !bannerData?.link){
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please enter link",
      });

      return;
    }

    // button text 

    // if(!bannerData?.buttonText){
    //   alert("Please enter button text");
    //   return;
    // }   


    
    const selectedImageForm = new FormData();
    let finalImage = currentPackage?.image || "";

    if(selectedImage){
      setStartLoader(true);
      selectedImageForm.append("image", selectedImage);
      const selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );

      finalImage = selectedImageRes?.data?.url;
      setStartLoader(false);
    }

    

    addData({
      ...bannerData,
      image: finalImage,
      item: bannerData?.type == "venue" ? venueId : bannerData?.type == "offer" ? offerId : bannerData?.type == "category" ? categoryId : bannerData?.link     
   });

  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="table-shorting">
                <Form onSubmit={addDataPackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue(
                                  "description",
                                  e.target.value
                                )
                              }
                              value={bannerData?.description}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>   

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.type}
                              onChange={(e) => 
                                updateValue("type", e.target.value)
                              }
                              class="form-select"
                            >
                              <option>Select Banner Content Type</option>
                              <option value="link">Link</option>
                              <option value="venue">Venue</option>
                              <option value="category">Category</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div> 
                        {(bannerData?.type == "venue" || bannerData?.type == "offer" )  && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {(bannerData?.type == "category" || bannerData?.type == "offer") && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleCategoryModel()}
                            >
                              {categoryText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >
                                    Choose Category
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img src={categoryImage} style={{ width: "40px", height: "40px", border: "1px solid #ced4da", borderRight: "none", borderLeft: "none" }}></img>

                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >

                                    Choose Category
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              )}
                            </div>

                          </div>
                        )}

                        {bannerData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}




                        {bannerData?.type == "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                value={bannerData?.link}
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              value={bannerData?.buttonText}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {bannerData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>                      
                        



                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
        <OfferModal
          show={offerModel}
          closeOfferModal={closeOfferModal}
          selectRow={selectOfferRow}
          venueId={venueId}
          categoryId={categoryId}
        />

        <CategoryModal
          show={categoryModel}
          closeCategoryModal={closeCategoryModal}
          selectRow={selectCategoryRow}
        />

        <FileUpload message="File Uploading" status={startLoader} />

      </React.Fragment>
    </>
  );
};

const PackageModal = ({ show, closeModal, addData, currentPackage, currentIndex }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add Banner
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <FormComponent addData={addData} currentPackage={currentPackage} currentIndex={currentIndex} />
      </div>
    </Modal>
  );
};

export default PackageModal;
