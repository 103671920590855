import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import PreviewAd from "pages/ADsManagements/PreviewAd.js";
import PreviewAdRead from "pages/ADsManagements/PreviewAdRead.js";

const AdsPreviewModal = ({ show, closeModal, currentForm }) => {
  const [updatedForm, setUpdatedForm] = useState(currentForm);

  useEffect(() => {
    const tempForm = { ...currentForm };
    tempForm.size = tempForm?.size ? tempForm.size : "large";
    tempForm.title = tempForm.headline;
    tempForm.subHeading = tempForm.title;
    tempForm.subText = tempForm.subTitle;
    tempForm.description = tempForm.subHeadline;
    tempForm.badgeText = tempForm.badgeText;
    tempForm.buttonText = tempForm.buttonText;
    tempForm.item = tempForm.item;
    tempForm.background = tempForm.background;
    tempForm.logo = tempForm.logo;
    tempForm.type = tempForm.type;
    setUpdatedForm(tempForm);
  }, [currentForm]);

  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Ad Preview
        </h5>

        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className="modal-body"
        style={{
          minHeight: "80vh",
          background: "#ccc",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PreviewAdRead currentForm={updatedForm} />
      </div>
    </Modal>
  );
};

export default AdsPreviewModal;
