import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, format } from "date-fns";
import moment from "moment";
import OfferPackageModal from "../../../components/Modal/OfferPackageModal.js";
import FileUpload from "../../../components/Common/FileUpload";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm,
  deleteRequest,
  pushUpdates,
} from "../../../components/Common/Utils.js";
//import DateTimePicker from 'react-datetime-picker';

import PackageModal from "components/Modal/PackageModal.js";
import HolidayModal from "../../../components/Modal/HolidayModal.js";

import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";

import VenueModal from "../../../components/Modal/VenueModalOrg.js";
import Lightbox from "react-image-lightbox";
export default function UpdateOffer() {
  const location = useLocation();
  const state = location.state?.row;
  const today = new Date().toISOString().split("T")[0];
  const [offerData, setOfferData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [venueList, setVenueList] = useState([]);

  const [venueTimings, setVenueTimings] = useState([]);
  const [venueDays, setVenueDays] = useState([]);

  const [openPackage, setOpenPackage] = useState(false);

  const [dayList, setDayList] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [defaultDays, setDefaultDays] = useState([]);

  const [listFetched, setListFetched] = useState(false);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  const [model, setModel] = useState(false);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingIndex1, setEditingIndex1] = useState(null);
  const [currentPackageData, setCurrentPackageData] = useState({});

  const [modelHoliday, setModelHoliday] = useState(false);

  const [selectedHoliday, setSelectedHoliday] = useState([]);

  const selectRow = (id, name, venue) => {
    // setVenueId(id);
    // setVenueText(name);
    // setVenueImage(venue?.logo);
    // closeModalVenue();

    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings);

    const days = venue?.timings?.map((item) => item.day);
    setVenueDays(days);
    closeModalVenue();
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const closeModalVenue = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    setPackages(state.pack);
    setLoading(false);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  const toggleModal = () => {
    setOpen(!open);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  useEffect(() => {
    if (!state) return;

    state.allowWhosin = state.allowWhosIn ? 1 : 0;

    const parseTime = (dateTime) => {
      const dateObj = parseISO(dateTime);
      const date = format(dateObj, "yyyy-MM-dd"); // Formats to date string
      const time = format(dateObj, "HH:mm:ss"); // Formats to local time string

      return { date, time };
    };
    // Process start time and date


    // remove Z from time    
    state.startTime = state.startTime?.replace("Z", "") || "";
    state.endTime = state.endTime?.replace("Z", "") || "";


    if (state?.startTime) {
      const { date: startDate, time: startTime } = parseTime(state?.startTime);
      state.startDate = startDate;
      state.startTime = startTime;
    }


    if (state?.allowCliamed === "public-holiday") {
      setSelectedHoliday(state?.publicHolidays || []);
    }

    if (state?.endTime) {
      // Process end time and date
      const { date: endDate, time: endTime } = parseTime(state.endTime);
      state.endDate = endDate;
      state.endTime = endTime;
    }

    let days = state.days?.split(",") || [];




    if (state?.pack?.length) {

      // in state

      //console.log("state.pack", state.pack)

      const packages = state.pack.map((item) => {
        return {
          ...item,
          package_type: item.type || "regular",
          buyQty: item.buyQty || 0,
          getQty: item.getQty || 0
        }
      });


      setPackages(packages);

    }


    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venue?.logo);

    let daysFull = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];

    // Filter days based on `days` array from state
    days = daysFull.filter((dayFull) => days.includes(dayFull.id));

    setDefaultDays(days);

    //console.log("days", days);

    setSelectedDay(days.map((item) => item.id));

    setPreviewImage(state.image);
    setOfferData(state);
    setSelectedCategory(state?.categoryId ? state?.categoryId : []);
    // If you need to fetch data, call those functions here
    // fetchList();
    // fetchPackages();
  }, [state]);

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedVenue.length) return;
    if (listFetched) return;

    fetchList();
  }, [selectedVenue]);

  useEffect(() => {
    fetchCategoryList();
    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, [selectedCategory]);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
      setListFetched(true);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };

  const toggleModelHoliday = () => {
    setModelHoliday(!modelHoliday);
  };

  const closeModalHoliday = () => {
    setModelHoliday(false);
  };

  const selectHoliday = (items) => {

    setSelectedHoliday(items);
    closeModalHoliday();
    //console.log("items", items);

  };

  const deleteHoliday = (index) => {
    const newHoliday = selectedHoliday.filter((_, idx) => idx !== index);
    setSelectedHoliday(newHoliday);
  };




  function isTimeWithinTimings(offerDate, timings) {
    const offerTime = moment(offerDate);
    const openingTime = moment(
      `${offerDate.split("T")[0]}T${timings.openingTime}`
    );
    const closingTime = moment(
      `${offerDate.split("T")[0]}T${timings.closingTime}`
    );

    // Adjust for next day if closing time is earlier than opening time
    if (closingTime.isBefore(openingTime)) {
      closingTime.add(1, "day");
    }
    return offerTime.isBetween(openingTime, closingTime, null, "[]");
  }

  const search = location.search;
  const from = new URLSearchParams(search).get("from");
  const updateOffer = async (e) => {
    e.preventDefault();

    // combine date and time
    let startDate = null;
    let endDate = null;

    if (!venueId) {
      toast.error("Please select venue");
      return;
    }

    if (!offerData.title) {
      toast.error("Please enter title");
      return;
    }

    if (!selectedCategory.length) {
      toast.error("Please select category");
      return;
    }

    if (!selectedDay.length) {
      // mon tue
      // check default days
      toast.error("Please select days");
      return;
    }

    if (packages.some(pkg => pkg.isAllowClaim)) {
      if (!offerData.specialOffer_title) {
        toast.error("Please select Special Offer Title");
        return false;
      }
      if (!offerData.specialOffer_discription) {
        toast.error("Please select Special Offer Description");
        return false;
      }
      if (!offerData.specialOffer_discount) {
        toast.error("Please select Special Offer Discount");
        return false;
      }
    }

    // if (!offerData.startDate) {
    //   toast.error("Please select start date");
    //   return;
    // }

    // if (!offerData.endDate) {
    //   toast.error("Please select end date");
    //   return;
    // }

    // fetch day from start date & endDate and check is its one of the selected day
    const dayMap = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    // Fetching the day of the week from start and end dates
    const startDayOfWeek = dayMap[new Date(offerData.startDate).getDay()];
    const endDayOfWeek = dayMap[new Date(offerData.endDate).getDay()];

    // Checking if the day of the week is in the selected days
    //  if (!selectedDay.includes(startDayOfWeek)) {
    //    toast.error("Start date is not on a selected day");
    //    return;
    //  }

    //  if (!selectedDay.includes(endDayOfWeek)) {
    //    toast.error("End date is not on a selected day");
    //    return;
    //  }

    //console.log("venueTimings", venueTimings);

    const startDayTiming = venueTimings.find(
      (timing) => timing.day === startDayOfWeek
    );
    const endDayTiming = venueTimings.find(
      (timing) => timing.day === endDayOfWeek
    );

    // Checking timings for start and end days
    if (
      startDayTiming &&
      !isTimeWithinTimings(
        offerData.startDate + "T" + offerData.startTime,
        startDayTiming
      )
    ) {
      toast.error("Start time is not within the venue timings");
      return;
    }

    if (
      endDayTiming &&
      !isTimeWithinTimings(
        offerData.endDate + "T" + offerData.endTime,
        endDayTiming
      )
    ) {
      toast.error("End time is not within the venue timings");
      return;
    }

    if (offerData.startDate) {
      if (offerData.startTime) {
        startDate = new Date(offerData.startDate + " " + offerData.startTime);
      } else {
        startDate = new Date(offerData.startDate + " " + "00:00");
      }
    }

    if (offerData.endDate) {
      if (offerData.endTime) {
        endDate = new Date(offerData.endDate + " " + offerData.endTime);
      } else {
        endDate = new Date(offerData.endDate + " " + "00:00");
      }
    }

    // check if the start date is greater than end date

    if (startDate && endDate) {
      // if (startDate >= endDate) {
      //   toast.error("End date should be greater than start date");
      //   return;
      // }
    }

    if (!offerData.allowCliamed) {
      toast.error("Please select cliam on");
      return;
    }

    // if cliamed on = 'public-holiday' then check selected holiday

    if (offerData.allowCliamed === "public-holiday") {
      if (selectedHoliday.length === 0) {
        toast.error("Please select public holiday");
        return;
      }
    }

    const formData = new FormData();
    formData.append("title", offerData.title);
    formData.append("description", offerData.description);
    formData.append("discountTag", offerData.discountTag);
    formData.append("disclaimerTitle", offerData.disclaimerTitle);
    formData.append("disclaimerDescription", offerData.disclaimerDescription);
    formData.append(`specialOffer_title`, offerData.specialOffer_title,);
    formData.append(`specialOffer_discription`, offerData.specialOffer_discription);
    formData.append(`specialOffer_discount`, offerData.specialOffer_discount || 0);

    formData.append("allowCliamed", offerData.allowCliamed);


    const selectedHolidayIds = selectedHoliday.map((item) => item._id);

    selectedHolidayIds.forEach((id, index) => {
      formData.append(`publicHolidays[${index}]`, id);
    });


    formData.append("venue", venueId || "");

    // sort the selected days by day order
    selectedDay.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });
    formData.append("days", selectedDay.join(","));
    if (selectedCategory && selectedCategory.length > 0) {
      selectedCategory.forEach((item) => {
        formData.append("categoryId[]", item);
      });
    }

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    formData.append("allowWhosin", offerData.allowWhosin == "1" ? true : false);
    if (offerData.startDate) {
      formData.append("startTime", startDate);
    }
    if (offerData.endDate) {
      formData.append("endTime", endDate);
    }
    formData.append("offerId", state?._id || "");
    const finalPackages = packages.filter((pack) => pack.title && pack.amount);
    if (packages.length && finalPackages.length === 0) {
      toast.error("Please add atleast one package with title and amount");
      return;
    }
    finalPackages.forEach((item, index) => {
      formData.append(`packages_type[${index}]`, item.package_type || "regular");
      formData.append(`packages_id[${index}]`, item._id || "");
      formData.append(`packages_title[${index}]`, item.title);
      formData.append(`packages_amount[${index}]`, item.amount);
      formData.append(`packages_discount[${index}]`, item.discount || 0);

      formData.append(`packages_buyQty[${index}]`, item.buyQty || 0);
      formData.append(`packages_getQty[${index}]`, item.getQty || 0);


      // formData.append(`packages_claim_code[${index}]`, item.claimCode);
      formData.append(`packages_qty[${index}]`, item.qty || 0);
      formData.append(
        `packages_leftQtyAlert[${index}]`,
        item.leftQtyAlert || 0
      );
      formData.append(`packages_isAllowSale[${index}]`, item.isAllowSale || 0);
      formData.append(
        `packages_isAllowClaim[${index}]`,
        item.isAllowClaim || 0
      );
      formData.append(
        `packages_price_per_brunch[${index}]`,
        item.pricePerBrunch || 0
      );
      formData.append(`packages_featured[${index}]`, item.isFeatured || false);
      formData.append(`packages_isFeatured[${index}]`, item.isFeatured || false);
      formData.append(`packages_isFree[${index}]`, item.isFree || false);
      formData.append(
        `packages_description[${index}]`,
        item.packages_description || item.description || " "
      );
    });

    // packages.forEach((item, index) => {
    //   formData.append(`package_ids[]`, item._id);
    //   formData.append(`package_keys[]`, item.package_key);
    //   formData.append(`offer_discounts[]`, item.discount);
    // });

    setLoading(true);
    const response = await putRequestForm("venue/offer/update", formData);
    setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "offer",
          id: id,
          receivers: [],
        });
        // Check if the pushUpdates call was successful, handle the response as needed
        toast.success(response.message);

        setTimeout(() => {
          if (!from) history.goBack();
          else history.push("/venuedetails/" + venueId + "?active=Offers");
        }, 3000);
      } catch (pushUpdatesError) {
        console.error("Error in pushUpdates:", pushUpdatesError);
        toast.error("Error in pushUpdates");
      }
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;
    setOfferData(list);
  };

  const addPackage = () => {

    setCurrentPackageData({});

    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "",description:"", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {

    // check editingIndex is not -1
    let newPackages = [...packages];
    // If the new package is marked as featured, unselect any previously selected featured packages
    if (data.packages_isFeatured) {
      newPackages = newPackages.map(pkg => {
        pkg.packages_isFeatured = false
        return pkg;
      });
      // setPackages(newPackages);
    }
    if (editingIndex !== -1) {
      // const newPackages = [...packages];
      newPackages[editingIndex] = data;
      setPackages(newPackages);
      setCurrentPackageData({});
      setEditingIndex(-1);
      setOpenPackage(false);
      return;
    }

    setPackages([...newPackages, data]);
    setOpenPackage(false);
  };

  const updatePackageData = (index, field, value) => {

    // if (field === "discount" && (value == 0 || value < 5)) {
    //   alert("Discount is only allowed to be 0 or greater than 5");
    //   return; // Do not proceed with the update
    // }

    const newPackages = [...packages];
    newPackages[index][field] = value;
    setPackages(newPackages);
  };

  const saveEditedPackage = (index) => {
    // Add any validation logic here before saving

    if (packages[index].title === "") {
      toast.error("Please enter package title");
      return;
    }

    // check amount is number

    if (isNaN(packages[index].amount)) {
      toast.error("Amount must be number");
      return;
    }

    // check amount is not negative

    if (packages[index].amount < 1) {
      toast.error("Amount must be greater than 0");
      return;
    }

    // check discount 0 - 100

    if (packages[index].discount) {
      if (isNaN(packages[index].discount)) {
        toast.error("Discount must be number");
        return;
      }

      // check discount not be 0

      // if (packages[index].discount < 1) {
      //   toast.error("Discount must be greater than 0");
      //   return;
      // }

      if (packages[index].discount < 5) {
        toast.error("Discount must be 5 or greater than 5");
        return;
      }

      if (packages[index].discount > 99) {
        toast.error("Discount must be less than 100");
        return;
      }
    }

    // // cliam code must be 8 digit
    // if (packages[index].claimCode){
    //   if(packages[index].claimCode.length !== 8) {
    //     toast.error("Claim code must be  8 digit");
    //     return;
    //   }
    //   if (/^[A-Za-z]{2}\d{6}$/.test(packages[index].claimCode) === false) {
    //     toast.error("Claim code must be 2 letter and 6 digit");
    //     return;
    //   }
    // }

    setEditingIndex(null); // Reset editing index
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, idx) => idx !== index);
    setPackages(newPackages);
  };

  const editPackage = (index) => {
    setEditingIndex(index);
    let currentPackageDt = packages[index];
    setCurrentPackageData({ ...currentPackageDt });
    setOpenPackage(true);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  const [allAllowSale, setAllAllowSale] = React.useState(false);
  const allAllowSaleSelected = (e) => {
    let value = e.target.value;
    setAllAllowSale(!allAllowSale);
    if (!allAllowSale) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: false };
      });
      setPackages(updatedPackages);
    }
  };
  const [allAllowClaim, setAllAllowClaim] = React.useState(false);
  const allAllowClaimSelected = (e) => {
    let value = e.target.value;
    setAllAllowClaim(!allAllowClaim);
    if (!allAllowClaim) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: false };
      });
      setPackages(updatedPackages);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Update Offer</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update offer </h4>
                <Form onSubmit={updateOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              value={offerData.title}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* {offerData.description} */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={offerData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Discount Tag
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("discountTag", e.target.value)
                              }
                              value={offerData.discountTag}
                              name="discountTag"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={offerData?.disclaimerTitle}
                              onChange={(e) =>
                                updateValue("disclaimerTitle", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimerDescription", e.target.value)
                              }
                              value={offerData?.disclaimerDescription}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={categoryList}
                              onSelect={msUpdateCategory}
                              onRemove={msUpdateCategory}
                              selectedValues={defaultCategory}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => {
                              if (from) {
                                // Do nothing or handle the case where 'from' is present
                              } else {
                                toggleModel(); // Open the model when 'from' is not present
                              }
                            }}
                          >
                            {venueText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {venueText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              selectedValues={defaultDays}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate) {
                                  updateValue("startDate", selectedDate);
                                  // Update the minimum date for the End Date input
                                  const endDateInput =
                                    document.querySelector('[name="endDate"]');
                                  if (endDateInput) {
                                    endDateInput.min = selectedDate;
                                  }
                                }
                              }}
                              name="startDate"
                              value={offerData.startDate}
                              // min={offerData.startDate}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTime", e.target.value)
                              }
                              name="startTime"
                              value={offerData.startTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate >= offerData?.startDate) {
                                  updateValue("endDate", selectedDate);
                                }
                              }}
                              name="endDate"
                              className="form-control"
                              value={offerData.endDate}
                              min={offerData.startDate} // Set the minimum date to today
                            // value={formData.endDate}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTime", e.target.value)
                              }
                              name="endTime"
                              value={offerData.endTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Whosin
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowWhosin", e.target.value)
                              }
                              value={offerData.allowWhosin}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Cliamed On
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowCliamed", e.target.value)
                              }
                              value={offerData.allowCliamed}
                              name="allowWhosin"
                              className="form-select"
                            >
                              <option value="all-week" selected>All week</option>
                              <option value="weekeneds">Weekends</option>
                              <option value="weekdays">Weekdays</option>
                              <option value="public-holiday">Public Holidays</option>
                            </select>
                          </div>
                        </div>

                        {offerData?.allowCliamed === "public-holiday" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Holiday's List
                            </label>
                            <div className="col-sm-8">
                              <button
                                type="button"
                                onClick={toggleModelHoliday}
                                className="btn btn-primary mb-3"
                              >
                                Select Holiday
                              </button>
                              <div className="table-responsive">

                                <table className="table table-bordered">

                                  <thead>
                                    <tr>
                                      <th className="col-2">Holiday Name</th>
                                      <th className="col-2">Holiday Date</th>
                                      <th className="col-1">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {selectedHoliday.map((item, index) => (
                                      <tr key={index}>
                                        <td className="col-2">
                                          {item.title}
                                        </td>
                                        <td className="col-2">
                                          {item.date}
                                        </td>
                                        <td className="col-1">
                                          <button
                                            type="button"
                                            onClick={() => deleteHoliday(index)}
                                            className="btn btn-danger mb-3"
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </div>
                        )}



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-6">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Package Title</th>
                                  <th>Package Amount</th>
                                  <th>Package Sub title</th>
                                  <th>Offer Discount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr>
                                    <td>{item.title}</td>
                                    <td>
                                      {item.discountedPrice} / {item.time}
                                    </td>
                                    <td>{item.subTitle}</td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateDiscount(index, e.target.value)
                                        }
                                        value={item.discount}
                                        name="discount"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-8">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add Package
                            </button>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="col-2">Package Type</th>
                                    <th className="col-2">Package Title</th>
                                    <th className="col-2">
                                      Package Description
                                    </th>
                                    <th className="col-1">Package Amount</th>
                                    <th className="col-1">Package Discount</th>
                                    {/* <th className="col-1">Claim Code</th> */}
                                    <th className="col-1">Qty</th>
                                    <th className="col-1">Left Qty Alert On</th>
                                    <th className="col-1">Buy Qty</th>
                                    <th className="col-1">Get Qty</th>

                                    <th className="col-1">Claim Amount</th>
                                    <th className="col-1">is Featured </th>
                                    <th className="col-1">is Packages Free </th>
                                    <th className="col-1">
                                      Is Allow Sell{" "}
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          allAllowSaleSelected(e)
                                        }
                                      />
                                    </th>
                                    <th className="col-1">
                                      Is Allow Claim{" "}
                                      <input
                                        onChange={(e) =>
                                          allAllowClaimSelected(e)
                                        }
                                        type="checkbox"
                                      />
                                    </th>
                                    {/* <th className="col-1">Is Featured</th> */}
                                    <th className="col-1">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packages.map((item, index) => (
                                    <tr key={index}>

                                      <td className="col-2">
                                        {item?.package_type || "Regular"}
                                      </td>


                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            value={item.title}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "title",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.title
                                        )}
                                      </td>
                                      <td className="col-2">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="text"
                                            defaultValue={
                                              item.packages_description
                                                ? item.packages_description
                                                : item.description
                                            }
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "packages_description",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : item.packages_description ? (
                                          item.packages_description
                                        ) : (
                                          item.description
                                        )}
                                      </td>
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.amount}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "amount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.amount
                                        )}
                                      </td>
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.discount || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "discount",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.discount || 0
                                        )}
                                      </td>
                                      {/* <td className="col-1">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={item.claimCode}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "claimCode",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.claimCode
                                        )}
                                      </td> */}
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            style={{ minWidth: "50px" }}
                                            value={item.qty || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "qty",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.qty || 0
                                        )}
                                      </td>
                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            style={{ minWidth: "50px" }}
                                            value={item.leftQtyAlert || 0}
                                            max={item.qty}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "leftQtyAlert",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.leftQtyAlert || 0
                                        )}
                                      </td>

                                      <td className="col-2">
                                        {item?.buyQty || 0}
                                      </td>

                                      <td className="col-2">
                                        {item?.getQty || 0}
                                      </td>


                                      <td className="col-1">
                                        {editingIndex1 === index ? (
                                          <input
                                            type="number"
                                            value={item.pricePerBrunch || 0}
                                            onChange={(e) =>
                                              updatePackageData(
                                                index,
                                                "pricePerBrunch",
                                                e.target.value
                                              )
                                            }
                                            className="form-control"
                                          />
                                        ) : (
                                          item.pricePerBrunch || 0
                                        )}
                                      </td>

                                      <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isFeatured}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "packages_isFeatured",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isFree
                                          }
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "packages_isFree",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>

                                      <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isAllowSale}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "isAllowSale",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>
                                      <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isAllowClaim}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "isAllowClaim",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td>

                                      {/* <td className="col-1">
                                        <input
                                          type="checkbox"
                                          checked={item.isFeatured}
                                          onChange={(e) =>
                                            updatePackageData(
                                              index,
                                              "isFeatured",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </td> */}

                                      <td className="col-1">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* {editingIndex === index ? (
                                            <button
                                              type="button"
                                              className="btn btn-success btn-sm me-1"
                                              onClick={() => {
                                                saveEditedPackage(index);
                                              }}
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <span
                                              role="button"
                                              className="btn btn-primary btn-sm me-1"
                                              onClick={() => {
                                                setEditingIndex(index);
                                              }}
                                            >
                                              Edit
                                            </span>
                                          )} */}

                                          <i
                                            className="fas fa-pencil-alt"
                                            onClick={() => editPackage(index)}
                                            style={{ cursor: "pointer", color: "#E22A2A" }}
                                          ></i>


                                          <i
                                            className="fas fa-trash-alt mx-3"
                                            onClick={() => deletePackage(index)}
                                            style={{ cursor: "pointer", color: "red" }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        {packages.some(pkg => pkg.isAllowClaim) && (<>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Title
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                defaultValue={offerData?.specialOffer_title}
                                onChange={(e) =>
                                  updateValue("specialOffer_title", e.target.value)
                                }
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Description
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                type="textarea"
                                onChange={(e) =>
                                  updateValue("specialOffer_discription", e.target.value)
                                }
                                defaultValue={offerData?.specialOffer_discription}
                                name="specialOffer_discription"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Special Offer Discount
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                defaultValue={offerData?.specialOffer_discount}
                                onChange={(e) =>
                                  updateValue("specialOffer_discount", e.target.value)
                                }
                                name="specialOffer_discount"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>)}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />


      <HolidayModal
        showUser={modelHoliday}
        closeUserModal={closeModalHoliday}
        onSelectUser={selectHoliday}
      />


      <OfferPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
        currentPackageData={currentPackageData}
      />
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
      {/* modal */}
    </React.Fragment>
  );
}
