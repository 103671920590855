import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { postRequest } from "../../../components/Common/Utils";
import "./dashboard.scss";

class VenueAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: this.getChartOptions(),
            totals: {
                thisYearTotal: 0,
                prevYearTotal: 0,
            },
            percentages: {
                yearChange: 0
            },
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async fetchData() {
        const venueId = localStorage.getItem("stored_venueId");
        try {
            const response = await postRequest(`log/venue-log-stat-offer`, { venueId });
            const data = response.data;

            const currentYearData = data.currentYear.map(item => item.value);
            const prevYearData = data.prevYear.map(item => item.value);

            const series = [
                { name: 'Current Year', data: currentYearData, type: 'bar' },
                { name: 'Previous Year', data: prevYearData, type: 'bar' }
            ];

            const totals = {
                thisYearTotal: data.thisYearTotal,
                prevYearTotal: data.prevYearTotal,
            };

            const percentages = this.calculatePercentages(totals);

            this.setState({
                series,
                options: this.getChartOptions(data),
                totals,
                percentages
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    calculatePercentages(totals) {
        const calculateChange = (current, previous) => {
            if (previous === 0) return 0;
            return (((current - previous) / previous) * 100).toFixed(1);
        };

        return {
            yearChange: calculateChange(totals.thisYearTotal, totals.prevYearTotal),
        };
    }

    getChartOptions(data = { currentYear: [], prevYear: [] }) {
        const categories = data.currentYear.map(item => item.offerName);

        return {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '10%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Values'
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            colors: ['#5664d2', '#3DDC84'],
            fill: {
                type: ['solid', 'gradient'],
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ['#87D4F9'],
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 90, 100]
                }
            },
        };
    }

    render() {
        const { series, options, totals, percentages } = this.state;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-2">Analytics (By Offer)</h4>
                        <div id="bar-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart options={options} series={series} type="bar" height="350" />
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={6}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Year :</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{totals.thisYearTotal}</h5>
                                        <div className={percentages.yearChange >= 0 ? "text-success" : "text-danger"}>
                                            <i className={`mdi mdi-menu-${percentages.yearChange >= 0 ? "up" : "down"} font-size-14`}> </i>
                                            {percentages.yearChange} %
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Year :</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0">{totals.prevYearTotal}</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default VenueAnalytics;
