import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import Lightbox from "react-image-lightbox";

import FilterModal from "../../components/Modal/FilterModal";

import {
  postRequest,
  deleteRequest,
  putRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const filterObj = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  createdAt: "",
};

const ContactUsList = (props) => {
  const  permissions  = props.permissions || { permissions: { create: false, update: false, delete: false, show: false } };
  const [modal_center, setmodal_center] = useState(false);
  const [messagedata, setMessageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [selectedItems, setselectedItems] = useState([]);
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  useEffect(() => {
    messageManagment();
  }, [currentPage]);

  const messageManagmentSearch = async () => {
    if(currentPage == 1){
      messageManagment();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage == 1) {
      messageManagment();
      return;
    }
    setCurrentPage(1);
  }, [sort, sortOrder, sizePerPageOrg]);  

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value;

    const res = await putRequest("venue/update", {
      contactUsId: actId,
      status: status,
    });

    if (res.status == 1) {
      toast.success("Message Status updated Successfully");
      messageManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {    
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);        
      }
    } 

   
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {   
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "name" ||
          key === "phone" ||
          key === "email" ||
          key === "message" ||
          key === "subject"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    return filterArgs;
    
  };
  const applyFilter = () => {   
    setFilterActive(true)  
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "name" ||
          key === "phone" ||
          key === "email" ||
          key === "message" ||
          key === "subject"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });   
    toggleModel();
    messageManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false);
    setFilter({ ...filterObj });    
    toggleModel();
    messageManagment(null, true);
  };

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status === true ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange(0, row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange(1, row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.name}
          onChange={(e) => updateFilter("name", e.target.value)}
          name="name"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="email">
      <label className="col-sm-3 col-form-label">Email</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.email}
          onChange={(e) => updateFilter("email", e.target.value)}
          name="email"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="phone">
      <label className="col-sm-3 col-form-label">Phone</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.phone}
          onChange={(e) => updateFilter("phone", e.target.value)}
          name="phone"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="subject">
      <label className="col-sm-3 col-form-label">Subject</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.subject}
          onChange={(e) => updateFilter("subject", e.target.value)}
          name="subject"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="message">
      <label className="col-sm-3 col-form-label">Message</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.message}
          onChange={(e) => updateFilter("message", e.target.value)}
          name="message"
          className="form-control"
        />
      </div>
    </div>,   

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: false,
    },
    {
      dataField: "message",
      text: "Message",
      sort: false,
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: false,
    },   

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");
  };

  useEffect(() => {
    if (!resetSearch) return;
    if (currentPage == 1) {
      messageManagment([], true);
      return;
    }
    setCurrentPage(1);
  }, [resetSearch]);

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <Tippy content="Message">
            <button type="button" className=" btn btn-primary mx-1">
              <Link
                to={{
                  pathname: `/messages`,
                  state: { row },
                }}
              >
                <i className="fal fa-comment text-light"></i>
              </Link>
            </button>
          </Tippy>
          
          <Tippy content="Reply Message">
            <button type="button" className=" btn btn-secondary  mx-2">
              {" "}
              <Link
                to={{
                  pathname: `/addMessage/${row._id}`,
                  state: { row },
                }}
              >
                {" "}
                <i class="fal fa-reply fs-5 text-light"></i>
              </Link>{" "}
            </button>
          </Tippy>
          {permissions.delete && (
          <Tippy content="Delete">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const messageManagment = async (filterArgs = [], resetFilter = false) => {    
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    if (filterActive && !filterArgs?.length && !resetFilter) {
      payload.filterArgs = fetchFilter();
    }
    try {
      const response = await postRequest(`comman/contact-us/query-list-admin`,payload);
      const data = response.data;
      if (resetSearch) {
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setMessageData(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this message",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFeature(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFeature = async (id) => {
    const response = await deleteRequest("comman/contact-us/query-delete", {
      conctactUsId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      messageManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("comman/contact-us/query-delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      messageManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Contact Us</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Contact Us</li>:{" "}
                <li className="breadcrumb-item">Message List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">            
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={messagedata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>

                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="name">Name</option>
                                    <option value="email">Email</option>
                                    <option value="phone">Phone</option>
                                    <option value="subject">Subject</option>
                                    <option value="message">Message</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        messageManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => (
                                      <div>No data available</div>
                                    )}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default ContactUsList;
