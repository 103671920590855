import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Spinner, Button } from 'reactstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import VenueModal from "components/Modal/VenueModal.js";
import PaymentModal from './PaymentModal'; // Import the PaymentModal component
import { postRequest } from "../../../components/Common/Utils.js";
// import "./dashboard.scss";

import moment from "moment"; // Import moment.js for date formatting
import "./Subscriptionlist.css"; // Import your custom CSS file

const Subscriptionlist = () => {
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState({});
  const [showPricing, setShowPricing] = useState(false);
  const [showAccount, setShowAccount] = useState(true);
  const [loader, setLoader] = useState(false);
  const [model, setModel] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const selectedBid = localStorage.getItem("stored_bussinessId");

  const handleChangePlan = () => {
    setShowPricing(true);
  };

  // const handleCancel = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Are you sure you want to cancel your package?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setShowAccount(false);
  //       setShowPricing(false);
  //       Swal.fire("Your package has been canceled!", {
  //         icon: "success",
  //       });
  //     }
  //   });
  // };

  // const handleSelectPlan = (selectedPlan) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     html: `Are you sure you want to get <b style="color: #e3232b;">${selectedPlan.type.toUpperCase()}</b> subscription?`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         subscriptionId: selectedPlan._id,
  //         venueId: venueId
  //       };
  //       try {
  //         const response = await postRequest(`subscription/venue/order/create`, payload);
  //         const data = response.data;
  //         toast.success(response.message);
  //       } catch (error) {
  //         console.error("Failed to fetch data:", error);
  //       } finally {
  //         setLoader(false);
  //       }
  //     }
  //   });
  // };

  const handleCancel = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to cancel your package?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then( async (result) => {
      if (result.isConfirmed) {
        const response = await postRequest(`subscription/venue/order/cancel`, {venueId: venueId});
        if (response.status) {
          setShowAccount(false);
          setShowPricing(false);
          Swal.fire("Your package has been canceled!", {
            icon: "success",
          });
          return window.location.reload();
        }
        Swal.fire("Failed to cancel your package!", {
          icon: "error",
        });
      }
    });
  };

  const handleSelectPlan = (selectedPlan) => {
    Swal.fire({
      title: "Are you sure?",
      html: `Are you sure you want to ${selectedPlan.amount > (activePlan?.subscription?.amount || 0) ? 'upgrade' : 'downgrade'} to <b style="color: #e3232b;">${selectedPlan.type.toUpperCase()}</b> subscription?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedPlan(selectedPlan);
        setIsPaymentModalOpen(true); // Open the payment modal
      }
    });
  };


  const SubscriptionPlansManagement = async () => {
    setLoader(true);
    const payload = {
      limit: 1000000,
      page: 1,
    };
    try {
      const response = await postRequest(`subscription/venue/list`, payload);
      const data = response.data;
      if (data) {
        setPlans(data?.list || [])
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchActivePlan = async () => {
    setLoader(true);
    const payload = {
      venueId: venueId
    };
    try {
      const response = await postRequest(`subscription/fetch-venue-subscription`, payload);
      const data = response?.data;
      if (data != null) {
        setActivePlan(data)
      } else {
        setShowAccount(false)
        setShowPricing(true)
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    SubscriptionPlansManagement();
    fetchActivePlan();
  }, [venueId]);

  const selectVenueRow = (venue) => {
    setVenueId(venue?._id);
    setVenueText(venue?.name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const getButtonLabel = (plan) => {

    //console.log("plan", plan)

    if (plan._id === activePlan?.subscriptionId) {
      return "Subscribed";
    }
    if (plan.amount > activePlan?.subscription?.amount) {
      return "Upgrade to";
    }
    return "Select Plan";
  };

  const isButtonDisabled = (plan) => {
    if (plan._id === activePlan?.subscriptionId) {
      return true;
    }
    if (activePlan?.subscription && plan.amount < activePlan?.subscription.amount) {
      return true;
    }
    return false;
  };

  const getDaysRemaining = (validTill) => {
    const now = moment();
    const expiration = moment(validTill);
    return expiration.diff(now, 'days');
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <div className="col-sm-3" onClick={() => toggleModel()}>
          {venueText ? (
            <div class="input-group">
              <button
                class="btn"
                style={{
                  border: "1px solid #ced4da",
                  background: "#E22A2A",
                  color: "#ffff",
                }}
                type="button"
                id="chooseVenueBtn"
                onClick={() => toggleModel()}
              >
                Choose Venue
              </button>
              <div
                style={{
                  border: "1px solid #ced4da",
                  width: "16px",
                }}
              ></div>
              <img
                src={venueImage}
                style={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid #ced4da",
                  borderRight: "none",
                  borderLeft: "none",
                }}
              ></img>
              <input
                type="text"
                style={{ borderLeft: "none" }}
                class="form-control"
                readOnly
                value={venueText}
                id="selectedVenue"
                placeholder="No venue chosen"
              />
            </div>
          ) : (
            <div class="input-group">
              <button
                class="btn"
                style={{
                  border: "1px solid #ced4da",
                  background: "#E22A2A",
                  color: "#ffff",
                }}
                type="button"
                id="chooseVenueBtn"
                onClick={() => toggleModel()}
              >
                Choose Venue
              </button>
              <input
                type="text"
                class="form-control"
                readOnly
                id="selectedVenue"
                placeholder="No Venue chosen"
              />
            </div>
          )}
        </div>

        <Row>
          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              {venueId && showAccount && activePlan && (
                <div className="account-info">
                  <>
                    <h3>Account Information</h3>
                    <p>Current Subscription: <span>{activePlan?.subscription?.type.toUpperCase()}</span></p>
                    <p>Next Payment Date: <span>{activePlan?.validTill?.slice(0, 10)} {activePlan?.validTill?.slice(11, 16)}</span></p>
                    <p>Days Remaining: <span>{getDaysRemaining(activePlan?.validTill)} days</span></p>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      {new Date(activePlan?.validTill) > new Date() ?
                        <>
                          <button className="button mt-0" onClick={handleChangePlan}>Upgrade</button>
                          <button className="button mt-0" onClick={handleCancel}>Cancel</button>
                        </>
                        : <>
                          <button className="button mt-0" onClick={handleChangePlan}>Plan Expired, Click to view plans</button>
                        </>}
                    </div>
                  </>
                </div>
              )}

              {venueId && showPricing && plans && (
                <div className="pricing-table">
                  {plans?.map((plan, index) => (
                    <div key={index} className={`pricing-plan ${plan?.type?.toLowerCase()}`}>
                      <h3>{plan?.type.toUpperCase()}</h3>
                      <p>{plan?.amount} {plan?.currency || "AED"} / {plan?.validity == "monthly" ? <>month</> : <>{plan?.validity == "yearly" ? <>year</> : <>3 month</>}</>} </p>
                      <ul>
                        {plan?.isPromoteAllowed ? <li >Get Access to Promote</li> : <></>}
                        {plan?.isPromoteTop5 ? <li >Will be Promoted in Top 5</li> : <></>}
                        {plan?.isManageBy7n ? <li >Managed By 7NIGHTS</li> : <></>}
                        {plan?.haveAnalytics ? <li >Get Analysis</li> : <></>}
                        {plan?.isOfferAddAllowed ? <li >Get acccess to add offers</li> : <><li >No add offer acccess</li></>}
                        {plan?.isOfferAddAllowed && (
                          <>
                            {plan?.offerAddLimit == 0 ? <li >Unlimited offer can be add</li> : <li >Only {plan?.offerAddLimit} offer can be add</li>}
                            {plan?.offerAddCharge == 0 ? <li >No charges on offer add</li> : <li >Have to pay {plan?.offerAddCharge} AED per offer add </li>}
                            {plan?.offerAddFree != 0 ? <li >You can add {plan?.offerAddFree} without any charges</li> : <></>}
                            {plan?.commissionOnOfferPurchase == 0 ? <li >No commission on offer purchase</li> : <li >{plan?.commissionOnOfferPurchase} percent commission will be charged </li>}
                          </>
                        )}
                        {plan?.isAddStoryAllowed ? <li >Get acccess to add stories</li> : <><li >No add stories acccess</li></>}
                        {plan?.isAddStoryAllowed && (
                          <>
                            {plan?.addStoryLimit == 0 ? <li >Unlimited stories can be add</li> : <li >Only {plan?.addStoryLimit} stories can be add</li>}
                            {plan?.addStoryCharge == 0 ? <li >No charges on stories add</li> : <li >Have to pay {plan?.addStoryCharge} AED per stories add </li>}
                            {plan?.addStoryFree != 0 ? <li >You can add {plan?.addStoryFree} without any charges</li> : <></>}
                          </>
                        )}
                      </ul>
                      {/* <button
                        className="button"
                        onClick={() => handleSelectPlan(plan)}
                        disabled={isButtonDisabled(plan)}
                      >
                        {getButtonLabel(plan)}
                      </button> */}
                       <Button
                        className="button"
                        onClick={() => handleSelectPlan(plan)}
                        disabled={activePlan.subscription && plan._id === activePlan.subscription._id}
                      >
                        {activePlan.subscription && plan._id === activePlan.subscription._id
                          ? 'Current Plan'
                          : plan.amount > (activePlan?.subscription?.amount || 0)
                            ? 'Upgrade Plan'
                            : 'Downgrade Plan'}
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Row>
        <VenueModal show={model} closeModal={closeModal} selectRow={selectVenueRow} bId={selectedBid} />

        {selectedPlan && (
          <PaymentModal
            isOpen={isPaymentModalOpen}
            onRequestClose={() => setIsPaymentModalOpen(false)}
            plan={selectedPlan}
            venueId={venueId}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default Subscriptionlist;
