import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { postRequest } from "./Utils.js";
import { Link } from "react-router-dom";
import moment from 'moment';

const ActiveSubscription = () => {
  const [activePlan, setActivePlan] = useState(null);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const vid = localStorage.getItem("stored_venueId");

  const fetchActivePlan = async () => {
    setLoader(true);
    try {
      const response = await postRequest(`subscription/fetch-venue-subscription`, { venueId: vid });
      const data = response?.data;
      if (data != null && data.subscriptionId) {
        setActivePlan(data);
      } else {
        setActivePlan(null);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchActivePlan();
  }, [vid]);

  const handleRedirect = () => {
    window.location.href = '/venue-account/get-subscription'; // Update this URL to your subscription page
  };

  // const getRemainingDays = (validTill) => {
  //   const now = moment();
  //   const end = moment(validTill);
  //   return end.diff(now, 'days');
  // };
  const getRemainingDays = (validTill) => {
    const now = moment().startOf('day'); // Start of today
    const end = moment(validTill).startOf('day'); // Start of validTill day
    const daysRemaining = end.diff(now, 'days');
    return daysRemaining < 0 ? 0 : daysRemaining;
  };

  const getPaymentStatus = (validTill) => {
    const now = moment().startOf('day'); // Start of today
    const end = moment(validTill).startOf('day'); // Start of validTill day
    return end.isBefore(now) ? `Payment Failed on ${moment(validTill).format('MMMM Do, YYYY')}` : moment(validTill).format('MMMM Do, YYYY');
  };
  

  return (
    <React.Fragment>
      <ToastContainer />
      {loader ? (
        <CardBody style={{ height: "100px" }}>
          <Spinner
            color="info"
            style={{
              height: "4rem",
              width: "4rem",
              position: "absolute",
              left: "50%",
            }}
          >
            Loading...
          </Spinner>
        </CardBody>
      ) : (
        <Card className="" style={{ fontFamily: 'Arial, sans-serif' }}>
          <CardBody className="custom-card-body">
            {activePlan ? (
              <>
                <Row>
                  <Col md="12">
                    <div className="card-statistics">
                      <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{activePlan?.subscription?.type.toUpperCase()}</h3>
                      <p className="text-muted" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        {/* Next Payment Date: {moment(activePlan?.validTill).format('MMMM Do, YYYY')} */}
                        Next Payment Date: <strong>{getPaymentStatus(activePlan?.validTill)}</strong>
                      </p>
                      <p className="text-muted" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        Days Remaining: <strong>{getRemainingDays(activePlan?.validTill)} Days </strong>
                      </p>
                      <Button color="primary" onClick={toggleModal} style={{ fontSize: '1rem' }}>
                        Learn more <i className="mdi mdi-chevron-double-right"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h4 className="card-title mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>No Active Subscription</h4>
                <Row>
                  <Col md="12">
                    <div className="card-statistics">
                      <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>No Plan / Pay as Go</h3>
                      <p className="text-muted" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        You currently do not have an active subscription plan. Please subscribe to a plan to enjoy our services.
                      </p>
                      <Button color="primary" onClick={handleRedirect} style={{ fontSize: '1rem' }}>
                        Go to Subscription Page
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      )}

      {/* Modal for subscription details */}
      {activePlan && (
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Subscription Details</ModalHeader>
          <ModalBody>
            <h5>Plan Information</h5>
            <p><strong>Type:</strong> {activePlan?.subscription?.type.toUpperCase()}</p>
            {/* <p><strong>Offers Allowed:</strong> {activePlan?.subscription?.OfferlistingLimit}</p> */}
            <p><strong>Offers Allowed:</strong>  {activePlan?.subscription?.OfferlistingLimit >= 0  ? activePlan?.subscription?.OfferlistingLimit  : '0'} </p>

            <p><strong>Additional Offer Cost:</strong> AED {activePlan?.subscription?.offerAddCharge} per offer/month</p>
            <p><strong>Dashboard Access:</strong> {activePlan?.subscription?.isDashboard ? 'Yes' : 'No'}</p>
            {/* Add more plan details as required */}

            <hr />

            <h5>Payment Information</h5>
            <p><strong>Amount:</strong> AED {activePlan?.amount}</p>
            <p><strong>Payment Status:</strong> {activePlan?.paymentStatus}</p>
            <p><strong>Stripe Status:</strong> {activePlan?.stripeStatus}</p>
            <p><strong>Valid From:</strong> {moment(activePlan?.validFrom).format('MMMM Do, YYYY')}</p>
            <p><strong>Next Payment:</strong> {moment(activePlan?.validTill).format('MMMM Do, YYYY')}</p>
            {/* Add more payment details as required */}

            <hr />

            <h5>Usage Information</h5>
            {/* <p><strong>Offers Created:</strong> {activePlan?.limit?.offersCreated}</p>
            <p><strong>Offers Remaining:</strong> {activePlan?.limit?.offersRemaining}</p> */}

            <p><strong>Offers Created:</strong> {activePlan?.limit?.offersCreated >= 0 
                ? activePlan?.limit?.offersCreated 
                : '0'}
            </p>
            <p><strong>Offers Remaining:</strong> {activePlan?.limit?.offersRemaining >= 0 
                ? activePlan?.limit?.offersRemaining 
                : '0'}
            </p>

            {/* { activePlan?.limit?.storiesRemaining ? ( <>
            <p><strong>Stories Created:</strong> {activePlan?.limit?.storiesCreated}</p>  
            <p><strong>Stories Remaining:</strong> {activePlan?.limit?.storiesRemaining}</p> </>) : null } */}

            { activePlan?.limit?.storiesRemaining ? ( <>
            <p><strong>Stories Created:</strong> {activePlan?.limit?.storiesCreated >= 0 ? activePlan?.limit?.storiesCreated : '0'}</p>
            <p><strong>Stories Remaining:</strong> {activePlan?.limit?.storiesRemaining >= 0 ? activePlan?.limit?.storiesRemaining : '0'}</p> </>) : null }

             

            {/* { activePlan?.limit?.pushNotificationRemaining ? (
              <>
              <p><strong>Push Notifications Created:</strong> {activePlan?.limit?.pushNotificationCreated}</p>
              <p><strong>Push Notifications Remaining:</strong> {activePlan?.limit?.pushNotificationRemaining}</p>
              </>
            ) : null} */}

            { activePlan?.limit?.pushNotificationRemaining ? (
              <>
              <p><strong>Push Notifications Created:</strong> {activePlan?.limit?.pushNotificationCreated >= 0 ? activePlan?.limit?.pushNotificationCreated : '0'}</p>
              <p><strong>Push Notifications Remaining:</strong> {activePlan?.limit?.pushNotificationRemaining >= 0 ? activePlan?.limit?.pushNotificationRemaining : '0'}</p></> ) : null}

            
            {/* Add more usage details as required */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ActiveSubscription;
