import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import {
  postRequest,
  putRequest,
  deleteRequest,
  callCSVRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone  
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const filterObj = {
  name: "",
  email: "",
  phone: "",
  status: "",
  startCreatedAt: "",
  endCreatedAt: "",
};

const OnBoardingManagementlist = (props) => {
  const  permissions  = props.permissions || { permissions: { create: false, update: false, delete: false, show: false } };
  const [resetSearch, setResetSearch] = useState(false);
  const [businessdata, setbusinessdata] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(20);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

//   useEffect(() => {
//     businessManagment();
//   }, [currentPage]);

//   useEffect(() => {
//     if (currentPage == 1){
//     businessManagment();
//     return;
//   }
//   setCurrentPage(1);
//  }, [sizePerPageOrg, sort, sortOrder]);

//   useEffect(() => {  
//     if(!resetSearch) return;
//     if (currentPage == 1) {
//       businessManagment([], true);
//       return;
//     }
//     setCurrentPage(1);    
//   }, [resetSearch]);

useEffect(() => {
  const fetchData = () => {
    if (resetSearch) {
      businessManagment([], true);
    } else {
      businessManagment();
    }
  };

  fetchData();
}, [currentPage, sizePerPageOrg, sort, sortOrder, resetSearch]);

useEffect(() => {
  if (currentPage !== 1) {
    setCurrentPage(1);
  }
}, [sizePerPageOrg, sort, sortOrder]);

  useEffect(() => {
    fetchList();
  }, []);



  const handleDownload = async () => {
    try {
      const response = await callCSVRequest("venue/business/get-csv")

      //console.log('response', response)

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response], { type: 'text/csv' });

      // Use FileSaver to save the file
      saveAs(blob, 'business-data.csv');
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const businessManagmentSearch = async () => {
    if(currentPage === 1){
      businessManagment();
      return;
    }
    setCurrentPage(1);
  };

 
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {    
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);        
      }
    }
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == "active" ? "inactive" : "active";   

    const res = await putRequest("venue/onboarding/user/update", {
     adminId: actId,
      status: status,
    });

    if (res.status == 1) {
      toast.success(" Status updated Successfully");
      businessManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
 
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {
    const filterArgs = [];

    //console.log("filter", filter);

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "business_name") {
          var filterObject = {
            "business_id.name": filter["business_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        //console.log("key", key);

        if (key === "name" || key === "phone" || key === "email") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "startCreatedAt") {
          filterObject.type = "date-range-start";
          filterObject.name = "createdAt";
        } else if (key === "endCreatedAt") {
          filterObject.type = "date-range-end";
          filterObject.name = "createdAt";
        }
        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  }  

  const applyFilter = () => {
    setFilterActive(true) 
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "business_name") {
          var filterObject = {
            "business_id.name": filter["business_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (key === "name" || key === "phone" || key === "email") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        } else if (key === "startCreatedAt") {
          filterObject.type = "date-range-start";
          filterObject.name = "createdAt";
        } else if (key === "endCreatedAt") {
          filterObject.type = "date-range-end";
          filterObject.name = "createdAt";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    businessManagment(filterArgs);
  };
  const resetFilter = () => {
    setFilterActive(false)
    setFilter({ ...filterObj });
    toggleModel();
    businessManagment(null, true);
  };

  function WebsiteFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          Website
        </a>
      </div>
    );
  }

  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.status == "active" ? "active" : "inactive"; // Invert the status
      GetValueOnChange(newStatus, row?._id);
    };

    return (
      <label>
        <Toggle
          defaultChecked={row.status === "active"} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br></br>
        {row.status === "active" ? "Active" : "Inactive"}
      </label>
    );
  }

  function dateFormatter(cellContent, row) {
    return (
      <div >
        {convertTimeToFormattedString(row.createdAt)}
      </div>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  
  function SNoFormatter(cellContent, row, rowIndex) {
    let firstItemIndex;

      //console.log("totalPage", sortOrder)
  
      // if (sortOrder === 'desc') {
      //   // Calculate the first item index on the current page in descending order

      //   //console.log("totalPage", totalPage)

      //   firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      // } else {
      //   // Calculate the first item index on the current page in ascending order
      //   firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
        
      // }

      if (sortOrder === 'desc') {
        // Calculate the first item index on the current page in descending order
        firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      } else {
        // Calculate the first item index on the current page in ascending order
        firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
      }

      // Adjust the rowIndex to calculate the actual item index
      const itemIndex = sortOrder === 'desc' ? firstItemIndex - rowIndex : firstItemIndex + rowIndex;


      return <>{itemIndex}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.name}
          onChange={(e) => updateFilter("name", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Email</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.email}
          onChange={(e) => updateFilter("email", e.target.value)}
          name="email"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Phone</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.phone}
          onChange={(e) => updateFilter("phone", e.target.value)}
          name="phone"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created At</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,


    


    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },

    {
      dataField: "email",
      text: "Email",
      sort: false,
    },

    {
      dataField: "phone",
      text: "Phone No.",
      sort: false,
    },

    {
      dataField: "status",
      formatter: activeFormatter,
      text: "Status",
      sort: false,
    },

    {
      dataField: "createdAt",
      formatter: dateFormatter,
      text: "Created At",
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");  
  };

 

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >

        {permissions.delete && (
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
        )}
         
        </div>
      </React.Fragment>
    );
  }

  const businessManagment = async (filterArgs = [], resetFilter = false) => {
    setLoader(true);

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        // remove + from search text
        const searchTextNew = searchText.replace(/\+/g, '');
        payload.search = searchTextNew.trim();
      }
    }

    //console.log("payload old", filterArgs);


    if(filterActive && !filterArgs?.length && !resetFilter){
      payload.filterArgs = fetchFilter()
    }

   // //console.log("payload", payload.filterArgs);

    //return;

    try {
      const response = await postRequest(`venue/onboarding/user/list`, payload);
      const data = response?.data || {};
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data?.count || 0);
      setCurrentFPage(data?.page || 1);
      setbusinessdata(data?.list || data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Onboarding User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed && result.value === randomWord) {
        const response = await deleteRequest(`venue/onboarding/user/delete`, {
          adminId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          businessManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const [itemsdata, setItems] = useState([]);

 

  const fetchList = async () => {
    setLoader(true);
    const themes = await postRequestForm(
      "venue/theme/list?page=0&limit=1000&sortBy=first_name&sortOrder=desc&q="
    );

    setItems(themes.data.list);
    setLoader(false);
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("onboarding/user/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      businessManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Onboarding User</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Onboarding User</li>:{" "}
                <li className="breadcrumb-item">User List</li>
              </ol>
            </div>
          </Col>
         
         
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={businessdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="name"> Name</option>
                                    <option value="email">Email ID</option>
                                    <option value="phone">Phone No.</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) => setSearchText(e.target.value)}
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        businessManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>

                                   

                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>

                                { totalPage > sizePerPageOrg ? (
                                   <p style={{ marginTop: "12px", fontSize: "1rem" }}> 
                                   Showing <b>{ sizePerPageOrg }</b> From <b>{totalPage}</b>
                                   </p> 
                                ) : (
                                  <p style={{ marginTop: "12px", fontSize: "1rem" }}>
                                     Showing <b>{ totalPage }</b> From <b>{totalPage}</b> 
                                  </p>
                                ) }
                                

                              </Row>

                              

                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default OnBoardingManagementlist;
