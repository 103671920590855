import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";

import {
  postRequest,
  deleteRequest,
  putRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { file } from "@babel/types";

const BusinessGalleryList = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [gallerydata, setgallerydata] = useState([]);
  const [galleryPartialData, setGalleryPartialdata] = useState([]);

  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [gallery, setGallery] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showload, setShowLoad] = useState(false);

  useEffect(() => {
    galleryManagment(false);
  }, [currentPage]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  function loadMore() {
    setCurrentPage(currentPage + 1);
  }

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("themeId", actId);
    formData.append("status", status);

    const res = await putRequestForm("theme/update", formData);

    if (res.status == 1) {
      toast.success("Theme Status updated Successfully");
      themeManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.gallery}
          onClick={() => showLightBox(row.gallery)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  const showLightBox = (gallery) => {
    setGallery(gallery);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },
    {
      dataField: "gallery",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id, row.gallery)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const galleryManagment = async (reset = true) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    try {
      const response = await postRequest(
        `venue/business/my-gallery-list`,
        payload
      );
      const data = response.data;
      setTotalPage(data.count);

      if (data?.list?.length) {
        let galleryCurrent = [];
        if (!reset) {
          galleryCurrent = [...gallerydata];
        }
        galleryCurrent = [...galleryCurrent, ...data.list];
        setgallerydata(galleryCurrent);

        if (galleryCurrent?.length >= data?.count) {
          setShowLoad(false);
        } else {
          setShowLoad(true);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this gallery image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryImage(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryImage = async (id, file) => {
    const response = await deleteRequest("venue/gallery/delete", {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      galleryManagment(true);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        {/* <ToastContainer /> */}
        <Row>
          {loader ? (
            <Col md={12} style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "2rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </Col>
          ) : (
            <>
              {gallerydata.map((item) => (
                <Col key={item._id} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <Card>
                    <CardBody>
                      <img
                        src={item.gallery}
                        onClick={() => showLightBox(item.gallery)}
                        alt="img"
                        className="mb-3"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          objectFit: "cover"
                        }}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="text-truncate font-size-14">
                          {item.venueName}
                        </h5>
                        <Button
                          color="danger"
                          onClick={() => handlerdelete(item._id, item.gallery)}
                        >
                          <i className="fal fa-trash fs-5"></i>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>

        {showload && (
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <Button
                color="info"
                style={{ align: "center" }}
                onClick={() => loadMore()}
              >
                Load More
              </Button>
            </Col>
          </Row>
        )}
      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={gallery}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
};

export default BusinessGalleryList;
