import React, { useState, useEffect } from "react";

const Countdown = ({ endDate = false }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [expired, setExpired] = useState(false);

  //console.log("timeLeft", timeLeft, endDate);  

  useEffect(() => {
    if (!endDate) {
      setDefaults();
    } else {
      setTimeLeft(calculateTimeLeft());
      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        setTimeLeft(newTimeLeft);
        if (newTimeLeft.days === "00" && newTimeLeft.hours === "00" && newTimeLeft.minutes === "00") {
          setExpired(true);
          clearInterval(timer);
        }
      }, 3000);

      return function cleanup() {
        clearInterval(timer);
      };
    }
  }, [endDate]);

  function setDefaults() {
    setTimeLeft({
      days: "00",
      hours: "00",
      minutes: "00",
    });
    setExpired(false);
  }

  function calculateTimeLeft() {
    if (!endDate) {
      return {
        days: "00",
        hours: "00",
        minutes: "00",
      };
    }

    let difference = new Date(endDate).getTime() - new Date().getTime();

    let timeLeft = {
      days: "00",
      hours: "00",
      minutes: "00",
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24))
          .toString()
          .padStart(2, "0"),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, "0"),
        minutes: Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0"),
      };
    }

    return timeLeft;
  }

  if (expired) {
    return (
      <div className="countdown-expired" style={{ fontSize: "42px", color: "#ea230a", fontWeight: "bold" }}>
        Expired
      </div>
    );
  }

  return (
    <div className="countdown">
      {timeLeft.days &&
        timeLeft.days
          .split("")
          .map((digit, i) => <div key={`days-${i}`}>{digit}</div>)}
      <p>:</p>
      {timeLeft.hours &&
        timeLeft.hours
          .split("")
          .map((digit, i) => <div key={`hours-${i}`}>{digit}</div>)}
      <p>:</p>
      {timeLeft.minutes &&
        timeLeft.minutes
          .split("")
          .map((digit, i) => <div key={`minutes-${i}`}>{digit}</div>)}
    </div>
  );
};

export default Countdown;
