import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Story.css';

const stories = [
    {
        "_id": "655f84792536440c70c717de",
        "name": "Attiko",
        "address": "W Hotel Mina Seyahi, King Salman Bin Abdulaziz Al Saud St, Dubai Marina\r\n",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1700758346652a_logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1700758359627a_over.jpg",
        "phone": "+971 4 350 9983",
        "email": "reservations@theattiko.com",
        "isFollowing": false        
    },
    {
        "_id": "64bfc73168afe7079a3033c2",
        "name": "sparkle",
        "address": "behala",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1690289966161sips_&_tips-logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1690289967468file-nxyjqu8sayokbcku.jpg",
        "phone": "07984571609",
        "email": "Subhajit7252@gmail.com",
        "isFollowing": false
        
    },
    {
        "_id": "64743c2ca021f3bf326ab23f",
        "name": "Cresendo",
        "address": "Anantara Dubai The Palm Resort & Spa, East Crescent",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1695320512336cresendo-logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1695320518712vecteezy_photo-smores-on-black-background-food-photography_19035666_956.jpg",
        "phone": "+971 (0)4 567 8304, +971 56 189 7000",
        "email": "restaurants.dubai@anantara.com",
        "isFollowing": true
        
    },
    {
        "_id": "6565b21187c186da4dd4c8d2",
        "name": "Bedrock",
        "address": "1st Floor, Pier 7, Marina, Dubai",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1701162040095b_logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1701162044947b_over.jpg",
        "phone": "+971 4 578 1668",
        "email": "bookings@bedrockdxb.ae",
        "isFollowing": true        
    },
    {
        "_id": "6547665aee3ebc4baa5ad450",
        "name": "Bar Du Port",
        "address": "Dubai Harbour Pier Club - Dubai Marina",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1699177491147b_logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1699177493776b_over.jpg",
        "phone": "+971 43 324 868",
        "email": "reservations@bdpdubai.com",
        "isFollowing": false       
    },
    {
        "_id": "64744009a021f3bf326ab851",
        "name": "Sparkle",
        "address": "9th floor, Fairmont Dubai",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344396374sparkle-logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1685344397552file-nm3hzzfy2lnfhxdl.jpg",
        "phone": "+971585321447",
        "email": "reservations@bistrot90.com",
        "isFollowing": false       
    },
    {
        "_id": "64743cfba021f3bf326ab39a",
        "name": "Meliá Desert Palm",
        "address": "Al Awir Road - Warisan - Dubai - Émirats arabes unis",
        "logo": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1695320701577meliá_desert_palm-logo.jpg",
        "cover": "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1695320705418vecteezy_ai-generated-butterfly-on-blue-background_21633445_722.jpg",
        "phone": "971 56 681 6175 , 971 50 900 2128",
        "email": "zeeshan.khan@melia.com",
        "isFollowing": false
       
    }
]
const StorySlider = ({  }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="story-slider">
      <Slider {...settings}>
        {stories.map((story) => (
          <div key={story._id} className="story">
            <div className="story-icon" style={{ backgroundImage: `url(${story.logo})` }}>
              {/* Add an image tag if you also want to show a logo inside the circle */}             
            </div>
            <p className="story-name">{story.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default StorySlider;