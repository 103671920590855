import React, { Component } from 'react';
import { VectorMap } from 'react-jvectormap';
import './jquery-jvectormap.css'; // Ensure you have the necessary CSS for vector maps

class Vector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapData: this.transformData(props.countryData),
        };
    }

    transformData(data) {
        const mapData = {};
        data.forEach(item => {
            mapData[item.country] = item.count;
        });
        return mapData;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.countryData !== prevState.prevCountryData) {
            const newMapData = nextProps.countryData.reduce((acc, item) => {
                acc[item.country] = item.count;
                return acc;
            }, {});
            return {
                mapData: newMapData,
                prevCountryData: nextProps.countryData,
            };
        }
        return null;
    }

    render() {
        const scaleColors = this.props.scale ? [...this.props.scale, '#C8EEFF'] : ['#2ecc71', '#27ae60'];

        return (
            <div style={{ height: "100%", width: "100%" }}>
                <VectorMap
                    map="world_mill"
                    backgroundColor="transparent"
                    containerStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    regionStyle={{
                        initial: {
                            fill: '#e8ecf4',
                        },
                        hover: {
                            fill: "#c9dfaf",
                        },
                        selected: {
                            fill: '#F4A582',
                        },
                        selectedHover: {
                            fill: '#8E8E38',
                        },
                    }}
                    series={{
                        regions: [{
                            values: this.state.mapData,
                            attribute: 'fill',
                            scale: scaleColors,
                            normalizeFunction: 'polynomial',
                        }],
                    }}
                    onRegionTipShow={(e, el, code) => {
                        if (this.state.mapData[code]) {
                            el.html(`${el.html()}: ${this.state.mapData[code]} impressions`);
                        }
                    }}
                />
            </div>
        );
    }
}

export default Vector;
