import React , {useEffect,useRef, useState } from "react"

import { CountUp } from 'countup.js';

import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Button
} from "reactstrap"

import { 
  postRequest
  
} from "../../../components/Common/Utils";

import ActiveVenueSubscription from "../../../components/Common/ActiveVenueSubscription";

// Pages Components
// import Miniwidget from "./Miniwidget"
// import MonthlyEarnings from "./montly-earnings";
// import EmailSent from "./email-sent";
// import MonthlyEarnings2 from "./montly-earnings2";
// import Inbox from "./inbox";
// import RecentActivity from "./recent-activity";
// import WidgetUser from "./widget-user";
// import YearlySales from "./yearly-sales";
// import LatestTransactions from "./latest-transactions";
// import LatestOrders from "./latest-orders";
// import { useHistory, useParams, useLocation } from "react-router-dom";
// import ChartComponets from "./ChartData.js";
// import MiniWidgets from "./MiniWidgets";
// import RevenueAnalytics from "./RevenueAnalytics";
// import VenueAnalytics from "./VenueAnalytics";
// import OfferAnalytics from "./OfferAnalytics";
// import SalesAnalytics from "./SalesAnalytics";
// import EarningReports from "./EarningReports";
// import Sources from "./Sources";
// import RecentlyActivity from "./RecentlyActivity";
// import RevenueByLocations from "./RevenueByLocations";

import DashCard from "./DashCard";
// import Calendar from "./Calendar";


//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";

const temporaryData = {
  currentMonth: [
    { date: "2024-05-01", value: 100 },
    { date: "2024-05-02", value: 150 },
    { date: "2024-05-03", value: 200 },
    // Add more daily data as needed
  ],
  prevMonth: [
    { date: "2024-04-01", value: 80 },
    { date: "2024-04-02", value: 120 },
    { date: "2024-04-03", value: 160 },
    // Add more daily data as needed
  ],
  currentYear: [
    { month: "January", value: 2000 },
    { month: "February", value: 2500 },
    { month: "March", value: 3000 },
    // Add more monthly data as needed
  ],
  prevYear: [
    { month: "January", value: 1800 },
    { month: "February", value: 2200 },
    { month: "March", value: 2700 },
    // Add more monthly data as needed
  ],
  thisMonthTotal: 4500,
  prevMonthTotal: 3600,
  thisYearTotal: 30000,
  prevYearTotal: 29000
};

const defaultSalesData = [
  {
    "data": [10, 8, 6, 9, 11, 14, 8, 5, 10, 13, 14, 12],
    "title": "User Registration",
    "description": "user registered in last 12 months",
    "link": "/more-details",
    "count": "8,542"
  },
  {
    "data": [8, 6, 4, 7, 10, 12, 7, 4, 9, 12, 13, 11],
    "title": "Venue Added",
    "description": "venue added in last 12 months",
    "link": "/more-details",
    "count": "315"
  },
  {
    "data": [6, 4, 3, 6, 8, 10, 6, 3, 7, 20, 11, 9],
    "title": "Offer Created",
    "description": "offer created in last 12 months",
    "link": "/more-details",
    "count": "1,245"
  },
  {
    "data": [4, 3, 2, 4, 6, 8, 4, 2, 5, 8, 9, 12],
    "title": "Discount Claimed",
    "description": "discount claimed in last 12 months",
    "link": "/more-details",
    "count": "3,245"
  }
]


const Dashboard = (props) => {
   

    const [dashCardStat, setDashCardStat] = React.useState({});
    const [apiData, setApiData] = useState({});

    // const [salesData, setSalesData] = React.useState([]);

    // const [countryWiseData, setCountryWiseData] = React.useState([]);
    // const [activePlan, setActivePlan] = useState(true);
    // const [activeAnalytic, setActiveAnalytic] = useState(false);
  

    // const [ calendarEvents, setCalendarEvents ] = React.useState([]);

    const [loader, setLoader] = React.useState(false);

    // const [pieChartData, setPieChartData] = React.useState({});

    // const [claimedAmount, setClaimedAmount] = React.useState(null);

    // const [analyticsData, setAnalyticsData] = React.useState(null);  

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)


  useEffect(() => {
    const fetchActivePlan = async () => {
      setLoader(true);

      const venueId = localStorage.getItem("stored_venueId");

      const payload = {
        venueId: venueId // ensure you pass venueId as a prop
      };
      try {
        const response = await postRequest(`subscription/fetch-venue-subscription`, payload);
        const data = response?.data;    
        
        // ;        



        if(data == null){
          if(props.paymentAmount){
            props.paymentAmount(50);
            props.bpaymentAmount(50);
          }          
        }else{
          // check if offerREmaining is available
          if(!data.limit.offersRemaining){
            if(props.paymentAmount){
              props.paymentAmount(data.limit.additinalOffer);
              props.bpaymentAmount(data.limit.additinalOffer);
            }  
          }
         
          setDashCardStat(data.limit);
        }

       

        // if (data != null) {
        //   setActivePlan(data);
        // }

        if(props.setActiveAnalytic){
          if(data?.subscription){         
            props.setActiveAnalytic(data?.subscription?.haveAnalytics || false);         
          }else{
            props.setActiveAnalytic(false);
          }
        }

       







      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchActivePlan();
  }, []);

  const DashboardStat = async () => {    

    const venueId = localStorage.getItem("stored_venueId");

    const bId = "6655d1d32b6d9a979c1dfd48"

    postRequest(`user/admin/dashboard-data`, {bid: bId} ).then((data) => {
      let Counts = data?.data;  

      // remove 1st element from counts

      // Counts.dashCard.shift();
      // setDashCardStat(Counts.dashCard);
      // setDashCardStat(Counts.limit);

      let tempSales = [...salesData];
      tempSales = Counts.salesData;
      setSalesData(tempSales);
      setCountryWiseData(Counts.countryWiseData);
      setCalendarEvents(Counts.calendarData);
      setPieChartData(Counts.pieChartData);

      let tempClaimedAmount = {...claimedAmount};
      tempClaimedAmount = Counts.claimedAmount;

      //console.log('tempClaimedAmount', tempClaimedAmount);

      setClaimedAmount(tempClaimedAmount)


      let tempStat = {...analyticsData};
      tempStat = Counts.logstat;
      setAnalyticsData(tempStat);

   
      
      
    });
  };

  useEffect(() => {
    //DashboardStat();
  }, []);

  

  const reports = [
    { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" },
    { title: "Revenue", iconClass: "buffer", total: "$46,782", average: "-29%", badgecolor: "danger" },
    { title: "Average Price", iconClass: "tag-text-outline", total: "$15.9", average: "0%", badgecolor: "warning" },
    { title: "Product Sold", iconClass: "briefcase-check", total: "1890", average: "+89%", badgecolor: "info" },
  ]

  return (
    <React.Fragment>

    
<Row>
{/* <Col className='12'>
            <div className="page-title-box">
              <h4>Dashboard </h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Dashboard</li>
                : <li className="breadcrumb-item">Dashboard Counter</li>
              </ol>
            </div>
          </Col> */}

          {/* <div className="col-md-2 text-end">
            <Link to="/graph">
              {" "}
              <Button>
                
                {" "}
                <i class="fal fa-chart-line fs-5 text-light"></i> View Graph
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> */}


<Row>
<Col className='4' xl="4">
{/* <ActiveVenueSubscription /> */}
  </Col>
<Col className='8' xl="8">
    <DashCard values={dashCardStat} />
    
  </Col>

 

</Row>
</Row>


   

      

   
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);