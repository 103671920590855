import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../components/Common/Utils.js";
import "./chat.css";

export default function ReplyBox({ onFormSubmit, newId }) {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [ratingData, setRatingData] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedCover, setSelectedCover] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setRatingData(state);   
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const Addreply = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await postRequest("comman/contact-us/query-reply-admin", {
      conctactUsId: newId,
      reply: ratingData.reply,
    });
    setLoading(false);

    if (response.status == 1) {      
      onFormSubmit();
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...ratingData };
    list[index] = value;
    setRatingData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="reply-box">
        <textarea
          className="reply-textarea"
          placeholder="Type your reply here..."
          onChange={(e) => updateValue("reply", e.target.value)}
          value={ratingData?.reply}
          name="reply"
        />
        <button className="reply-button" onClick={Addreply} style={{fontSize: 16}}>
          Send
        </button>
      </div>
    </React.Fragment>
  );
}
