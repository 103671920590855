import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  putRequestForm,
  postRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModalOrg.js";
import FileUpload from "components/Common/FileUpload.js";

export default function CreateBanner() {
  const location = useLocation();
  const state = location.state?.row;

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [bannerTypeList, setBannerTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    fetchCategoryList();
  }, []);

  const showLightBox = (previewImage) => {0
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  useEffect(() => {
    if (state) {
      setBannerData(state);
      setVenueId(state.venueId);
      setVenueText(state.venueName);
      setVenueImage(state.venueImage);
      setOfferId(state.offerId);
      setOfferText(state.offerName);
      setOfferImage(state.offerImage);
      setPreviewImage(state.image);
    }
  }, [state]);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
    }
    setLoading(false);
  };

  const fetchTypeList = async () => {
    setLoading(true);
    const response = await postRequest("banner/type/list", { limit: 10000000 });
    if (response.status == 1) {
      setBannerTypeList(response.data.list);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);

    closeModal();
  };

  const selectOfferRow = (id, name, offer) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(offer?.image);
    closeOfferModal();
  };

  const Createbanner = async (e) => {
    e.preventDefault();
   
    

    if(!bannerData?.categoryId){
      toast.error("Please select category");
      return;
    }

    if (!bannerData?.type) {
      toast.error("Please select banner type");
      return;
    }

    if (bannerData?.type == "venue" && !venueId) {
      toast.error("Please select venue");
      return;
    }

    if (bannerData?.type == "offer" && !offerId) {
      toast.error("Please select offer");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    const selectedImageForm = new FormData();
    let selectedImageRes = null;
    if(selectedImage){
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
     selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    setStartLoader(false);
    }
    formData.append("bannerId", id);

    if(bannerData?.type == 'venue' && venueId){
      formData.append("venueId",  venueId);
    }

    if(bannerData?.type == 'offer' && offerId){
      formData.append("offerId",  offerId);
    }
    
    formData.append("type", bannerData.type);
    formData.append("categoryId", bannerData.categoryId);
    formData.append("title", bannerData.title);
    formData.append("link", bannerData.type == "link" ? bannerData.link : "");



    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }

    const response = await putRequestForm("venue/banner/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/banner");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Banner Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Banner Management</li>:{" "}
              <li className="breadcrumb-item">Update Banner</li>
            </ol>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Banner</h4>
                <Form onSubmit={Createbanner}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("categoryId", e.target.value)
                              }
                              defaultValue={bannerData?.categoryId}
                              name="categoryId"
                              className="form-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="link">Link</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div>

                        {bannerData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {bannerData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {bannerData?.type == "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                value={bannerData?.link}
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {bannerData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
