import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModalOrg.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "components/Common/FileUpload.js";
export default function UpdateCustomComponent() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [customData, setCustomData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [imageIcon, setImageIcon] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [listFetched, setListFetched] = useState(false);
  const [listFetched2, setListFetched2] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [offerId, setOfferId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerText, setOfferText] = useState("");
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const { id } = useParams();
  useEffect(() => {

    //console.log("state", state);

    setCustomData(state);
    setImageIcon(state?.image);
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    fetchOfferList();
    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.vanueImage);
    setOfferId(state?.offerId);
    setOfferText(state?.offerTitle);
    setPreviewImage(state.image);
  }, [state]);
  useEffect(() => {
    if (!selectedVenue.length) return;
    if (listFetched) return;

    fetchList();
  }, [selectedVenue]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  useEffect(() => {
    if (!selectedOffer.length) return;
    if (listFetched2) return;

    fetchOfferList();
  }, [selectedOffer]);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
      setListFetched(true);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel2 = () => {
    setModel2(!model2);
  };
  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectRow2 = (id, title) => {
    setOfferId(id);
    setOfferText(title);
    closeModal2();
  };

  const Updatecustom = async (e) => {
    e.preventDefault();
    // if (!venueId) {
    //   toast.error("Please select venue");
    //   return;
    // }
    if (!customData.title) {
      toast.error("Title is required");
      return;
    }
    // if (!customData.description) {
    //   toast.error("Please enter description");
    //   return;
    // }
    if (!customData?.type) {
      toast.error("Please select type");
      return;
    }

    if (customData?.type == "venue" && !venueId) {
      toast.error("Please select venue");
      return;
    }

    if (customData?.type == "offer" && !offerId) {
      toast.error("Please select offer");
      return;
    }

    if (customData?.type == "link" && !customData?.item) {
      toast.error("Please enter link");
      return;
    }

    // if(!selectedImage){
    //   toast.error("Please select image");
    //   return;
    // }


    if(customData?.componentType == "custom_component" && !customData?.buttonText){
      toast.error("Please enter button text");
      return;
    }

    if(customData?.componentType == "custom_component" && !customData?.tags){
      toast.error("Please enter tags");
      return;
    }
    setStartLoader(true);
    setLoading(true);

    // formData.append("description", customData.description);
    let selectedImageRes = null;
    if (selectedImage) {
      const formData = new FormData();
      const selectedImageForm = new FormData();
      selectedImageForm.append("image", selectedImage);
       selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );
    }

    const tags = customData.tags.split(",").map((item) => item.trim());

    const payload = {
      id: id,
      venueId: venueId || "",
      offerId: offerId || "",
      title: customData.title,
      // badge: customData.badge, // Uncomment if needed
      type: customData.type,
      componentType: customData.componentType,
      buttonText: customData.buttonText || "",
      showTitle: customData.showTitle || false,
      tags: tags || [],
    };
    
    if (customData.type === 'link') {
      payload.item = customData.item;
    }

    if (selectedImageRes?.data?.url) {
      payload.image = selectedImageRes?.data?.url;      
    } 

    const response = await postRequest("homeblock/custom/update", payload);
    setStartLoader(false);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/customComponents");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...customData };
    list[index] = value;
    setCustomData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Custom Component Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Custom Component Management
              </li>
              : <li className="breadcrumb-item">Update Custom Component</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Custom Component</h4>
                <Form onSubmit={Updatecustom}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Component Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={customData?.componentType}
                              // defaultValue="custom_image"
                              onChange={(e) =>
                                updateValue("componentType", e.target.value)
                              }
                              name = "componentType"
                              class="form-select"
                            >                              
                               <option value="custom_image">Custom Image</option>  
                               <option value="custom_component">Custom Component With Tags</option> 
                            </select>
                          </div>
                        </div>

                       

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={customData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              value={customData?.type}
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="type"
                              class="form-select"
                            >
                             <option>Select Banner Content Type</option>
                              <option value="link">Link</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div>

                        {customData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {customData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel2()}
                            >
                              {offerText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel2()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel2()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {customData?.type == "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("item", e.target.value)
                                }
                                value={customData?.item}
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}


                        {customData?.componentType == "custom_component" && (

                          <>

                            <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Title
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("title", e.target.value)
                                    }
                                    value={customData?.title}
                                    name="tags"
                                    className="form-control"
                                  />
                              </div>

                            </div>


                            <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Button Text
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("buttonText", e.target.value)
                                    }
                                    value={customData?.buttonText}
                                    name="tags"
                                    className="form-control"
                                  />
                              </div>

                            </div>      
                                  

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Tags (Comma Separated)
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    updateValue("tags", e.target.value)
                                  }
                                  value={customData?.tags}
                                  name="tags"
                                  className="form-control tags-input"
                                />
                              </div>

                            </div>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Show Title
                              </label>
                              <div className="col-sm-6">
                                <Input
                                  type="checkbox"
                                  checked={customData.showTitle}
                                  onChange={(e) =>
                                    updateValue('showTitle', e.target.checked)
                                  }
                                  name="showTitle"
                                  className="form-check-input"
                                />
                              </div>

                            </div>
                          
                          </>                        

                        )}

                       

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <OfferModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow2}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
