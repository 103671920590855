import { Modal } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import DraggableList from 'react-draggable-list';

const Item = ({ item, dragHandleProps, index }) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      <div
        className={`disable-select ${isDragging ? 'dragging' : ''}`}
        style={{
          border: "1px solid #e4dfdf",
          borderRadius: '5px',
          margin: "4px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-around",
          background: "white",
          alignItems: "center",
          userSelect: "none"
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          //console.log("touchStart");
          e.target.style.backgroundColor = "blue";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          //console.log("mouseDown");
          setIsDragging(true); 
          onMouseDown(e);
        }}
        onTouchEnd={(e) => {
          e.target.style.backgroundColor = "black";
          setIsDragging(false);
        }}
        onMouseUp={() => {
          setIsDragging(false);
        }}
      >
        <span class="fas fa-grip-vertical" style={{ fontSize: "1.5rem"}}></span>
        <div style={{ width: '60%' }}>
          {item.title || item.name || item.label}
        </div>
          <img src={item?.image} style={{ width: "50px", height: "50px" }} />
      </div>
    </>
  );
};

const DragDropCategory = ({ data, show, closeModal, setData }) => {
  const [list, setList] = useState(data.map((item, index) => ({ ...item, index: index })));

  const containerRef = useRef();

  const handleListChange = (newList) => {
    setList(newList);
  };

  const handleDragEnd = (newList) => {
    setList(newList);
    newList.forEach(item => item.isDragging = false);
  };

  return (
    <>
      <div className="" style={{ maxHeight: '500px', overflowY: 'auto' }} ref={containerRef}>
        <DraggableList
          itemKey="index"
          template={Item}
          list={list}
          onMoveEnd={handleDragEnd} 
          container={() => containerRef.current}
        />
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <button
          onClick={() =>
            setData(list)
          }
          className="btn btn-primary"
        >
          Save Order
        </button>
      </div>
    </>
  );
};

export default DragDropCategory;
