import React, { Component } from 'react';
import { Row, Card, CardBody, Col, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from "reactstrap";
import { postRequest } from "components/Common/Utils";
import moment from 'moment';
import Vector from "./Vector"; // Ensure the path is correct

class RevenueByLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData: [],
            view: 'overall',
            startDate: '2023-01-01',
            endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            limit: 25,
            offerId: props.offerId,
            modal: false,
            selectedYear: moment().year(),
            selectedMonth: ""
        };
    }

    componentDidMount() {
        this.fetchCountryData();
    }

    fetchCountryData = () => {
        const { offerId, startDate, endDate, limit } = this.state;
        postRequest(`log/offer-by-country-category`, { offerId, startDate, endDate, limit }).then(response => {
            if (response.status === 1) {
                const countryData = Object.keys(response.data).map(key => ({
                    country: key.toUpperCase(), // Ensure country code is uppercase
                    count: response.data[key]
                }));
                this.setState({ countryData });
            }
        });
    }

    updateView = (view) => {
        let startDate, endDate;
        if (view === 'monthly') {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().endOf('month').format('YYYY-MM-DD');
        } else if (view === 'annual') {
            startDate = moment().startOf('year').format('YYYY-MM-DD');
            endDate = moment().endOf('year').format('YYYY-MM-DD');
        } else if (view === 'overall') {
            startDate = '2023-01-01';
            endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        } else {
            this.toggleModal();
            return;
        }
        this.setState({ view, startDate, endDate }, this.fetchCountryData);
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value });
    }

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value });
    }

    handleCustomApply = () => {
        const { selectedYear, selectedMonth } = this.state;
        let startDate, endDate;
        if (selectedMonth) {
            startDate = moment(`${selectedYear}-${parseInt(selectedMonth) + 1}-01`).startOf('month').format('YYYY-MM-DD');
            endDate = moment(`${selectedYear}-${parseInt(selectedMonth) + 1}-01`).endOf('month').format('YYYY-MM-DD');
        } else {
            startDate = moment(`${selectedYear}-01-01`).startOf('year').format('YYYY-MM-DD');
            endDate = moment(`${selectedYear}-12-31`).endOf('year').format('YYYY-MM-DD');
        }
        this.setState({ view: 'custom', startDate, endDate, modal: false }, this.fetchCountryData);
    }

    render() {
        const { countryData, view, modal, selectedYear, selectedMonth } = this.state;

        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        <CardBody style={{ minHeight: "560px"}}>
                            <h4 className="card-title mb-4">Impression by Locations</h4>
                            <div className="float-end d-none d-md-inline-block">
                                <ButtonGroup className="mb-2">
                                    <Button size="sm" color="light" active={view === 'monthly'} onClick={() => this.updateView('monthly')}>Monthly</Button>
                                    <Button size="sm" color="light" active={view === 'annual'} onClick={() => this.updateView('annual')}>Annual</Button>
                                    <Button size="sm" color="light" active={view === 'custom'} onClick={this.toggleModal}>Custom</Button>
                                    <Button size="sm" color="light" active={view === 'overall'} onClick={() => this.updateView('overall')}>Overall</Button>
                                </ButtonGroup>
                            </div>
                            <div id="world-vectormap" style={{ height: "400px" }}>
                                <Vector countryData={countryData} />
                            </div>
                            <Row className="justify-content-center">
                                <Col xl={5} md={6}>
                                    {countryData.map((country, index) => (
                                        <div className="clearfix py-2" key={index}>
                                            {/* <h5 className="float-end font-size-16 m-0">{country.count}</h5>
                                            <p className="text-muted mb-0 text-truncate">{country.country} :</p> */}
                                        </div>
                                    ))}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Select Date</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="yearSelect">Year</Label>
                            <Input type="select" id="yearSelect" value={selectedYear} onChange={this.handleYearChange}>
                                {Array.from({ length: moment().year() - 2023 + 1 }, (_, i) => 2023 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Month (optional)</Label>
                            <Input type="select" id="monthSelect" value={selectedMonth} onChange={this.handleMonthChange}>
                                <option value="">All</option>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCustomApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RevenueByLocations;
