import React, { Component } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.css";

class Vectormap extends Component {
  constructor(props) {
    super(props);

    // Define initial state
    this.state = {
      mapData: {}
    };
  }

  componentDidUpdate(prevProps) {
    // Check if countryData prop has changed
   


    if (prevProps.countryData !== this.props.countryData) {
      this.updateMapData();
    }
  }

  updateMapData = () => {
    const { countryData } = this.props;
    const mapData = {};
    countryData.forEach((country) => {
      mapData[country.countryCode] = country.color;
    });
    this.setState({ mapData });
  };


  render() {


    return (
      <div id="asia-vectormap" style={{ height: "400px", width: "90%" }}>
        <VectorMap
          map={"asia_mill"}
          backgroundColor="#ffffff"
          containerStyle={{
            width: "100%",
            height: "100%"
          }}
          regionStyle={{
            initial: {
              fill: "#e8ecf4"
            },
            hover: {
              fill: "#c9dfaf"
            },
            selected: {
              fill: "#F4A582"
            },
            selectedHover: {
              fill: "#8E8E38"
            }
          }}
          series={{
            regions: [
              {
                values: this.state.mapData,
                attribute: "fill"
              }
            ]
          }}
          onRegionTipShow={(e, el, code) => {
            const country = this.props.countryData.find((c) => c.countryCode === code);
            if (country) {
              el.html(`${el.html()} (${country.count})`);
            }
          }}
        />
      </div>
    );
  }
}

export default Vectormap;
