import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function UpdateDiscount() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [discountData, setDiscountData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [discounttypeList, setDiscounttypeList] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    state.startDate = state.startDate.split("T")[0];
    state.expiryDate = state.expiryDate.split("T")[0];

    setDiscountData(state);
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/discount-type/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setDiscounttypeList(response.data.list);
    }
    setLoading(false);
  };

  const Updatediscount = async (e) => {
    e.preventDefault();
    if (!discountData.title) {
      toast.error("Title is required");
      return;
    }

    if (!discountData.discount) {
      toast.error("Please enter discount %");
      return;
    }

    if (!discountData.description) {
      toast.error("Please enter description");
      return;
    }

    if (!discountData.discountTypeId) {
      toast.error("Please enter discountTypeId");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("discountId", id);
    formData.append("discount", discountData.discount);
    formData.append("discountTypeId", discountData.discountTypeId);
    formData.append("title", discountData.title);
    formData.append("description", discountData.description);
    formData.append(
      "startDate",
      discountData?.startDate ? discountData?.startDate : ""
    );
    formData.append(
      "expiryDate",
      discountData?.expiryDate ? discountData?.expiryDate : ""
    );
    formData.append("status", discountData.status == "true" ? 1 : 0);

    const response = await putRequestForm("venue/discount/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/discount");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...discountData };
    list[index] = value;
    setDiscountData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Discount Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Discount Management</li>:{" "}
              <li className="breadcrumb-item">Update Discount</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Discount</h4>
                <Form onSubmit={Updatediscount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={discountData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount %
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              value={discountData?.discount}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Discount Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={discountData?.discountTypeId}
                              onChange={(e) =>
                                updateValue("discountTypeId", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              {discounttypeList.map((item, index) => (
                                <option value={item._id}>{item.title}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={discountData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              value={discountData?.startDate}
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Expiry Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("expiryDate", e.target.value)
                              }
                              value={discountData?.expiryDate}
                              name="expiryDate"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={discountData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
