import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";

import DayTimePicker from "./DayTimePicker";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../../components/Common/Utils.js";
import LocationPicker from "react-location-picker";
import { te } from "date-fns/locale";
import { set } from "lodash";
import BusinessModal from "components/Modal/BusinessModal";

import FileUpload from "../../../components/Common/FileUpload";

const defaultPosition = {
  lat: 25.2048345,
  lng: 55.2708345,
};
export default function CreateVenue() {
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [venueData, setVenueData] = useState({
    latitude: "",
    longitude: "",
  });
  const [startLoader, setStartLoader] = useState(false);

  const [tempLogo, setTempLogo] = useState(null);
  const [tempCover, setTempCover] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [lockSubmit, setLockSubmit] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [selectedCover, setSelectedCover] = useState("");

  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [businessOptions, setBusinessOptions] = useState([
    { value: "", label: "None" },
  ]);

  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinessData, setSelectedBusinessData] = useState([]);

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  let history = useHistory();

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };
  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };
  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const selectRow = (id, name, logo) => {
    setBusinessId(id?._id);
    setBusinessText(id?.name);
    setBusinessImage(id?.logo);
    setSelectedBusinessData(id);
    closeModal();
  };

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    setPreviewLogo(
      selectedBusinessData?.logo ? selectedBusinessData?.logo : ""
    );
    setPreviewCover(
      selectedBusinessData?.cover ? selectedBusinessData?.cover : ""
    );

    setTempLogo(selectedBusinessData?.logo ? selectedBusinessData?.logo : "");

    setTempCover(
      selectedBusinessData?.cover ? selectedBusinessData?.cover : ""
    );

    const venueDt = { ...venueData };

    venueDt["about"] = selectedBusinessData?.about
      ? selectedBusinessData?.about
      : "";
    venueDt["phone"] = selectedBusinessData?.phone
      ? selectedBusinessData?.phone
      : "";
    venueDt["email"] = selectedBusinessData?.email
      ? selectedBusinessData?.email
      : "";
    venueDt["website"] = selectedBusinessData?.website
      ? selectedBusinessData?.website
      : "";
    setVenueData({ ...venueDt });
  }, [selectedBusinessData]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const venueDt = { ...venueData, latitude: lat, longitude: lng };
    setVenueData(venueDt);
  };

  useEffect(() => {
    fetchList();
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
  }, []);

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);
  const fetchList = async () => {
    setLoading(true);

    let selectedBusiness = localStorage.getItem("stored_bussinessId");


    const param = { page: 1, limit: 1, bid: selectedBusiness};
    const business = await postRequest("venue/business/list", param);
    if (business.status == 1) {
      const options = business.data.list.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBusinessData(business.data.list);
      setBusinessOptions(options);
    }

    setLoading(false);
  };

  function handleFileUpload(e) {
    const file = e.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    // Check the file type

    if (file.type === "application/pdf") {
      // Handle PDF file
      handleUpload(file, "pdf");
    } else if (file.type.startsWith("image/")) {
      // Handle image file
      handleUpload(file, "image");
    } else {
      // Invalid file type
      alert("Unsupported file type. Please select a PDF or an image.");
    }
  }

  async function handleUpload(file, type) {
    //setLoading(true);
    setLockSubmit(true);
    setStartLoader(true);

    let url = "comman/doc/upload";

    if(type === "image") {
      url = "comman/img/upload";
    }

    const pdfFile = file;
    const formData = new FormData();
    formData.append("file", pdfFile);

    const selectedImageRes = await postRequestForm(url, formData);

    const venData = { ...venueData };
    if (selectedImageRes?.data) {
      venData["menu_url"] = selectedImageRes.data.url;
      setVenueData(venData);
    } else {
      console.error("Image URL not found in the response.");
    }
    setLockSubmit(false);
    setStartLoader(false);
    //setLoading(false);
  }

  const Createvenueform = async (e) => {
    e.preventDefault();
    //console.log(selectedTheme);
    if (!businessId) {
      toast.error("Please select business");
      return;
    }

    if (!venueData.name) {
      toast.error("Please enter venue name");
      return;
    }

    if (!venueData.about) {
      toast.error("Please enter venue about");
      return;
    }
    if (!venueData.address) {
      toast.error("Please enter venue address");
      return;
    }
    if (!venueData.phone) {
      toast.error("Please enter venue phone");
      return;
    }

    if (!venueData.latitude) {
      toast.error("Please enter venue latitude");
      return;
    }

    if (!venueData.longitude) {
      toast.error("Please enter venue longitude");
      return;
    }

    // claim code must be 4 digit
    // if (!venueData.claimCode) {
    //   toast.error("Please enter venue claim code (4 digit)");
    //   return;
    // }

    if (!remoteLogo && !tempLogo) {
      toast.error("Please select venue logo");
      return;
    }

    if (!selectedCover && !tempCover) {
      toast.error("Please select venue cover");
      return;
    }

    if (!selectedTheme.length) {
      toast.error("Please select venue theme");
      return;
    }

    if (!selectedFeature.length) {
      toast.error("Please select venue feature");
      return;
    }


    if (!selectedCuisine.length) {
      toast.error("Please select venue cuisine");
      return;
    }
    if (!selectedMusic.length) {
      toast.error("Please select venue music");
      return;
    }

    let atLeastOneChecked = false;

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        atLeastOneChecked = true;
        return; // Break out of the loop since at least one is checked
      }

      // check close time is greater than open time    
      if (item.openTime >= item.closeTime) {
        return "closeTime must be greater than openTime";
      }

    });

    if (!atLeastOneChecked) {
      toast.error("Please select at least one time");
      return;
    }

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", remoteLogo);

    setStartLoader(true);
    const selectedImageRes = await postRequestForm(
      "comman/logo/upload",
      selectedImageForm
    );

    const selectedImageForm2 = new FormData();

    selectedImageForm2.append("image", selectedCover);
    const selectedImageRes2 = await postRequestForm(
      "comman/img/upload",
      selectedImageForm2
    );

    setStartLoader(false);

    setLoading(true);

    formData.append("businessId", businessId);
    formData.append("name", venueData.name.trim());
    formData.append("about", venueData.about);
    formData.append("address", venueData.address);
    formData.append("phone", venueData.phone);
    formData.append("email", venueData.email);
    formData.append("website", venueData.website);
    formData.append("latitude", venueData.latitude);
    formData.append("longitude", venueData.longitude);
    formData.append("booking_url", venueData.booking_url ? venueData.booking_url : "");
    formData.append("menu_url", venueData.menu_url ? venueData.menu_url : "");
    formData.append("dress_code", venueData.dress_code ? venueData.dress_code : "");
    formData.append("claimCode", venueData.claimCode ? venueData.claimCode : "");
    formData.append("discountText", venueData.discountText ? venueData.discountText : "");
    formData.append("tempLogo", tempLogo);
    formData.append("tempCover", tempCover);

    if (selectedImageRes?.data?.url) {
      formData.append("logo", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("cover", selectedImageRes2.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
    formData.append("isAllowReview", venueData.isAllowReview);
    formData.append("isAllowRatting", venueData.isAllowRatting);

    selectedTheme.forEach((item) => {
      formData.append("themes[]", item);
    });

    selectedMusic.forEach((item) => {
      formData.append("music[]", item);
    });

    selectedFeature.forEach((item) => {
      formData.append("features[]", item);
    });

    selectedCuisine.forEach((item) => {
      formData.append("cuisines[]", item);
    });

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        formData.append(`days[]`, days[index].toString().toLowerCase());
        formData.append(`open_time[]`, item.open);
        formData.append(`close_time[]`, item.close);
      }
    });

    setLoading(true);
    const response = await postRequestForm("venue/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venues");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);

    if (index == "business_id") {
      const Bdata = businessData.find((x) => x._id === list.business_id);
      setSelectedBusinessData(Bdata);
    }
  };
  const updateValuePhone = (value) => {

    if (!selectedBusinessData?.phone) {
      if (isNaN(value)) {
        return;
      }

      if (value.length > 10) {
        return;
      }
    }

    const list = { ...venueData };
    list["phone"] = value;
    setVenueData(list);
  };
  const updateValueEmail = (value) => {
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(value)) {
      return;
    }

    const list = { ...venueData };
    list["email"] = value;
    setVenueData(list);
  };

  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }

    setCurrentLocation(newLocation);
    setVenueData({
      ...venueData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Create Venue</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <>
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              </>
            ) : null}
            <CardBody style={{ display: `${loading ? 'none' : 'block'}` }}>
              <h4 className="card-title"> Create Venue </h4>
              <Form onSubmit={Createvenueform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Business Name
                        </label>

                        <div
                          className="col-sm-6"
                          onClick={() => toggleModel()}
                        >

                          {businessText ? (

                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleModel()}
                              >
                                Select Business Name
                              </button>
                              <div
                                style={{
                                  border: "1px solid #ced4da",
                                  width: "16px",
                                }}
                              ></div>
                              <img
                                src={businessImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid #ced4da",
                                  borderRight: "none",
                                  borderLeft: "none",
                                }}
                              ></img>
                              <input
                                type="text"
                                style={{ borderLeft: "none" }}
                                class="form-control"
                                readonly
                                value={businessText}
                                id="selectedVenue"
                                placeholder="No venue chosen"
                              />
                            </div>
                          ) : (

                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#E22A2A",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleModel()}
                              >
                                Select Business Name
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="selectedVenue"
                                placeholder="No Business chosen"
                              />
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("name", e.target.value)
                            }
                            name="name"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          About
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="textarea"
                            defaultValue={selectedBusinessData?.about}
                            onChange={(e) =>
                              updateValue("about", e.target.value)
                            }
                            rows="4"
                            cols="50"
                            name="about"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Address
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="textarea"
                            defaultValue={selectedBusinessData?.address}
                            onChange={(e) =>
                              updateValue("address", e.target.value)
                            }
                            rows="4"
                            cols="50"
                            name="address"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Phone
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="tel"
                            defaultValue={selectedBusinessData?.phone}
                            onChange={(e) => updateValuePhone(e.target.value)}
                            name="phone"
                            className="form-control"
                            value={venueData?.phone}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Email
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            defaultValue={selectedBusinessData?.email}
                            onChange={(e) =>
                              updateValue("email", e.target.value)
                            }
                            name="email"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Website
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            defaultValue={selectedBusinessData?.website}
                            onChange={(e) =>
                              updateValue("website", e.target.value)
                            }
                            name="website"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Booking Url
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("booking_url", e.target.value)
                            }
                            name="booking_url"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Menu PDF or Image
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            accept=".pdf, image/*"
                            onChange={(e) => handleFileUpload(e)}
                            name="menu_url"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Dress Code
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("dress_code", e.target.value)
                            }
                            name="dress_code"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Claim Code
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            maxLength={4} // Set maximum length to 4
                            onChange={(e) => {
                              const inputClaimCode = e.target.value;
                              // Check if the input is a numeric 4-digit number or empty
                              if (/^\d{4}$/.test(inputClaimCode) || inputClaimCode === '') {
                                // If it's a 4-digit number or empty, update the value
                                updateValue("claimCode", inputClaimCode);
                              }
                            }}
                            name="claimCode"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Tag
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) => {
                              updateValue("discountText", e.target.value);
                            }}
                            name="discountText"
                            className="form-control"
                          />
                        </div>
                      </div>


                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow Reviews
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("isAllowReview", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow Ratings
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("isAllowRatting", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Logo
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={handleLogoChange}
                            name="logo"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <br />

                          {previewLogo && selectedLogo ? (
                            <ReactCrop
                              crop={crop}
                              src={previewLogo}
                              onChange={(newCrop) => setCrop(newCrop)}
                              onComplete={handleCropComplete}
                              style={{
                                maxWidth: "720px",
                                maxHeight: "600px",
                              }}
                            >
                              <img ref={imgRef} src={previewLogo} />
                            </ReactCrop>
                          ) : previewLogo ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={previewLogo}
                              onClick={() => showLightBox2(previewLogo)}
                            />
                          ) : null}

                          {completedCrop && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop.width,
                                  height: completedCrop.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Cover
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              setSelectedCover(e.target.files[0])
                            }
                            name="cover"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <br />
                          {previewCover && (
                            <img
                              src={previewCover}
                              onClick={() => showLightBox(previewCover)}
                              style={{ width: "100px", height: "100px" }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Theme
                        </label>
                        <div className="col-sm-6">
                          <MultiSelect
                            options={themeList}
                            onSelect={msUpdateTheme}
                            selectedValues={[]}
                            placeholder="Select Theme"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Cuisine
                        </label>
                        <div className="col-sm-6">
                          <MultiSelect
                            options={cuisineList}
                            onSelect={msUpdateCuisine}
                            selectedValues={[]}
                            placeholder="Select Cuisine"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Music
                        </label>
                        <div className="col-sm-6">
                          <MultiSelect
                            options={musicList}
                            onSelect={msUpdateMusic}
                            selectedValues={[]}
                            placeholder="Select Music"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Feature
                        </label>
                        <div className="col-sm-6">
                          <MultiSelect
                            options={featureList}
                            onSelect={msUpdateFeature}
                            selectedValues={[]}
                            placeholder="Select Feature"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Time
                        </label>
                        <div className="col-sm-6">
                          {days.map((day, index) => (
                            <DayTimePicker
                              updateTiming={updateTiming}
                              currentValue={timeOptions[index]}
                              index={index}
                              key={index}
                              day={day}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Select Location
                        </label>
                        <div className="col-sm-6">
                          <LocationPicker
                            containerElement={
                              <div style={{ height: "100%" }} />
                            }
                            mapElement={<div style={{ height: "600px" }} />}
                            zoom={14}
                            defaultPosition={currentLocation}
                            onChange={handleLocationChange}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Latitude
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number" // Change the type to "number"
                            step="any"
                            name="latitude"
                            min={0}
                            value={venueData?.latitude}
                            onChange={(e) =>
                              updateLocation(e.target.value, "lat")
                            }
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Longitude
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number" // Change the type to "number"
                            step="any"
                            name="longitude"
                            min={0}
                            value={venueData?.longitude}
                            onChange={(e) =>
                              updateLocation(e.target.value, "lng")
                            }
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>

                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                                disabled={lockSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>

          </Card>
        </Col>
      </Row>

      <BusinessModal show={model} closeModal={closeModal} selectRow={selectRow} />
      
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
