import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DateRangePicker.css';

const DateRangePicker = ({ selectedStartDate, selectedEndDate, onSubmit }) => {
    const [startDate, setStartDate] = useState(selectedStartDate);
    const [endDate, setEndDate] = useState(selectedEndDate);

    const handleSubmit = () => {
        onSubmit({ startDate, endDate });
    }

    return (
        <div className="date-range-picker">
            
            
            <div className="col-lg-3 d-flex mb-3">
                <label className="date-label">Start Date </label>
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="fancy-input form-control"
                />
                
            </div>
            <div className="col-lg-3 d-flex mb-3">
                <label className="date-label">End Date</label>
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="fancy-input form-control"
                />
            </div>
            <div className="row2">       
                <button className="btn btn-primary mx-2" onClick={handleSubmit}>Submit</button>
            </div>
    </div>
    );
}

export default DateRangePicker;