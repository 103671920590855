import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import VenueModal from "components/Modal/VenueModalOrg.js";
import EventPackageModal from "components/Modal/EventPackageModal.js";

import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";

import UserlistModal from "components/Modal/UserlistModal.js";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import AdminModal from "components/Modal/AdminlistModalId";
import InviteModal from "components/Modal/InvitelistModalId.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload";


import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => (
  <i className="fas fa-grip-lines" style={{ cursor: 'move' }}></i>
));

const SortableItem = SortableElement(({ item, deletePackage }) => (
  <tr>
    <td>
      {/* Drag Handle */}
      <DragHandle />
    </td>
    <td>{item.title}</td>
    <td>{item.description}</td>
    <td>
      <img
        src={item.image}
        style={{ width: '100px' }}
        alt={item.title}
        onClick={() => showLightBox(item.image)}
      />
    </td>
    <td>{item.type}</td>
    <td>{item.buttonText}</td>
    <td>
      <i
        className="fas fa-trash-alt"
        style={{
          marginLeft: '10px',
          cursor: 'pointer',
          color: 'red',
        }}
        onClick={() => deletePackage()} // No need to pass index explicitly here
      ></i>
    </td>
  </tr>
));

const SortableBody = SortableContainer(({ items, deletePackage }) => {
  return (
    <tbody>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item._id}`}
          index={index} // This index is automatically handled by SortableElement
          item={item}
          deletePackage={() => deletePackage(index)}  // Pass the correct index here
        />
      ))}
    </tbody>
  );
});

export default function CreateHomeBanner() {
  const location = useLocation();
  const state = location.state?.row;
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [modelAdmin, setModelAdmin] = useState(false);
  const [modelInvite, setModelInvite] = useState(false);
  const [adminPreview, setAdminPreview] = useState([]);
  const [invitePreview, setInvitePreview] = useState([]);
  const [adminId, setAdminId] = useState([]);
  const [inviteId, setInviteId] = useState([]);

  const [packages, setPackages] = useState([]);
  const [openPackage, setOpenPackage] = useState(false);

  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [eventOrganizerId, setEventOrganizerId] = useState("");
  const [eventOrganizerText, setEventOrganizerText] = useState("");
  const [eventOrganizerImage, setEventOrganizerImage] = useState("");

  const [eventInviteId, setEventInviteId] = useState("");
  const [eventInviteText, setEventInviteText] = useState("");
  const [eventInviteImage, setEventInviteImage] = useState("");

  const [orgList, setOrgList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [orgText, setOrgText] = useState("");

  const [userId, setUserId] = useState("");
  const [userText, setUserText] = useState("");
  const [userImage, setUserImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [model, setModel] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [orgModal, setOrgModal] = useState(false);

  const [disableType, setDisableType] = useState(false);
  let history = useHistory();
  const { id } = useParams();
  const [eventTime, setEventTime] = useState('');
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPackages((prevPackages) =>
      arrayMoveImmutable(prevPackages, oldIndex, newIndex)
    );
  };

  useEffect(() => {
    if (!state) return;
    if (state?.organizerId) {
      setEventOrganizerId(state?.organizerId);
      setEventOrganizerText(state?.organizerName);
      setEventOrganizerImage(state?.organizerLogo);
      let tempData = { ...eventData };
      tempData.user_type = "org";
      setEventData(tempData);
    }
  }, [state]);

 

  useEffect(() => {
    if (eventData?.user_type == "user") {
      // set type into private & disable type dropdown

      const data = { ...eventData };
      data.type = "private";
      setEventData(data);
      setDisableType(true);
    } else {
      setDisableType(false);
    }
  }, [eventData?.user_type]);
 
 

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const closeModalAdmin = () => {
    setModelAdmin(false);
  };

  const closeModalInvite = () => {
    setModelInvite(false);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };


  const selectAdminRow = (selectRow, id) => {
    // check if activity is already added   

    const isAdminAlreadyAdded = adminId.includes(selectRow?._id);

    if (isAdminAlreadyAdded) {
      toast.error("Admin already added");
      return;
    }
       
    closeModalAdmin();
    let arr = [],
      cover,
      label,
      title,
      description;
    title = selectRow?.first_name + " " + selectRow?.last_name;
    cover = selectRow?.image;
    label = "";

    arr = { cover, label, title };
    setAdminPreview([...adminPreview, arr]);
    setAdminId([...adminId, selectRow._id]);
  };

  const selectInviteRow = (selectRow, id) => {
    // check if activity is already added

   

    const isInviteAlreadyAdded = inviteId.includes(selectRow?._id);

    if (isInviteAlreadyAdded) {
      toast.error("Invite already added");
      return;
    }
       
    closeModalInvite();
    let arr = [],
      cover,
      label,
      title,
      description;
    title = selectRow?.first_name + " " + selectRow?.last_name;
    cover = selectRow?.image;
    label = "";

    arr = { cover, label, title };
    setInvitePreview([...invitePreview, arr]);
    setInviteId([...inviteId, selectRow._id]);
  };

  const updateEventsData = async (e) => {
    e.preventDefault();
    if (!eventData.title) {
      toast.error("Title is required");
      return;
    }

    const dataObj = {
      title: eventData.title,
      status: eventData.status ? eventData.status : 0,
      items: packages,
    };   

    setLoading(true);
    const response = await postRequest("homeblock/banner/create", dataObj);
    setLoading(false);
    

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        // history.push("/events");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeUserModal = () => {
    setUserModel(false);
  };

  const closeOrgModal = () => {
    setOrgModal(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectUserRow = (id, name, user) => {
    setUserId(id);
    setUserText(name);
    setUserImage(user?.image);
    closeUserModal();
  };

  const selectOrgRow = (id, name, org) => {
    setEventOrganizerId(id);
    setEventOrganizerText(name);
    setEventOrganizerImage(org?.logo);
    closeOrgModal();
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };


  const addPackage = () => {
    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {
    setPackages([...packages, data]);
    setOpenPackage(false);
  };

  const deletePackage = (index) => {

    console.log("index", index);

    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };



  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Banner Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Banner Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Create Banner</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Create Banner</h4>
                <Form onSubmit={updateEventsData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={eventData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Create Banner
                          </label>
                          <div className="col-sm-6">
                            <button
                              type="button"
                              onClick={addPackage}
                              className="btn btn-primary mb-3"
                            >
                              Add Banner
                            </button>


                            <table className="table">
                              <thead>
                                <tr>
                                  <th>-</th>
                                  <th>Title</th>
                                  <th>Description</th>
                                  <th>Image</th>
                                  <th>Type</th>
                                  <th>Button Text</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <SortableBody items={packages} onSortEnd={onSortEnd} lockAxis="y" useDragHandle  deletePackage={deletePackage} updatePackage={null}    />
                            </table> 
                            
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={eventData?.status}
                              onChange={(e) =>
                                updateValue("status", Number(e.target.value))
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                        </div>


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <UserlistModal
        show={userModel}
        closeModal={closeUserModal}
        selectRow={selectUserRow}
      />
      <OrganizerlistModal
        show={orgModal}
        closeModal={closeOrgModal}
        selectRow={selectOrgRow}
      />
      <AdminModal
        show={modelAdmin}
        closeModal={closeModalAdmin}
        selectRow={selectAdminRow}
        orgId={eventOrganizerId}
      />

      <InviteModal
        show={modelInvite}
        closeModal={closeModalInvite}
        selectRow={selectInviteRow}
        orgId={eventOrganizerId}
      />

      <MultipleSelectUserList
        showUser={userModel}
        // onSelectUser={onSelectedUser}
        closeUserModal={closeUserModal}
        selectUserRow={selectUserRow}
      />

      <EventPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
