import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
} from "../../../components/Common/Utils.js";
import VenueModal from "../../../components/Modal/VenueModalOrg.js";
import Lightbox from "react-image-lightbox";
import FileDrop from "components/FileMultipleDrop.js";

import FileUpload from "../../../components/Common/FileUpload"; 

export default function CreateGallery() {
  const location = useLocation();
  const state = location.state?.row;
  const [startLoader, setStartLoader] = useState(false);
  const [galleryData, setGalleryData] = useState({ title: "" });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);

  const [venueList, setVenueList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);

  let history = useHistory();
  const { id } = useParams();

  const handleFileUpload = (url) => {
    setSelectedImage((prevState) => [...prevState, url]);

    //console.log(selectedImage, "selectedImage")

  };
  

  useEffect(() => {
    if (!state) return;
    setVenueId(state?.venueId);
    setVenueText(state?.venueName);
    setVenueImage(state?.venueLogo);
  }, [state]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const Creategallery = async (e) => {
    e.preventDefault();

    if (!venueId) {
      toast.error("Please select venue");
      return;
    }

    // check file is selected or not

    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }

    //console.log(selectedImage, "selectedImage")

    //return;


    setLoading(true);
    const search = location.search;
    const from = new URLSearchParams(search).get("from");
    
    const response = await postRequest("venue/gallery/add", {
      venueId: venueId,
      files: selectedImage
    });
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        if (!from) history.goBack();
        else history.push("/venuedetails/" + venueId + "?active=Gallery");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...galleryData };
    list[index] = value;
    setGalleryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Gallery Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Gallery Management</li>:{" "}
              <li className="breadcrumb-item">Create Gallery</li>
            </ol>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Gallery </h4>
                <Form onSubmit={Creategallery}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Upload Gallery Image
                          </label>
                          <div className="col-sm-6">
                            <FileDrop
                              accept="image/*"
                              onFileUpload={handleFileUpload}
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
