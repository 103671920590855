import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class SalesAnalytics extends Component {
    render() {
        const { 'get-promoted': getPromoted, 'add-on-package': addOnPackage, 'basic-benefits': basicBenefits, 'basic': basic } = this.props.data || {};

        //console.log("this.props.data", getPromoted, addOnPackage, basicBenefits, basic);

        const series = [getPromoted, addOnPackage, basicBenefits, basic];
        const total = getPromoted + addOnPackage + basicBenefits + basic;

        const getPromotedPercentage = ((getPromoted / total) * 100).toFixed(2);
        const addOnPackagePercentage = ((addOnPackage / total) * 100).toFixed(2);
        const basicBenefitsPercentage = ((basicBenefits / total) * 100).toFixed(2);
        const basicPercentage = ((basic / total) * 100).toFixed(2);

        const options = {
            labels: ["Get Promoted", "Add-On Package", "Basic Benefits", "Basic"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#3DDC84', '#5664d2', '#FFB900', '#FF4560'],
        };

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Active Subscription for Each Plan</h4>

                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={options} series={series} type="donut" height="250" />
                        </div>

                        <Row>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i>Get Promoted</p>
                                    <h5>{getPromotedPercentage} %</h5>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i>Add-On Package</p>                                    
                                    <h5>{addOnPackagePercentage} %</h5>
                                </div>
                            </Col>                            
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 me-1"></i>Basic Benefits</p>
                                    <h5>{basicBenefitsPercentage} %</h5>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-danger font-size-10 me-1"></i>Basic</p>                                    
                                    <h5>{basicPercentage} %</h5>
                                </div>
                            </Col>                            
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;
