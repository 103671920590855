import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { postRequest } from "components/Common/Utils";
import "./dashboard.scss";

class RevenueAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'monthly',
            series: [],
            options: this.getChartOptions('monthly', []),
            modal: false,
            selectedYear: moment().year(),
            selectedMonth: moment().month(),
            data: {
                view: [],
                click: [],
                search: []
            }
        };
    }

    getChartOptions(view, data) {
        let categories;

        if (view === 'monthly') {
            categories = data?.view?.map(item => moment(item.date).format('DD MMM')) || [];
        } else {
            categories = moment.months();
        }

        return {
            chart: {
                type: 'line', // Use 'line' to handle both bar and line series
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [0, 0, 3], // Width for bar and line
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%'
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            colors: ['#5664d2', '#ff4b5c', '#3DDC84'],
            labels: categories
        };
    }

    updateChart(view, data) {
        let series;

        if (view === 'monthly') {
            series = [
                { name: 'Views', data: data?.view?.map(item => item.value) || [], type: 'bar' },
                { name: 'Searches', data: data?.search?.map(item => item.value) || [], type: 'bar' },
                { name: 'Clicks', data: data?.click?.map(item => item.value) || [], type: 'line' }
            ];
        } else {
            series = [
                { name: 'Views', data: data?.view?.map(item => item.value) || [], type: 'bar' },
                { name: 'Searches', data: data?.search?.map(item => item.value) || [], type: 'bar' },
                { name: 'Clicks', data: data?.click?.map(item => item.value) || [], type: 'line' }
            ];
        }

        this.setState({
            view,
            series,
            options: this.getChartOptions(view, data)
        });
    }

    componentDidMount() {
        this.fetchData('monthly');
    }

    fetchData = (view) => {
        const { venueId } = this.props;
        const { selectedYear, selectedMonth } = this.state;

        let payload = { };
        if (view === 'monthly') {
            payload.monthChange = selectedMonth;
            payload.yearChange = selectedYear;
        } else {
            payload.monthChange = -1;
            payload.yearChange = selectedYear;
        }

        postRequest('log/venue-data', payload).then((response) => {
            if (response.status === 1) {
                this.setState({ data: response.data }, () => {
                    this.updateChart(view, response.data);
                });
            }
        });
    };

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleCustomApply = () => {
        const { selectedYear, selectedMonth } = this.state;
        //console.log('Selected Year:', selectedYear);
        //console.log('Selected Month:', selectedMonth);
        this.toggleModal();

        this.setState({
            view: selectedMonth ? 'monthly' : 'annual'
        }, () => {
            this.fetchData(this.state.view);
        });
    }

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value });
    }

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value });
    }

    render() {
        const { view, series, options, modal, selectedYear, selectedMonth } = this.state;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active={view === 'monthly'} onClick={() => this.fetchData('monthly')}>Monthly</Button>
                                <Button size="sm" color="light" active={view === 'annual'} onClick={() => this.fetchData('annual')}>Annual</Button>
                                <Button size="sm" color="light" active={view === 'custom'} onClick={this.toggleModal}>Custom</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Venue Impressions</h4>
                        <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart options={options} series={series} type="line" height="350" />
                        </div>
                    </CardBody>
                </Card>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Select Date</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="yearSelect">Year</Label>
                            <Input type="select" id="yearSelect" value={selectedYear} onChange={this.handleYearChange}>
                                {Array.from({ length: moment().year() - 2023 + 1 }, (_, i) => 2023 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Month (optional)</Label>
                            <Input type="select" id="monthSelect" value={selectedMonth} onChange={this.handleMonthChange}>
                                <option value="">All</option>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCustomApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RevenueAnalytics;
