import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "components/Common/Utils";
import Avatar from "react-avatar";
import { useParams } from "react-router-dom";
import moment from "moment";

import { format } from "date-fns";
import StarComponent from "./StarComponent";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import "./CustomCarduser.css";
import { DetailsOutlined, Visibility } from "@material-ui/icons";
import BucketList from "./BucketList";
// import UserFollowerListTable from "./UserFollowerListTable";
import BucketListTable from "./BucketListTable";
import ReviewListTable from "./ReviewListTable";
// import EventFollowingListTable from "./EventFollowingListTable";
// import UserCheckinTable from "./UserCheckinTable.js";
import SubscriptionTablelist from "./SubsriptionTablelist";
import VenueFollowingListTable from "./VenueFollowingListTable";
// import UserFollowingListTable from "./UserFollowingListTable";
import ClaimsListTable from "./ClaimsListTable";
import NotificationListTable from "./NotificationListTable";
import OrderManagementListTable from "./OrderManagmentListTable.js";
// import OutingsListTable from "./OutingsListTable.js";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Userdetails() {
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [venueData, setData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const location1 = useLocation();
  const [model, setModel] = useState(false);
  const [offer, setOffer] = useState();
  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#e22a2a", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    detailIcon: {
      color: "blue",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const UserFetchDetail = async () => {
    setLoader(false);
    postRequest(`user/detail`, { userId: `${id}` }).then((data) => {
      let userdata = data.data;
      let status = data.status;
      setData(userdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    UserFetchDetail();
  }, []);
  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const {
    first_name,
    last_name,
    dateOfBirth,
    gender,
    country_code,
    platform,
    about,
    social_platform,
    address,
    image,
    cover,
    phone,
    email,
    website,
    booking_url,
    menu_url,
    dress_code,
    createdAt,
    updatedAt,
    location,
    subscription,
  } = venueData || {};

  const initials =
    venueData?.first_name?.charAt(0).toUpperCase() +
    venueData?.last_name?.charAt(0).toUpperCase();
  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="10">
            <div className="page-title-box">
              <h4>User Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">User Management</li>:
                <li className="breadcrumb-item">User details</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.back()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              <Col md="12">
                <Row>
                  <Card className="custom-card">
                    <div
                      className="cover-image"
                      style={{ backgroundColor: "rgb(29, 155, 240)" }}
                    ></div>
                    <div className="profile-image">
                      {venueData?.image ? (
                        <img
                          src={image}
                          onClick={() => showLightBox(image)}
                          alt="User Avatar"
                        />
                      ) : (
                        // <Avatar
                        //   name={`${first_name} ${last_name}`}
                        //   src={image}
                        //   round={true}
                        //   size="74"
                        //   textSizeRatio={2}
                        //   // className="profile-image2"
                        // >
                        //   {initials}
                        // </Avatar>
                        <CustomAvatar
                          iconSize="74"
                          name={
                            first_name + last_name == ""
                              ? "*"
                              : `${first_name} ${last_name} `
                          }
                        />
                      )}
                    </div>

                    <div className="row my-2 py-2">
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "20px" }}
                      >
                        <br></br>

                        <div className="fst">
                          <h3>
                            {first_name} {last_name}
                            {name}
                          </h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4 fst py-1">
                            <p>
                              DOB: <span> {dateOfBirth}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Gender: <span> {gender}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              phone:
                              <span>
                                {country_code} {phone}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Email: <span> {email}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Platform: <span> {platform}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Social Platform: <span> {social_platform}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Created At: <span> {formatDate(createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>
              <Col md="12" style={{marginLeft:"inherit"}}>
                <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{ background: "#ffff", marginLeft: "25px" }}
                >
                  <ul
                    className="nav nav-pills"
                    style={{ width: "480px", display: "contents" }}
                  >
                    <li className="active">
                      <a href="#bucketList" data-toggle="tab">
                        Bucket List
                      </a>
                    </li>

                    <li>
                      <a href="#vfollowing" data-toggle="tab">
                        Venue Following
                      </a>
                    </li>
                    {/* <li>
                      <a href="#efollowing" data-toggle="tab">
                        Event Following
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#lfollowing" data-toggle="tab">
                        Following
                      </a>
                    </li> */}
                    {/* <li>
                      <a href="#Ufollowing" data-toggle="tab">
                        Followers
                      </a>
                    </li> */}

                    <li>
                      <a href="#claims" data-toggle="tab">
                        Claims
                      </a>
                    </li>
                    {/* <li>
                      <a href="#checkin" data-toggle="tab">
                        Checkin
                      </a>
                    </li> */}

                    <li>
                      <a href="#notifications" data-toggle="tab">
                        Notifications
                      </a>
                    </li>
                    <li>
                      <a href="#orders" data-toggle="tab">
                        Orders
                      </a>
                    </li>
                    <li>
                      <a href="#subscription" data-toggle="tab">
                        {" "}
                        Subscription{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#reviews" data-toggle="tab">
                        Reviews
                      </a>
                    </li>
                    {/* <li>
                      <a href="#outings" data-toggle="tab">
                        Outings
                      </a>
                    </li> */}
                  </ul>
                </div>
              </Col>

              <Col md="12">
                <div className="tab-content clearfix">
                  <div className="tab-pane active padded-tab" id="bucketList">
                    {venueData?.length !== 0 && (
                      <BucketListTable
                        userData={venueData?.buckets}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>

                  {/* <div className="tab-pane padded-tab" id="outings">
                    {venueData?.length !== 0 && (
                      <OutingsListTable
                        userData={venueData?.buckets}
                        outingsUserId={venueData?._id}
                      />
                    )}
                  </div> */}
                  <div className="tab-pane padded-tab" id="reviews">
                    {venueData?.length !== 0 && (
                      <ReviewListTable
                        userData={venueData?.ratings}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>

                  <div className="tab-pane padded-tab" id="vfollowing">
                    {venueData?.length !== 0 && (
                      <VenueFollowingListTable
                        userData={venueData?.follows}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>

                  {/* <div className="tab-pane padded-tab" id="efollowing">
                    {venueData?.length !== 0 && (
                      <EventFollowingListTable
                        userData={[]}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div> */}

                  {/* <div className="tab-pane padded-tab" id="lfollowing">
                    {venueData?.length !== 0 && (
                      <UserFollowingListTable
                        userData={[]}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div> */}

                  {/* <div className="tab-pane padded-tab" id="Ufollowing">
                    {venueData?.length !== 0 && (
                      <UserFollowerListTable
                        userData={[]}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div> */}
                  {/* <div className="tab-pane padded-tab" id="checkin">
                    {venueData?.length !== 0 && (
                      <UserCheckinTable
                        userData={venueData?.checkins}
                        userId={venueData?._id}
                      />
                    )}
                  </div> */}

                  <div className="tab-pane padded-tab" id="subscription">
                    {venueData?.subscription ? (
                      <SubscriptionTablelist
                        key="subscriptionTable"
                        userData={venueData?.subscription}
                        bucketUserId={venueData?._id}
                      />
                    ) : (
                      <p key="noSubscription" style={{ fontSize: "18px" }}>
                        User don't have subscription to any plan
                      </p>
                    )}
                  </div>

                  <div className="tab-pane padded-tab" id="claims">
                    {venueData?.length !== 0 && (
                      <ClaimsListTable
                        userData={venueData?.claims}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>

                  <div className="tab-pane padded-tab" id="notifications">
                    {venueData?.length !== 0 && (
                      <NotificationListTable
                        userData={venueData}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>
                  <div className="tab-pane padded-tab" id="orders">
                    {venueData?.length !== 0 && (
                      <OrderManagementListTable
                        userData={venueData}
                        bucketUserId={venueData?._id}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <BucketList
        showBucketModal={model}
        closeBucketModal={closeBucketModal}
        offer={offer}
      />
    </>
  );
}
