import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {postRequest} from "../../components/Common/Utils.js";

export default function adminSetting() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [businessData, setBusinessData] = useState({
   login_credential: "", // Initialize with empty strings
    password: "",
    confirm_password: "",
  });

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    setBusinessData(state || {}); // Set state to an empty object if 'state' is undefined
  }, [state]);

  const updateBusinessPassword = async (e) => {
    e.preventDefault();

    if(!businessData.oldPassword){
      toast.error("Old password is required.");
      return;
    }
    
    if (!businessData.password) {
      toast.error("password is required.");
      return;
    }

    if(!businessData.confirm_password){
      toast.error("Confirm password is required.");
      return;
    }

    // check old password and new password are same

    if(businessData.oldPassword === businessData.password){
      toast.error("Old password and new password should not be the same.");
      return;
    }

    if (businessData.password !==businessData.confirm_password) {
      toast.error("Password and Confirm password should  be the same.");
      return;
    }
   

    setLoading(true);

    // Create form data with businessId and password only
  
    



    const response = await postRequest("user/admin/update-password", {
      old_password: businessData.oldPassword,
      password: businessData.password     
    });

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venues");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updatePassword = (field, value) => {
    setBusinessData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Admin Setting </h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Setting Management</li>
              <li className="breadcrumb-item">Update Password</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4>Update Credential</h4>
                <Form onSubmit={updateBusinessPassword}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Login Credential
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="email"
                              onChange={(e) =>
                                updatePassword("login_credential", e.target.value)
                              }
                              name="login_credential"
                              className="form-control"
                              value={businessData.login_credentail}
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Old Password
                          </label>
                          <div className="col-sm-6">
                          <span
                              style={{
                                padding: "0",
                                border: "none",
                                background: "none",
                              }}
                              className="input-group-text"
                              
                            >
                            <input
                              type={showPassword ? "text" : "password"}
                              onChange={(e) =>
                                updatePassword("oldPassword", e.target.value)
                              }
                              name="oldPassword"
                              className="form-control"                              
                            />
                            <i
                                style={{ marginLeft: 15 }}
                                onClick={() => setShowPassword(!showPassword)}
                                className={`fa ${
                                  showPassword ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            New Password
                          </label>
                          <div className="col-sm-6">
                          <span
                              style={{
                                padding: "0",
                                border: "none",
                                background: "none",
                              }}
                              className="input-group-text"
                             
                            >
                            <input
                              type={showPassword2 ? "text" : "password"}
                              onChange={(e) =>
                                updatePassword("password", e.target.value)
                              }
                              name="password"
                              className="form-control"                              
                            />
                             <i
                                style={{ marginLeft: 15 }}
                                onClick={() => setShowPassword2(!showPassword2)}
                                className={`fa ${
                                  showPassword2 ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </span>
                          </div>
                        </div>                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Confirm Password
                          </label>
                          <div className="col-sm-6">
                            
                          <span
                              style={{
                                padding: "0",
                                border: "none",
                                background: "none",
                              }}
                              className="input-group-text"
                              
                            >
                            <input
                              type={showPassword3 ? "text" : "password"}
                              onChange={(e) =>
                                updatePassword(
                                  "confirm_password",
                                  e.target.value
                                )
                              }
                              name="confirm_password"
                              className="form-control"                              
                            />
                            <i
                                style={{ marginLeft: 15 }}
                                onClick={() => setShowPassword3(!showPassword3)}
                                className={`fa ${
                                  showPassword3 ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
