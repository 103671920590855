import React from 'react';
import { Bar } from 'react-chartjs-2';


function allLabelsHaveSameYear(labels) {
    const years = new Set(labels.map(label => label.split('-')[2]));
    return years.size === 1;
}

function formatLabelToHumanReadable(labels) {
    return labels.map(label => {
        const [day, month, year] = label.split('-').map(str => parseInt(str, 10));
        
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return label;  // Return the original label if there's a parsing issue.
        }
        
        
        const date = new Date(year + 2000, month - 1, day); // +2000 assumes years are like '23 for 2023
        
        if (isNaN(date)) {
            return label;  // Return the original label if the date is not valid.
        }

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        return `${monthNames[date.getMonth()]} ${date.getDate().toString().padStart(2, '0')}`;
    });
}

const BarChartComponent = ({ label, data }) => {
   

    let formattedLabels = data.labels;
    if (allLabelsHaveSameYear(data.labels)) {
        formattedLabels = formatLabelToHumanReadable(data.labels);
    }

    const options = {
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
                gridLines: {
                    display: false,
                },
            }],
            xAxes: [{
                gridLines: {
                    display: false,
                },
                barPercentage: 0.4,
            }],
        },
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: label
        }
    };

    // Modify the datasets for non-transparency
    data.datasets.forEach(dataset => {
        dataset.backgroundColor = dataset.backgroundColor.replace('0.6', '1');
    });

    data.labels = formattedLabels;

    return <Bar data={data} options={options} />;
};

export default BarChartComponent;
