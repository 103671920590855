import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";

export default function CreateTheme() {
  const state = location.state?.row;
  const [themeData, setThemeData] = useState({ title: "" });
  const [countries, setCountries] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [loading, setLoading] = useState(false);

  let history = useHistory();


  useEffect(() => {
    if (!state) return;

    

    if (state?.categoryId) {
      setCategoryId(state?.categoryId);

      const defaulit = [];
      defaulit.push(state?.categoryId);

      setSelectedCategory([...defaulit]);

     
    }

  }, [state]);


  useEffect(() => {
    fetchTheme();
    fetchCategoryList();
  }, []);

  const Createtheme = async (e) => {
    e.preventDefault();

    if(!themeData.title){
      toast.error("Please enter  title");
      return;
    }

    if(!themeData.type){
      toast.error("Please select type");
      return;
    }

    if(selectedCategory.length == 0){
      toast.error("Please select category");
      return;
    }
    

    setLoading(true);
    const dataObj = {     
      title: themeData.title,
      type: themeData.type,
      categoryIds: selectedCategory,

    };

    const response = await postRequest("user/add-filter-category", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/filtercategories");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const updateValue = (index, value) => {
    const list = { ...themeData };
    list[index] = value;
    setThemeData(list);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/country/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCountries(options);
    }
  };

  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Filter Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Filter Category Management</li>:{" "}
              <li className="breadcrumb-item">Create Filter Category</li>
            </ol>
          </div>
        </div>
        <div className="col-lg-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
        </div>
      </div>     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Filter Category </h4>
                <Form onSubmit={Createtheme}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Type
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("type", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="">Select Type</option>
                            {/* {countries.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))} */}
                            <option value="sortByPurchased">Sort By Purchased</option>
                            <option value="sortByClick">Sort By Click</option>
                            
                          </select>
                        </div>
                      </div>

                      {state?.categoryId ? null : (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>
                            <div className="col-sm-6">
                              <MultiSelect
                                options={categoryList}
                                onSelect={msUpdateCategory}
                                selectedValues={defaultCategory}
                                placeholder="Select Category"
                              />
                            </div>
                          </div>
                        )}


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
