import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  putRequestForm,postRequestForm } from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUpload from "../../components/Common/FileUpload"; 
export default function UpdatePage() {

  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;  
  
  const [pageData, setPageData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);  

 

  const { id } = useParams();

  useEffect(() => {
    setPageData(state);
    setImageIcon( state?.image ? state?.image : "" )
  }, [state])

  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
      setImageIcon("");
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const showLightBox2 = (imageIcon) => {
    setImageIcon(imageIcon);
    setOpenLightBox2(true);
  };
  

  const Updatepage = async (e) => {
    e.preventDefault();
    if(!pageData.title){
      toast.error("Title is required");
      return;
    }
    if(!pageData.description){
      toast.error("Please enter description");
      return;
    }
   
  
    const formData = new FormData();

    let selectedImageRes = null;

    if(selectedImage){
      const selectedImageForm = new FormData();
      selectedImageForm.append("image", selectedImage);
      setStartLoader(true);
      selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
      setStartLoader(false);
    }

    formData.append("pageId", id);
    formData.append("title", pageData.title);
    formData.append("description", pageData.description);
    formData.append("status", pageData.status == 'true' ||pageData.status == true  ? 1 : 0);    
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {      
      console.error("Image URL not found in the response.");
    } 
    setLoading(true);
    const response = await postRequestForm("comman/dynamic-page/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/pages");
      }, 3000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...pageData}
    list[index] = value
    setPageData(list)
  }    

  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Page Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Page Management</li>:{" "}
              <li className="breadcrumb-item">
                Update Page
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
     
     
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Update Page </h4>
              <Form onSubmit={Updatepage}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } value={pageData?.title} name='title'  className="form-control"  />
                            </div>
                        </div>   
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Description</label>
                            <div className="col-sm-6">
                              {/* <textarea type="textarea" onChange={ (e) => updateValue('description', e.target.value) } value={pageData?.description} name='description' className="form-control" id="horizontal-password-input" /> */}
                              <ReactQuill
                            style={{height: "400px",width: "100%", marginBottom: "40px"}}  
                            value={pageData?.description}                            
                              onChange={ (e) => updateValue('description', e) }
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, false] }],
                                  [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                  ],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image", "video"],
                                  ["clean"],
                                ],
                              }}
                              formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "video",
                              ]} 
                            />
                            </div>
                        </div>  
                        <div className="row mb-4">
                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Image</label>
                            <div className="col-sm-6">
                            <input type="file" accept="image/*" onChange={(e) => setSelectedImage(e.target.files[0])}  name='image' className="form-control" id="horizontal-password-input" />
                           <br/>
                            {previewImage && (                            
                                  <img src={previewImage} 
                                   onClick={() => showLightBox(previewImage)}
                                  style={{width: "100px", height: "100px"}}/>                              
                              )}

                              {imageIcon && (
                                <img src={imageIcon}
                                onClick={() => showLightBox2(imageIcon)}
                                 style={{width: "100px", height: "100px"}}/>
                              )}
                            </div>                           
                        </div>   
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Status</label>
                            <div className="col-sm-6">
                              <select value={pageData?.status} onChange={ (e) => updateValue('status', e.target.value) }  class="form-select">
                                   <option>  Select</option>
                                   <option value="true">True</option>
                                   <option value="false">False</option>
                                 </select>
                            </div>                           
                        </div>              
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        {openLightbox2 && (
          <Lightbox
            mainSrc={imageIcon}
            onCloseRequest={() => setOpenLightBox2(false)}
          />
        )}
        <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
