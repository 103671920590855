// CustomEvent.js
import React from 'react';
import { Badge } from 'react-bootstrap';

const CustomEvent = ({ event }) => {
    const eventData = event.title.split(', ').map((item) => {
        const [label, value] = item.split(': ');
        return { label, value };
    });

    const getBadgeVariant = (label) => {
        switch (label) {
            case 'Total Commission':
                return 'primary';
            case 'Venue Add':
                return 'success';
            case 'Business Add':
                return 'warning';
            case 'Offer Create':
                return 'info';
            case 'Discount Add':
                return 'danger';
            case 'Discount Claimed':
                return 'secondary';
            // case 'Deal Created':
            //     return 'dark';
            default:
                return 'light';
        }
    };

    return (
        <div className="event-badges"  style={{ marginTop: "80px !important" }}>
            {eventData.map((item, index) => (
                <Badge key={index} bg={getBadgeVariant(item.label)} className="slim-badge text-center">
                    {item.value}
                </Badge>
            ))}
        </div>
    );
};

export default CustomEvent;
