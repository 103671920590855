import React, { useState } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./preview.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    maxWidth: "375px",
    margin: "0 auto",
    borderRadius: "10px",
    backgroundColor: "#F9FBFC", // Customize the background color of AppBar
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "white", // Customize the indicator color
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ overflow: "hidden" }} // Set overflow to hidden
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const defaultPropValues = {
  packageId: "",
  frequency: 1,
  title: "Enter title",
  subTitle: "Enter subtitle",
  description: "Enter description",
  buttonText1: "Click Me",

  image: "https://ehs.stanford.edu/wp-content/uploads/missing-image.png",
  backgroundType: "color",
  backgroundImage:
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1692362954233abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner.jpg",
  startColor: "#3254EB",
  endColor: "#14183C",
  packageName: "",
  packagePrice: "",
  packageDuration: "",
  titleColor: "#ffffff",
  subTitleColor: "#ffffff",
  descriptionColor: "#808080",
};

const MockupWrapper = ({ children, backgroundImage }) => (
  <div
    style={{
      position: "relative",
      maxWidth: "375px", // Adjust this to the desired width of the mockup
      margin: "0 auto",
      background: `url(${backgroundImage}) no-repeat center`,
      backgroundSize: "cover",
      height: "667px", // Adjust this to match the height of the mockup
    }}
  >
    {children}
  </div>
);

const mergeProps = (defaultProps, passedProps) => {
  let mergedProps = Object.assign({}, defaultProps);
  for (let key in passedProps) {
    if (passedProps[key] === undefined) {
      continue;
    }
    passedProps[key] = passedProps[key].toString();
    if (passedProps[key] && passedProps[key]?.trim() !== "") {
      mergedProps[key] = passedProps[key];
    }
  }
  return mergedProps;
};

const NotificationPreview = ({
  notificationDialog = defaultProps, 
  onTabChange}) => {
  const classes = useStyles();
  const dialogData = mergeProps(defaultPropValues, notificationDialog);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeTab, setActiveTab] = useState("classic");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Update the active tab based on the selected index
    if (newValue === 0) {
      setActiveTab("classic");
      onTabChange("classic");
    } else if (newValue === 1) {
      setActiveTab("feed");
      onTabChange("feed");
    }

    // Call the callback to pass the activeTab value to the parent component
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const bgStyle = {
    // background: `linear-gradient(#f8f9fa, #e9ecef)`,
    minHeight: "600px",
  };

  const cardBgStyle = {
    background: dialogData?.bgColor || "#3B3B3B",
    marginTop: dialogData?.buttonText2 ? "125px" : "160px",
    borderRadius: "10px",
  };
  const cardBgStyle2 = {
    background: dialogData?.bgColor || "#3B3B3B",
    marginTop: dialogData?.buttonText2 ? "125px" : "160px",
    borderRadius: "10px",

    maxHeight: "410px",
    width: "242px",
  };

  return (
    <div className="notification-preview-container">
      <AppBar
        style={{
          maxWidth: "375px",
          margin: "auto",
          borderRadius: "10px",
          background: "#1A76D2",
        }}
        position="static"
        className={classes.appBar}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab label="Classic" {...a11yProps(0)} />
          <Tab label="Feed" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabPanel value={value} index={0} dir={theme.direction}>
          <MockupWrapper backgroundImage="https://img.freepik.com/free-vector/realistic-front-view-smartphone-mockup-mobile-iphone-purple-frame-with-blank-white-display-vector_90220-959.jpg?w=740&t=st=1693026908~exp=1693027508~hmac=975859aab937e4ecc869e3db4d344a83985307c144eb04bfaa26a22bc8ae66ef">
            <div className=" mt-lg-0" style={bgStyle}>
              <div
                className="row mb-4 align-items-center"
                style={{ marginBottom: "50px" }}
              >
                <label className="col-sm-2 col-form-label text-center"></label>

                <div className="col-sm-8">
                  <div className="card" style={cardBgStyle}>
                    <div className="card-body">
                      <h4
                        className="title"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "24px",
                          lineHeight: "25px",
                          fontWeight: "bold",
                          color: dialogData?.titleColor,
                          textAlign: dialogData.titleAlignment || "center",
                        }}
                      >
                        {dialogData.title}
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5
                        className="subtitle"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: dialogData?.subTitleColor,
                          textAlign: dialogData.subTitleAlignment || "center",
                        }}
                      >
                        {dialogData.subTitle}
                      </h5>
                      <img
                        src={dialogData.image}
                        alt="Card content"
                        style={{
                          display: "block",
                          margin: "20px auto",
                          width: "150px",
                          height: "150px",
                          // borderRadius: "10%",
                          // marginRight: "60px",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                          // fontWeight: "bold",
                          padding: "0px",
                          margin: "10px 0",
                          color: dialogData.descriptionColor,
                          textAlign: dialogData.descAlignment || "center",
                        }}
                      >
                        {dialogData.description}
                      </p>

                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          background: dialogData.btn1Color || "white",
                          color: dialogData.btn1TextColor || "black",
                          width: "100%",
                          borderRadius: "7px",
                          fontSize: "16px",
                          fontWeight: "550",
                          border: "none",
                          cursor: "pointer",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {dialogData.buttonText1}
                      </button>
                      {dialogData.buttonText2 && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            background: dialogData.btn2Color || "white",
                            color: dialogData.btn2TextColor || "black",
                            width: "100%",
                            borderRadius: "7px",
                            fontSize: "16px",
                            fontWeight: "550",
                            border: "none",
                            cursor: "pointer",
                            fontFamily: "Montserrat",
                            margin: "10px 0",
                          }}
                        >
                          {dialogData.buttonText2}
                        </button>
                      )}
                    </div>
                  </div>

                  {dialogData.packageName &&
                    dialogData.packagePrice &&
                    dialogData.packageDuration && (
                      <div className="card" style={cardBgStyle}>
                        <div className="card-body" style={{ color: "white" }}>
                          <h4
                            className="card-title"
                            style={{ fontFamily: "Georgia, serif" }}
                          >
                            {dialogData.packageName}
                          </h4>
                          <h5
                            className="card-title"
                            style={{ fontWeight: "bold", marginBottom: "20px" }}
                          >
                            {dialogData.packagePrice}{" "}
                            <span style={{ fontSize: "12px" }}>
                              / {dialogData.packageDuration}
                            </span>
                          </h5>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              background: "white",
                              color: "black",
                              width: "100%",
                              borderRadius: "7px",
                              fontSize: "16px",
                              fontWeight: "550",
                              border: "none",
                              cursor: "pointer",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {dialogData.buttonText}
                          </button>
                          <a
                            href="#"
                            className="text-center"
                            style={{
                              color: "#626262",
                              fontSize: "10px",
                              textDecoration: "underline",
                              display: "block",
                              margin: "10px 0",
                            }}
                          >
                            Terms & Condition Applies
                          </a>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </MockupWrapper>
        </TabPanel>
      )}
      {value === 1 && (
        <TabPanel value={value} index={1} dir={theme.direction}>
          <MockupWrapper backgroundImage="https://img.freepik.com/free-vector/realistic-front-view-smartphone-mockup-mobile-iphone-purple-frame-with-blank-white-display-vector_90220-959.jpg?w=740&t=st=1693026908~exp=1693027508~hmac=975859aab937e4ecc869e3db4d344a83985307c144eb04bfaa26a22bc8ae66ef">
            <div className="mt-5 mt-lg-0" style={bgStyle}>
              <div
                className="row mb-4 align-items-center"
                style={{ marginBottom: "50px" }}
              >
                <label className="col-sm-2 col-form-label text-center"></label>

                <div className="col-sm-8">
                  <div className="card" style={cardBgStyle2}>
                    <img
                      src={dialogData.image}
                      alt="Card content"
                      style={{
                        display: "block",
                        maxWidth: "250px",
                        height: "180px",
                        borderTopLeftRadius: "9px", // Radius for top-left corner
                        borderTopRightRadius: "9px",
                        // marginRight: "60px",
                      }}
                    />
                    <div className="card-body">
                      <h4
                        className="title"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "24px",
                          lineHeight: "25px",
                          fontWeight: "bold",
                          color: dialogData?.titleColor,
                          textAlign: dialogData.titleAlignment || "center",
                        }}
                      >
                        {dialogData.title}
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5
                        className="subtitle"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "25px",
                          color: dialogData?.subTitleColor,
                          textAlign: dialogData.subTitleAlignment || "center",
                        }}
                      >
                        {dialogData.subTitle}
                      </h5>

                      <p
                        style={{
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                          // fontWeight: "bold",
                          padding: "0px",
                          margin: "10px 0",
                          color: dialogData.descriptionColor,
                          textAlign: dialogData.descAlignment || "center",
                        }}
                      >
                        {dialogData.description}
                      </p>

                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          background: dialogData.btn1Color || "white",
                          color: dialogData.btn1TextColor || "black",
                          width: "100%",
                          borderRadius: "7px",
                          fontSize: "16px",
                          fontWeight: "550",
                          border: "none",
                          cursor: "pointer",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {dialogData.buttonText1}
                      </button>
                      {dialogData.buttonText2 && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            background: dialogData.btn2Color || "white",
                            color: dialogData.btn2TextColor || "black",
                            width: "100%",
                            borderRadius: "7px",
                            fontSize: "16px",
                            fontWeight: "550",
                            border: "none",
                            cursor: "pointer",
                            fontFamily: "Montserrat",
                            margin: "10px 0",
                          }}
                        >
                          {dialogData.buttonText2}
                        </button>
                      )}
                    </div>
                  </div>

                  {dialogData.packageName &&
                    dialogData.packagePrice &&
                    dialogData.packageDuration && (
                      <div className="card" style={cardBgStyle}>
                        <div className="card-body" style={{ color: "black" }}>
                          <h4
                            className="card-title"
                            style={{ fontFamily: "Georgia, serif" }}
                          >
                            {dialogData.packageName}
                          </h4>
                          <h5
                            className="card-title"
                            style={{ fontWeight: "bold", marginBottom: "20px" }}
                          >
                            {dialogData.packagePrice}{" "}
                            <span style={{ fontSize: "12px" }}>
                              / {dialogData.packageDuration}
                            </span>
                          </h5>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              background: "white",
                              color: "black",
                              width: "100%",
                              borderRadius: "7px",
                              fontSize: "16px",
                              fontWeight: "550",
                              border: "none",
                              cursor: "pointer",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {dialogData.buttonText}
                          </button>
                          <a
                            href="#"
                            className="text-center"
                            style={{
                              color: "#626262",
                              fontSize: "10px",
                              textDecoration: "underline",
                              display: "block",
                              margin: "10px 0",
                            }}
                          >
                            Terms & Condition Applies
                          </a>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </MockupWrapper>
        </TabPanel>
      )}
    </div>
  );
};

export default NotificationPreview;
