import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm,
  pushUpdates,
} from "../../../components/Common/Utils.js";

import FileUpload from "../../../components/Common/FileUpload";

import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";
import DayTimePicker from "./DayTimePicker";

import LocationPicker from "react-location-picker";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

import BusinessModal from "components/Modal/BusinessModal";
export default function UpdateVenue() {
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [venueData, setVenueData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loading, setLoading] = useState(false);

  const [startLoader, setStartLoader] = useState(false);

  const [lockSubmit, setLockSubmit] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [completedCrop, setCompletedCrop] = useState(null);

  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);

  const [remoteLogo, setRemoteLogo] = useState(null);
  const [businessOptions, setBusinessOptions] = useState([
    { value: "", label: "None" },
  ]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinessData, setSelectedBusinessData] = useState([]);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };

  const fetchIndex = (items, search) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value === search) {
        return i;
      }
    }
    return -1;
  };

  let history = useHistory();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const locationArray = state?.location?.coordinates;
    state.latitude = locationArray[1];
    state.longitude = locationArray[0];
    state.business_id = state.business._id;
    setCurrentLocation({
      lat: parseFloat(state.latitude),
      lng: parseFloat(state.longitude),
    });

    //console.log("state", state)

    setVenueData(state);
    setBusinessId(state?.business?._id)
    setBusinessText(state?.business?.name)
    setBusinessImage(state?.business?.logo)
    setPreviewCover(state?.cover ? state?.cover : "");
    setPreviewLogo(state?.logo ? state?.logo : "");

    if (state?.timings && state?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      state?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));

        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });

      setTimeOptions(newTimeOptions);
    }

    setSelectedTheme(state?.themes ? state?.themes : []);
    setSelectedMusic(state?.music ? state?.music : []);
    setSelectedFeature(state?.features ? state?.features : []);
    setSelectedCuisine(state?.cuisines ? state?.cuisines : []);

  }, [state]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

    const selectRow = (id, name, logo) => {

    setBusinessId(id?._id);
    setBusinessText(id?.name);
    setBusinessImage(id?.logo);
    setSelectedBusinessData(id);
    closeModal();
  };

  useEffect(() => {
    fetchTheme();
  }, [selectedTheme]);

  useEffect(() => {
    fetchMusic();
  }, [selectedMusic]);

  useEffect(() => {
    fetchFeature();
  }, [selectedFeature]);

  useEffect(() => {
    fetchCuisine();
  }, [selectedCuisine]);

 

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], venueData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
      
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;    
    const venueDt = { ...venueData, latitude: lat, longitude: lng };
    setVenueData(venueDt);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setThemeList(options);

      if (selectedTheme.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedTheme.includes(item.id);
        });        
        setDefaultTheme(defaultItems);
      }
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);

      if (selectedMusic.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedMusic.includes(item.id);
        });
        setDefaultMusic(defaultItems);
      }
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);
      if (selectedFeature.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedFeature.includes(item.id);
        });
        
        setDefaultFeature(defaultItems);
      }
    }
  };

  function handleFileUpload(e) {
    const file = e.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    // Check the file type

    if (file.type === "application/pdf") {
      // Handle PDF file
      handleUpload(file, "pdf");
    } else if (file.type.startsWith("image/")) {
      // Handle image file
      handleUpload(file, "image");
    } else {
      // Invalid file type
      alert("Unsupported file type. Please select a PDF or an image.");
    }
  }

  async function handleUpload(file, type) {
    setLockSubmit(true);
    setStartLoader(true);
    let url = "comman/doc/upload";

    if (type === "image") {
      url = "comman/img/upload";
    }

    const pdfFile = file;
    const formData = new FormData();
    formData.append("file", pdfFile);

    const selectedImageRes = await postRequestForm(url, formData);

    const venData = { ...venueData };
    if (selectedImageRes?.data) {      
      venData["menu_url"] = selectedImageRes.data.url;
      setVenueData(venData);
    } 
    setStartLoader(false);
    setLockSubmit(false);
  }
  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCuisineList(options);
      if (selectedCuisine.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCuisine.includes(item.id);
        });
        setDefaultCuisine(defaultItems);
      }
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);

  const Updatevenue = async (e) => {
    e.preventDefault();  

    if (!venueData.name) {
      toast.error("Please enter venue name");
      return;
    }
    if (!venueData.about) {
      toast.error("Please enter venue about");
      return;
    }
    if (!venueData.address) {
      toast.error("Please enter venue address");
      return;
    }
    if (!venueData.phone) {
      toast.error("Please enter venue phone");
      return;
    }
    

    if (!venueData.latitude) {
      toast.error("Please enter venue latitude");
      return;
    }

    if (!venueData.longitude) {
      toast.error("Please enter venue longitude");
      return;
    }
     // claim code must be 4 digit
    //  if (!venueData.claimCode) {
    //   toast.error("Please enter venue claim code (4 digit)");
    //   return;
    // }

    if (!selectedTheme.length) {
      toast.error("Please select venue theme");
      return;
    }

    if (!selectedFeature.length) {
      toast.error("Please select venue feature");
      return;
    }

    if (!selectedCuisine.length) {
      toast.error("Please select venue cuisine");
      return;
    }

    let atLeastOneChecked = false;

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        atLeastOneChecked = true;
        return; // Break out of the loop since at least one is checked
      }
    });

    if (!atLeastOneChecked) {
      toast.error("Please select at least one time");
      return;
    }



    // let bussinessId = venueData.business_id?.value;

    // if (typeof venueData.business_id === "string") {
    //   bussinessId = venueData.business_id;
    // }

    const formData = new FormData();

    
    const selectedImageForm = new FormData();
    let selectedImageRes = null;
    if (remoteLogo) {
    selectedImageForm.append("image", remoteLogo);
    setStartLoader(true);
    selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    setStartLoader(false);
    }

    const selectedImageForm2 = new FormData();
    let selectedImageRes2 = null;
    if (selectedCover) {
    selectedImageForm2.append("image", selectedCover);
    setStartLoader(true);
    selectedImageRes2 = await postRequestForm(
      "comman/img/upload",
      selectedImageForm2
    );
    setStartLoader(false);
    }

    formData.append("venueId", id);    
    formData.append("name", venueData.name);
    formData.append("about", venueData.about);
    formData.append("address", venueData.address);
    formData.append("phone", venueData.phone);
    formData.append("email", venueData.email);
    formData.append("website", venueData.website);
    formData.append("latitude", venueData.latitude);
    formData.append("longitude", venueData.longitude);
    formData.append("booking_url", venueData.booking_url);
    formData.append("menu_url", venueData.menu_url);
    formData.append("dress_code", venueData.dress_code);
    formData.append("claimCode", venueData.claimCode);
    formData.append("discountText", venueData.discountText);
    formData.append("isAllowReview", venueData.isAllowReview);
    formData.append("isAllowRatting", venueData.isAllowRatting);

    if (selectedImageRes?.data?.url) {
      formData.append("logo", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("cover", selectedImageRes2.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }

    if (selectedTheme && selectedTheme.length > 0) {
      selectedTheme.forEach((item) => {
        formData.append("themes[]", item);
      });
    }

    if (selectedMusic && selectedMusic.length > 0) {
      selectedMusic.forEach((item) => {
        formData.append("music[]", item);
      });
    }

    if (selectedFeature && selectedFeature.length > 0) {
      selectedFeature.forEach((item) => {
        formData.append("features[]", item);
      });
    }

    if (selectedCuisine && selectedCuisine.length > 0) {
      selectedCuisine.forEach((item) => {
        formData.append("cuisines[]", item);
      });
    }

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        }
      });
    }
    setLoading(true);
    const response = await putRequestForm("venue/update", formData);
    setLoading(false);

    if (response.status === 1) {
      // If the update is successful, call the pushUpdates function
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "venue",
          id: id,
          receivers: [],
        });
        // Check if the pushUpdates call was successful, handle the response as needed       
        toast.success(response.message);
        setTimeout(() => {
          history.push("/venues");
        }, 3000);
      } catch (pushUpdatesError) {
        toast.error("Error in pushUpdates");
      }
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);

    if (index == "business_id") {
      const Bdata = businessData.find((x) => x._id === list.business_id);
      setSelectedBusinessData(Bdata);
    }
  };

  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }
    setCurrentLocation(newLocation);
    setVenueData({
      ...venueData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Update Venue</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Venue </h4>
                <Form onSubmit={Updatevenue}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                      
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={venueData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              value={venueData?.about}
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              value={venueData?.address}
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              defaultValue={venueData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={venueData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              value={venueData?.website}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Url
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("booking_url", e.target.value)
                              }
                              value={venueData?.booking_url}
                              name="booking_url"
                              className="form-control"
                            />
                          </div>
                        </div>
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Menu PDF or Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              accept=".pdf, image/*"
                              onChange={(e) => handleFileUpload(e)}
                              name="menu_url"
                              className="form-control"
                            />
                            <br></br>
                            {venueData?.menu_url && (
                              <a
                                href={venueData.menu_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                Download
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Dress Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("dress_code", e.target.value)
                              }
                              value={venueData?.dress_code}
                              name="dress_code"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Claim Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              maxLength={4} // Set maximum length to 4
                              onChange={(e) =>                                
                                  updateValue("claimCode", e.target.value)
                                }
                              
                              value={venueData?.claimCode}
                              name="claimCode"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Tag
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>                                
                                  updateValue("discountText", e.target.value)
                                }
                              
                              value={venueData?.discountText}
                              name="discountText"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Reviews
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowReview ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowReview", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Rattings
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowRatting ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowRatting", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Theme
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={themeList}
                              onSelect={msUpdateTheme}
                              onRemove={msUpdateTheme}
                              selectedValues={defaultTheme}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Cuisine
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={cuisineList}
                              onSelect={msUpdateCuisine}
                              onRemove={msUpdateCuisine}
                              selectedValues={defaultCuisine}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Music
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={musicList}
                              onSelect={msUpdateMusic}
                              onRemove={msUpdateMusic}
                              selectedValues={defaultMusic}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={featureList}
                              onSelect={msUpdateFeature}
                              onRemove={msUpdateFeature}
                              selectedValues={defaultFeature}
                              placeholder="Search Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-6">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={venueData?.latitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lat")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={venueData?.longitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lng")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                  disabled={lockSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>     
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
