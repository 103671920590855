import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { postRequest } from "../../../components/Common/Utils";
import "./dashboard.scss";

class VenueAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'overall',
            series: [],
            options: this.getChartOptions(),
            totals: {
                thisYearTotal: 0,
            },
            percentages: {
                yearChange: 0
            },
            modal: false,
            selectedYear: moment().year(),
            selectedMonth: '',
            startDate: '2023-01-01',
            endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            limit: 10,
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async fetchData() {
        const { startDate, endDate, limit } = this.state;
        const { offerId } = this.props;
        try {
            const response = await postRequest(`log/offer-data-top-users`, { startDate, endDate, limit: parseInt(limit), offerId });
            const data = response.data.topUsers;

            const series = [
                { name: 'Total', data: data.map(item => item.total), type: 'bar' }
            ];

            const totals = {
                thisYearTotal: series[0].data.reduce((sum, value) => sum + value, 0),
            };

            const percentages = this.calculatePercentages(totals);

            this.setState({
                series,
                options: this.getChartOptions(data),
                totals,
                percentages
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    calculatePercentages(totals) {
        const calculateChange = (current, previous) => {
            if (previous === 0) return 0;
            return (((current - previous) / previous) * 100).toFixed(1);
        };

        return {
            yearChange: calculateChange(totals.thisYearTotal, 0),
        };
    }

    getChartOptions(data = []) {
        const categories = data.map(item => item.userName);

        return {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Values'
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            colors: ['#5664d2'],
        };
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleCustomApply = () => {
        const { selectedYear, selectedMonth } = this.state;
        const startDate = selectedMonth ? `${selectedYear}-${selectedMonth + 1}-01` : `${selectedYear}-01-01`;
        const endDate = selectedMonth ? moment(`${selectedYear}-${selectedMonth + 1}-01`).endOf('month').format('YYYY-MM-DD') : `${selectedYear}-12-31`;

        this.setState({ startDate, endDate }, () => {
            this.fetchData();
            this.toggleModal();
        });
    }

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value });
    }

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value });
    }

    handleLimitChange = (e) => {
        this.setState({ limit: e.target.value }, () => {
            this.fetchData();
        });
    }

    updateView = (view) => {
        let startDate, endDate;
        if (view === 'overall') {
            startDate = '2023-01-01';
            endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        } else if (view === 'annual') {
            startDate = moment().startOf('year').format('YYYY-MM-DD');
            endDate = moment().endOf('year').format('YYYY-MM-DD');
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().endOf('month').format('YYYY-MM-DD');
        }
        this.setState({ view, startDate, endDate }, () => {
            this.fetchData();
        });
    }

    render() {
        const { series, options, totals, percentages, modal, selectedYear, selectedMonth, limit } = this.state;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active={this.state.view === 'monthly'} onClick={() => this.updateView('monthly')}>Monthly</Button>
                                <Button size="sm" color="light" active={this.state.view === 'annual'} onClick={() => this.updateView('annual')}>Annual</Button>
                                <Button size="sm" color="light" active={this.state.view === 'custom'} onClick={this.toggleModal}>Custom</Button>
                                <Button size="sm" color="light" active={this.state.view === 'overall'} onClick={() => this.updateView('overall')}>Overall</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-2">Top Users (By Activity)</h4>
                        <div id="bar-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart options={options} series={series} type="bar" height="350" />
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={12}>
                                <div className="d-inline-flex">
                                    <h5 className="mb-0 me-2">{totals.thisYearTotal}</h5>                                    
                                </div>
                                <p className="text-muted text-truncate mb-0">Total</p>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <FormGroup>
                            <Label for="limitSelect">Number of items</Label>
                            <Input type="select" id="limitSelect" value={limit} onChange={this.handleLimitChange}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </Input>
                        </FormGroup>
                    </CardBody>
                </Card>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Select Date</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="yearSelect">Year</Label>
                            <Input type="select" id="yearSelect" value={selectedYear} onChange={this.handleYearChange}>
                                {Array.from({ length: moment().year() - 2023 + 1 }, (_, i) => 2023 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Month (optional)</Label>
                            <Input type="select" id="monthSelect" value={selectedMonth} onChange={this.handleMonthChange}>
                                <option value="">All</option>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCustomApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default VenueAnalytics;
