// CalendarWithStats.js
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card, Row, Col, Container } from 'react-bootstrap';
import CustomEvent from './CustomEvent';
import LabelSelector from './LabelSelector';
import './CalendarWithStats11.css?v=1.0.1'; // Import the scoped CSS

const localizer = momentLocalizer(moment);

const generateRandomEvents = () => {
    const events = [];
    const startDate = new Date(2024, 0, 1); // Start from January 1, 2024
    const endDate = new Date(2024, 11, 31); // End at December 31, 2024
    const dayCount = (endDate - startDate) / (1000 * 60 * 60 * 24);

    for (let i = 0; i <= dayCount; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);

        events.push({
            title: `User Registration: ${Math.floor(Math.random() * 20)}, Venue Add: ${Math.floor(Math.random() * 5)}, Business Add: ${Math.floor(Math.random() * 3)}, Offer Create: ${Math.floor(Math.random() * 10)}, Discount Add: ${Math.floor(Math.random() * 7)}, Discount Claimed: ${Math.floor(Math.random() * 15)}, Deal Created: ${Math.floor(Math.random() * 7)}`,
            start: new Date(date),
            end: new Date(date),
            allDay: true,
        });
    }

    return events;
};

const CalendarWithStats = ({ events }) => {
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState(['Offer Create']);
    const labels = [
        // 'User Registration',
        'Offer Create',
        'Venue Add',        
        'Discount Add',
        'Discount Claimed',
        // 'Deal Created'
    ];

    useEffect(() => {
        const filterEvents = (events) => {
            return events?.map((event) => {
                const eventData = event.title.split(', ').map((item) => {
                    const [label, value] = item.split(': ');
                    return { label, value };
                });

                const filteredEventData = eventData.filter((item) =>
                    selectedLabels.includes(item.label)
                );

                return {
                    ...event,
                    title: filteredEventData.map((item) => `${item.label}: ${item.value}`).join(', '),
                };
            });
        };

        setFilteredEvents(filterEvents(events));
    }, [events, selectedLabels]);

    return (
        <Container className="my-4">
            <Row>
                <Col md={3}>
                    <Card className="shadow-sm border-0 mb-4">
                        <Card.Header as="h5" className="bg-primary text-white">Select Labels</Card.Header>
                        <Card.Body>
                            <LabelSelector labels={labels} selectedLabels={selectedLabels} onChange={setSelectedLabels} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={9}>
                    <Card className="shadow-sm border-0 calendar-card">
                        <Card.Header as="h5" className="bg-primary text-white">Calendar with Daily Stats</Card.Header>
                        <Card.Body>
                            <Calendar
                                localizer={localizer}
                                events={filteredEvents}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 700 }}
                                views={['month', 'day']} // Specify available views
                                components={{
                                    event: CustomEvent,
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CalendarWithStats;
