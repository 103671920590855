import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function CreateBusinessTheme() {
  const location = useLocation();
  // const state = location.state?.row;
  

  const [themeData, setThemeData] = useState(null);  
  const [loading, setLoading] = useState(false);  
  const [themeList, setThemeList] = useState([]); 

  let history = useHistory();
  const { id } = useParams();  
 
  
  useEffect(() => {
    fetchList();
    }, []);

    const fetchList = async () => {
      setLoading(true);
      const response = await postRequest("venue/theme/list", { limit: 10000000});
      if (response.status == 1) {
        setThemeList(response.data.list);
      }
      setLoading(false);
    }; 
 
  

  const Createtheme = async (e) => {
    e.preventDefault();
   setLoading(true);
  
    const formData = new FormData();
    formData.append("businessId", id);
    formData.append("theme", themeData.theme);    
   
    
    const response = await postRequestForm("business/add_themes", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/business");
      }, 3000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...themeData}
    list[index] = value
    setThemeData(list)
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Assign Business Theme</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Business Theme</li>:{" "}
              <li className="breadcrumb-item">
              Assign Business Theme
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Assign Theme </h4>
              <Form onSubmit={Createtheme}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                         <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Theme</label>
                            <div className="col-sm-9">
                              <select onChange={ (e) => updateValue('theme', e.target.value) } value={themeData?.theme}  class="form-select">
                                   <option>  Select</option>
                                    {themeList.map((item, index) => (
                                      <option value={item._id}>{item.title}</option>
                                    ))}                                  
                                 </select>
                            </div>                           
                        </div>                   

                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
