import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  customValidator,
  putRequestForm,
} from "../../components/Common/Utils.js";

export default function UpdateEventOrg() {
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizerData, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [previewCover, setPreviewCover] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const OrgFetchDetail = async () => {
    setLoader(false);
    postRequest(`event/org/profile`, {}).then((data) => {
      let userdata = data.data.data;
      let status = data.status;
      setData(userdata);
      setLoader(false);
      eventRatingManagment();
    });
  };

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(() => {
    OrgFetchDetail();
  }, []);
  const Updateeventorganizer = async (e) => {
    e.preventDefault();

    const eventData = { ...organizerData };
    if (!eventData.name) {
      toast.error("Please enter event name");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("eventOrgId", id);
    formData.append("name", eventData.name);
    formData.append("description", eventData.description);
    formData.append("phone", eventData.phone);
    formData.append("email", eventData.email);
    formData.append("password", eventData.password);
    formData.append("website", eventData.website);

    if (remoteLogo) {
      formData.append("logo", remoteLogo);
    }
    if (selectedCover) {
      formData.append("cover", selectedCover);
    }

    if (response.status == 1) {
      toast.success(response.message);
      eventManagment();
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...organizerData };
    list[index] = value;
    setData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Profile Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Profile Management</li>:{" "}
              <li className="breadcrumb-item">Create Profile</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4> Save Profile </h4>
                <Form onSubmit={Updateeventorganizer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={organizerData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={organizerData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              value={organizerData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={organizerData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              value={organizerData?.website}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              // onChange={(e) =>
                              //   setSelectedLogo(e.target.files[0])
                              // }
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                                src={previewLogo}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
