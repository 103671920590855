import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Modal } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
} from "../Common/Utils.js";
import FileDrop from "components/FileMultipleDrop.js";
import FileUpload from "../Common/FileUpload"; 

function CreateGallery({orgId, reloadImage, closeModal}) {
  

  const [startLoader, setStartLoader] = useState(false);
  const [galleryData, setGalleryData] = useState({ title: "" });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");  
 
  const handleFileUpload = (url) => {
    setSelectedImage((prevState) => [...prevState, url]);
  };    

  const Creategallery = async (e) => {
    e.preventDefault();
    if (!selectedImage?.length) {
      toast.error("Please select image");
      return;
    }
    setLoading(true); 
    const response = await postRequest("event/org/gallery/create", {
      eventOrgId: orgId,
      files: selectedImage,
    });
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      closeModal();
      reloadImage();
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...galleryData };
    list[index] = value;
    setGalleryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />
     
      <Row>
        <Col lg={12}>
          <Card>           
              <CardBody>
                <Form onSubmit={Creategallery}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                        
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            
                          </label>
                          <div className="col-sm-6">
                            <FileDrop
                              accept="image/*"
                              onFileUpload={handleFileUpload}
                              style={{ height: "300px" }}
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
          </Card>
        </Col>
      </Row>
      <FileUpload message="Image Uploading" status={startLoader} />
    </React.Fragment>
  );
}

const EventGalleryModal = ({ show, closeModal, orgId, reloadImage  }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Upload Gallery Image
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <CreateGallery orgId={orgId} reloadImage={reloadImage} closeModal={closeModal} />
      </div>
    </Modal>
  );
};

export default EventGalleryModal;
