import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  putRequestForm,
  customValidator,
} from "../../components/Common/Utils.js";

export default function ResetBusinessPassword() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [businessData, setBusinessData] = useState({   
    new_password: "",
    confirm_password: "",
  });

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    setBusinessData(state || {}); // Set state to an empty object if 'state' is undefined
  }, [state]);

  const updateBusinessPassword = async (e) => {
    e.preventDefault();

    // Validate old_password and new_password

    

    if (!businessData.new_password) {
      toast.error(" new password is required.");
      return;
    }

    if(!businessData.confirm_password){
      toast.error("confirm password is required.");
      return;
    }


   

    // Validate new_password and confirm_password
    if (businessData.new_password !== businessData.confirm_password) {
      toast.error("New password and confirm password should be the same.");
      return;
    }


    // check new pasword minimum 4 character

    if (businessData.new_password.length < 4) {
      toast.error("New password should be minimum 4 character.");
      return;
    }


    setLoading(true);

    // Create form data with businessId and password only
    const formData = new FormData();
    formData.append("businessId", id);
    formData.append("password", businessData.new_password);

    const response = await putRequestForm("venue/business/reset/password", formData);

    setLoading(false);

    if (response.status == 1) {
      toast.success("Bussiness Ac Password Updated Successfully");
      setTimeout(() => {
        history.push("/business");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updatePassword = (field, value) => {
    setBusinessData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Business Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Business Management</li>
              <li className="breadcrumb-item">Reset Business Password</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4>Reset Password</h4>
                <Form onSubmit={updateBusinessPassword}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            New Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="password"
                              onChange={(e) =>
                                updatePassword("new_password", e.target.value)
                              }
                              name="new_password"
                              className="form-control"
                              value={businessData.new_password}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Confirm Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="password"
                              onChange={(e) =>
                                updatePassword(
                                  "confirm_password",
                                  e.target.value
                                )
                              }
                              name="confirm_password"
                              className="form-control"
                              value={businessData.confirm_password}
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Update Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
