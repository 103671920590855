import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";
import CustomAvatar from "../../components/Common/Avatar.js";

import {
  postRequest,
  deleteRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
const filterObj = {
  userName: "",
  orderName: "",
  type: "",
  qty: "",
  price: "",
  status: "",
  createdAt: "",
};

const OrderManagementListTable = ({ userData, bucketUserId }) => {
  const [modal_center, setmodal_center] = useState(false);
  const [businessdata, setbusinessdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    businessManagment();
  }, [currentPage]);

  useEffect(() => {
    businessManagment();
  }, [sizePerPageOrg, sort, sortOrder]);
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };
  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "business_name") {
          var filterObject = {
            "business_id.name": filter["business_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key],
          };
        }

        if (key === "userName" || key === "orderName" || key === "type" || key === "qty" || key === "price") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    
    toggleModel();
    businessManagment(filterArgs);
  };
  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    businessManagment();
  };

  function ThemeFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/createBusinessTheme/${row._id}`,
            state: { row },
          }}
        >
          Theme
        </Link>
      </div>
    );
  }
  function WebsiteFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          Website
        </a>
      </div>
    );
  }

  function dateFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.createdAt)}
      </div>
    );
  }

  function dateTimeFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {`${row.date} ${row.time}`}
      </div>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function UserFormatter(cellContent, row) {
    row = row.user ? row.user : null;


    if(!row){
      return "User Deleted"
    }


    return (
      <div style={{ display: "flex" }}>        
        {row.image ? (
          <img
            src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              row?.first_name + row?.last_name == ""
                ? "*"
                : `${row?.first_name} ${row?.last_name} `
            }
          />
        )}

        <p> {`${row?.first_name} ${row?.last_name}`} </p>

      </div>
    );
  }

  function ItemFormatter(cellContent, row) {

    let item = "";

    if(row?.type == "offer"){
      item = row?.offer ? row?.offer : "Not Found"
    }

    if(row?.type == "activity"){
      item = row?.activity ? row?.activity : "Not Found"
      if(row?.activity){
        item = {
          ...item, title: item.name,
          image: item.galleries?.length ? item.galleries[0] : "",          
        }
      }
    }

    if(row?.type == "deal"){
      item = row?.deal ? row?.deal : "Not Found"
    }

    if(item == "Not Found"){
      return (
        <>Not Found</>
      );
    }

    return (
      <div style={{ display: "flex" }}>        
        {item.image ? (
          <img
            src={item.image}
            onClick={() => showLightBox(item.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <CustomAvatar
            name={
              item?.title == ""
                ? "*"
                : `${item?.title}`
            }
          />
        )}

        <p> {`${item?.title}`} </p>

      </div>
    );
  }




  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">User Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.userName}
          onChange={(e) => updateFilter("userName", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Order Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.orderName}
          onChange={(e) => updateFilter("orderName", e.target.value)}
          name="email"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
    <label className="col-sm-3 col-form-label">Type</label>
    <div className="col-sm-6">
      <select
        onChange={(e) => updateFilter("type", e.target.value)}
        className="form-select"
        value={filter.type}
      >
        <option value="">Select Type</option>
        <option value="offer">Offer</option>
        <option value="deal">Deal</option>
        <option value="activity">Activty</option>
      </select>
    </div>
  </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Qty</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.qty}
          onChange={(e) => updateFilter("qty", e.target.value)}
          name="phone"
          className="form-control"
        />
      </div>
    </div>,
      <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Price</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.price}
          onChange={(e) => updateFilter("price", e.target.value)}
          name="phone"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="title">
    <label className="col-sm-3 col-form-label">Status</label>
    <div className="col-sm-6">
      <select
        onChange={(e) => updateFilter("status", e.target.value)}
        className="form-select"
        value={filter.status}
      >
        <option value="">Select Status</option>
        <option value="true">Complete</option>
        <option value="false">Pending</option>
      </select>
    </div>
  </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "user.name",
      formatter: UserFormatter,
      text: "User Name",
      sort: false,
    },

    {
      dataField: "Item",
      formatter: ItemFormatter,
      text: "Order Detail",
      sort: false,
    },
    {
      dataField: "type",  
      text: "Type",
      sort: false,
    },

    {
      dataField: "qty",  
      text: "Qty",
      sort: false,
    },

    {
      dataField: "price",  
      text: "Price",
      sort: false,
    },

    {
      dataField: "Date",  
      formatter: dateTimeFormatter,
      text: "Date & Time",
      sort: false,
    },
   
    {
      dataField: "status",
      text: "Status",     
      sort: false,
    },

    {
      dataField: "createdAt",
      formatter: dateFormatter,
      text: "Created At",
      sort: false,
    }
   
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          {/* <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/updateBusiness/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          <button type="button" className=" btn btn-primary  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/resetBusinessPassword/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-retweet fs-5 text-light"></i>
            </Link>{" "}
          </button> */}
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const businessManagment = async (filterArgs = []) => {
    setLoader(true);

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      // userId : userId,
      // userId: bucketUserId,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(`subscription/order/list/admin`, payload);
      const data = response.data;
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setbusinessdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this business",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed && result.value === randomWord) {
        const response = await deleteRequest(`venue/business/delete`, {
          businessId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          businessManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const [itemsdata, setItems] = useState([]);

 

  

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("venue/business/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      businessManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          {/* <Col className="12">
            <div className="page-title-box">
              <h4>Order Management</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Order Management</li>:{" "}
                <li className="breadcrumb-item">Order List</li>
              </ol>
            </div>
          </Col> */}
      
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={businessdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="qty">Qty</option>
                                    <option value="price">Price</option>
                                    <option value="status">Status</option>                                   
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        businessManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>
                                    {/* <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button> */}
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default OrderManagementListTable;
