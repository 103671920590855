import PropTypes from "prop-types";

import React from "react";

import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images


import { postRequest } from "components/Common/Utils";

const Login = (props) => {
  let history = useHistory();
  const [email, setEmail] = useState(""); 

  async function login() {
    let data = { email };
    postRequest("event-organizer/forgot-password", data).then((data) => {
      let log = data;
      if (log.error === false) {
        toast.success(log.msg);         
        setTimeout(() => {
          history.push("/eventLogin");
        }, 3000);
      } else {
        toast.error(log.msg);
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">Whosin Event Organizer </h3>
                  <div className="p-3">
                    
                    <p className="text-muted text-center">
                      Reset Password to continue to Whosin
                    </p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          // value="admin@themesbrand.com"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                     

                      <div className="mb-3 row mt-4">
                       
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="button"
                            onClick={login}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                      
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
