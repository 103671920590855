import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import {
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  RBButton,
} from "components/Common/Utils";

// import StarComponent from './StarComponent';
import Lightbox from "react-image-lightbox";
import Swal from "sweetalert2";
import LocationPicker from "react-location-picker";

import CountUp from "react-countup";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// import RatingTable from "./RatingTable";
// import ActivityBookingListTable from './ActivityBookingListTable';
import EventlogsTable from "./EventlogsTable.js";
import AllviewsTable from "./AllviewsTable.js";
import AllclicksTable from "./AllclicksTable.js";
// import AddedItemTable from "./AddedItemTable.js";
// import AllpurchaseTable from "./AllpurchaseTable.js";
// import AllgiftsendTable from "./AllgiftsendTable.js";
// import Dashboardtab from "./Dashboardtab.js";

import DateRangePicker from "./DateRangePicker";
import BarChartComponent from "./BarChartComponent";

import "./CustomCardAct.css";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

function formatDates(dataArray) {
  const allDates = dataArray.map((item) => new Date(item.date));

  const isSameYear = allDates.every(
    (date) => date.getFullYear() === allDates[0].getFullYear()
  );
  const isSameMonth = allDates.every(
    (date) => date.getMonth() === allDates[0].getMonth()
  );

  dataArray.forEach((item) => {
    const dateObj = new Date(item.date);

    if (isSameYear && isSameMonth) {
      item.date = `${dateObj.getDate()}${getOrdinalSuffix(dateObj.getDate())}`;
    } else if (isSameYear) {
      item.date = `${getMonthString(
        dateObj.getMonth()
      )} ${dateObj.getDate()}${getOrdinalSuffix(dateObj.getDate())}`;
    }
  });

  return dataArray;
}

function getOrdinalSuffix(i) {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) return "st";
  if (j === 2 && k !== 12) return "nd";
  if (j === 3 && k !== 13) return "rd";

  return "th";
}

function getMonthString(month) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[month];
}



function generateRandomDataForRange(startDate, endDate) {
  const data = {
    labels: [],
    datasets: [
      {
        label: "Views",
        data: [],
        backgroundColor: "rgb(255, 105, 180)",
        borderColor: "#46d5f1",
        hoverBackgroundColor: "#46a2d5",
      },
      {
        label: "Clicks",
        data: [],
        backgroundColor: "rgb(255, 102, 0)",
        borderColor: "#FF6384",
        hoverBackgroundColor: "#FF6384",
      },
      {
        label: "Total Added Items",
        data: [],
        backgroundColor: "rgb(0, 128, 0)",
        borderColor: "#FF6384",
        hoverBackgroundColor: "#FF6384",
      },
      {
        label: "Unique Clicks",
        data: [],
        backgroundColor: "rgb(0, 0, 255)",
        borderColor: "#FF6384",
        hoverBackgroundColor: "#FF6384",
      },
    ],
  };

  // Clone the start date to not modify it directly
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const views = Math.floor(Math.random() * 100);
    const clicks = Math.floor(Math.random() * views * 0.5); // 10-50% of views
    const uniqueClicks = Math.floor(Math.random() * clicks * 0.5); // 10-50% of clicks
    const uniqueViews = Math.floor(Math.random() * views * 0.5); // 10-50% of views

    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    data.labels.push(formattedDate);
    data.datasets[0].data.push(views);
    data.datasets[1].data.push(clicks);
    data.datasets[2].data.push(uniqueClicks);
    data.datasets[3].data.push(uniqueViews);

    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return data;
}

const getMaxYValue = (datasets) => {
  let maxVal = 0;
  datasets.forEach((dataset) => {
    dataset.data.forEach((value) => {
      if (value > maxVal) {
        maxVal = value;
      }
    });
  });
  return maxVal * 2;
};

export default function EventGraphdetails(activityId, activityName) {
  const location = useLocation();
  const state = location.state?.row;
  const { id } = useParams();
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [eventtrackData, seteventtrackData] = useState([]);
  // const [activityData, setData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [organizerData, setData] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [openLightbox, setOpenLightBox] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [activityTime, setActivityTime] = useState(null);
  const [textReply, setTextReply] = useState("");

  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );

  const [startDate, setStartDate] = useState(firstDayOfCurrentMonth);
  const [endDate, setEndDate] = useState(today);
  const [chartData, setChartData] = useState(null);

  const [totalClick, setTotalClick] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [uniqueClick, setUniqueClick] = useState(0);
  const [uniqueView, setUniqueView] = useState(0);

  const label = `${startDate.toISOString().slice(0, 10)} - ${endDate
    .toISOString()
    .slice(0, 10)}`;

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#e22a2a", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  // const showLightBox = (image) => {
  //   setImage(image);
  //   setOpenLightBox(true);
  // };
  
 

  useEffect(() => {
    if (!state) return;
    state.subHeading = state?.title ? state?.title : "";
    state.description = state?.subHeadline ? state?.subHeadline : "";
    state.subText = state?.subTitle ? state?.subTitle : "";
    state.title = state?.headline ? state?.headline : "";
    delete state?.headLine;
    delete state?.subHeadLine;
    delete state?.subTitle;
    seteventtrackData(state);
  }, [state]);

  useEffect(() => {
    if (!endDate) return;
    if (!startDate) return;
    loadChartData();
  }, [endDate, startDate]);

  const OrgFetchDetail = async () => {
    setLoader(false);
    postRequest(`event/detail`, { eventId: `${id}` }).then((data) => {
      let userdata = data.data;
      setData(userdata);

      console.info("userdata", userdata);

      if (userdata?.event?.admins?.length > 0) {
        const tadmins = userdata?.users.filter((user) => {
          return userdata?.event?.admins.includes(user._id);
        });

        setAdmins(tadmins);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    OrgFetchDetail();
  }, []);

  const loadChartData = async () => {
    setLoader(true);
    try {
      const formattedDates = {
        start_date: formatDateISO(startDate),
        end_date: formatDateISO(endDate),
        venueId: id,
      };

      // const response = await postRequest(`venue/log/chart-data`, formattedDates);

      if (response.status === 1) {
        let { charts, counter } = response.data;

        charts = formatDates(charts);

        // Updating the chart
        const updatedChartData = {
          labels: charts.map((chartItem) => chartItem.date),
          datasets: [
            {
              label: "Views",
              data: charts.map((chartItem) => chartItem.view),
              backgroundColor: "rgb(255, 105, 180)",
              // ... other dataset properties
            },
            {
              label: "Clicks",
              data: charts.map((chartItem) => chartItem.click),
              backgroundColor: "rgb(255, 102, 0)",
              // ... other dataset properties
            },
            {
              label: "Total Added Items",
              data: charts.map((chartItem) => chartItem.uniqueView),
              backgroundColor: "rgb(0, 128, 0)",
              // ... other dataset properties
            },
            {
              label: "Unique Clicks",
              data: charts.map((chartItem) => chartItem.uniqueClick),
              backgroundColor: "rgb(0, 0, 255)",
              // ... other dataset properties
            },
          ],
        };

        const maxYValue = getMaxYValue(updatedChartData.datasets);

        const options = {
          scales: {
            y: {
              beginAtZero: true,
              max: maxYValue,
            },
          },
        };

        setChartData({ ...updatedChartData, options });

        // Updating the counters
        setTotalClick(counter.totalClick);
        setTotalView(counter.totalView);
        setUniqueClick(counter.uniqueClick);
        setUniqueView(counter.uniqueView);
      } else {
        // Handle any error messages here
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setLoader(false);
    }
  };

  
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const updateDate = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...activityData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };
  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };  

  const deleteReview = async (id, index) => {
    const response = await deleteRequest("rating/event/delete", {
      ratingId: id,
    });
    // remove form OrganizerData.ratings

    if (response.status == 1) {
      const orgData = { ...activityData };
      orgData.ratings.splice(index, 1);
      setData(orgData);
      toast.success("Review Deleted");
    } else {
      toast.error(response.message);
    }
  };

  function formatDateISO(dateString) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based.
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const eventImage = organizerData?.event?.image;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Event Tracking Detail</h4>

            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Event Management</li>:{" "}
              <li className="breadcrumb-item">Detail</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            {/* <Card className="custom-card">
              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <br></br>

                  <div className="row">
                    <div className="col-md-12" style={{ minHeight: "50vh" }}>
                      <PreviewVenueRead currentForm={adData} />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}
           <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
                onClick={() => showLightBox(eventImage)}
              ></div>
              <div className="profile-image2">
                <img
                  src={eventImage}
                  onClick={() => showLightBox(eventImage)}
                  alt="Image Not Available"
                />
              </div>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px", marginTop: "20px" }}
                >
                  <br></br>

                  <div className="fst">
                    {" "}
                    <h3>{organizerData?.event?.title} </h3>{" "}
                  </div>

                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        User Type:{" "}
                        <span>{organizerData?.event?.user_type}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Type: <span> {organizerData?.event?.type}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Venue Type:{" "}
                        <span> {organizerData?.event?.venue_type}</span>{" "}
                      </p>{" "}
                    </div>

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Event Date:{" "}
                        <span> {organizerData?.event?.event_time}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Reservation Date:{" "}
                        <span>
                          {" "}
                          {organizerData?.event?.reservation_time}
                        </span>{" "}
                      </p>{" "}
                    </div>

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Total Guest:{" "}
                        <span> {organizerData?.event?.inGuestsCount}</span>{" "}
                      </p>{" "}
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-md-12 fst py-1">
                        <p>
                          {" "}
                          About:{" "}
                          <span> {organizerData?.event?.description}</span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <Col md="12" style={{marginLeft:"inherit", marginBottom:"25px"}}  >
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#dashboard" data-toggle="tab">
                      {" "}
                      Dashboard{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#logs" data-toggle="tab">
                      All Logs{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#views" data-toggle="tab">
                      All Views
                    </a>
                  </li>
                  <li>
                    <a href="#clicks" data-toggle="tab">
                      All Clicks
                    </a>
                  </li>
                  {/* <li>
                    <a href="#add-item" data-toggle="tab">
                      Added Items
                    </a>
                  </li>
                  <li>
                    <a href="#purchase" data-toggle="tab">
                      All Purchase
                    </a>
                  </li>

                  <li>
                    <a href="#send-as-gift" data-toggle="tab">
                      All Gift Send
                    </a>
                  </li> */}
                </ul>
              </div>
            </Col>

            <Col md="12">
              <div className="tab-content clearfix">
                <div
                  className=" custom-card card tab-pane active padded-tab"
                  id="dashboard"
                >
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col xl={3}>
                          <Card
                            style={{
                              background:
                                "linear-gradient(to right, #FFB6C1, #FF69B4)",
                            }}
                          >
                            <CardBody>
                              <Row className="text-center mt-4">
                                <div className="col-12">
                                  <a href="#">
                                    <CountUp
                                      start={0}
                                      end={totalView}
                                      className="font-size-20"
                                      duration={2.5}
                                      style={{ color: "#051c29aa" }}
                                    />
                                    <p style={{ color: "#051c29aa" }}>
                                      Total Views
                                    </p>
                                  </a>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col xl={3}>
                          <Card
                            style={{
                              background:
                                "linear-gradient(to right, #FFCC99, #FF6600)",
                            }}
                          >
                            <CardBody>
                              <Row className="text-center mt-4">
                                <div className="col-12">
                                  <a href="#">
                                    <CountUp
                                      start={0}
                                      end={totalClick}
                                      className="font-size-20"
                                      duration={2.5}
                                      style={{ color: "#051c29aa" }}
                                    />
                                    <p style={{ color: "#051c29aa" }}>
                                      Total Clicks
                                    </p>
                                  </a>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={3}>
                          <Card
                            style={{
                              background:
                                "linear-gradient(to right, #98FB98, #008000)",
                            }}
                          >
                            <CardBody>
                              <Row className="text-center mt-4">
                                <div className="col-12">
                                  <a href="#">
                                    <CountUp
                                      start={0}
                                      end={uniqueView}
                                      className="font-size-20"
                                      duration={1.5}
                                      style={{ color: "#051c29aa" }}
                                    />
                                    <p style={{ color: "#051c29aa" }}>
                                      Total Added Items
                                    </p>
                                  </a>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={3}>
                          <Card
                            style={{
                              background:
                                "linear-gradient(to right, #ADD8E6, #0000FF)",
                            }}
                          >
                            <CardBody>
                              <Row className="text-center mt-4">
                                <div className="col-12">
                                  <a href="#">
                                    <CountUp
                                      className="font-size-20"
                                      start={0}
                                      end={uniqueClick}
                                      duration={1}
                                      style={{ color: "#051c29aa" }}
                                    />
                                    <p style={{ color: "#051c29aa" }}>
                                      All Logs
                                    </p>
                                  </a>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>

                      {startDate && endDate && (
                        <DateRangePicker
                          selectedStartDate={startDate}
                          selectedEndDate={endDate}
                          onSubmit={updateDate}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {chartData && (
                        <BarChartComponent label={label} data={chartData} />
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="tab-pane padded-tab" id="logs">
                  {eventtrackData?.logs?.length !== 0 && <EventlogsTable venueId={id} />}
                </div>

                <div className="tab-pane padded-tab" id="views">
                  {eventtrackData?.logs?.length !== 0 && <AllviewsTable venueId={id} />}
                </div>

                <div className="tab-pane padded-tab" id="clicks">
                  {eventtrackData?.logs?.length !== 0 && <AllclicksTable venueId={id} />}
                </div>

                {/* <div className="tab-pane padded-tab" id="add-item">
                  {eventtrackData?.logs?.length !== 0 && (
                    <AddedItemTable venueId={id} />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="purchase">
                  {eventtrackData?.logs?.length !== 0 && <AllpurchaseTable venueId={id} />}
                </div>

                <div className="tab-pane padded-tab" id="send-as-gift">
                  {eventtrackData?.logs?.length !== 0 && <AllgiftsendTable venueId={id} />}
                </div> */}
              </div>
            </Col>
          </>
        )}
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
}
