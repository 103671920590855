import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./log.css"; // Custom CSS file for better styling

const UpdatedOffer = () => {
  const location = useLocation();
  const state = location.state?.row;
  const [loading, setLoading] = useState(false);

  const compareData = (updatedData, oldData, key) => {
    const updatedValue = updatedData[key];
    const oldValue = oldData[key];
    if (updatedValue !== oldValue) {
      return (
        <div>
          <span className="highlight-red">{oldValue}</span> &rarr;{" "}
          <span className="highlight-green">{updatedValue}</span>
        </div>
      );
    }
    return <span>{updatedValue}</span>;
  };

  const compareImages = (newImg, oldImg, label) => {
    if (!newImg && !oldImg) {
      return <span>No changes</span>;
    }
    if (newImg && oldImg && newImg === oldImg) {
      return (
        <div className="image-comparison">
          <div className="image-wrapper">
            <img src={newImg} alt={`${label} New`} />
          </div>
        </div>
      );
    }
    if (newImg && oldImg && newImg !== oldImg) {
      return (
        <div className="image-comparison">
          <div className="image-wrapper">
            <span className="highlight-red">
              <img src={oldImg} alt={`${label} Old`} />
              {label} Old
            </span>
          </div>
          <div className="image-wrapper">
            <span className="highlight-green">
              <img src={newImg} alt={`${label} New`} />
              {label} New
            </span>
          </div>
        </div>
      );
    }
    if (!oldImg && newImg) {
      return (
        <div className="image-comparison">
          <div className="image-wrapper">
            <span className="highlight-green">
              <img src={newImg} alt={` ${label} New`} />
              {label} Inserted
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="image-comparison">
        <div className="image-wrapper">
          <span className="highlight-red">
            <img src={oldImg} alt={`${label} Old`} />
            {label} Deleted
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (state) {
      setLoading(false);
    }
  }, [state]);

  let history = useHistory();

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Update Offer</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Offer</h4>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Title</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "title"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Description
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "description"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Tag
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "discountTag"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow WhosIn
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {state?.metadata?.updatedData?.allowWhosIn
                              ? "Yes"
                              : "No"}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow Claimed
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "allowCliamed"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Offer Timing
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData.offerTiming,
                              state?.metadata?.beforeUpdatedData.offerTiming,
                              "time"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Days</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "days"
                            )}
                          </div>
                        </div>
                      </div>

                      {state?.metadata?.updatedData?.venue !==
                        state?.metadata?.beforeUpdatedData?.venue && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Venue</label>
                          <div className="col-sm-6">
                            <div className="form-control">Updated</div>
                          </div>
                        </div>
                      )}
                    

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Image</label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.image,
                            state?.metadata?.beforeUpdatedData?.image,
                            "Image"
                          )}
                        </div>
                      </div>



                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Square Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.squareImage,
                            state?.metadata?.beforeUpdatedData?.squareImage,
                            "Square Image"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Rectangle Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.rectangleImage,
                            state?.metadata?.beforeUpdatedData?.rectangleImage,
                            "Rectangle Image"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Story Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.storyImage,
                            state?.metadata?.beforeUpdatedData?.storyImage,
                            "Story Image"
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdatedOffer;
