import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Alert } from "reactstrap";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest, 
  postRequestForm  
} from "../../components/Common/Utils.js";

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import DayTimePicker from "./DayTimePicker";

import LocationPicker from "react-location-picker";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

import BusinessModal from "components/Modal/BusinessModal";
export default function OnboardingVenue() {
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [venueData, setVenueData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);  
  const [regions, setRegions] = useState([]);

  const [startLoader, setStartLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [lockSubmit, setLockSubmit] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [completedCrop, setCompletedCrop] = useState(null);

  const [selectedLogo, setSelectedLogo] = useState("");  
  

  const handleApprove = async status => {
    try{

      setLoading(true);

      const param = { 
        status: status,
        venueId: venueData._id
       };

       //console.log("param", param)


      const theme = await postRequest("venue/onboarding/update/status", { ...param });
      setLoading(false);
      if (theme?.status == 1) {       
        toast.success(`Venue ${status} successfully`);
        setTimeout(() => {
          history.push("/onBoardingVenue");
        }, 1000);
        return;
      }

      toast.error(`Failed to ${status} venue`);
      return;    
      
      


    } catch (error) {
        setLoading(false);
       //console.error("Failed to approve venue:", error);
    }
    
  };
  

  const [selectedCover, setSelectedCover] = useState("");


  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const fetchCountry = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/country/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title, code : item.code};
      });
      setCountries(options);
    }
  };

  const fetchRegion = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/region/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.name };
      });
      setRegions(options);
    }
  };

  useEffect(() => {
    fetchCountry();
    fetchRegion();
  }, []);


  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };


  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };
  

  let history = useHistory();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const locationArray = state?.location?.coordinates;
    state.latitude = locationArray[1];
    state.longitude = locationArray[0];
    state.user_id = state.user._id; 
    setCurrentLocation({
      lat: parseFloat(state.latitude),
      lng: parseFloat(state.longitude),
    });

    //console.log("state", state)

    setVenueData(state);
    setBusinessId(state?.business?._id)
    setBusinessText(state?.business?.name)
    setBusinessImage(state?.business?.logo)
    setPreviewCover(state?.cover ? state?.cover : "");
    setPreviewLogo(state?.logo ? state?.logo : "");
    // setRectangleImagePreview(state?.rectangleImage ? state?.rectangleImage : "");
    // setSquareImagePreview(state?.squareImage ? state?.squareImage : "");
    // setStoryImagePreview(state?.storyImage ? state?.storyImage : "");

    if (state?.promo?.length) {
      const features = state?.promo?.map((item) => {
        return {
          image: item.image,
          title: item.title,
          discountTag: item.discountTag,
          saved: true,
        };
      });
      setPackages([...features]);
    }

    if (state?.timings && state?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      state?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });

      setTimeOptions(newTimeOptions);
    }
    setSelectedTheme(state?.themes ? state?.themes : []);
    setSelectedMusic(state?.music ? state?.music : []);
    setSelectedFeature(state?.features ? state?.features : []);
    setSelectedCuisine(state?.cuisines ? state?.cuisines : []);

  }, [state]);



   

  useEffect(() => {
    fetchTheme();
  }, [selectedTheme]);

  useEffect(() => {
    fetchMusic();
  }, [selectedMusic]);

  useEffect(() => {
    fetchFeature();
  }, [selectedFeature]);

  useEffect(() => {
    fetchCuisine();
  }, [selectedCuisine]);
 



  
 
  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setThemeList(options);

      if (selectedTheme.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedTheme.includes(item.id);
        });        
        setDefaultTheme(defaultItems);
      }
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);

      if (selectedMusic.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedMusic.includes(item.id);
        });
        setDefaultMusic(defaultItems);
      }
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);
      if (selectedFeature.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedFeature.includes(item.id);
        });
        
        setDefaultFeature(defaultItems);
      }
    }
  };

  function handleFileUpload(e) {
    const file = e.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    // Check the file type

    if (file.type === "application/pdf") {
      // Handle PDF file
      handleUpload(file, "pdf");
    } else if (file.type.startsWith("image/")) {
      // Handle image file
      handleUpload(file, "image");
    } else {
      // Invalid file type
      alert("Unsupported file type. Please select a PDF or an image.");
    }
  }

  async function handleUpload(file, type) {
    setLockSubmit(true);
    setStartLoader(true);
    let url = "comman/doc/upload";
   
    if(type === "image") {
      url = "comman/img/upload";
    }

    const pdfFile = file;
    const formData = new FormData();
    formData.append("file", pdfFile);

    const selectedImageRes = await postRequestForm(url, formData);

    const venData = { ...venueData };
    if (selectedImageRes?.data) {      
      venData["menu_url"] = selectedImageRes.data.url;
      setVenueData(venData);
    } 
    setStartLoader(false);
    setLockSubmit(false);
  }
  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCuisineList(options);
      if (selectedCuisine.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCuisine.includes(item.id);
        });
        setDefaultCuisine(defaultItems);
      }
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

 



  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>OnBoarding Venue Request</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">OnBoarding Venue Request</li>:{" "}
              <li className="breadcrumb-item">Venue Request View</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Venue Request view</h4>
                <Form>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">  

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          User Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"                             
                              value={venueData?.user?.name}
                              name="username"
                              className="form-control"
                              disabled="disabled"
                            />
                          </div>
                        </div>   
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            User Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"                             
                              value={venueData?.user?.email}
                              disabled="disabled"
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            User Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"                              
                              defaultValue={venueData?.user?.phone}
                              disabled="disabled"
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>                  
                       

                          <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                         Country
                        </label>
                        <div className="col-sm-6">
                          <select                                                    
                            className="form-select"
                            value={venueData?.country}
                            disabled="disabled"
                          >
                            <option value="">Select Country</option>
                            {countries.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                            
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                         Region
                        </label>
                        <div className="col-sm-6">
                          <select           
                            value={venueData?.region}                
                            className="form-select"
                            disabled="disabled"
                          >
                            <option value="">Select Region</option>
                            {regions.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                            
                          </select>
                        </div>
                      </div>

                     


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"                              
                              value={venueData?.name}
                              name="name"
                              className="form-control"
                              disabled="disabled"
                            />
                          </div>
                        </div>

                        

                       
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"                             
                              value={venueData?.about}
                              disabled="disabled"
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"                             
                              value={venueData?.address}
                              disabled="disabled"
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                     

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"                              
                              defaultValue={venueData?.phone}
                              disabled="disabled"
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"                              
                              value={venueData?.email}
                              disabled="disabled"
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"                             
                              value={venueData?.website}
                              disabled="disabled"
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                      
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"                             
                              disabled="disabled"
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              disabled="disabled"
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>


  

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Theme
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                            isDisabled="disabled"
                              options={themeList}
                              
                              // onSelect={msUpdateTheme}
                              // onRemove={msUpdateTheme}
                              selectedValues={defaultTheme}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Cuisine
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                            isDisabled="disabled"
                              options={cuisineList}
                              // onSelect={msUpdateCuisine}
                              // onRemove={msUpdateCuisine}
                              selectedValues={defaultCuisine}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Music
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                             isDisabled="disabled"
                              options={musicList}
                              // onSelect={msUpdateMusic}
                              // onRemove={msUpdateMusic}
                              selectedValues={defaultMusic}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                            isDisabled="disabled"
                              options={featureList}
                              // onSelect={msUpdateFeature}
                              // onRemove={msUpdateFeature}
                              selectedValues={defaultFeature}
                              placeholder="Search Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-6">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                                disabled="disabled"
                              />
                            ))}
                          </div>
                        </div>
                      

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              // onChange={handleLocationChange}
                              disabled="disabled"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={venueData?.latitude}
                              // onChange={(e) =>
                              //   updateLocation(e.target.value, "lat")
                              // }
                              className="form-control"
                              id="horizontal-email-input"
                              disabled="disabled"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={venueData?.longitude}
                              // onChange={(e) =>
                              //   updateLocation(e.target.value, "lng")
                              // }
                              className="form-control"
                              id="horizontal-email-input"
                              disabled="disabled"
                            />
                          </div>
                        </div>

                        {venueData?.status == 'pending' ? (<div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-8">
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-success w-md"
                                  onClick={() => handleApprove('approved') }
                                >
                                  Approve
                                </button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="btn btn-danger w-md"
                                  onClick={() => handleApprove('rejected')}
                                  style={{ marginLeft: '10px' }}
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>) : (
                          <>
                             {venueData?.status == 'approved' ? (
                                <Alert color="success">
                                  Venue already approved
                                </Alert>
                             ) : (
                                <Alert color="danger">
                                  Venue already rejected
                                </Alert>
                             )}
                          </>
                        )}
                        
                         




                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      
     


     
    </React.Fragment>
  );
}
