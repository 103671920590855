import React, { useState, useEffect } from "react";
import { Row, CardBody, Spinner, Button, Col } from "reactstrap";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import PaymentModal from './PaymentModal';
import { postRequest } from "../../../components/Common/Utils.js";
import moment from "moment";
import "./Subscriptionlist.css";

const Subscriptionlist = () => {
  const [plans, setPlans] = useState([]);
  const [payAsYouGoPlans, setPayAsYouGoPlans] = useState([]);
  const [activePlan, setActivePlan] = useState({});
  const [showPricing, setShowPricing] = useState(false);
  const [showAccount, setShowAccount] = useState(true);
  const [loader, setLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('regular');
  const [venueInfo, setVenueInfo] = useState({});
  const venueId = localStorage.getItem("stored_venueId");

  const handleChangePlan = () => {
    setShowPricing(true);
  };

  const handleCancel = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to cancel your package?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await postRequest(`subscription/venue/order/cancel`, {
          stripeSubscriptionId: activePlan.stripeSubscriptionId,
          _id: activePlan._id,
        });
        if (response.status) {
          setShowAccount(false);
          setShowPricing(false);
          Swal.fire("Your package has been canceled!", {
            icon: "success",
          });
          return window.location.reload();
        }
        Swal.fire("Failed to cancel your package!", {
          icon: "error",
        });
      }
    });
  };

  // const handleSelectPlan = async (selectedPlan) => {
  //   const isPayAsYouGo = selectedPlan.type === 'pay-as-you-go';
  //   Swal.fire({
  //     title: "Are you sure?",
  //     html: `Are you sure you want to ${isPayAsYouGo ? 'select' : fetchPlanDayliAmount(selectedPlan.amount, selectedPlan.validity) >  ( fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0 )  ? 'upgrade' : 'downgrade'} to <b style="color: #e3232b;">${selectedPlan.type.toUpperCase()}</b> subscription?`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       if (venueInfo.customerId && venueInfo.creditCardId) {
  //         const orderResponse = await postRequest(`subscription/venue/order/create`, {
  //           venueId,
  //           subscriptionId: selectedPlan._id,
  //           stripePlanId: selectedPlan.stripePlanId,
  //           stripeCardId: venueInfo.creditCardId,
  //           stripeCustomerId: venueInfo.customerId
  //         });

  //         if (orderResponse.status) {
  //           Swal.fire('Subscription successful!', '', 'success');
  //           window.location.reload();
  //         } else {
  //           Swal.fire('Failed to create subscription!', '', 'error');
  //         }
  //       } else {
  //         if (!venueInfo.email) {
  //           const { value: email } = await Swal.fire({
  //             title: 'Enter your email address',
  //             input: 'email',
  //             inputLabel: 'Email address',
  //             inputPlaceholder: 'Enter your email address',
  //             inputValidator: (value) => {
  //               if (!value) {
  //                 return 'You need to enter an email address!';
  //               }
  //             }
  //           });

  //           if (email) {
  //             venueInfo.email = email;
  //             setVenueInfo((prevInfo) => ({ ...prevInfo, email }));
  //           }
  //         }

  //         if (!venueInfo.customerId) {
  //           const response = await postRequest(`subscription/venue/stripe/customer`, { email: venueInfo.email });
  //           if (response.status) {
  //             const { customerId } = response.data;
  //             setVenueInfo((prevInfo) => ({ ...prevInfo, customerId }));
  //             localStorage.setItem("stripeCustomerId", customerId);
  //           }
  //         }

  //         setSelectedPlan(selectedPlan);
  //         setIsPaymentModalOpen(true);
  //       }
  //     }
  //   });
  // };


  const handleSelectPlan = async (selectedPlan) => {
    const isPayAsYouGo = selectedPlan.type === 'pay-as-you-go';
    const isUpgrade = fetchPlanDayliAmount(selectedPlan.amount, selectedPlan.validity) > 
                      (fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0);
  
    Swal.fire({
      title: "Are you sure?",
      html: `
        Are you sure you want to ${isPayAsYouGo ? 'select' : isUpgrade ? 'upgrade' : 'downgrade'} 
        to <b style="color: #e3232b;">${selectedPlan.type.toUpperCase()}</b> subscription?
        <br><br>
        <input type="checkbox" id="updateRightNow" ${isUpgrade ? 'checked' : ''}>
        <label for="updateRightNow">Start new plan right away?</label>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updateSubscriptionRightNow = document.getElementById('updateRightNow').checked;
  
        if (venueInfo.customerId && venueInfo.creditCardId) {
          const orderResponse = await postRequest(`subscription/venue/order/create`, {
            venueId,
            subscriptionId: selectedPlan._id,
            stripePlanId: selectedPlan.stripePlanId,
            stripeCardId: venueInfo.creditCardId,
            stripeCustomerId: venueInfo.customerId,
            updateSubscriptionRightNow
          });
  
          if (orderResponse.status) {
            Swal.fire('Subscription successful!', '', 'success');
            window.location.reload();
          } else {
            Swal.fire('Failed to create subscription!', '', 'error');
          }
        } else {
          if (!venueInfo.email) {
            const { value: email } = await Swal.fire({
              title: 'Enter your email address',
              input: 'email',
              inputLabel: 'Email address',
              inputPlaceholder: 'Enter your email address',
              inputValidator: (value) => {
                if (!value) {
                  return 'You need to enter an email address!';
                }
              }
            });
  
            if (email) {
              venueInfo.email = email;
              setVenueInfo((prevInfo) => ({ ...prevInfo, email }));
            }
          }
  
          if (!venueInfo.customerId) {
            const response = await postRequest(`subscription/venue/stripe/customer`, { email: venueInfo.email });
            if (response.status) {
              const { customerId } = response.data;
              setVenueInfo((prevInfo) => ({ ...prevInfo, customerId }));
              localStorage.setItem("stripeCustomerId", customerId);
            }
          }
  
          setSelectedPlan(selectedPlan);
          setIsPaymentModalOpen(true);
        }
      }
    });
  };
  
  


  const handlePaymentModalClose = async (paymentMethodId) => {
    setIsPaymentModalOpen(false);

    if (paymentMethodId && venueInfo.customerId) {
      const attachResponse = await postRequest(`subscription/venue/stripe/attach-payment-method`, {
        paymentMethodId,
        customerId: venueInfo.customerId
      });

      if (!attachResponse.status) {
        Swal.fire('Failed to attach payment method!', '', 'error');
        return;
      }
    }

    if (paymentMethodId && venueInfo.email && venueInfo.customerId) {
      await postRequest(`venue/update-stripe`, {
        venueId,
        email: venueInfo.email,
        stripeCardId: paymentMethodId,
        stripeCustomerId: venueInfo.customerId
      });

      const orderResponse = await postRequest(`subscription/venue/order/create`, {
        venueId,
        subscriptionId: selectedPlan._id,
        stripePlanId: selectedPlan.stripePlanId,
        stripeCardId: paymentMethodId,
        stripeCustomerId: venueInfo.customerId
      });

      if (orderResponse.status) {
        Swal.fire('Subscription successful!', '', 'success');
        window.location.reload();
      } else {
        Swal.fire('Failed to create subscription!', '', 'error');
      }
    }
  };

  const sortPlansByDailyRate = (plans) => {
    return plans.sort((a, b) => {
      const dailyRateA = a.amount / getDaysForValidity(a.validity);
      const dailyRateB = b.amount / getDaysForValidity(b.validity);
      return dailyRateA - dailyRateB;
    });
  };

  const fetchPlanDayliAmount = (amount, validity) => {   
    if(!amount || !validity) return 0;
    //console.log("amount", amount, "validity", validity);
    //console.log("final amount", amount / getDaysForValidity(validity));
    return amount / getDaysForValidity(validity);   
  };


  const getDaysForValidity = (validity) => {
    switch (validity) {
      case 'day':
        return 1;
      case 'week':
        return 7;
      case 'month':
        return 30;
      case 'quarter':
        return 90;
      case 'year':
        return 365;
      default:
        return 1;
    }
  };

  const SubscriptionPlansManagement = async () => {
    setLoader(true);
    const payload = {
      limit: 1000000,
      page: 1,
      venueInfo: localStorage.getItem("stored_venueId"),
    };
    try {
      const response = await postRequest(`subscription/venue/list`, payload);
      const data = response.data;
      if (data) {
        const sortedPlans = sortPlansByDailyRate(data?.list.filter(plan => plan.type !== 'pay-as-you-go') || []);
        const sortedPayAsYouGoPlans = sortPlansByDailyRate(data?.list.filter(plan => plan.type === 'pay-as-you-go') || []);



        setPlans(sortedPlans);
        setPayAsYouGoPlans(sortedPayAsYouGoPlans);
        if (data.venueInfo) {
          setVenueInfo(data.venueInfo);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchActivePlan = async () => {
    setLoader(true);
    const payload = {
      venueId: venueId
    };
    try {
      const response = await postRequest(`subscription/fetch-venue-subscription`, payload);
      const data = response?.data;
      if (data != null && data.subscriptionId) {
        setActivePlan(data);
      } else {
        setShowAccount(false);
        setShowPricing(true);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    SubscriptionPlansManagement();
    fetchActivePlan();
  }, [venueId]);

  // const getDaysRemaining = (validTill) => {
  //   const now = moment();
  //   const expiration = moment(validTill);
  //   return expiration.diff(now, 'days');
  // };

  const getRemainingDays = (validTill) => {
    const now = moment().startOf('day'); // Start of today
    const end = moment(validTill).startOf('day'); // Start of validTill day
    const daysRemaining = end.diff(now, 'days');
    return daysRemaining < 0 ? 0 : daysRemaining;
  };

  const getPaymentStatus = (validTill) => {
    const now = moment().startOf('day'); // Start of today
    const end = moment(validTill).startOf('day'); // Start of validTill day
    return end.isBefore(now) ? `Payment Failed on ${moment(validTill).format('MMMM Do, YYYY')}` : moment(validTill).format('MMMM Do, YYYY');
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getValidityText = (validity) => {
    switch (validity) {
      case "daily":
        return "day";
      case "weekly":
        return "week";
      case "quarterly":
        return "quarter";
      case "monthly":
        return "month";
      case "yearly":
        return "year";
      default:
        return validity;
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            {venueId && showAccount && activePlan && activePlan.subscription && activePlan.subscriptionId && (
                <div className="account-info">
                  <h3 className="account-info__title">Account Information</h3>
                  <p>Current Subscription: <span>{activePlan.subscription?.type?.toUpperCase()}</span></p>
                  {activePlan.stripeStatus === "cancelled" ? (
                    <>
                      <p>Expire Date: <span>{moment(activePlan.validTill).format('MMMM Do YYYY, h:mm a')}</span></p>
                      <p>Cancelled At: <span>{moment(activePlan.cancelledDate).format('MMMM Do YYYY, h:mm a')}</span></p>
                    </>
                  ) : (
                    // <p>Next Payment: <span>{moment(activePlan.validTill).format('MMMM Do YYYY, h:mm a')}</span></p>
                    <p>Next Payment: <span>{getPaymentStatus(activePlan?.validTill)}</span></p>
                  )}
                  {/* <p>Days Remaining: <span>{getDaysRemaining(activePlan.validTill)} days</span></p> */}
                  <p>Days Remaining: <span>{getRemainingDays(activePlan?.validTill)} days</span></p>
                  <div className="account-info__buttons">
                    {new Date(activePlan.validTill) > new Date() ? (
                      <>
                        <Button className="mt-10 account-info__button" onClick={handleChangePlan}>Change Plan</Button>
                        {!activePlan.cancelledDate && (
                          <Button className="mt-10 account-info__button" onClick={handleCancel}>Cancel Plan</Button>
                        )}
                      </>
                    ) : (
                      <Button className="mt-10 account-info__button" onClick={handleChangePlan}>Plan Expired, Click to view plans</Button>
                    )}
                  </div>
                </div>
              )}

            {venueId && showPricing && (
              <>
                <div className="tab-buttons" style={{ marginTop: 48 }}>
                  <button
                    className={`tab-button ${activeTab === 'regular' ? 'active' : ''}`}
                    onClick={() => handleTabChange('regular')}
                  >
                    Regular Plans 
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'pay-as-you-go' ? 'active' : ''}`}
                    onClick={() => handleTabChange('pay-as-you-go')}
                  >
                    Pay As You Go Plans
                  </button>
                </div>

                {activeTab === 'regular' && (
                  <div className="pricing-section">
                    <Row className="pricing-table">
                      {plans?.map((plan, index) => (
                        <div key={index} className={`pricing-plan ${plan?.type?.toLowerCase()} ${ plan.isPopular  ? 'blue-bg' : ''}`}>
                          <h3>{plan?.type.toUpperCase()}</h3>
                          <p><span>{plan?.amount}</span> / {getValidityText(plan?.validity)}</p>
                          <ul className="plan-features">
                            {plan?.isPromoteAllowed && <li>Get Access to Promote</li>}
                            {plan?.isPromoteTop5 && <li>Will be Promoted in Top 5</li>}
                            {plan?.isManageBy7n && <li>Managed By 7NIGHTS</li>}
                            {plan?.isOfferlisting && (
                              <li>
                                {plan?.OfferlistingLimit === -1 ? "Unlimited Offer Listing" : `${plan?.OfferlistingLimit} Offer Listing`}
                              </li>
                            )}
                            {plan?.isStoryListing && (
                              <li>
                                {plan?.StoryListingLimit === 0 ? "Unlimited Story Listing" : `${plan?.StoryListingLimit} Story Listing`}
                              </li>
                            )}
                            {plan?.isBookingAllowed && <li>Booking System Allowed</li>}
                            {plan?.isMessangingAllowed && <li>Messaging Allowed</li>}
                            {plan?.isVenueListioning && <li>Venue Listing Allowed</li>}
                            {plan?.placementDiscover && <li>Placement on Discover</li>}
                            {plan?.placementDiscoverCount > 0 && <li>Placement Discover Count: {plan?.placementDiscoverCount}</li>}
                            {plan?.placementRecommendedVenue && <li>Placement on Recommended Venue</li>}
                            {plan?.placementOnTrendingVenue && <li>Placement on Trending Venue</li>}
                            {plan?.isPushNotificationAllowed && <li>Push Notification Allowed</li>}
                            {plan?.pushNotificationLimit > 0 && <li>Push Notification Limit: {plan?.pushNotificationLimit}</li>}
                            {plan?.isAppBannerAllowed && <li>App Banner Allowed</li>}
                            {plan?.appBannerLimit > 0 && <li>App Banner Limit: {plan?.appBannerLimit}</li>}
                            {plan?.isDiscount && <li>Discount Allowed</li>}
                            {plan?.discountLimit > 0 && <li>Discount Limit: {plan?.discountLimit}</li>}
                            {plan?.isDashboard && <li>Basic Dashboard Access</li>}
                            {plan?.haveAnalytics && <li>User & Business Analytics</li>}
                            {plan?.isFilterOnTop && <li>Filter on Top</li>}
                            {plan?.sliderVenue && <li>Slider for Venue</li>}
                            {plan?.socialMediaExposure && <li>Social Media Exposure</li>}
                            {plan?.promotedReel && <li>Promoted Reel</li>}
                            {plan?.socialMediaPromotion && <li>Social Media Promotion</li>}
                            {plan?.marketingAd && <li>Marketing and Advertising</li>}
                          </ul>
                          <Button
                            className="button"
                            onClick={() => handleSelectPlan(plan)}
                          >
                            {activePlan.subscription && plan._id === activePlan.subscription._id
                              ? 'Current Plan'
                              : fetchPlanDayliAmount(plan.amount, plan.validity) > ( fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0)
                                ? 'Upgrade Plan'
                                : fetchPlanDayliAmount(plan.amount, plan.validity) < ( fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0)
                                  ? 'Downgrade Plan'
                                  : 'Select Plan'}
                          </Button>
                        </div>
                      ))}
                    </Row>
                  </div>
                )}

                {activeTab === 'pay-as-you-go' && (
                  <div className="pricing-section">
                    <Row className="pricing-table">
                      {payAsYouGoPlans?.map((plan, index) => (
                        <div key={index} className={`pricing-plan ${plan?.type?.toLowerCase()} ${ plan.isPopular  ? 'blue-bg' : ''}`}>
                          <h3>{plan?.type.toUpperCase()}</h3>
                          <h4 style={{ fontSize: "16px" }}>({plan?.title})</h4>
                          <p>{plan?.amount} {plan?.currency || "AED"} / {getValidityText(plan?.validity)}</p>
                          <ul className="plan-features">
                            {plan?.isPromoteAllowed && <li>Get Access to Promote</li>}
                            {plan?.isPromoteTop5 && <li>Will be Promoted in Top 5</li>}
                            {plan?.isManageBy7n && <li>Managed By 7NIGHTS</li>}
                            {plan?.haveAnalytics && <li>User & Business Analytics</li>}
                            {plan?.isOfferlisting && (
                              <li>
                                {plan?.OfferlistingLimit === -1 ? "Unlimited Offer Listing" : plan?.OfferlistingLimit === 0 ? `${plan?.offerAddCharge} AED Per Offer Listing` : `${plan?.OfferlistingLimit} Offer Listing`}
                              </li>
                            )}
                            {plan?.isStoryListing && (
                              <li>
                                {plan?.StoryListingLimit === 0 ? "Unlimited Story Listing" : `${plan?.StoryListingLimit} Story Listing`}
                              </li>
                            )}
                            {plan?.isBookingAllowed && <li>Booking System Allowed</li>}
                            {plan?.isMessangingAllowed && <li>Messaging Allowed</li>}
                            {plan?.isVenueListioning && <li>Venue Listing Allowed</li>}
                            {plan?.placementDiscover && <li>Placement on Discover</li>}
                            {plan?.placementDiscoverCount > 0 && <li>Placement Discover Count: {plan?.placementDiscoverCount}</li>}
                            {plan?.placementRecommendedVenue && <li>Placement on Recommended Venue</li>}
                            {plan?.placementOnTrendingVenue && <li>Placement on Trending Venue</li>}
                            {plan?.isPushNotificationAllowed && <li>Push Notification Allowed</li>}
                            {plan?.pushNotificationLimit > 0 && <li>Push Notification Limit: {plan?.pushNotificationLimit}</li>}
                            {plan?.isAppBannerAllowed && <li>App Banner Allowed</li>}
                            {plan?.appBannerLimit > 0 && <li>App Banner Limit: {plan?.appBannerLimit}</li>}
                            {plan?.isDiscount && <li>Discount Allowed</li>}
                            {plan?.discountLimit > 0 && <li>Discount Limit: {plan?.discountLimit}</li>}
                            {plan?.isDashboard && <li>Basic Dashboard Access</li>}
                            {plan?.isFilterOnTop && <li>Filter on Top</li>}
                            {plan?.sliderVenue && <li>Slider for Venue</li>}
                            {plan?.socialMediaExposure && <li>Social Media Exposure</li>}
                            {plan?.promotedReel && <li>Promoted Reel</li>}
                            {plan?.socialMediaPromotion && <li>Social Media Promotion</li>}
                            {plan?.marketingAd && <li>Marketing and Advertising</li>}
                          </ul>
                          <Button
                            className="button"
                            onClick={() => handleSelectPlan(plan)}
                          >
                            {activePlan.subscription && plan._id === activePlan.subscription._id
                              ? 'Current Plan'
                              : fetchPlanDayliAmount(plan.amount, plan.validity) > ( fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0)
                                ? 'Upgrade Plan'
                                : fetchPlanDayliAmount(plan.amount, plan.validity) < ( fetchPlanDayliAmount(activePlan?.subscription?.amount, activePlan?.subscription?.validity) || 0)
                                  ? 'Downgrade Plan'
                                  : 'Select Plan'}
                          </Button>
                        </div>
                      ))}
                    </Row>
                  </div>
                )}

              </>
            )}
          </>
        )}
      </Row>

      {selectedPlan && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onRequestClose={() => setIsPaymentModalOpen(false)}
          plan={selectedPlan}
          venueId={venueId}
          onPaymentSuccess={handlePaymentModalClose}
        />
      )}
    </React.Fragment>
  );
};

export default Subscriptionlist;
 