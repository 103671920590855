import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './styles.css'; // Import your custom styles if any
import { postRequest } from "components/Common/Utils";

// SVG Icons as React components (you can replace these with actual SVGs or import SVG files)
const UserIcon = () => (
    <i class="fas fa-user" style={{ color: '#212121' }}></i>
);

const BusinessIcon = () => (
    <i class="fas fa-eye" style={{ color: '#212121' }}></i>
);

const VenueIcon = () => (
    <i class="fas fa-map-marker-alt" style={{ color: '#212121' }}></i>
);

const OfferIcon = () => (
    <i class="fas fa-search" style={{ color: '#212121' }}></i>
);

const DiscountIcon = () => (
    <i class="fa fa-mobile" style={{ color: '#212121' }}></i>
);

const DealsIcon = () => (
    <i class="fas fa-mobile-alt" style={{ color: '#212121' }}></i>
);


const defaultData = [
    {
        color: '#3498DB', // Vibrant color for Total Users
        icon: <UserIcon />, // Unique Users
        label: 'Unique Users',
    },
    {
        color: '#2ECC71', // Vibrant color for Total View
        icon: <BusinessIcon />, // Total View
        label: 'Total View',
    },
    {
        color: '#F1C40F', // Vibrant color for Total Click
        icon: <VenueIcon />, // Total Click
        label: 'Total Click',
    },
    {
        color: '#E67E22', // Vibrant color for Total Search
        icon: <OfferIcon />, // Total Search
        label: 'Total Search',
    },
    {
        color: '#E74C3C', // Vibrant color for Activity on Android
        icon: <DiscountIcon />, // Activity on Android
        label: 'Activity on Android',
    },
    {
        color: '#9B59B6', // Vibrant color for Activity on iOS
        icon: <DealsIcon />, // Activity on iOS
        label: 'Activity on iOS',
    },
];


const DashboardCard = ({ color, icon, value, label }) => {
    return (
        <Col md="2">
            <Card style={{ backgroundColor: color, borderRadius: '10px', textAlign: 'center' }}>
                <CardBody>
                    <div style={{ fontSize: '30px', marginBottom: '10px' }}>
                        {icon}
                    </div>
                    <h3 style={{ fontSize: '24px', marginBottom: '10px', color: "#212121" }}>{value}</h3>
                    <p style={{ fontSize: '14px', color: '#6c757d', color: "#212121" }}>{label}</p>
                </CardBody>
            </Card>
        </Col>
    );
};

const Dashboard = ({ offerId }) => {
    const [data, setData] = useState(defaultData);

    //console.log(offerId);

    const DashboardStat = async () => {
        postRequest(`log/count-offer-by-category`, { offerId }).then((response) => {
            if (response.status === 1) {
                const stats = response.data;
                const updatedData = [
                    {
                        ...defaultData[0],
                        value: stats.uniqueUsers,
                    },
                    {
                        ...defaultData[1],
                        value: stats.totalView,
                    },

                    {
                        ...defaultData[2],
                        value: stats.totalClick,
                    },

                    {
                        ...defaultData[3],
                        value: stats.totalSearch,
                    },
                   
                    {
                        ...defaultData[4],
                        value: stats.activityOnAndroid,
                    },
                    {
                        ...defaultData[5],
                        value: stats.activityOnIos,
                    },
                ];
                setData(updatedData);
            }
        });
    };

    useEffect(() => {
        DashboardStat();
    }, []);

    return (
        <Row>
            {data.map((item, index) => (
                <DashboardCard
                    key={index}
                    color={item.color}
                    icon={item.icon}
                    value={item.value}
                    label={item.label}
                />
            ))}
        </Row>
    );
};

export default Dashboard;
