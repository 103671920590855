import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/VenueModalOrg.js";

export default function CreateVenueMusic() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [musicList, setMusicList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name) => {
    setVenueId(id);
    setVenueText(name);
    closeModal();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("music/list", { limit: 10000000 });
    if (response.status == 1) {
      setMusicList(response.data.list);
    }
    setLoading(false);
  };

  const Createmusic = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("venueId", venueId);
    formData.append("music", data.music);

    const response = await postRequestForm("venue/music/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...data };
    list[index] = value;
    setData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Assign Venue Music</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Music</li>:{" "}
              <li className="breadcrumb-item">Assign Venue Music</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Assign Music </h4>
                <Form onSubmit={Createmusic}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {venueText}
                                  </a>
                                </div>
                                <div>                                 
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Venue
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Music
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("music", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              {musicList.map((item, index) => (
                                <option value={item._id}>{item.title}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
    </React.Fragment>
  );
}
