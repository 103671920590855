import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { postRequest } from "../Common/Utils";

const SidebarContent = (props) => {
  const ref = useRef();

  const [admin, setAdmin] = useState("super-admin");
  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem("permissions")) || null);
  const [activePlan, setActivePlan] = useState(true);
  const [loader, setLoader] = useState(false);

  const [activeOffer, setActiveOffer] = useState(true);
  const [activeStory, setActiveStory] = useState(true);
  const [activeDiscount, setActiveDiscount] = useState(true);
  const [activeChat, setActiveChat] = useState(true);
  const [activeNotification, setActiveNotification] = useState(true);
  const [activeReservation, setActiveReservation] = useState(true);

  useEffect(() => {
    const accType = localStorage.getItem("acc_type");
    setAdmin(accType);
  }, []);



  useEffect(() => {
    const fetchActivePlan = async () => {
      const venueId = localStorage.getItem("stored_venueId");
      const businessId = localStorage.getItem("stored_bussinessId");

      let payload = {
        bId: businessId // ensure you pass venueId as a prop
      };

      if (venueId) {
        payload = {
          venueId: venueId
        };
      }

      //console.log('payload', payload);

      try {
        const response = await postRequest(`subscription/fetch-venue-subscription`, payload);

        //console.log('response1', response.data);
        //console.log('businessId', businessId, venueId);

        let data = null;

        if (businessId) {
          data = response.data[0];
        } else {
          data = response.data;
        }

        if (!data) {
          data = response.data;
        }

        //console.log('data', data);

        if (data?.subscription) {
          setActivePlan(true);
          setActiveOffer(data?.subscription?.isOfferlisting|| false);
          setActiveStory(data?.subscription?.isStoryListing || false);
          setActiveDiscount(data?.subscription?.isDiscount || false);
          setActiveChat(data?.subscription?.isMessangingAllowed || false);
          setActiveReservation(data?.subscription?.isBookingAllowed || false);
          setActiveNotification(data?.subscription?.isPushNotificationAllowed || false);
        } else {
          setActivePlan(false);
          setActiveOffer(false);
          setActiveStory(false);
          setActiveDiscount(false);
          setActiveChat(false);
          setActiveNotification(false);
        }

      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchActivePlan();
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const renderMenuItem = (permission, link, icon, title) => {
    
    if (!permissions || permissions[permission]) {
      return (
        <li>
          <Link to={link} className="waves-effect">
            <i className={icon}></i>
            <span>{props.t(title)}</span>
          </Link>
        </li>
      );
    }
    return null;
  };

  const renderSubMenu = (permission, parentIcon, parentTitle, subMenus) => {
    if (!permissions || permissions[permission].show) {
      return (
        <li>
          <Link to="#" className="has-arrow waves-effect">
            <i className={parentIcon}></i>
            <span>{props.t(parentTitle)}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            {subMenus.map((subMenu, index) => (
              <li key={index}>
                <Link to={subMenu.link} className="waves-effect">
                  <span>{props.t(subMenu.title)}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      );
    }
    return null;
  };

  const businessACTemplate = () => {
    return (
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu" >
          {/* <li className="menu-title">{props.t("Main")} </li> */}
          <li>
            <Link to="/business-account/dashboard" className="waves-effect">
              <i className="mdi mdi-view-dashboard"></i>
              {/* <span className="badge rounded-pill bg-primary float-end">
          2
        </span> */}
              <span>{props.t("Dashboard")}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-city"></i>
              <span>{props.t("Business")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/business" className=" waves-effect">
                  <span>{props.t("Business Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/venues" className=" waves-effect">
                  <span>{props.t("Venue Management")}</span>
                </Link>
              </li>
              

              <li>
                <Link to="/galleries" className=" waves-effect">
                  <span>{props.t("Gallery Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/music" className=" waves-effect">
                  <span>{props.t("Music Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/feature" className=" waves-effect">
                  <span>{props.t("Feature Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/themes" className=" waves-effect">
                  <span>{props.t("Theme Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/cuisines" className=" waves-effect">
                  <span>{props.t("Cuisine Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/ratings" className=" waves-effect">
                  <span>{props.t("Rating Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/follows" className=" waves-effect">
                  <span>{props.t("Follow Management")}</span>
                </Link>
              </li>

             
             
            </ul>
          </li>

          {/* <li>
        <Link to="/#" className="has-arrow waves-effect">
          <i className="mdi mdi-tooltip-edit"></i>
          <span>{props.t("Category")}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/category" className=" waves-effect">
              <span>{props.t("Category Management")}</span>
            </Link>
          </li>
          <li>
            <Link to="/banner" className=" waves-effect">
              <span>{props.t("Banner Management")}</span>
            </Link>
          </li>
        </ul>
      </li> */}
        {activeOffer && (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-map"></i>
              <span>{props.t("Offers")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              {/* <li>
            <Link to="/public-holiday" className=" waves-effect">
              <span>{props.t("Public Holiday")}</span>
            </Link>
          </li> */}
              <li>
                <Link to="/offer" className=" waves-effect">
                  <span>{props.t("Offer Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/specialOffer" className=" waves-effect">
                  <span>{props.t("Discount")}</span>
                </Link>
              </li>
              <li>
                <Link to="/specialOfferclaim" className=" waves-effect">
                  <span>{props.t("Discount Claim")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/business-account/vouchers"
                  className="waves-effect"
                >
                  <span>{props.t("Deal Management")}</span>
                </Link>
              </li>
              {/* <li>
          <Link to="/offerOrder" className=" waves-effect">
            <span>{props.t("Offer Orders")}</span>
          </Link>
        </li>
        <li>
          <Link to="/offerNotification" className=" waves-effect">
            <span>{props.t("Offer Notification")}</span>
          </Link>
        </li> */}
            </ul>
          </li>
        )}
           {activeStory && (
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-tooltip-edit"></i>
              <span>{props.t("Stories")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              {/* <li>
            <Link to="/public-holiday" className=" waves-effect">
              <span>{props.t("Public Holiday")}</span>
            </Link>
          </li> */}
              <li>
                <Link to="/business-account/stories" className=" waves-effect">
                  <span>{props.t("Story Management")}</span>
                </Link>
              </li>
             
            </ul>
          </li>
          )}

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-cash"></i>
              <span>{props.t("Subscription")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/business-account/get-subscription" className=" waves-effect">
                  <span>{props.t("Get Subscription")}</span>
                </Link>
              </li>
              <li>
                <Link to="/business-account/my-subscription" className=" waves-effect">
                  <span>{props.t("My Subscription")}</span>
                </Link>
              </li>
              <li>
                <Link to="/business-account/subscriptions/commissions" className=" waves-effect">
                  <span>{props.t("Venue Subscription Commissions")}</span>
                </Link>
              </li>

              {/* <li>
            <Link to="/business-account/stories" className=" waves-effect">
              <span>{props.t("Story Management")}</span>
            </Link>
          </li> */}

            </ul>
          </li>

          {/* <li>
      <Link to="/dashboard" className="waves-effect">
        <i className="mdi mdi-view-dashboard"></i>               
        <span>{props.t("Dashboard")}</span>
      </Link>
    </li> */}

          {/* <li>
      <Link to="/outinglist" className=" waves-effect">
        <i className="mdi mdi-walk"></i>
        <span>{props.t("Outing Management")}</span>
      </Link>
    </li> */}
        </ul>
      </div>
      </SimpleBar>
    )
  }

  const adminTemplate = () => {
    return (
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {renderMenuItem("dashboard", "/dashboard", "mdi mdi-view-dashboard", "Dashboard")}
            {renderMenuItem("commissionReport", "/commission-report", "mdi mdi-percent", "Commissions")}
            {renderMenuItem("analytics", "/venues-analytics", "mdi mdi-checkbox-blank", "Venues Analytics")}
            {renderMenuItem("analytics", "/offers-analytics", "mdi mdi-checkbox-blank", "Offers Analytics")}
            {renderMenuItem("subscriptionReport", "/subscription-report", "mdi mdi-cash", "Subscription Plan")}

            {renderSubMenu("users", "mdi mdi-account", "Users", [
              { link: "/users", title: "User Management" },
              { link: "/deleteAccount", title: "Delete Account Request" },
            ])}

            {renderSubMenu("adminManagement", "mdi mdi-account", "Role Access", [
              { link: "/roles", title: "Role Access Management" },
            ])}

            {renderMenuItem("logs", "/Log-Management-list", "fas fa-users-cog", "Log Management")}

            {renderSubMenu("onboarding", "mdi mdi-tooltip-edit", "Onboarding", [
              { link: "/onBoardingUser", title: "Onboarding Users" },
              { link: "/onBoardingVenue", title: "Onboarding Venues" },
            ])}

            {renderSubMenu("businessManagement", "mdi mdi-city", "Business", [
              { link: "/business", title: "Business Management" },
              { link: "/venues", title: "Venue Management" },
              { link: "/venue-reservations", title: "Venue Reservations" },
              { link: "/venue-verify", title: "Venue Verify List" },
              { link: "/venue-badge", title: "Venue Badge List" },
              { link: "/galleries", title: "Gallery Management" },
              
              { link: "/music", title: "Music Management" },
              { link: "/feature", title: "Feature Management" },
              { link: "/themes", title: "Theme Management" },
              { link: "/cuisines", title: "Cuisine Management" },
              { link: "/ratings", title: "Rating Management" },
              { link: "/follows", title: "Follow Management" },
            ])}

            {renderSubMenu("appManagement", "mdi mdi-apps", "App", [
              { link: "/7n/settings", title: "7Night Management" },
              { link: "/pages", title: "Setting Management" },
              { link: "/activityIcon", title: "Icon Management" },
              { link: "/country", title: "Country Management" },
              { link: "/region", title: "Region Management" },
              { link: "/filtercategories", title: "Filter Category" },
            ])}



            {renderSubMenu("categoryManagement", "mdi mdi-tooltip-edit", "Category", [
              { link: "/category", title: "Category Management" },
            ])}

            {renderSubMenu("subscriptionManagement", "mdi mdi-cash", "Subscription", [
              { link: "/venue/subscriptions", title: "Venue Plans" },
              { link: "/venue/subscriptions/orders", title: "Venue Subscription Orders" },
              { link: "/venue/subscriptions/commissions", title: "Venue Subscription Commissions" },
              { link: "/orders", title: "Order Management" },
            ])}

            {renderSubMenu("offerManagement", "mdi mdi-map", "Offers", [
              { link: "/public-holiday", title: "Public Holiday" },
              { link: "/offer", title: "Offer Management" },
              { link: "/specialOffer", title: "Discount" },
              { link: "/specialOfferclaim", title: "Discount Claim" },
              { link: "/offerOrder", title: "Offer Orders" },
              { link: "/offerCommission", title: "Offer Commission" },
              { link: "/offerNotification", title: "Offer Notification" },
            ])}

            {renderSubMenu("HotDealManagement", "mdi mdi-percent", "Hot Deals", [
              { link: "/hot-deals", title: "Hot Deals" },
            ])}

            {renderSubMenu("HomeBlockManagement", "mdi mdi-cube-outline", "Home Blocks", [
              { link: "/homeblock", title: "Home Block Management" },
              { link: "/searchblock", title: "Search Block Management" },
              { link: "/exploreblock", title: "Explore Block Management" },
              { link: "/videos", title: "Video Management" },
              { link: "/homeblockbanner", title: "Banner Management" },
              { link: "/customComponents", title: "CustomComponent Management" },
              { link: "/dealOrder", title: "Deal Orders" },
              { link: "/blocksize", title: "Size Management" },
              { link: "/stories", title: "Story Management" },
            ])}

            {renderSubMenu("NotificationManagement", "mdi mdi-bell-ring", "Notification", [
              { link: "/notification", title: "Notification" },
              { link: "/notification-pending", title: "Notification Pending" },
              { link: "/notificationread", title: "Notification Read" },
            ])}

            {permissions && permissions.NotificationManagement?.show && (
              <>
                {renderMenuItem("NotificationManagement.show", "/inAppNotification", "mdi mdi-message", "In-app Notification")}
                {renderMenuItem("NotificationManagement.show", "/contactUs", "mdi mdi-account-box", "Contact Us")}
              </>
            )}
           
          </ul>
        </div>
      </SimpleBar>
    );
  };

  const superAdminTemplate = () => {
    return(
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {/* <li className="menu-title">{props.t("Main")} </li> */}
          <li>
          {/* <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-account"></i>
              <span>{props.t("Users")}</span>
            </Link> */}
            <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-view-dashboard"></i>
              {/* <span className="badge rounded-pill bg-primary float-end">
              2
            </span> */}
              <span>{props.t("Dashboard")}</span>
            </Link>
          </li>

          <li>
            <Link to="/commission-report" className=" waves-effect">
              <i className="mdi mdi-percent"></i>
              <span>{props.t("Commissions")}</span>
            </Link>
          </li>
          <li>
            <Link to="/venues-analytics" className=" waves-effect">
              <i className="mdi mdi-checkbox-blank"></i>
              <span>{props.t("Venues Analytics")}</span>
            </Link>
          </li>
          <li>
            <Link to="/offers-analytics" className=" waves-effect">
              <i className="mdi mdi-checkbox-blank"></i>
              <span>{props.t("Offers Analytics")}</span>
            </Link>
          </li>

          <li>
            <Link to="/subscription-report" className=" waves-effect">
              <i className="mdi mdi-cash"></i>
              <span>{props.t("Subscription Plan")}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-account"></i>
              <span>{props.t("Users")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/users" className=" waves-effect">
                  <span>{props.t("User Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/deleteAccount" className=" waves-effect">
                  <span>{props.t("Delete Account Request")}</span>
                </Link>
              </li>

            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-account"></i>
              <span>{props.t("Role Access")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/roles" className=" waves-effect">
                  <span>{props.t("Role Access Management")}</span>
                </Link>
              </li>

              
            </ul>
          </li>

          <li>
                <Link to="/Log-Management-list" className=" waves-effect">
                  <i className="fas fa-users-cog"></i>
                  <span>{props.t("Log Management")}</span>
                </Link>
              </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-tooltip-edit"></i>
              <span>{props.t("Onboarding")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">             
              
              <li>
                <Link to="/onBoardingUser" className=" waves-effect">
                  <span>{props.t("Onboarding Users")}</span>
                </Link>
              </li>

              <li>
                <Link to="/onBoardingVenue" className=" waves-effect">
                  <span>{props.t("Onboarding Venues")}</span>
                </Link>
              </li>
            

            
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-city"></i>
              <span>{props.t("Business")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/business" className=" waves-effect">
                  <span>{props.t("Business Management")}</span>
                </Link>
              </li>
              
             

              <li>
                <Link to="/venues" className=" waves-effect">
                  <span>{props.t("Venue Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/galleries" className=" waves-effect">
                  <span>{props.t("Gallery Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/music" className=" waves-effect">
                  <span>{props.t("Music Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/feature" className=" waves-effect">
                  <span>{props.t("Feature Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/themes" className=" waves-effect">
                  <span>{props.t("Theme Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/cuisines" className=" waves-effect">
                  <span>{props.t("Cuisine Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/ratings" className=" waves-effect">
                  <span>{props.t("Rating Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/follows" className=" waves-effect">
                  <span>{props.t("Follow Management")}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-apps"></i>
              <span>{props.t("App")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/pages" className=" waves-effect">
                  <span>{props.t("Setting Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/activityIcon" className=" waves-effect">
                  <span>{props.t("Icon Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/country" className=" waves-effect">
                  <span>{props.t("Country Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/region" className=" waves-effect">
                  <span>{props.t("Region Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/filtercategories" className=" waves-effect">
                  <span>{props.t("Filter Category")}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-tooltip-edit"></i>
              <span>{props.t("Category")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/category" className=" waves-effect">
                  <span>{props.t("Category Management")}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-cash"></i>
              <span>{props.t("Subscription")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/venue/subscriptions" className=" waves-effect">
                  <span>{props.t("Venue Plans")}</span>
                </Link>
              </li>
              <li>
                <Link to="/venue/subscriptions/orders" className=" waves-effect">
                  <span>{props.t("Venue Subscription Orders")}</span>
                </Link>
              </li>
              <li>
                <Link to="/venue/subscriptions/commissions" className=" waves-effect">
                  <span>{props.t("Venue Subscription Commissions")}</span>
                </Link>
              </li>
              <li>
                <Link to="/orders" className=" waves-effect">
                  <span>{props.t("Order Management")}</span>
                </Link>
              </li>

            </ul>
          </li>



          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-map"></i>
              <span>{props.t("Offers")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/public-holiday" className=" waves-effect">
                  <span>{props.t("Public Holiday")}</span>
                </Link>
              </li>
              <li>
                <Link to="/offer" className=" waves-effect">
                  <span>{props.t("Offer Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/specialOffer" className=" waves-effect">
                  <span>{props.t("Discount")}</span>
                </Link>
              </li>
              <li>
                <Link to="/specialOfferclaim" className=" waves-effect">
                  <span>{props.t("Discount Claim")}</span>
                </Link>
              </li>
              <li>
                <Link to="/offerOrder" className=" waves-effect">
                  <span>{props.t("Offer Orders")}</span>
                </Link>
              </li>
              <li>
                <Link to="/offerCommission" className=" waves-effect">
                  <span>{props.t("Offer Commission")}</span>
                </Link>
              </li>
              <li>
                <Link to="/offerNotification" className=" waves-effect">
                  <span>{props.t("Offer Notification")}</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
          <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-percent"></i>
              <span>{props.t("Hot Deals")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
          <li>
            <Link to="/hot-deals" className=" waves-effect">             
              <span>{props.t("Hot Deals")}</span>
            </Link>
          </li>
          </ul>
          </li>


          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-cube-outline"></i>
              <span>{props.t("Home Blocks")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/homeblock" className=" waves-effect">
                  <span>{props.t("Home Block Management")}</span>
                </Link>
              </li>

              <li>
              <Link to="/searchblock" className=" waves-effect">
                <span>{props.t("Search Block Management")}</span>
              </Link>
            </li>
              <li>
                <Link to="/exploreblock" className=" waves-effect">
                  <span>{props.t("Explore Block Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/videos" className=" waves-effect">
                  <span>{props.t("Video Management")}</span>
                </Link>
              </li>

              <li>
                <Link to="/homeblockbanner" className=" waves-effect">
                  <span>{props.t("Banner Management")}</span>
                </Link>
              </li>


              <li>
                <Link to="/customComponents" className=" waves-effect">
                  <span>{props.t("CustomComponent Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/dealOrder" className=" waves-effect">
                  <span>{props.t("Deal Orders")}</span>
                </Link>
              </li>
              <li>
                <Link to="/blocksize" className=" waves-effect">
                  <span>{props.t("Size Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/stories" className=" waves-effect">
                  <span>{props.t("Story Management")}</span>
                </Link>
              </li>
              
            </ul>
          </li>



          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-bell-ring"></i>
              <span>{props.t("Notification")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/notification" className=" waves-effect">
                  <span>{props.t("Notification")}</span>
                </Link>
              </li>
              <li>
                <Link to="/notification-pending" className=" waves-effect">
                  <span>{props.t("Notification Pending")}</span>
                </Link>
              </li>
              <li>
                <Link to="/notificationread" className=" waves-effect">
                  <span>{props.t("Notification Read")}</span>
                </Link>
              </li>
            </ul>
          </li>

        

          <li>
            <Link to="/inAppNotification" className=" waves-effect">
              <i className="mdi mdi-message"></i>
              <span>{props.t("In-app Notification")}</span>
            </Link>
          </li>
          <li>
            <Link to="/contactUs" className=" waves-effect">
              <i className="mdi mdi-account-box"></i>
              <span>{props.t("Contact Us")}</span>
            </Link>
          </li>

       
        </ul>
      </div>
      </SimpleBar>
    )
  }

  const venueTemplate = () => {
    return (
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/venue-account/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
  
            <li>
              <Link to="/venue-account/order-report" className=" waves-effect">
                <i className="mdi mdi-percent"></i>
                <span>{props.t("Orders")}</span>
              </Link>
            </li>
  
            
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="mdi mdi-city"></i>
                <span>{props.t("Venue")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/venues" className="waves-effect">
                    <span>{props.t("Venue Management")}</span>
                  </Link>
                </li>

                { activeReservation && (
                     <li>
                     <Link to="/venue-reservations" className="waves-effect">
                        <span>{props.t("Venue Reservations")}</span>
                      </Link>
                   </li>
                )}

                <li>
                  <Link to="/galleries" className="waves-effect">
                    <span>{props.t("Gallery Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ratings" className="waves-effect">
                    <span>{props.t("Rating Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/follows" className="waves-effect">
                    <span>{props.t("Follow Management")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            {activeOffer && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-map"></i>
                  <span>{props.t("Offers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/offer" className="waves-effect">
                      <span>{props.t("Offer Management")}</span>
                    </Link>
                  </li>     
                    <li>
                    <Link to="/offerOrder" className=" waves-effect">
                      <span>{props.t("Offer Orders")}</span>
                    </Link>
                  </li>
                  <li>
                  <Link to="/offerCommission" className=" waves-effect">
                    <span>{props.t("Offer Commission")}</span>
                  </Link>
                </li>          
                  {/* <li>
                    <Link to="/venue-account/vouchers" className="waves-effect">
                      <span>{props.t("Deal Management")}</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
            )}
            {activeStory && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Stories")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/venue-account/stories" className="waves-effect">
                      <span>{props.t("Story Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
  
            {activeDiscount && (
  
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-clock"></i>
                  <span>{props.t("Discounts")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                 
                  <li>
                    <Link to="/specialOffer" className="waves-effect">
                      <span>{props.t("Discount")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOfferclaim" className="waves-effect">
                      <span>{props.t("Discount Claim")}</span>
                    </Link>
                  </li>               
                </ul>
              </li>
            )}
            
  
            { activeChat && ( <li>
              <Link to="/#" className="has-arrow waves-effect">
              <i class="fas fa-comments"></i>
                <span>{props.t("Support")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/venue-account/conversation" className=" waves-effect">
                    <span>{props.t("Chat ")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            ) }
         
  
            { activeNotification && ( <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-bell-ring"></i>
                <span>{props.t("Notification")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/notification" className=" waves-effect">
                    <span>{props.t("Notification")}</span>
                  </Link>
                </li>              
              </ul>
            </li> ) } 
  
            <li>
              <Link to="#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash"></i>
                <span>{props.t("Subscription")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/venue-account/get-subscription" className="waves-effect">
                    <span>{props.t("Get Subscription")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/venue-account/my-subscription" className="waves-effect">
                    <span>{props.t("Subscription History")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/venue-account/subscriptions/commissions" className="waves-effect">
                    <span>{props.t("Venue Subscription Commissions")}</span>
                  </Link>
                </li>
              </ul>
            </li>
  
            
           
          </ul>
        </div>
      </SimpleBar>
    );
  };

  const renderTemplate = () => {    
    
    if (admin === "venue") return venueTemplate();
    if (admin === "business") return businessACTemplate();

    //if (!permissions) return superAdminTemplate();

    if (admin === "super-admin" || admin === "superadmin") return superAdminTemplate();
    if(admin === "admin" || admin === "sub-admin" || admin === 'data-entry' || admin === 'stake-holder') return adminTemplate();
    
  };

  return <React.Fragment>{renderTemplate()}</React.Fragment>;
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
