import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import "./chat.css";
import FilterModal from "../../components/Modal/FilterModal";

import {
  postRequest,
  deleteRequest,
  putRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ReplyBox from "./ReplyBox";
import UserMessageList from "./UserMessageList";

const filterObj = {
  title: "",
  status: "",
  createdAt: "",
};

const ContactUsMessages = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [messagedata, setMessageData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [selectedItems, setselectedItems] = useState([]);
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [contactUsId, setContactUsId] = useState(location.state.row._id);
  const chatContainerRef = useRef(null);

  // Scroll to the bottom of the chat container when the component is loaded
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagedata]);

  useEffect(() => {
    messageManagment();
  }, [currentPage]);

  useEffect(() => {
    const payload = {
      conctactUsId: contactUsId,
    };

    postRequest(`comman/contact-us/query-detail-by-id`, payload)
      .then((response) => {
        const data = response.data;
        setTotalPage(data.count);
        setCurrentFPage(data.page);
        setMessageData(data);
      })
      .catch((err) => console.log(err));
  }, [contactUsId]);

  useEffect(() => {
    messageManagment();
  }, [sizePerPageOrg, sort, sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value;

    const res = await putRequest("venue/update", {
      contactUsId: actId,
      status: status,
    });

    if (res.status == 1) {
      toast.success("Message Status updated Successfully");
      messageManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {    
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);        
      }
    }
    
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });    
    toggleModel();
    messageManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    messageManagment();
  };

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status === true ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange(0, row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange(1, row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function dateFormatter(cellContent, row) {    
    return <div>{convertTimeToFormattedString(row.replies.reply)}</div>;
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Status</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("status", e.target.value)}
          className="form-select"
          value={filter.status}
        >
          <option value="">Select Status</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  function convertTimeToFormattedString(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Ensure single-digit minutes are formatted with a leading 0, e.g., 05
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const textValue = messagedata[0]?.subject;
  const capitalizedTextValue = textValue
    ? textValue.charAt(0).toUpperCase() + textValue.slice(1)
    : "";
  const onChatChange = (newId) => {
    setContactUsId(newId);
  };
  const columns = [
    {
      dataField: "replies",
      text: capitalizedTextValue,
      formatter: (replies, row) => {
        let currentDate = null; // Initialize currentDate

        return (
          <>
            <div className="chat-container" ref={chatContainerRef}>
              {replies.map((reply) => {
                const messageDate = new Date(reply.createdAt).toDateString();
                let dateStamp = null;

                // Check if the message date is different from the currentDate
                if (messageDate !== currentDate) {
                  currentDate = messageDate;
                  dateStamp = <div className="date-stamp">{messageDate}</div>;
                }

                return (
                  <>
                    {dateStamp}
                    <div
                      key={reply._id}
                      className={`${
                        reply.replyBy === "user"
                          ? "user-message"
                          : "admin-message"
                      }`}
                    >
                      <div className="message-content">{reply.reply}</div>
                      <div
                        className={`${
                          reply.replyBy === "user"
                            ? "user-message-time"
                            : "admin-message-time"
                        }`}
                      >
                        {convertTimeToFormattedString(row.createdAt)}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <ReplyBox onFormSubmit={messageManagment} newId={contactUsId} />
            </div>
          </>
        );
      },
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };

  const messageManagment = async (filterArgs = []) => {    
    setLoader(true);
    const payload = {
      conctactUsId: contactUsId,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(
        `comman/contact-us/query-detail-by-id`,
        payload
      );
      const data = response.data;
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setMessageData(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Contact Us</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Contact Us</li>:{" "}
                <li className="breadcrumb-item">Conversation</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">           
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting custom-card">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={messagedata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-3 text-end">
                                  <UserMessageList
                                    onChatChange={onChatChange}
                                  />
                                </Col>
                                <Col className="col-lg-9 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    className="table-custom-striped custom-overflow-hidden"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">                                
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default ContactUsMessages;
