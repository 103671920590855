import React, { useState, useEffect } from "react";
import countries from './countries.json';
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModalOrg.js";
import FileUpload from "../../components/Common/FileUpload"; 

export default function CreateUser() {
  const location = useLocation();
  const state = location.state?.row;

  const [userData, setUserData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [model, setModel] = useState(false);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const Createuser = async (e) => {
    e.preventDefault();

    if (!userData?.firstName) {
      toast.error("Please enter First Name");
      return;
    }

    // if(!userData?.email){
    //   toast.error("Please enter Email");
    //   return;
    // }    

    if(!userData?.phone){
      toast.error("Please enter Phone");
      return;
    }
    if(userData?.phone.length < 7 || userData?.phone.length > 14){
      toast.error("Please enter valid Phone");
      return;
    }

    if(!userData?.country_code){
      toast.error("Please select Country Code");
      return;
    }    

    

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);  
    setStartLoader(false);
    const payload = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      platform: userData.platform,
      isVip: userData.isVip,
      country_code: userData.country_code,
      gender: userData.gender,
      nationality: userData.nationality
    }



    if (selectedImageRes?.data?.url) {
      payload.image = selectedImageRes.data.url;     
    } else {      
      console.error("Image URL not found in the response.");
    } 
    setLoading(true);
    const response = await postRequest("user/create", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success("User created successfully");
      setTimeout(() => {
        history.push("/users");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...userData };
    list[index] = value;
    setUserData(list);
  };

  const updateValuePhone = (value) => {

    // check if value is number   

    if(isNaN(value)){     
      return;
    }

    if(value.length > 10){
      return;
    }


    const list = { ...userData };
    list['phone'] = value;
    setUserData(list);
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>User Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">User Management</li>:{" "}
              <li className="breadcrumb-item">Create User</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create User</h4>
                <Form onSubmit={Createuser}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("firstName", e.target.value)
                              }
                              value={userData?.firstName}
                              name="firstName"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("lastName", e.target.value)
                              }
                              value={userData?.lastName}
                              name="lastName"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={userData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValuePhone( e.target.value)
                              }
                              value={userData?.phone ?? ""}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>
                    

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                              Country Code
                          </label>
                          <div className="col-sm-6">
                          <select
                          onChange={(e) =>
                            updateValue("country_code", e.target.value)
                          }
                          value={userData?.country_code}
                          name="country"
                          className="form-select"
                        >
                          <option value="">Select country code</option>
                          {countries.map((country) => (
                            <option key={country.phoneCode} value={country.phoneCode}>
                              {country.name} ({country.phoneCode})
                            </option>
                          ))}
                        </select>
                          </div>
                        </div> 

                        
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-6">
                          <select
                            onChange={(e) => updateValue("platform", e.target.value)}
                            value={userData?.platform}
                            name="platform"
                            className="form-select"
                          >
                            <option value="">Select an option</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="web">Web</option>
                          </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            VIP Access
                          </label>
                          <div className="col-sm-6">
                          <select
                            onChange={(e) => updateValue("isVip", e.target.value)}
                            value={userData?.isVip ||false }
                            name="isVip"
                            className="form-select"
                          >
              
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Gender
                          </label>
                          <div className="col-sm-6">
                          <select
                              onChange={(e) => updateValue("gender", e.target.value)}
                              value={userData?.gender}
                              name="gender"
                              className="form-select"
                            >
                              <option value="">Select an option</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="prefer_not_to_say">Prefer not to say</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Nationality
                          </label>
                          <div className="col-sm-6">
                          <select
                          onChange={(e) => updateValue("nationality", e.target.value)}
                          value={userData?.nationality}
                          name="country"
                          className="form-select"
                        >
                          <option value="">Select a country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                          </div>
                        </div>                      

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
    
  );
}
