import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        const currentYear = moment().year();
        const currentMonth = moment().month();

        this.state = {
            options: {
                colors: ['#28bbe3'],
                chart: {
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        columnWidth: '70%',
                    },
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: Array.from({ length: 30 }, (_, i) => moment().year(currentYear).month(currentMonth).date(i + 1).format('DD MMM YYYY')),
                    labels: {
                        formatter: function (val) {
                            return val
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        };
    }

    render() {
        const { series, todaysIncome, totalIncome } = this.props.data;

        //console.log(series , todaysIncome , totalIncome);

        // const series = [];
        // const todaysIncome = 0;
        // const totalIncome = 0;

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Claimed Amount</h4>

                        <Row className="text-center mt-4">
                            <Col xs="6">
                                <h5 className="font-size-20">AED {todaysIncome}</h5>
                                <p className="text-muted">Today's Income</p>
                            </Col>
                            <Col xs="6">
                                <h5 className="font-size-20">AED {totalIncome}</h5>
                                <p className="text-muted">Total Income</p>
                            </Col>
                        </Row>

                        <div id="morris-bar-stacked" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={series} type="bar" height="290" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MonthlyEarnings;
