import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
import moment from "moment";



import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { size } from "lodash";
import FilterModal from "../../components/Modal/FilterModal";
import { da } from "date-fns/locale";

const filterObj = {
  title: "",
  subTitle: "",
  discountText: "",
  time: "",
  actualPrice: "",
  discountedPrice: "",
  features: "",
  createdAt: "",
};
const VenueSubscriptionOrders = (props) => {
  const  permissions  = props.permissions || { permissions: { create: false, update: false, delete: false, show: false } };
    const [modal_center, setmodal_center] = useState(false);
    const [packagedata, setpackagedata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedItems, setselectedItems] = useState([]);
  
    const [value, setValue] = React.useState(10);
  
    const [sort, setsort] = React.useState("createdAt");
    const [sortOrder, setsortOrder] = React.useState("desc");
    const [resetSearch, setResetSearch] = useState(false);
    const [filter, setFilter] = useState({ ...filterObj });
    const [model, setModel] = useState(false);
    const [currentFPage, setCurrentFPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPageOrg, setSizePerPage] = useState(20);
    const [image, setImage] = React.useState("");
    const [openLightbox, setOpenLightBox] = React.useState(false);
  
    useEffect(() => {
      packageManagment();
    }, [currentPage]);
  
    useEffect(() => {
      if (currentPage == 1) {
        packageManagment();
        return;
      }
      setCurrentPage(1);
    }, [sort, sortOrder, sizePerPageOrg]);
  
    const updateFilter = (key, value) => {
      const filterObj = { ...filter };
      filterObj[key] = value;
      setFilter(filterObj);
    };
  
    const applyFilter = () => {
      const filterArgs = [];
  
      Object.keys(filter).forEach((key) => {
        if (
          filter[key] !== "" &&
          filter[key] !== null &&
          filter[key] !== undefined
        ) {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
  
          if (
            key === "title" ||
            key === "subTitle" ||
            key === "discountText" ||
            key === "actualPrice" ||
            key === "discountedPrice" ||
            key === "features"
          ) {
            filterObject.type = "regex";
          } else if (key === "time") {
            filterObject.type = "eq";
          } else if (key === "createdAt") {
            filterObject.type = "date-eq";
          }
  
          filterArgs.push(filterObject);
        }
      });
      
      toggleModel();
      packageManagment(filterArgs);
    };
  
    const GetValueOnChange = async (value, _id) => {
      let actId = _id;
      let status = value == 1 ? 1 : 0;   
  
      const res = await putRequest("subscription/venue/update", {
          id: actId,
        isPublished: status ? false : true,
      });
  
      if (res.status == 1) {
        toast.success("Subscription Status updated Successfully");
        venueManagment();
      } else {
        toast.error("Something went wrong");
        setLoader(false);
      }
    };
  
    function activeFormatter(cellContent, row) {
      const toggleStatus = () => {
        const newStatus = row.isPublished == true ? true : false; // Invert the status
        GetValueOnChange(newStatus, row?._id);
      };
  
  
      return (
        <label>
          <Toggle
            defaultChecked={row.isPublished === true} // Check if status is 0 for "Active"
            onChange={toggleStatus}
            icons={false} // Hide the default icons
          /><br></br>
          {row.isPublished === true ? "Active" : "Inactive"}
        </label>
      );
    }
  
  
    const resetFilter = () => {
      setFilter({ ...filterObj });
      toggleModel();
      packageManagment();
    };
  
    const closeModal = () => {
      setModel(false);
    };
  
    const toggleModel = () => {
      setModel(!model);
    };
  
  //   const GetValueOnChange = async (value, _id) => {
  //     let actId = _id;
  //     let status = value == 1 ? 1 : 0;
  
  //     const formData = new FormData();
  //     formData.append("packageId", actId);
  //     formData.append("status", status);
  
  //     const res = await putRequestForm("membership-package/update", formData);
  
  //     if (res.status == 1) {
  //       toast.success("Package Status updated Successfully");
  //       packageManagment();
  //     } else {
  //       toast.error("Something went wrong");
  //       setLoader(false);
  //     }
  //   };
  
    const handleTableChange = (type, { page, sizePerPage, searchText }) => {
      if (type === "pagination") {
        if (page != currentPage) {
          setCurrentPage(page);
        }
      }
    };
  
    function removeBodyCss() {
      document.body.classList.add("no_padding");
    }
  
    const defaultSorted = [
      {
        dataField: "_id",
        order: "asc",
      },
    ];
  
    function tog_center() {
      setmodal_center(!modal_center);
      removeBodyCss();
    }
  
    function dateFormatter(cellContent, row) {
      return (
        <div style={{ display: "flex" }}>
          {convertTimeToFormattedString(row.createdAt)}
        </div>
      );
    }
  
    function ImgCoverFormatter(cellContent, row) {
      return (
        <>
          <img
            src={row.backgroundImage}
            onClick={() => showLightBox(row.backgroundImage)}
            alt="background img"
            width={"50px"}
          />
        </>
      );
    }
  
    const showLightBox = (image) => {
      setImage(image);
      setOpenLightBox(true);
    };
  
    function SNoFormatter(cellContent, row, i) {
      return <>{i + 1}</>;
    }
  
    function IsPopularFormatter(cellContent, row) {
      return (
        <>
          {row.isPopular === true ? (
            <>
              <p>Yes</p>
            </>
          ) : (
            <>
              <p>No</p>
            </>
          )}
        </>
      );
    }
  
    const components = [
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Title</label>
        <div className="col-sm-6">
          <input
            type="text"
            value={filter.title}
            onChange={(e) => updateFilter("title", e.target.value)}
            name="title"
            className="form-control"
          />
        </div>
      </div>,
  
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Sub Title</label>
        <div className="col-sm-6">
          <input
            type="text"
            value={filter.subTitle}
            onChange={(e) => updateFilter("subTitle", e.target.value)}
            name="title"
            className="form-control"
          />
        </div>
      </div>,
  
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Discount Text</label>
        <div className="col-sm-6">
          <input
            type="text"
            value={filter.discountText}
            onChange={(e) => updateFilter("discountText", e.target.value)}
            name="title"
            className="form-control"
          />
        </div>
      </div>,
  
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Time</label>
        <div className="col-sm-6">
          <select
            value={filter.time}
            onChange={(e) => updateFilter("time", e.target.value)}
            className="form-control"
          >
            <option value="">Select Time</option>
            <option value="monthly">Monthly</option>
            <option value="annually">Annually</option>
          </select>
        </div>
      </div>,
  
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Actual Price</label>
        <div className="col-sm-6">
          <input
            type="text"
            value={filter.actualPrice}
            onChange={(e) => updateFilter("actualPrice", e.target.value)}
            name="title"
            className="form-control"
          />
        </div>
      </div>,
  
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Discountd Price</label>
        <div className="col-sm-6">
          <input
            type="text"
            value={filter.discountedPrice}
            onChange={(e) => updateFilter("discountedPrice", e.target.value)}
            name="title"
            className="form-control"
          />
        </div>
      </div>,
      <div className="row mb-4" key="title">
        <label className="col-sm-3 col-form-label">Created Date</label>
        <div className="col-sm-6">
          <input
            type="date"
            value={filter.createdAt}
            onChange={(e) => updateFilter("createdAt", e.target.value)}
            name="createdAt"
            className="form-control"
          />
        </div>
      </div>,
  
      <div className="mt-5 mt-lg-4" key="submit">
        <div className="row justify-content-end">
          <div className="col-sm-9">
            <div>
              <button
                type="button"
                onClick={applyFilter}
                className="btn btn-primary w-md"
              >
                Filter
              </button>
              <button
                onClick={resetFilter}
                type="button"
                className="btn btn-danger w-md mx-4"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>,
    ];

    function CreatedAt(cellContent, row) {
        let createdAt = row.validFrom;
        return (
          <React.Fragment>
            <div>
              <p>{moment(createdAt).format("DD-MM-YYYY")} </p>
            </div>
          </React.Fragment>
        );
      }

    function ExpiryAt(cellContent, row) {
        let createdAt = row.validTill;
        return (
          <React.Fragment>
            <div>
              <p>{moment(createdAt).format("DD-MM-YYYY")} </p>
            </div>
          </React.Fragment>
        );
      }
  
    const columns = [
      {
        text: "S.NO",
        formatter: SNoFormatter,
        sort: true,
      },
      // {
      //   dataField: "package_key",
      //   text: "Package Key",
      //   sort: true,
      // },
  
      {
        dataField: "subscription.type",
        text: "Type",
        sort: true,
      },
      {
        dataField: "amount",
        text: "Price",
        sort: true,
      },
      {
        dataField: "validity",
        text: "Validity",
        sort: true,
      },
      {
        dataField:   "validFrom",
        text: "Valid From",
        sort: true,
      formatter: CreatedAt,

      },
  
      {
        dataField: "validTill",
        text: "Expired At",
        sort: true,
      formatter: ExpiryAt,

      },
      {
        dataField: "venue.name",
        text: "Venue Name",
        sort: true,
      },
      {
        dataField: "validity",
        text: "validity",
        sort: true,
      },
      {
        dataField: "subscription.commissionOnOfferPurchase",
        text: "Commission On Offer Purchase",
        sort: true,
      },
  
      {
        dataField: "paymentStatus",
        text: "Payment Status",
        sort: true,
      },
    //   {
    //     dataField: "status",
    //     text: "Stataus",
    //     sort: true,
    //   },
    //   {
    //     dataField: "addStoryCharge",
    //     text: "Add Story Charge",
    //     sort: true,
    //   },
  
    //   {
    //     dataField: "addStoryFree",
    //     text: "Add Story Free",
    //     sort: false,
    //   },
    //   {
    //     dataField: "isPromoteTop5",
    //     text: "Promote Top5",
    //     sort: false,
    //   },
    //   {
    //     dataField: "isManageBy7n",
    //     text: "Manage By 7night",
    //     sort: false,
    //   },
    //   {
    //     dataField: "haveAnalytics",
    //     text: "Have Analytics",
    //     sort: false,
    //   },
      {
          dataField: "status",
          text: "Status",
          formatter: activeFormatter,
          sort: false,
        },
    //   {
    //     text: "Actions",
    //     formatter: iconFormatter,
    //     sort: false,
    //   },
    ];
  
    const handleChange = (event) => {
      setSizePerPage(event.target.value);
    };
    const handlesorting = (event) => {
      setsort(event.target.value);
    };
    const handlesortOrder = (event) => {
      setsortOrder(event.target.value);
    };
    const handleReload = () => {
      setResetSearch(true);
      setSearchText("");
    };
  
    useEffect(() => {
      if (!resetSearch) return;
      if (currentPage == 1) {
        packageManagment([], true);
        return;
      }
      setCurrentPage(1);
    }, [resetSearch]);
    function iconFormatter(cellContent, row) {
      return (
        <React.Fragment>
          <div className="d-flex">
          {permissions.update && (
            <Tippy content="Edit">
              <button type="button" className=" btn btn-info  mx-2">
                {" "}
                <Link
                  to={{
                    pathname: `/venue/update/subscriptions/${row._id}`,
                    state: { row },
                  }}
                >
                  {" "}
                  <i class="fal fa-pencil fs-5 text-light"></i>
                </Link>{" "}
              </button>
            </Tippy>
          )}
           {permissions.delete && (
            <Tippy content="Delete">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handlerdelete(row._id)}
              >
                <i class="fal fa-trash  fs-5"></i>
              </button>
            </Tippy>
          )}
            &nbsp;&nbsp;
          </div>
        </React.Fragment>
      );
    }
  
    const packageManagment = async (filterArgs = []) => {
      setLoader(true);
      const payload = {
        limit: sizePerPageOrg,
        page: currentPage,
        sortField: sort,
        sortOrder: sortOrder,
      };
  
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText && resetSearch === false) {
          payload.search = searchText.trim();
        }
      }
      try {
        const response = await postRequest(
          `subscription/venue/order/list`,
          payload
        );
        const data = response.data;
        if (resetSearch) {
          setResetSearch(false);
        }        
        setTotalPage(data.count);
        setCurrentFPage(data.page);
        setpackagedata(data.subscriptions);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoader(false);
      }
    };
  
    const handlerdelete = (id) => {
      Swal.fire({
        title: "Are you sure delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          showRandomWordDialog(id);
        }
      });
    };
  
    const showRandomWordDialog = (id) => {
      const randomIndex = Math.floor(
        Math.random() * deleteConfirmationWords.length
      );
      const randomWord = deleteConfirmationWords[randomIndex];
  
      Swal.fire({
        title: "Confirmation Required",
        text: `To confirm deletion, please enter the word: ${randomWord}`,
        icon: "warning",
        input: "text",
        inputPlaceholder: `Type '${randomWord}' to confirm`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed && result.value === randomWord) {
          deleteFunction(id);
        } else if (result.isConfirmed) {
          Swal.fire(
            "Incorrect Entry",
            "Please enter the correct word to confirm.",
            "error"
          );
        }
      });
    };
  
    const deleteFunction = async (id) => {
      const response = await deleteRequest("subscription/venue/remove", {
        subscriptionId: id,
      });
      if (response.status) {
        Swal.fire("Deleted!", "Deleted successfully");
        packageManagment();
      } else {
        Swal.fire("Error!", "Something went wrong");
      }
    };
  
    const handlerdeleteMultiple = (ids) => {
      Swal.fire({
        title: "Are you sure delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          showRandomWordDialogM(ids);
        }
      });
    };
  
    const showRandomWordDialogM = (ids) => {
      const randomIndex = Math.floor(
        Math.random() * deleteConfirmationWords.length
      );
      const randomWord = deleteConfirmationWords[randomIndex];
  
      Swal.fire({
        title: "Confirmation Required",
        text: `To confirm deletion, please enter the word: ${randomWord}`,
        icon: "warning",
        input: "text",
        inputPlaceholder: `Type '${randomWord}' to confirm`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed && result.value === randomWord) {
          deleteMultipleFunction(ids);
        } else if (result.isConfirmed) {
          Swal.fire(
            "Incorrect Entry",
            "Please enter the correct word to confirm.",
            "error"
          );
        }
      });
    };
  
    const deleteMultipleFunction = async (ids) => {
      const response = await deleteRequest("subscription/membership/delete", {
        ids: "ids",
      });
      if (response.status) {
        Swal.fire("Deleted!", "Cancel successfully");
        packageManagment();
        setselectedItems([]);
      } else {
        Swal.fire("Error!", "Something went wrong");
      }
    };
  
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      style: { background: "#c8e6c9" },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          setselectedItems([...selectedItems, row._id]);
        } else {
          setselectedItems(selectedItems.filter((x) => x !== row._id));
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        if (!isSelect) {
          setselectedItems([]);
          return;
        }
  
        const ids = rows.map((r) => r._id);
        setselectedItems(ids);
      },
    };
  
    const rowClasses = (row, rowIndex) => {
      return selectedItems.includes(row._id) ? "selected-row-class" : "";
    };
  
    return (
      <>
        <React.Fragment>
          <ToastContainer />
          <Row>
            <Col className="12">
              <div className="page-title-box">
                <h4>Subscription Management</h4>
  
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Subscription Management</li>:{" "}
                  <li className="breadcrumb-item">Subscription List</li>
                </ol>
              </div>
            </Col>
            <div className="col-md-2 text-end">
            {permissions.create && 
              (
              <Link to="/venue/create/subscriptions">
                {" "}
                <Button>
                  {" "}
                  <i class="fas fa-plus"></i> Create
                </Button>{" "}
              </Link>
              )}
              &nbsp;&nbsp;&nbsp;
            </div>
            <Col className="col-12">
              <Card>
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={packagedata}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  <Col className="col-lg-12 d-flex mb-3">
                                    <label className="mb-0 text-label">
                                      Page Size
                                    </label>
  
                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "10%" }}
                                    >
                                      <option value="10">10</option>
  
                                      <option value="20">20</option>
  
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
  
                                    <label className="mb-0 text-label ms-3">
                                      Sort By:
                                    </label>
                                    <select
                                      value={sort}
                                      onChange={handlesorting}
                                      className="form-select"
                                      style={{ width: "25%" }}
                                    >
                                      <option value="type">Type</option>
                                      <option value="amount">Price</option>
                                      {/* <option value="discountText">
                                        Discount Text
                                      </option>
                                      <option value="time">Time</option>
                                      <option value="actualPrice">
                                        Actual Price
                                      </option>
                                      <option value="discountedPrice">
                                        Discounted Price
                                      </option>
                                      <option value="feature">Feature</option>
                                      <option value="createdAt">
                                        Created At
                                      </option> */}
                                    </select>
                                    <label className="mb-0 text-label ms-3">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      style={{ width: "20%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>
                                    </select>
                                    <div className="filter-item">
                                      {selectedItems.length > 0 && (
                                        <button
                                          className="btn btn-danger mx-2"
                                          onClick={() => {
                                            handlerdeleteMultiple(selectedItems);
                                          }}
                                        >
                                          Cancel Selected
                                        </button>
                                      )}
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        style={{ width: "30%" }}
                                      />
                                      <button
                                        onClick={() => {
                                          packageManagment();
                                        }}
                                        type="button"
                                        className="btn btn-primary mx-2"
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="button"
                                        onClick={handleReload}
                                        className="btn btn-danger"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </Col>
  
                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      noDataIndication={() => (
                                        <div>No data available</div>
                                      )}
                                      remote={true}
                                      selectRow={selectRow}
                                      rowClasses={rowClasses}
                                      classes="table-custom-striped"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>
  
                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          {openLightbox && (
            <Lightbox
              mainSrc={image}
              onCloseRequest={() => setOpenLightBox(false)}
            />
          )}
          <FilterModal
            show={model}
            closeModal={closeModal}
            components={components}
          />
        </React.Fragment>
      </>
    );
  };
export default VenueSubscriptionOrders