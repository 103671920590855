import { NoEncryption } from '@material-ui/icons';
import React from 'react';
import StarRatings from 'react-star-ratings';

function RatingsComponent({ ratingData }) {
  const { totalRating, avgRating, summary } = ratingData;

  return (
    <div style={{display:'flex'}}>
      <p style={{fontFamily:'Google Sans Display",Roboto,Arial,sans-serif',lineHeight: '3rem',
      fontSize: '2.8rem',fontWeight:'600'}}>{ parseFloat(avgRating).toFixed(1) }
     <p style={{lineHeight: '1rem'}}><StarRatings
      
      rating={parseFloat(avgRating)}
      numberOfStars={5}
      starDimension="20px"
      starSpacing="1px"
      starRatedColor="green"
    /></p>
     
     <h6>{`${totalRating} reviews`}</h6>
      </p>
      {/* <p>{`Total Ratings: ${totalRating}`}</p> */}

      {/* <h3 style={{ color: '#212121 !important' }}>Ratings Breakdown</h3> */}
      <div style={{ display: 'grid', width:'50%', height: "100px" }}>
        
        {Object.entries(summary).sort(([starsA], [starsB]) => starsB - starsA).map(([stars, { total_ratings, percentage }], index) => (
          
          <div style={{ height: "0px", margin: "0px", pading: "0px" }}  key={index} >
            
            <div  style={{ 
              display: 'flex', 
              alignItems: 'center',
              height: '0px !important',
              // marginTop: '20px',
              // border: '1px solid #01875F',             
              // borderRadius: '10px',
              // boxShadow: '0px 0px 5px #888888'
            }} >
               <p style={{ margin: '0px 10px', lineHeight: "0.7" }}>{`${stars}`}</p>
              <div style={{ 
                background: '#E8EAED',
                width: '100%',
                height: '10px',
                position: 'relative',
                borderRadius: '10px',               
                
              }}>
                
                <div style={{ 
                  background: '#01875F',
                  width: percentage,
                  height: '100%',
                  position: 'inherit',
                  borderRadius: '10px'
                  
                }}/>
                {/* <p style={{ margin: '0' }}>{`${stars} Stars`}</p> */}
              </div>
              {/* <p style={{ margin: '0px 10px' }}>{`${total_ratings} `}</p> */}
             
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RatingsComponent;