import React from "react";

const defaultPropValues = {
  packageId: "",
  frequency: 1,
  title: "Enter title",
  subTitle: "Enter subtitle",
  description: "Enter description",
  buttonText: "Click Me",
  image: "https://placehold.co/150",
  backgroundType: "color",
  backgroundImage:
    "https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1692362954233abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner.jpg",
  startColor: "#3254EB",
  endColor: "#14183C",
  packageName: "",
  packagePrice: "",
  packageDuration: "",
};

const mergeProps = (defaultProps, passedProps) => {
  let mergedProps = Object.assign({}, defaultProps);
  for (let key in passedProps) {
    if (passedProps[key] === undefined) {
      continue;
    }
    passedProps[key] = passedProps[key].toString();
    if (passedProps[key] && passedProps[key]?.trim() !== "") {
      mergedProps[key] = passedProps[key];
    }
  }
  return mergedProps;
};

const CustomCard = ({ subscriptionDialog = defaultProps }) => {
  const dialogData = mergeProps(defaultPropValues, subscriptionDialog);

  const bgStyle = {
    background: `linear-gradient(#f8f9fa, #e9ecef)`,
    minHeight: "600px",
  };

  const cardBgStyle =
    dialogData.backgroundType === "image"
      ? {
          backgroundImage: `url(${dialogData.backgroundImage})`,
          borderRadius: "10px",
        }
      : {
          background: `linear-gradient(${dialogData.startColor}, ${dialogData.endColor})`,
          marginTop: "50px",
          borderRadius: "10px",
        };

  return (
    <div className="mt-5 mt-lg-0" style={bgStyle}>
      <div
        className="row mb-4 align-items-center"
        style={{ marginBottom: "50px" }}
      >
        <label className="col-sm-2 col-form-label text-center"></label>

        <div className="col-sm-8">
          <div className="card" style={cardBgStyle}>
            <div className="card-body" style={{ color: "white" }}>
              <h4
                className="title"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "36px",
                }}
              >
                {dialogData.title}
              </h4>
              <img
                src={dialogData.image}
                alt="Card content"
                style={{
                  display: "block",
                  margin: "20px auto",
                  width: "150px",
                  height: "150px",
                  borderRadius: "10%",
                }}
              />
              <h5
                className="subtitle"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  lineHeight: "36px",
                  fontWeight: "bold",
                }}
              >
                {dialogData.subTitle}
              </h5>
              <p
                style={{
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {dialogData.description}
              </p>
              <a
                href="#"
                style={{
                  fontSize: "13px",
                  margin: "10px 0",
                  display: "block",
                  fontFamily: "Montserrat",
                  textDecoration: "underline",
                  fontWeight: "400",
                  padding: "0px",
                }}
              >
                See What You Get
              </a>
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  background: "white",
                  color: "black",
                  width: "100%",
                  borderRadius: "7px",
                  fontSize: "16px",
                  fontWeight: "550",
                  border: "none",
                  cursor: "pointer",
                  fontFamily: "Montserrat",
                }}
              >
                {dialogData.buttonText}
              </button>
            </div>
          </div>

          {dialogData.packageName &&
            dialogData.packagePrice &&
            dialogData.packageDuration && (
              <div className="card" style={cardBgStyle}>
                <div className="card-body" style={{ color: "white" }}>
                  <h4
                    className="card-title"
                    style={{ fontFamily: "Georgia, serif" }}
                  >
                    {dialogData.packageName}
                  </h4>
                  <h5
                    className="card-title"
                    style={{ fontWeight: "bold", marginBottom: "20px" }}
                  >
                    {dialogData.packagePrice}{" "}
                    <span style={{ fontSize: "12px" }}>
                      / {dialogData.packageDuration}
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      background: "white",
                      color: "black",
                      width: "100%",
                      borderRadius: "7px",
                      fontSize: "16px",
                      fontWeight: "550",
                      border: "none",
                      cursor: "pointer",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {dialogData.buttonText}
                  </button>
                  <a
                    href="#"
                    className="text-center"
                    style={{
                      color: "#626262",
                      fontSize: "10px",
                      textDecoration: "underline",
                      display: "block",
                      margin: "10px 0",
                    }}
                  >
                    Terms & Condition Applies
                  </a>
                </div>
              </div>
            )}
        </div>
      </div>
      {/* ... rest of your component */}
    </div>
  );
};

export default CustomCard;
