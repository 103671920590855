import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";

import { getRequest, postRequest } from "components/Common/Utils";
import { useParams } from "react-router-dom";
import moment from "moment";

import { format } from "date-fns";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import "./CustomCarduser.css";
import HBVenueListTable from "./HBVenueTable";
import HBOfferListTable from "./HBOfferTable";
import HBDealListTable from "./HBDealsTable";
import HBVideoListTable from "./HBVideoTable";
import HBActivityListTable from "./HBActivityTable";
import HBCustomComponentsListTable from "./HBCustomComponentTable";

export default function Homeblockdetails() {
  const [homeblockdata, setData] = useState([]);
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);

  const [ids, setIds] = useState([]);

  const [loader, setLoader] = useState(false);
  const location = useLocation();
  
  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#e22a2a", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();
  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  // const { title, description,type, createdAt, updatedAt } = homeblockdata;

  return (
    <>
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>SearchBlock Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">SearchBlock Management</li>:{" "}
              <li className="breadcrumb-item">SearchBlock details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundColor: "rgb(29, 155, 240)" }}
              ></div>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "20px" }}
                >
                  <br></br>
                  <div className="fst">
                    {" "}
                    <h3> {location?.state.row.title}</h3>{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Description: :{" "}
                        <span> {location?.state.row.description}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Type: <span> {location?.state.row.type}</span>{" "}
                      </p>{" "}
                    </div>
                    {/* <div className="col-md-4 fst py-1"> 
            <p> Created At:    <span> {formatDate(createdAt)}</span> </p> </div>          */}
                  </div>
                </div>
              </div>
            </Card>
            {/* <Col md="12">   
            <div id="exTab2" className="twitter-like-tabs2" style={{ background: "#ffff", marginLeft: "25px" }}>
              <ul className="nav nav-pills" style={{ width: "480px", display: "contents" }}>
                <li className="active">
                  <a href="#venues" data-toggle="tab">
                    {" "}
                    Venues{" "}
                  </a>
                </li>
                <li>
                  <a href="#offers" data-toggle="tab">
                    {" "}
                    Offers{" "}
                  </a>
                </li>
                <li>
                  <a href="#deals" data-toggle="tab">
                    {" "}
                    Deals{" "}
                  </a>
                </li>
                <li>
                  <a href="#videos" data-toggle="tab">
                    {" "}
                    Videos{" "}
                  </a>
                </li>
                <li>
                  <a href="#customcomponents" data-toggle="tab">
                    {" "}
                    Custom Components{" "}
                  </a>
                </li>
              </ul>
              </div>
              </Col> */}
              <Col md="12">
              <div >
                <div>
                  {location?.state.row.type === "venue" && (
                    <HBVenueListTable
                      hbData={location?.state.row?.venues}                      
                    />
                  )}                  
                </div>

                <div>
                  {location?.state.row.type === "custom-venue" && (
                    <HBVenueListTable
                      type="custom-venue"
                      hbData={location?.state.row?.customVenues.map((item) => item.venueId)}
                      mainData={location?.state.row?.customVenues}                      
                    />
                  )}                  
                </div> 

                  <div>
                  {location?.state.row.type === "activity" && (
                    <HBActivityListTable
                      type="activity"
                      hbData={location?.state.row?.activities.map((item) => item)}
                      mainData={location?.state.row?.activities}                      
                    />
                  )}                  
                </div>  

                <div >
                  {location?.state.row.type === "offer" &&  (
                    <HBOfferListTable
                      hbData={location?.state.row?.offers}    
                    />
                  )}                  
                </div>

                <div >
                  {location?.state.row.type === "custom-offer" &&  (
                    <HBOfferListTable
                    hbData={location?.state.row?.customOffers.map((item) => item.offerId)}   
                    />
                  )}                  
                </div>

                <div >
                  {location?.state.row.type === "deal"  && (
                    <HBDealListTable
                      hbData={location?.state.row?.deals}    
                    />
                  )}
                  
                </div>
                <div>
                  {location?.state.row.type === "video" && (
                    <HBVideoListTable
                      hbData={location?.state.row?.videos}    
                    />
                  )}                 
                </div>
                <div>
                  {location?.state.row.type === "custom-components" && (
                    <HBCustomComponentsListTable
                      hbData={location?.state.row?.customComponents}    
                    />
                  )}
                  
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}
