import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import "./dashboard.scss";

class RevenueAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: props.active,
            series: [],
            options: this.getChartOptions('monthly'),
            totals: {
                thisMonthTotal: props.data.thisMonthTotal,
                prevMonthTotal: props.data.prevMonthTotal,
                thisYearTotal: props.data.thisYearTotal,
                prevYearTotal: props.data.prevYearTotal,
                currentYearName:  props.data.currentYearName,
                prevYearName: props.data.prevYearName,
                currentMonthName: props.data.currentMonthName,
                prevMonthName: props.data.prevMonthName,
            },
            percentages: this.calculatePercentages(props.data),
            modal: false,
            selectedYear: moment().year(),
            selectedMonth: '',
        };
    }

    calculatePercentages(data) {
        const calculateChange = (current, previous) => {
            if (previous === 0) return 0;
            return (((current - previous) / previous) * 100).toFixed(1);
        };

        return {
            monthChange: calculateChange(data.thisMonthTotal, data.prevMonthTotal),
            yearChange: calculateChange(data.thisYearTotal, data.prevYearTotal),
        };
    }

    getChartOptions(view) {
        let categories;

        if (view === 'monthly') {
            categories = this.props.data.currentMonth.map(item => moment(item.date).format('DD MMM'));
        } else {
            categories = moment.months();
        }

        return {
            chart: {
                type: 'line', // Use 'line' to handle both bar and line series
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: view === 'monthly' ? [0, 3] : [0, 3], // Width for bar and line
                curve: 'smooth',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '20%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            },
            colors: ['#5664d2', '#3DDC84'],
            labels: categories,
        };
    }

    updateChart(view) {
        const data = this.props.data;
        let series;

        if (view === 'monthly') {
            series = [
                { name: 'Current Month', data: data.currentMonth.map(item => item.value), type: 'bar' },
                { name: 'Previous Month', data: data.prevMonth.map(item => item.value), type: 'line' }
            ];
        } else {
            series = [
                { name: 'Current Year', data: data.currentYear.map(item => item.value), type: 'bar' },
                { name: 'Previous Year', data: data.prevYear.map(item => item.value), type: 'line' }
            ];
        }

        this.setState({
            view,
            series,
            options: this.getChartOptions(view)
        });
    }

    componentDidMount() {
        this.updateChart(this.state.view);
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleCustomApply = () => {
        const { selectedYear, selectedMonth } = this.state;
        //console.log('Selected Year:', selectedYear);
        //console.log('Selected Month:', selectedMonth);
        this.toggleModal();

        this.props.setParams(prevParams => ({
            ...prevParams,
            logstat: {
                ...prevParams.logstat,
                monthChange: parseInt(selectedMonth || -1),
                yearChange: parseInt(selectedYear || -1)
            }
        }));

        if (selectedMonth) {
            this.updateChart('monthly');
        } else {
            this.updateChart('annual');
        }
    }

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value });
    }

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value });
    }

    render() {
        const { view, series, options, totals, percentages, modal, selectedYear, selectedMonth } = this.state;

        //console.log('Revenue Analytics:', totals)

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active={view === 'monthly'} onClick={() => this.updateChart('monthly')}>Monthly</Button>
                                <Button size="sm" color="light" active={view === 'annual'} onClick={() => this.updateChart('annual')}>Annual</Button>
                                <Button size="sm" color="light" active={view === 'custom'} onClick={this.toggleModal}>Custom</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-2">Commision Earned</h4>
                        <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <ReactApexChart options={options} series={series} type="line" height="350" />
                        </div>
                    </CardBody>

                    <CardBody className="border-top text-center">
                        <Row>
                            <Col sm={4}>
                                <p className="text-muted text-truncate mb-0">This month ({totals.currentMonthName})</p>
                                <div className="d-inline-flex">
                                    <h5 className="me-2">{totals.thisMonthTotal}</h5>
                                    <div className={percentages.monthChange >= 0 ? "text-success" : "text-danger"}>
                                        <i className={`mdi mdi-menu-${percentages.monthChange >= 0 ? "up" : "down"} font-size-14`}> </i>
                                        {percentages.monthChange} %
                                    </div>
                                </div>
                            </Col>

                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Year ({totals.currentYearName}):</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0 me-2">{totals.thisYearTotal}</h5>
                                        <div className={percentages.yearChange >= 0 ? "text-success" : "text-danger"}>
                                            <i className={`mdi mdi-menu-${percentages.yearChange >= 0 ? "up" : "down"} font-size-14`}> </i>
                                            {percentages.yearChange} %
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                    <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Year ({totals.prevYearName}):</p>
                                    <div className="d-inline-flex">
                                        <h5 className="mb-0">{totals.prevYearTotal}</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Select Date</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="yearSelect">Year</Label>
                            <Input type="select" id="yearSelect" value={selectedYear} onChange={this.handleYearChange}>
                                {Array.from({ length: moment().year() - 2023 + 1 }, (_, i) => 2024 + i).map(year => (
                                    <option key={year} value={year - 1}>{year - 1}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Month (optional)</Label>
                            <Input type="select" id="monthSelect" value={selectedMonth} onChange={this.handleMonthChange}>
                                <option value="">All</option>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCustomApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RevenueAnalytics;
