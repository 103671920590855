import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  customValidator,
} from "../../components/Common/Utils.js";

export default function CreateTheme() {
  const [themeData, setThemeData] = useState({ title: "" });

  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const Createtheme = async (e) => {
    e.preventDefault();

    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Please enter theme title",
        },
        {
          field: "date",
          errorMsg: "Please enter theme date",
        },
      ],
      themeData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    setLoading(true);
    const dataObj = {      
      title: themeData.title,
      date: themeData.date,
    };

    const response = await postRequest("venue/holiday/create", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/public-holiday");
      }, 2000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...themeData };
    list[index] = value;
    setThemeData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Public Holidays</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Holiday</li>:{" "}
              <li className="breadcrumb-item">Add</li>
            </ol>
          </div>
        </div>
        <div className="col-lg-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
        </div>
      </div>     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Holiday </h4>
                <Form onSubmit={Createtheme}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("date", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>



                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
