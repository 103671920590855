
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { useHistory,useParams, useLocation  } from "react-router-dom";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ToastContainer, toast } from 'react-toastify';
import 'sweetalert2/src/sweetalert2.scss'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { getRequest, postRequest,deleteRequest, putRequest,postRequestForm } from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, CardTitle , Spinner,Badge, UncontrolledTooltip,Modal, Button} from "reactstrap"
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ReactPaginate from "react-paginate";



const MenuManagementlist = (props) => {

  const location = useLocation();
  const state = location.state?.row;

  const [modal_center, setmodal_center] = useState(false)  
  const [menudata, setmenudata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [value, setValue] = React.useState(10);
  const [sort, setsort] = React.useState("first_name");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [loader2, setLoader2] = useState(false);
  
  useEffect(() => {  
    menuManagment();    
  }, [currentPage])


  
  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    setmenudata(state);
  }, [state])

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {

    if (type === "pagination") {

      if(page != currentPage){
        setCurrentPage(page); 
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function ImgFormatter(cellContent, row) {
    return (
      <><img src={row.image} onClick={ () => showLightBox(row.image) } alt="squad img" width={"50px"} /></>
    );
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];


  function tog_center() {  
    setmodal_center(!modal_center)
    removeBodyCss()
  }
 

  function SNoFormatter(cellContent, row, i) {
   return (
     <>{i + 1}</>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "venueId",
      text: "Venue",
      sort: false,
    },

    {
      dataField: "title",
      text: "Title",
      sort: false,
    },

    {
      dataField: "image",
     formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
   

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];  
  
    const handleReload = () => {
      window.location.reload();
    };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className='d-flex'>         
          <button type='button' className=' btn btn-info  mx-2'>  <Link to={{
              pathname: `/updateMenu/${row._id}`,
              state: { row }
            }}> <i class="fal fa-pencil fs-5 text-light"></i></Link> </button>
          
          <button type='button' className="btn btn-danger" onClick={() => handlerdelete(row._id)}><i class="fal fa-trash  fs-5"></i></button>&nbsp;&nbsp;
          
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const menuManagment = async () => {
    setLoader(true);
    const response = await postRequest(`venue/menu/list`, { venueId: id });
    const data = response.data;
       
    setTotalPage(data.length);
    setmenudata(data);   
    setLoader(false);    
  }



  const handlerdelete = (id) => {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) =>  {
      if (result.isConfirmed) {
        const response = await deleteRequest(`venue/menu/delete`, { menuId: id })
        if(response.status){
          Swal.fire(
            'Deleted!',
            'Deleted successfully',
          )
          menuManagment();
        }else{
          Swal.fire(
            'Error!',
            'Something went wrong',
          )
        }

    }})
  }

 
  const [itemsdata, setItems] = useState([]);

  useEffect(() => {
    fetchList();
   }, []);

  const fetchList = async () => {
    setLoading(true);
    const themes = await postRequestForm("venue/theme/list?page=0&limit=1000&sortBy=first_name&sortOrder=desc&q=");
    setItems(themes.data.list);
    setLoading(false); 
 }

  return (
    <>
      <React.Fragment>
        <ToastContainer />      
        <Row>
          <Col className='12'>
            <div className="page-title-box">
              <h4>Menu Management</h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Menu Management</li>
                : <li className="breadcrumb-item">Menu List</li>
              </ol>
            </div>
          </Col>
          <div className='col-md-2 text-end' >
            <Link to={{
             pathname: `/createMenu/${id}`            
             }}> <Button> <i class="fas fa-plus"></i> Create</Button> </Link>&nbsp;&nbsp;&nbsp; 
            </div>
          <Col className="col-12">
            <Card>
            { loader ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :
              <CardBody className="table-shorting">
               { 
               
               <PaginationProvider
               pagination={paginationFactory({ page: currentPage , sizePerPage: sizePerPageOrg, totalSize: totalPage , custom: true })}            
             >
              {({ paginationProps, paginationTableProps }) => (  <>
           <ToolkitProvider
             keyField="_id"
             data={menudata}
             columns={columns}             
           >
             {
               props =>
                 <Row> 
                  
                       
                  

                   <Col className='col-lg-12 text-end'>
                     <BootstrapTable  
                                    remote={false} classes='table-striped'  {...props.baseProps} {...paginationTableProps}/>  
                   </Col>
                   
                 </Row>
             }
           </ToolkitProvider> 

           <Row>
             <Col className='col-lg-12 text-end'>

                         <div className="text-md-right">
                               <PaginationListStandalone
                                 {...paginationProps}
                               />
                             </div>
             </Col>

           </Row>
           </>
       )}
       </PaginationProvider>


                }
                
              
              </CardBody>
              }
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}           
            onCloseRequest={() => setOpenLightBox(false)}            
          />
        )}
      </React.Fragment>
    </>

  )
}

export default MenuManagementlist;