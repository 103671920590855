// StripeCheckout.js
// StripeCheckout.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import {
    postRequest
  } from "../../../components/Common/Utils.js";

const StripeCheckout = ({ plan, venueId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('authUser')));

    const completePayment = async (client_secret) => {
        const payload = {
            paymentIntentId: client_secret
        };
    
        try {
            let response = await postRequest(`subscription/venue/order/complete`, payload);
    
            if (response.status	) {
                // redirect to /venue-account/get-subscription
                window.location.reload();
            } else {
                console.error('Failed to complete the payment:', response.message);
            }
        } catch (error) {
            console.error('Error completing the payment:', error);
        }
    };
    
        


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const payload = {
            venueId: venueId,
            subscriptionId: plan._id,
        };

        // Create a payment intent on the server
        let paymentIntent = await postRequest(`subscription/venue/order/create`, payload);
       

        if (paymentIntent.error) {
            toast.error(paymentIntent.error.message);
            setLoading(false);
            return;
        }

        //console.log(paymentIntent)

        // return;

        paymentIntent = paymentIntent.data;

        // Confirm the payment on the client
        const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: userData.name,
                    email: userData.email,
                    address: {
                        line1: userData.address,
                        city: userData.region,
                        // postal_code: userData.zip,
                        country: userData.country == 'United Arab Emirates' ? 'AE' : 'SA',
                    },                   
                },
            },
        });

        if (result.error) {
            toast.error("Payment failed:");
            console.error("payment_error",result.error.message);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                toast.success('Payment succeeded!');

                completePayment(paymentIntent.client_secret);

                // Handle successful payment here (e.g., update plan status)
            }
        }

        setLoading(false);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FormGroup style={{ flex: 1 }}>
                    <Label for="card-element">Credit or Debit Card</Label>
                    <CardElement id="card-element" options={{ style: { base: { fontSize: '16px', color: '#32325d', '::placeholder': { color: '#aab7c4' }, }, invalid: { color: '#fa755a', iconColor: '#fa755a' } } }} />
                </FormGroup>
                <Button type="submit" color="primary" style={{ backgroundColor: "#635BFF", marginTop: "30px" }} disabled={!stripe || loading}>
                    {loading ? <Spinner size="sm" /> : 'Pay'}
                </Button>
            </div>
        </Form>
    );
};

export default StripeCheckout;
