// PaymentModal.js
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckout from './StripeCheckout'; // Import the StripeCheckout component
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_test_51PLgJHBH6kBH6g0bvIV94NK87Rd4zWm3vh5Xfttxx4NmAvT7dozaIekmkKqlZJEATouqVVQkOoX6LDd104SpzeLv00K9lQwOY8'); // Replace with your Stripe publishable key

const PaymentModal = ({ isOpen, onRequestClose, plan, venueId }) => {

    const stripeLogoUrl = "https://asset.brandfetch.io/idxAg10C0L/idTHPdqoDR.jpeg"; // URL for Stripe logo


    return (
        <Modal isOpen={isOpen} toggle={onRequestClose} centered>
            <ModalHeader toggle={onRequestClose}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={stripeLogoUrl} alt="Stripe Logo" style={{ height: '24px', marginRight: '10px' }} />
                    Complete Your Payment
                </div>
            </ModalHeader>
            <ModalBody>
                <Elements stripe={stripePromise}>
                    <StripeCheckout plan={plan} venueId={venueId} />
                </Elements>
            </ModalBody>
            <ModalFooter>
                <Button color="primary"  onClick={onRequestClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PaymentModal;
