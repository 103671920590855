import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './styles.css'; // Import your custom styles if any

// SVG Icons as React components (you can replace these with actual SVGs or import SVG files)
const UserIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
    </svg>
);

const BusinessIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8V4H8v4H2v14h20V8h-6zM10 6h4v2h-4V6zM4 10h16v10H4V10z"/>
    </svg>
);

const VenueIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L4 6V12C4 16.97 7.69 21.43 12 22C16.31 21.43 20 16.97 20 12V6L12 2ZM12 20C8.5 19.57 6 15.92 6 12V7.23L12 4.1L18 7.23V12C18 15.92 15.5 19.57 12 20ZM11 11H9V13H11V16H13V13H15V11H13V8H11V11Z" fill="#212121"/>
  </svg>
  
);

const OfferIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm3 12h-2v2c0 .55-.45 1-1 1s-1-.45-1-1v-2H9c-.55 0-1-.45-1-1s.45-1 1-1h2V9c0-.55.45-1 1-1s1 .45 1 1v2h2c.55 0 1 .45 1 1s-.45 1-1 1z" fill="#212121"/>
</svg>

);

const DiscountIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C11.55 2 11.11 2.21 10.83 2.58L2.58 10.83C2.21 11.11 2 11.55 2 12C2 12.45 2.21 12.89 2.58 13.17L10.83 21.42C11.11 21.79 11.55 22 12 22C12.45 22 12.89 21.79 13.17 21.42L21.42 13.17C21.79 12.89 22 12.45 22 12C22 11.55 21.79 11.11 21.42 10.83L13.17 2.58C12.89 2.21 12.45 2 12 2ZM16 8.5C16 9.33 15.33 10 14.5 10C13.67 10 13 9.33 13 8.5C13 7.67 13.67 7 14.5 7C15.33 7 16 7.67 16 8.5ZM8.5 17C7.67 17 7 16.33 7 15.5C7 14.67 7.67 14 8.5 14C9.33 14 10 14.67 10 15.5C10 16.33 9.33 17 8.5 17ZM8 8L16 16" fill="#212121"/>
  </svg>
  
);

const DealsIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 2C11.58 2 11.17 2.18 10.88 2.5L7.24 6.14L2.5 7.5C2.18 7.58 2 7.92 2 8.28V12C2 12.42 2.18 12.83 2.5 13.12L6.14 16.76L7.5 21.5C7.58 21.82 7.92 22 8.28 22H12C12.42 22 12.83 21.82 13.12 21.5L16.76 17.86L21.5 16.5C21.82 16.42 22 16.08 22 15.72V12C22 11.58 21.82 11.17 21.5 10.88L17.86 7.24L16.5 2.5C16.42 2.18 16.08 2 15.72 2H12ZM12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 14C13.1 14 14 14.9 14 16C14 17.1 13.1 18 12 18C10.9 18 10 17.1 10 16C10 14.9 10.9 14 12 14Z" fill="#212121"/>
</svg>

);

const defaultData = [
    // {
    //     color: '#A9CCE3', // Darker shade for Total Users
    //     icon: <UserIcon />, // Total Users
    //     label: 'Total Users',
    // },
    // {
    //     color: '#85C1E9', // Darker shade for Total Business
    //     icon: <BusinessIcon />, // Total Business
    //     label: 'Total Business',
    // },
    // {
    //     color: '#FFF176', // Darker shade for Total Venue
    //     icon: <VenueIcon />, // Total Venue
    //     label: 'Total Venue',
    // },
    {
        color: '#e67e22', // Darker shade for Total Offer
        icon: <OfferIcon />, // Total Offer
        label: 'Total Offer',
    },
    {
        color: '#e74c3c', // Darker shade for Total Discount
        icon: <DiscountIcon />, // Total Discount
        label: 'Total Discount',
    },
    {
        color: '#9b59b6', // Darker shade for Total Deals
        icon: <DealsIcon />, // Total Deals
        label: 'Total Deals',
    },
     {
        color: '#f1c40f', // Darker shade for Total Venue
        icon: <VenueIcon />, // Total Venue
        label: 'Total Cliams',
    },
];

const DashboardCard = ({ color, icon, value, label }) => {
    return (
        <Col md="3">
            <Card style={{ backgroundColor: color, borderRadius: '10px', textAlign: 'center' }}>
                <CardBody>
                    <div style={{ fontSize: '30px', marginBottom: '10px' }}>
                        {icon}
                    </div>
                    <h3 style={{ fontSize: '24px', marginBottom: '10px', color: '#212121' }}>{value}</h3>
                    <p style={{ fontSize: '14px', color: '#212121' }}>{label}</p>
                </CardBody>
            </Card>
        </Col>
    );
};

const Dashboard = ({ values = [] }) => {
    const data = defaultData.map((item, index) => ({
        ...item,
        value: values[index] !== undefined ? values[index] : item.value
    }));

    return (
        <Row>
            {data.map((item, index) => (
                <DashboardCard
                    key={index}
                    color={item.color}
                    icon={item.icon}
                    value={item.value}
                    label={item.label}
                />
            ))}
        </Row>
    );
};

export default Dashboard;
