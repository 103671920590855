import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './styles.css'; // Import your custom styles if any

// SVG Icons as React components (you can replace these with actual SVGs or import SVG files)
const UserIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
    </svg>
);

const BusinessIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8V4H8v4H2v14h20V8h-6zM10 6h4v2h-4V6zM4 10h16v10H4V10z"/>
    </svg>
);

const VenueIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 4C15.87 4 19 7.13 19 11C19 14.87 15.87 18 12 18C8.13 18 5 14.87 5 11C5 7.13 8.13 4 12 4ZM11 8V14L15 11L11 8Z" fill="#212121"/>
  </svg>
  
);

const OfferIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 13v-2h-4V9h-2v2H9v2h4v2h2v-2h4z"/>
    </svg>
);

const DiscountIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM10 17L16 12L10 7V17Z" fill="#212121"/>
</svg>

);

const DealsIcon = () => (
    <svg width="40" height="40" viewBox="0 0 24 24" fill="#212121" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2l-4 8h8l-4-8zm0 18l4-8H8l4 8z"/>
    </svg>
);
const Dashboard = ({ values }) => {
  const data = [
   
    // {
    //     color: '#FFB74D', // Darker shade for Total Offer
    //     icon: <OfferIcon />, // Total Offer
    //     label: 'Maximum Offer',
    //     value: values.maxOffers
    // },
    {
        color: '#e67e22', // Darker shade for Total Discount
        icon: <DiscountIcon />, // Total Discount
        label: 'Stories Created',
         value: values.storiesCreated
    },
    // {
    //     color: '#81C784', // Darker shade for Total Deals
    //     icon: <DealsIcon />, // Total Deals
    //     label: 'Offer Credit Left',
    //     value: values.offersCreditLeft
    // },
     {
        color: '#e74c3c', // Darker shade for Total Venue
        icon: <VenueIcon />, // Total Venue
        label: 'Stories Remaining',
        value: values.storiesRemaining === -1 ? "Unlimited" : values.storiesRemaining,
    },
];

return (
    <Row>
        {data.map((item, index) => (
            <DashboardCard
                key={index}
                color={item.color}
                icon={item.icon}
                value={item.value}
                label={item.label}
            />
        ))}
    </Row>
);
};

const DashboardCard = ({ color, icon, value, label }) => {
    return (
        <Col md="3">
            <Card style={{ backgroundColor: color, borderRadius: '10px', textAlign: 'center' }}>
                <CardBody>
                    <div style={{ fontSize: '30px', marginBottom: '10px' }}>
                        {icon}
                    </div>
                    <h3 style={{ fontSize: '24px',color: 'black', marginBottom: '10px' }}>{value}</h3>
                    <p style={{ fontSize: '14px', color: 'black' }}>{label}</p>
                </CardBody>
            </Card>
        </Col>
    );
};


export default Dashboard;
