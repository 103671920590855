import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,  
  Form,  
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";


import { putRequestForm } from "../../components/Common/Utils.js";

import { Checkbox, FormControlLabel } from "@material-ui/core";
const UpdateVenueSubscripiton = () => {  
  const location = useLocation();
  const state = location.state?.row;



  const [isVenueListningAllowed, setIsVenueListningAllowed] = useState(state?.isVenueListioning || false);
  const [isMessaging, setIsMessaging] = useState(state?.isMessangingAllowed || false);
  const [isDealAllowed, setIsDealAllowed] = useState(state?.isDealAllowed || false);
  const [isBooking, setIsBooking] = useState(state?.isBookingAllowed || false);  
  const [isPromote, setIsPromote] = useState(state?.isPromoteAllowed || false);

  const [isPlacementRecommendedVenue, setIsPlacementRecommendedVenue] = useState( state?.placementRecommendedVenue || false);
  const [isPlacementTrendingVenue, setIsPlacementTrendingVenue] = useState(state?.placementOnTrendingVenue || false);

  const [isPromote5, setIsPromote5] = useState(state?.isPromoteTop5 || false);
  const [isAnalytic, setIsAnalytic] = useState(state?.haveAnalytics || false);
  const [isPlacementDiscoverAllowed, setIsPlacementDiscoverAllowed] = useState(state?.placementDiscover || false); 
  const [isManageBy7, setisManageBy7] = useState(state?.isManageBy7n || false);
  const [isAppBannerAllowed, setIsAppBannerAllowed] = useState(state?.isAppBannerAllowed || false);
  const [isOfferListning, setIsOfferListning] = useState(state?.isOfferlisting || false);
  const [isUnlimitedOffers, setIsUnlimitedOffers] = useState(state?.OfferlistingLimit === -1 ? true : false);
  const [isStoryListing, setIsStoryListing] = useState( state?.isStoryListing || false);
  const [isPushNotificationAllowed, setIsPushNotificationAllowed] = useState(state?.isPushNotificationAllowed || false);
  const [isDiscountAllowed, setIsDiscountAllowed] = useState(state?.isDiscount || false);

  const [isSliderVenue, setIsSliderVenue] = useState(state?.sliderVenue || false);
  const [isDashboardAllowed, setIsDashboardAllowed] = useState(state?.isDashboard || false);
  const [isFilterOnTop, setIsFilterOnTop] = useState(state?.isFilterOnTop || false);
  const [promoteReel, setPromoteReel] = useState(state?.promotedReel || false);
  const [isSocialMediaExposure, setIsSocialMediaExposure] = useState(state?.socialMediaExposure || false);
  const [isSocialMediaPromotion, setIsSocialMediaPromotion] = useState(state?.socialMediaPromotion || false);
  const [isMarketingAndAdvertising, setIsMarketingAndAdvertising] = useState(state?.marketingAd || false);
  const [packageData, setPackageData] = useState(state || {});
  const [loading, setLoading] = useState(false); 

  let history = useHistory();
  

  const Createpackage = async (e) => {
    e.preventDefault();
    if (!packageData?.type) {
      toast.error("type is required");
      return;
    }



    const formData = new FormData();  
    formData.append("id", state?._id);
    formData.append("title", packageData.title);
    formData.append("type", packageData.type);
    formData.append("amount", packageData.amount || 0);
    formData.append("validity", packageData.validity);
    formData.append("isPopular", packageData.isPopular || false);
    formData.append("isVenueListioning", isVenueListningAllowed);
    formData.append("placementDiscover", isPlacementDiscoverAllowed);
    formData.append("placementDiscoverCount", packageData?.placementDiscoverCount || 0);
    formData.append("placementRecommendedVenue", isPlacementRecommendedVenue);
    formData.append("placementOnTrendingVenue", isPlacementTrendingVenue);
    formData.append("isMessangingAllowed", isMessaging);
    formData.append("isDealAllowed", isDealAllowed);
    formData.append("isBookingAllowed", isBooking);
    formData.append("isOfferlisting", isOfferListning);
    formData.append("OfferlistingLimit",  isUnlimitedOffers ? - 1 :  packageData?.offerListningLimit || 0  );
    formData.append("commissionOnOfferPurchase", packageData?.commissionOnOfferPurchase || 0);
    formData.append("offerAddCharge", packageData?.offerAddCharge || 0);
    formData.append("isStoryListing", isStoryListing);
    formData.append("StoryListingLimit", packageData?.storyListingLimit || 0);
    formData.append("isPromoteAllowed", isPromote);
    formData.append("isPromoteTop5", isPromote5);
    formData.append("isManageBy7n", isManageBy7); 
    formData.append("haveAnalytics", isAnalytic);
    
    formData.append("isAppBannerAllowed", isAppBannerAllowed);
    formData.append("appBannerLimit", packageData?.appBannerLimit || 0);
    formData.append("isPushNotificationAllowed", isPushNotificationAllowed);
    formData.append("pushNotificationLimit", packageData?.pushNotificationLimit || 0);

    formData.append("isDiscount", isDiscountAllowed);
    formData.append("discountLimit", packageData?.discountLimit || 0);
    formData.append("isDashboard", isDashboardAllowed);
    formData.append("isFilterOnTop", isFilterOnTop);
    formData.append("sliderVenue", isSliderVenue);
    formData.append("socialMediaExposure", isSocialMediaExposure);
    formData.append("socialMediaPromotion", isSocialMediaPromotion);
    formData.append("marketingAd", isMarketingAndAdvertising);
    formData.append("promotedReel", promoteReel);    
    
    setLoading(true);
    const response = await putRequestForm(
      "subscription/venue/update",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venue/subscriptions");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);   
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Subscription Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Subscription Management</li>:{" "}
              <li className="breadcrumb-item">Create Subscription</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Package </h4>
                <Form onSubmit={Createpackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input                              
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              Value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              value={packageData?.type}
                              name="type"
                              class="form-select"
                            >
                              
                              <option value="basic">Basic</option>
                              <option value="basic-benefits">Basic Benefits</option>
                              <option value="add-on-package">Add on Package</option>
                              <option value="get-promoted">Get Promoted</option>
                              <option value="pay-as-you-go">Pay As You Go</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              Value={packageData?.amount}
                              name="amount"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Validity
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("validity", e.target.value)
                              }
                              Value={packageData?.validity}
                              name="validity"
                              class="form-select"
                            > 
                            
                              <option value="month">Monthly</option>     
                              <option value="quarter">quarterly</option>                                  
                              <option value="year">Yearly</option>
                              <option value="week">Weekly</option>
                              <option value="day">Daily</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Popular
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("isPopular", e.target.value)
                              }
                              value={packageData?.isPopular}
                              name="validity"
                              class="form-select"
                            >
                              <option value="false">No</option> 
                              <option value="true">Yes</option>                          
                             
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Placement on Discover
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementDiscoverAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementDiscoverAllowed(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementDiscoverAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementDiscoverAllowed(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        {isPlacementDiscoverAllowed && (
                              <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Placement Discover Limit                           
                              </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                min={0}
                                onChange={(e) =>
                                  updateValue("placementDiscoverCount", e.target.value)
                                }
                                Value={packageData?.placementDiscoverCount}
                                name="placementDiscoverCount"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Placement on Recommended Venue
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementRecommendedVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementRecommendedVenue(e.target.checked)                                      
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementRecommendedVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementRecommendedVenue(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Get listed on trending & hottest venues
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPlacementTrendingVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementTrendingVenue(e.target.checked)                                  
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPlacementTrendingVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPlacementTrendingVenue(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isPlacementDiscoverAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Messaging With users
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isMessaging}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMessaging(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isMessanging"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isMessaging}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMessaging(!e.target.checked)
                                    }                                    
                                  }}
                                  color="primary"
                                  value="false"
                                  name="isMessanging"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Booking System
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isBooking}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsBooking(e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isBooking"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isBooking}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsBooking(!e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="false"
                                  name="isBooking"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>
                        



                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Offer Listning
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isOfferListning}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsOfferListning(e.target.checked)                                      
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isOfferAdd"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isOfferListning}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsOfferListning(!e.target.checked)
                                    }                                    
                                  }}
                                 
                                  color="primary"
                                  value="false"
                                  name="isOfferAddAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isOfferListning && (

                          <>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Offer Add Commission                            </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={0}
                                  max={100}
                                  onChange={(e) =>
                                    updateValue("commissionOnOfferPurchase", e.target.value)
                                  }
                                  Value={packageData?.commissionOnOfferPurchase}
                                  name="commissionOnOfferPurchase"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Unlimited Offers
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isUnlimitedOffers}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsUnlimitedOffers(e.target.checked)                                                                         
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isOfferAdd"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isUnlimitedOffers}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsUnlimitedOffers(!e.target.checked)
                                    }                                    
                                  }}
                                 
                                  color="primary"
                                  value="false"
                                  name="isOfferAddAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>



                              { !isUnlimitedOffers && (
                                  <>

                                      <div className="row mb-4">
                                        <label className="col-sm-3 col-form-label">
                                          Offer Listning Limit
                                        </label>

                                        <div className="col-sm-6">
                                          <input
                                            type="number"
                                            min={0}
                                            max={100}
                                            onChange={(e) =>
                                              updateValue("offerListningLimit", e.target.value)
                                            }
                                            Value={packageData?.OfferlistingLimit}
                                            name="offerListningLimit"
                                            className="form-control"
                                          />

                                        </div>
                                      </div>

                                      <div className="row mb-4">
                                            <label className="col-sm-3 col-form-label">
                                              Additional Offer Add Charges
                                            </label>
                                            <div className="col-sm-6">
                                              <input
                                                type="number"
                                                min={0}
                                                onChange={(e) =>
                                                  updateValue("offerAddCharge", e.target.value)
                                                }
                                                Value={packageData?.offerAddCharge}
                                                name="offerAddCharge"
                                                className="form-control"
                                              />
                                            </div>
                                          </div>  

                                  </>

                              )}

                             

                               

                          </>
                        )}
                          

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Stories Placement on 7nights
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isStoryListing}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsStoryListing(e.target.checked)                                      
                                    }                                    
                                  }}
                                  color="primary"
                                  value="true"
                                  name="isAddStoryAllowed"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isStoryListing}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsStoryListing(!e.target.checked)
                                    }                                    
                                  }}
                                  color="primary"
                                  value="false"
                                  name="isAddStoryAllowed"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        { isStoryListing && (
                            <>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Story Listning Limit                            </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  min={0}
                                  onChange={(e) =>
                                    updateValue("storyListingLimit", e.target.value)
                                  }
                                  Value={packageData?.storyListingLimit || 0}
                                  name="addStoryLimit"
                                  className="form-control"
                                />
                              </div>
                            </div> 
                          </>

                        )}

                        
                          {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Promote Allowed
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPromote}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote(e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isPromote"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPromote}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote(!e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="false"
                                  name="isPromote"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        { isPromote && (<>
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Promote In Top 5
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPromote5}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote5(e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="true"
                                  name="isPromote"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPromote5}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPromote5(!e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="false"
                                  name="isPromote"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Manage By 7 Nights
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isManageBy7}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setisManageBy7(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="isManageBy7n"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isManageBy7}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setisManageBy7(!e.target.checked)
                                    }                                    
                                  }}                                  
                                  color="primary"
                                  value="false"
                                  name="isManageBy7n"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>
                        </>)} */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Dashboard users & Business Analytics
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAnalytic}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAnalytic(e.target.checked)
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isAnalytic}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAnalytic(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            App Banners Allowed
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAppBannerAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAppBannerAllowed(e.target.checked)                                      
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isAppBannerAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsAppBannerAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isAppBannerAllowed && (

                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            App Banner Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("appBannerLimit", e.target.value)
                              }
                              Value={packageData?.appBannerLimit}
                              name="appBannerLimit"
                              className="form-control"
                            />

                          </div>
                        </div>
                          
                          )}


                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Push Notification Allowed
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPushNotificationAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPushNotificationAllowed(e.target.checked)                                                                         
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPushNotificationAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsPushNotificationAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isPushNotificationAllowed && (
                           
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Push Notification Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("pushNotificationLimit", e.target.value)
                              }
                              Value={packageData?.pushNotificationLimit}
                              name="pushNotificationLimit"
                              className="form-control"
                            />

                          </div>

                        </div>

                        )} 

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Heighlight Special Discount
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isDiscountAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsDiscountAllowed(e.target.checked)                                                                         
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isDiscountAllowed}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsDiscountAllowed(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        {isDiscountAllowed && (
                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("discountLimit", e.target.value)
                              }
                              Value={packageData?.discountLimit}
                              name="discountLimit"
                              className="form-control"
                            />

                          </div>
                        </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Highligted offers on Top within Search & Filter
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isFilterOnTop}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsFilterOnTop(e.target.checked)                                                                                                               
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isFilterOnTop}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsFilterOnTop(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Special Slider for Venue
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSliderVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSliderVenue(e.target.checked)                                                                                                                                                                                                                            
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSliderVenue}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSliderVenue(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Social Media Exposure
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSocialMediaExposure}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaExposure(e.target.checked)                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSocialMediaExposure}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaExposure(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Promote Reel
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={promoteReel}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setPromoteReel(e.target.checked)                                                                                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!promoteReel}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setPromoteReel(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>      

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Social Media Promotion
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isSocialMediaPromotion}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaPromotion(e.target.checked)                                                                                   
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isSocialMediaPromotion}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsSocialMediaPromotion(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>     


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Marketing And Advertising
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isMarketingAndAdvertising}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMarketingAndAdvertising(e.target.checked)                                 
                                    }                                    
                                  }}                                 
                                  color="primary"
                                  value="true"
                                  name="haveAnalytics"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isMarketingAndAdvertising}
                                  onChange={ e => {
                                    if(e.target.checked){
                                      setIsMarketingAndAdvertising(!e.target.checked)
                                    }                                    
                                  }}                                           
                                  color="primary"
                                  value="false"
                                  name="haveAnalytics"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div> 

                    
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>            
    </React.Fragment>
  );
}


export default UpdateVenueSubscripiton
