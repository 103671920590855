import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

import { postRequest, postRequestForm } from "../../../components/Common/Utils.js";
import VenueModal from "components/Modal/VenueModalOrg.js";
import OfferModal from "components/Modal/OfferModalOrg.js";
import CategoryModal from "components/Modal/CategoryModal.js";
import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";
import SelectedUsers from "./SelectedUsers.js";
import FileUpload from "components/Common/FileUpload.js";
import Dashboard from "./index.js";

export default function CreateNotification() {
  const location = useLocation();
  const state = location.state?.row;
  const [notificationData, setNotificationData] = useState({
    title: "",
    description: "",
    scheduled: "",
    date: "",
    time: "",
    platform: "",
    type: "",
    link: "",
    targetUser: "",
    status: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [userModel, setUserModel] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);


  const [activeCreate, setActiveCreate] = useState(true);

  const [model, setModel] = useState(false);
  const [categoryModel, setCategoryModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  let history = useHistory();
  const msUpdateTarget = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTarget(ids);
  };
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setNotificationData({ ...notificationData, image: reader.result });
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchState();
  }, [state]);

  const fetchState = async () => {
    const data = { ...state };
    if (data.venueId) {
      setVenueId(data.venueId);
      setVenueText(data.venueName);
      setVenueImage(data.venueLogo)
      const tempData = { ...notificationData };
      tempData.type = "venue";
      setNotificationData(tempData);
    }
    if (data.users) {
      const tempData = { ...notificationData };
      tempData.targetUser = "specific";
      setNotificationData(tempData);
      setSelectedUserIds(data?.users ? data?.users : []);
    }
  };

  useEffect(() => {
  }, [selectedUserIds]);

  const updateNotificationData = async (e) => {
    e.preventDefault();
    if (!notificationData.title) {
      toast.error("Title is required");
      return;
    }

    
    if (notificationData.scheduled === "") {
      toast.error("Please select Scheduled");
      return;
    }

    if (notificationData.type === "link") {
      if (!notificationData.link) {
        toast.error("Link is required");
        return;
      }
    }

    if (notificationData.targetUser == "") {
      toast.error("Target user is required");
      return;
    }

    if (notificationData.targetUser === "specific") {
      if (selectedUserIds.length === 0) {
        toast.error("Please select user");
        return;
      }
    }

    

    if (notificationData.type === "category") {
      if (!categoryId) {
        toast.error("Category is required");
        return;
      }
    }

    if (notificationData.type === "offer") {
      if (!offerId) {
        toast.error("Offer is required");
        return;
      }
    }

    setStartLoader(true);
    setLoading(true);

    const x = new FormData();

    x.append("image", selectedImage);

    const imgRes = await postRequestForm("comman/img/upload", x);
    setStartLoader(false);
    const dataObj = {
      title: notificationData.title,
      description: notificationData.description,
      scheduled: notificationData.scheduled,
      platform: notificationData.platform,
      type: notificationData.type,
      targetUser: notificationData.targetUser,
      status: notificationData.status,
      image: imgRes?.data.url,
    };

    if (parseInt(notificationData.scheduled) === 1) {
      dataObj.date = notificationData.date;
      dataObj.time = notificationData.time;
    }
    // if (notificationData.scheduled === "true") {
    //   const scheduledDateTime = `${notificationData.date}T${notificationData.time}:00.000Z`;
    //   dataObj.scheduledDateTime = scheduledDateTime;
    // }

    if (notificationData.type === "link") {
      if (!notificationData.link) {
        toast.error("Link is required");
        return;
      }
      dataObj.typeId = notificationData.link;
    }

    if (notificationData.targetUser == "") {
      toast.error("Target user is required");
      return;
    }

    if (notificationData.targetUser === "all") {
      dataObj.users = [];
    }

    if (notificationData.targetUser === "specific") {
      const users = [];

      selectedUserIds.forEach((item) => {
        users.push(item?._id);
      });
      dataObj.users = users;
    }
    var venueId = localStorage.getItem("stored_venueId");
    if (notificationData.type === "venue") {      
      dataObj.typeId = venueId;
    }

    dataObj.venueId = venueId;

    if (notificationData.type === "category") {
      if (!categoryId) {
        toast.error("Category is required");
        return;
      }
      dataObj.typeId = categoryId;
    }

    if (notificationData.type === "offer") {
      if (!offerId) {
        toast.error("Offer is required");
        return;
      }
      dataObj.typeId = offerId;
    }

    // const formData = new FormData();
    // formData.append("title", notificationData.title);
    // formData.append("description", notificationData.description);
    // formData.append("scheduled", notificationData.scheduled);

    // if (notificationData?.scheduled === 1) {
    //   formData.append("date", notificationData.date);
    // }
    // if (notificationData?.scheduled === 1) {
    //   formData.append("time", notificationData.time);
    // }
    // formData.append("platform", notificationData.platform);
    // formData.append("type", notificationData.type);

    // if (notificationData?.type === "link") {
    //   formData.append("link", notificationData.link);
    // }
    // formData.append("targetUser", notificationData.targetUser);
    // if (notificationData?.targetUser === "all") {
    //   formData.append("users", []);
    // }
    // if (notificationData?.targetUser === "specific") {
    //   const users = [];

    //   selectedTarget.forEach((item) => {
    //     users.push(item?.id);
    //   });
    //   formData.append("users", users);
    // }
    // formData.append("status", notificationData.status);
    // formData.append("image", imgRes?.data.url);

    // if (notificationData?.type === "venue") {
    //   formData.append("venueId", venueId);
    // }
    // if (notificationData?.type === "category") {
    //   formData.append("categoryId", categoryId);
    // }
    // if (notificationData?.type === "offer") {
    //   formData.append("offerId", offerId);
    // }

    // check target user is selected or not

    const response = await postRequest("user/notification/add/venue", dataObj);
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/notification");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  const toggleUserModel = () => {
    setUserModel(!userModel);
  };

  const closeUserModal = () => {
    setUserModel(false);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const selectUserRow = (id, name) => {
   

    setUserId(id);
    setUserText(name);
    closeUserModal();
  };

  const fetchUsers = async () => {
    const param = { page: 1, limit: 100000 };
    const user = await postRequest("user/list/admin", param);
    if (user.status == 1) {
      const options = user.data.list.map((item) => {
        return {
          id: item._id,
          phone: item.phone,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
        };
      });
      setUserList(options);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchVenueList();
    fetchOfferList();
    fetchCategoryList();
  }, []);

  const fetchVenueList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setCategoryList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };
  const selectRow = (id, name, row) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(row.logo);
    closeModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };
  const selectOfferRow = (id, name, row) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(row.image);
    closeOfferModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };
  const closeCategoryModal = () => {
    setCategoryModel(false);
  };
  const selectCategoryRow = (id, name, row) => {
    setCategoryId(id);
    setCategoryText(name);
    setCategoryImage(row.image);
    closeCategoryModal();
  };

  const popSelectedUser = (id) => {
    const newList = selectedUserIds.filter((item) => item._id !== id);
    setSelectedUserIds(newList);
  };

  const onSelectedUser = (selectedList) => {
    setSelectedUserIds(selectedList);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Notification Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Notification Management
              </li>
              :{" "}
              <li className="breadcrumb-item">
                <Link to="#">Notification create</Link>
              </li>
            </ol>
          </div>
          <Dashboard setActiveCreate={setActiveCreate}/>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>

                { activeCreate ? (
                    <>
                      <h4 className="card-title"> Notification Create </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              defaultValue={notificationData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              defaultValue={notificationData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Scheduled
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("scheduled", e.target.value)
                              }
                              value={notificationData?.scheduled}
                              name="scheduled"
                              className="form-select"
                            >
                              {/* <option value="">Select</option> */}
                              <option value="" selected>
                                Select
                              </option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                          </div>
                        </div>
                        {notificationData?.scheduled == 1 && (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  value={notificationData?.date}
                                  type="date"
                                  onChange={(e) =>
                                    updateValue("date", e.target.value)
                                  }
                                  name="date"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Time
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateValue("time", e.target.value)
                                  }
                                  value={notificationData?.time}
                                  name="time"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("platform", e.target.value)
                              }
                              value={notificationData?.platform}
                              name="platform"
                              className="form-select"
                            >
                              <option value="">Select Platform</option>
                              <option value="sendtoall">Android & iOS</option>
                              <option value="sendtoandroid">All Android User</option>
                              <option value="sendtoios">All iOS User</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={notificationData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                              name="type"
                            >
                              <option value="">Select</option>
                              {/* <option value="general">General</option> */}
                              <option value="link">Link</option>
                              <option value="category">Category</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div>
                        {notificationData?.type === "link" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                defaultValue={notificationData?.link}
                                name="link"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}



                        {notificationData?.type === "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {offerText}
                                //   </a>
                                // </div>
                                // <div></div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}http://localhost:3000/
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Offer
                                //     </a>
                                //   </div>
                                //   <div>
                                //     <a
                                //       onClick={() => toggleOfferModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "category" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleCategoryModel()}
                            >
                              {categoryText ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {categoryText}
                                //   </a>
                                // </div>
                                // <div></div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >
                                    Choose Category
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={categoryImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Category
                                //     </a>
                                //   </div>
                                //   <div>
                                //     <a
                                //       onClick={() => toggleCategoryModel()}
                                //       href="javascript:void(0)"
                                //     >
                                //       <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >
                                    Choose Category
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Target User
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("targetUser", e.target.value)
                              }
                              value={notificationData?.targetUser}
                              name="targetUser"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="all">All User</option>
                              <option value="specific" selected>
                                Specific User
                              </option>
                            </select>
                          </div>
                        </div>

                        {notificationData?.targetUser === "specific" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Users
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleUserModel()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select User
                                  </a>
                                </div>
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <SelectUserList
                              users={userList}
                              onSelect={msUpdateTarget}
                              selectedTarget={selectedTarget}
                              setSelectedTarget={setSelectedTarget}
                              placeholder="Select Target"
                            /> */}
                          </div>
                        )}

                        {selectedUserIds?.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Selected Users
                            </label>
                            <div className="col-sm-6">
                              <SelectedUsers
                                selectedUser={selectedUserIds}
                                deleteHandler={popSelectedUser}
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={notificationData?.status}
                              onChange={(e) =>
                                updateValue("status", Number(e.target.value))
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="1">Published</option>
                              <option value="0">Unpublished</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
                    </>

                ) : (
                  <h4 className="card-title"> Your have exceed your limit to create ppush notification for this billing cycle </h4>
                )}

                
              </CardBody>
            ) 
            
            }
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
        published={true}
      />
      <CategoryModal
        show={categoryModel}
        closeCategoryModal={closeCategoryModal}
        selectRow={selectCategoryRow}
      />
      <MultipleSelectUserList
        showUser={userModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeUserModal}
        selectUserRow={selectUserRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
