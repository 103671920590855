import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import LocationPicker from "react-location-picker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BusinessModal from "components/Modal/BusinessModal";
import "./log.css"; // Custom CSS file for better styling

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const UpdateVenue = () => {
  const location = useLocation();
  const state = location.state?.row;
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [oldLocation, setOldLocation] = useState(defaultPosition);


  const getGalleryChanges = () => {
    const oldGallerySet = new Set(state?.metadata?.beforeUpdatedData?.galleries?.map(g => g));
    const newGallerySet = new Set(state?.metadata?.updatedData?.galleries?.map(g => g));

    const changes = [];
    let combineList = [...Array.from(oldGallerySet), ...Array.from(newGallerySet) ]
    combineList = new Set(combineList)
    combineList = Array.from(combineList)


    //console.log(newGallerySet);




    combineList.forEach((newGallery) => {
        if(oldGallerySet.has(newGallery) && newGallerySet.has(newGallery)){
            changes.push({
                 type: "not_updated", img: newGallery  
            })
        } else if(!oldGallerySet.has(newGallery) || newGallerySet.has(newGallery)){
            changes.push({ type: "inserted", img: newGallery  });
        } else if(oldGallerySet.has(newGallery) || !newGallerySet.has(newGallery)){
            changes.push({ type: "deleted", img: newGallery   });
        }      
    });


    



    // state?.metadata?.updatedData?.galleries.forEach((newGallery, index) => {
    //   const oldGallery = state?.metadata?.beforeUpdatedData?.galleries[index];
    //   if (oldGallery && oldGallery !== newGallery) {
    //     changes.push({ type: "updated", oldImg: oldGallery, newImg: newGallery });
    //   }
    // });

    return changes;
  };


   const galleryChanges = getGalleryChanges();

   //console.log(galleryChanges);

   

  useEffect(() => {
    if (state) {
      const updatedLocation = state.metadata.updatedData.location.coordinates;
      const oldLocation = state.metadata.beforeUpdatedData.location.coordinates;

      setCurrentLocation({
        lat: updatedLocation[1],
        lng: updatedLocation[0],
      });

      setOldLocation({
        lat: oldLocation[1],
        lng: oldLocation[0],
      });
     
    }
  }, [state]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const mapUpdated = () => {
    return (
      currentLocation.lat !== oldLocation.lat ||
      currentLocation.lng !== oldLocation.lng
    );
  };

  let history = useHistory();

  const compareData = (updatedData, oldData, key) => {
    const updatedValue = updatedData[key];
    const oldValue = oldData[key];
    if (updatedValue !== oldValue) {
      return (
        <div>
          <span className="highlight-red">{oldValue}</span> &rarr;{" "}
          <span className="highlight-green">{updatedValue}</span>
        </div>
      );
    }
    return <span>{updatedValue}</span>;
  };

  const compareTags = (updatedTags, oldTags) => {
    const addedTags = updatedTags.filter(tag => !oldTags.includes(tag));
    const removedTags = oldTags.filter(tag => !updatedTags.includes(tag));
    
    return (
      <div>
        {removedTags.map((tag, index) => (
          <span key={index} className="tag highlight-red">
            {tag}
          </span>
        ))}
        {addedTags.map((tag, index) => (
          <span key={index} className="tag highlight-green">
            {tag}
          </span>
        ))}
        {updatedTags
          .filter(tag => !addedTags.includes(tag) && !removedTags.includes(tag))
          .map((tag, index) => (
            <span key={index} className="tag">
              {tag}
            </span>
          ))}
      </div>
    );
  };

  const compareTimings = (updatedTimings, oldTimings) => {
    const uniqueDays = [
      ...new Set([...updatedTimings.map(t => t.day), ...oldTimings.map(t => t.day)])
    ];

    return (
      <table className="table timings-table">
        <thead>
          <tr>
            <th>Day</th>
            <th>Opening Time</th>
            <th>Closing Time</th>
          </tr>
        </thead>
        <tbody>
          {uniqueDays.map(day => {
            const updatedTiming = updatedTimings.find(t => t.day === day);
            const oldTiming = oldTimings.find(t => t.day === day);

            // if updated timing is not available, then it is deleted

            if (!updatedTiming) {
                return (
                    <tr key={day}>
                    <td>                        
                        <span className="highlight-red">{day}</span>
                    </td>
                    <td>
                        <span className="highlight-red">{oldTiming.openingTime}</span>
                    </td>
                    <td>
                        <span className="highlight-red">{oldTiming.closingTime}</span>
                    </td>
                    </tr>
                );
                }

                // if old timing is not available, then it is inserted

                if (!oldTiming) {
                    return (
                        <tr key={day}>
                        <td>                        
                            <span className="highlight-green">{day}</span>
                        </td>
                        <td>
                            <span className="highlight-green">{updatedTiming.openingTime}</span>
                        </td>
                        <td>
                            <span className="highlight-green">{updatedTiming.closingTime}</span>
                        </td>
                        </tr>
                    );
                }

            return (
              <tr key={day}>
                <td>{day}</td>
                <td>
                  {updatedTiming && oldTiming && updatedTiming.openingTime !== oldTiming.openingTime ? (
                    <div>
                      <span className="highlight-red">{oldTiming.openingTime}</span>
                      <span className="highlight-green">{updatedTiming.openingTime}</span>
                    </div>
                  ) : (
                    <span>{updatedTiming?.openingTime || oldTiming?.openingTime}</span>
                  )}
                </td>
                <td>
                  {updatedTiming && oldTiming && updatedTiming.closingTime !== oldTiming.closingTime ? (
                    <div>
                      <span className="highlight-red">{oldTiming.closingTime}</span>
                      <span className="highlight-green">{updatedTiming.closingTime}</span>
                    </div>
                  ) : (
                    <span>{updatedTiming?.closingTime || oldTiming?.closingTime}</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

 



  const compareImages = (newImg, oldImg, label) => {

    // handle if no changes 

    if (!newImg && !oldImg) {
        return  <span>No changes</span>;
    }

    if (newImg && oldImg && newImg == oldImg) {
        return (

            <div className="image-comparison">
                <div className="image-wrapper">
                    <img src={newImg} alt={`${label} New`} />
                    
                </div>               
            </div>           
        );
    }




    if (newImg && oldImg && newImg !== oldImg) {
      return (
        <div className="image-comparison">
          <div className="image-wrapper">
            <span className="highlight-red">
              <img src={oldImg} alt={`${label} Old`} />
              {label} Old
            </span>
          </div>
          <div className="image-wrapper">
            <span className="highlight-green">
              <img src={newImg} alt={`${label} New`} />
              {label} New
            </span>
          </div>
        </div>
      );
    }
    if (!oldImg && newImg) {
      return (
        <div className="image-comparison">
          <div className="image-wrapper">
            <span className="highlight-green">
              <img src={newImg} alt={` ${label} New`} />
               { label} Inserted
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="image-comparison">
        <div className="image-wrapper">
          <span className="highlight-red">
            <img src={oldImg} alt={`${label} Old`} />
            {label} Deleted
          </span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Update Venue</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Venue</h4>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      {state?.metadata?.updatedData?.business && (
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Business Name
                          </label>
                          <div className="col-sm-6">
                            <div
                              className="form-control"
                              onClick={() => toggleModel()}
                            >
                              {state?.metadata?.updatedData?.business.name} (Updated)
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Name</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "name"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">About</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "about"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Address</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "address"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Phone</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "phone"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Email</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "email"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Website</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "website"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Booking Url
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "booking_url"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Menu PDF or Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.menu_url,
                            state?.metadata?.beforeUpdatedData?.menu_url,
                            "Menu"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Dress Code
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "dress_code"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Claim Code
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "claimCode"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Discount Tag
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareData(
                              state?.metadata?.updatedData,
                              state?.metadata?.beforeUpdatedData,
                              "discountText"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow Reviews
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {state?.metadata?.updatedData?.isAllowReview
                              ? "Yes"
                              : "No"}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Allow Ratings
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {state?.metadata?.updatedData?.isAllowRatting
                              ? "Yes"
                              : "No"}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Logo</label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.logo,
                            state?.metadata?.beforeUpdatedData?.logo,
                            "Logo"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Cover Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.cover,
                            state?.metadata?.beforeUpdatedData?.cover,
                            "Cover"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Rectangle Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.rectangleImage,
                            state?.metadata?.beforeUpdatedData?.rectangleImage,
                            "Rectangle"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Square Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.squareImage,
                            state?.metadata?.beforeUpdatedData?.squareImage,
                            "Square"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Story Image
                        </label>
                        <div className="col-sm-6">
                          {compareImages(
                            state?.metadata?.updatedData?.storyImage,
                            state?.metadata?.beforeUpdatedData?.storyImage,
                            "Story"
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Cuisines</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareTags(
                              state?.metadata?.updatedData?.cuisines,
                              state?.metadata?.beforeUpdatedData?.cuisines
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Features</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareTags(
                              state?.metadata?.updatedData?.features,
                              state?.metadata?.beforeUpdatedData?.features
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Music</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareTags(
                              state?.metadata?.updatedData?.music,
                              state?.metadata?.beforeUpdatedData?.music
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Themes</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareTags(
                              state?.metadata?.updatedData?.themes,
                              state?.metadata?.beforeUpdatedData?.themes
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Timings</label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {compareTimings(
                              state?.metadata?.updatedData?.timings,
                              state?.metadata?.beforeUpdatedData?.timings
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Location
                        </label>
                        <div className="col-sm-6">
                          <div className="form-control">
                            {mapUpdated() ? (
                              <div className="location-maps">
                                <div>
                                  <span className="highlight-red">
                                    <LocationPicker
                                      containerElement={<div style={{ height: "100%" }} />}
                                      mapElement={<div style={{ height: "200px" }} />}
                                      defaultPosition={oldLocation}
                                      readOnly
                                    />
                                    Old Location
                                  </span>
                                </div>
                                <div>
                                  <span className="highlight-green">
                                    <LocationPicker
                                      containerElement={<div style={{ height: "100%" }} />}
                                      mapElement={<div style={{ height: "200px" }} />}
                                      defaultPosition={currentLocation}
                                      readOnly
                                    />
                                    New Location
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <LocationPicker
                                containerElement={<div style={{ height: "100%" }} />}
                                mapElement={<div style={{ height: "200px" }} />}
                                defaultPosition={currentLocation}
                                readOnly
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {galleryChanges.length > 0 && (
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Gallery</label>
                        <div className="col-sm-6">
                          {galleryChanges.map((change, index) => (
                            <div key={index} className="gallery-item">
                              {change.type === "deleted" ? (
                                <div className="image-wrapper">
                                  <span className="highlight-red">
                                    <img src={change.img} alt="Deleted Gallery" />
                                    Deleted Image
                                  </span>
                                </div>
                              ) : change.type === "inserted" ? (
                                <div className="image-wrapper">
                                  <span className="highlight-green">
                                    <img src={change.img} alt="Inserted Gallery" />
                                    Inserted Image
                                  </span>
                                </div>
                              ) :  <div className="image-wrapper">
                                        <span className="highlight-green">
                                            <img src={change.img} alt="Inserted Gallery" />                                
                                        </span>
                            </div>  }
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>       
    </React.Fragment>
  );
};

export default UpdateVenue;
