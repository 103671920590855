import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import CustomAvatar from "../../components/Common/Avatar.js";
//import filter modal

import moment from "moment";
import FilterModal from "../../components/Modal/FilterModal";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import BucketList from "pages/UserManagement/BucketList";

const filterObj = {
  name: "",
  createdAt: "",
};

const AddedItemTable = ({ venueId }) => {
  const [modal_center, setmodal_center] = useState(false);
  const [adData, setAdData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [offer, setOffer] = useState();
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  useEffect(() => {
    AdManagment();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage == 1) {
      AdManagment();
      return;
    }
    setCurrentPage(1);
  }, [sort, sortOrder, sizePerPageOrg]);

  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? true : false;

    const formData = new FormData();
    formData.append("userId", actId);
    formData.append("enable", status);

    const res = await putRequestForm("bucket/update", formData);

    if (res.status == 1) {
      toast.success("User Status updated Successfully");
      AdManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email" ||
          key === "phone"
        ) {
          filterObject.type = "regex";
        } else if (key === "gender" || key === "enable") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    AdManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    AdManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }
  function UserFormatter(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>
            {row?.user?.first_name} {row?.user?.last_name}
          </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function MediaFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.background}
          onClick={() => showLightBox(row.background)}
          alt="Image"
          width={"50px"}
        />
      </>
    );
  }
  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }
  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }
  
  function UserNameFormatter(cellContent, row) {
    return (
      <>
       
          <img
            className="avatar-sm rounded-circle"
            src={row.image}
            onClick={() => showLightBox(row.coverImage)}
            alt="img"
            width={"50px"}
          />
          &nbsp;&nbsp;
          <a href={`/userdetails/${row.userId}`} target="_blank">
          {row.userName}
          </a>
        
      </>
    );
  }
  


  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

   
   
    {
      dataField: "userName",
      text: "User",
      formatter: UserNameFormatter,
      sort: false,
    },
   
    {
      dataField: "device",
      text: "Device",
      sort: false,
    },

    
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    }

    
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, offer, row) {
    return (
      <React.Fragment>
        <div className="d-flex gap-1 justify-content-center align-item-center">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(offer._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }
  // const bucketUserId = userData[0]?.user_id;

  const AdManagment = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      //   type: "venues",
      sortField: sort,
      sortOrder: sortOrder,
      venueId: venueId,
      type: "addedItems",
      unique: true,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    const response = await postRequest(`venue/log/list/admin`, payload);
    const data = response.data;
    setTotalPage(data?.count);
    setCurrentFPage(data?.page);
    setAdData(data?.list);
    setLoader(false);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this bucket",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteBucket(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteBucket = async (id) => {
    const response = await deleteRequest("venue/follow/delete", {
      adId: adId,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      window.location.reload();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids); // Call the function to show the random word dialog
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFollows(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFollows = async (ids) => {
    const response = await deleteRequest(`venue/follow/delete`, {
      ids: ids,
    });

    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      AdManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={adData}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="userName">User Name</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        AdManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <BucketList
        showBucketModal={model}
        closeBucketModal={closeBucketModal}
        offer={offer}
      />
    </>
  );
};

export default AddedItemTable;
