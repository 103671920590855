
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ToastContainer, toast } from 'react-toastify';
import 'sweetalert2/src/sweetalert2.scss'
import Lightbox from 'react-image-lightbox';

import { getRequest, postRequest,deleteRequest, putRequestForm, putRequest,postRequestForm } from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import { Row, Col, Card, CardBody, CardTitle , Spinner,Badge, UncontrolledTooltip,Modal, Button} from "reactstrap"
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ReactPaginate from "react-paginate";



const BannerTypeManagementlist = (props) => {

  const [modal_center, setmodal_center] = useState(false)  
  const [bannertypedata, setbannertypedata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("first_name");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [loader2, setLoader2] = useState(false);
  
  useEffect(() => {  
    bannerTypeManagment();    
  }, [currentPage])
  
  const GetValueOnChange = async (value, _id) => {

    let actId = _id
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("bannerTypeId", actId);
    formData.append("status", status);

    const res = await putRequestForm('banner/type/update', formData);

    if (res.status == 1) {
      toast.success("Banner Type Status updated Successfully");
      bannerTypeManagment();
    }else{
      toast.error("Something went wrong");
      setLoader(false);
    }    
     
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if(page != currentPage){
        setCurrentPage(page); 
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];


  function tog_center() {  
    setmodal_center(!modal_center)
    removeBodyCss()
  }

 
  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? <>
          <button className='btn btn-active' type='button' onClick={() => GetValueOnChange(row.status = false, row._id)}>Active</button></>
          : <>
            <button className='btn btn-deactive' type='button' onClick={() => GetValueOnChange(row.status = true, row._id)} >Inactive</button>
          </>}
      </>
    );
  }
  
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };


  function SNoFormatter(cellContent, row, i) {
   return (
     <>{i + 1}</>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      text: "Title",     
      sort: false,
    },  
   
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },     
    
    {
      dataField: "createdAt",
      text: "Created At",      
      sort: false,
    },   

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setValue(event.target.value);
   
  }
  const handlesorting = (event) => {
    setsort(event.target.value);    
    }
    const handleReload = () => {
      window.location.reload();
    };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className='d-flex'>         
          <button type='button' className=' btn btn-info  mx-2'>  <Link to={{
              pathname: `/updateBannerType/${row._id}`,
              state: { row }
            }}> <i class="fal fa-pencil fs-5 text-light"></i></Link> </button>
          
          <button type='button' className="btn btn-danger" onClick={() => handlerdelete(row._id)}><i class="fal fa-trash  fs-5"></i></button>&nbsp;&nbsp;
          
        </div>
      </React.Fragment>
    );
  }
  const limit = 10;

  const bannerTypeManagment = async (page = 0) => {
    setLoader(true);
    const response = await postRequest(`banner/type/list/?limit=${sizePerPageOrg}&page=${currentPage-1}`);
    const data = response.data;
    setTotalPage(data.count);    
    setbannertypedata(data.list);   
    setLoader(false);    
  }



  const handlerdelete = (id) => {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) =>  {
      if (result.isConfirmed) {
        const response = await deleteRequest(`banner/type/delete`, { bannerTypeId: id })
        if(response.status){
          Swal.fire(
            'Deleted!',
            'Deleted successfully',
          )
          bannerTypeManagment();
        }else{
          Swal.fire(
            'Error!',
            'Something went wrong',
          )
        }

    }})
  } 

  return (
    <>
      <React.Fragment>
        <ToastContainer />      
        <Row>
          <Col className='12'>
            <div className="page-title-box">
              <h4>Banner Type Management</h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Banner Type Management</li>
                : <li className="breadcrumb-item">Banner Type List</li>
              </ol>
            </div>
          </Col>
          <div className='col-md-2 text-end' >
            <Link to="/createBannerType"> <Button> <i class="fas fa-plus"></i> Create</Button> </Link>&nbsp;&nbsp;&nbsp; 
            </div>
          <Col className="col-12">
            <Card>
            { loader ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :
              <CardBody className="table-shorting">
               { 
               
               <PaginationProvider
               pagination={paginationFactory({ page: currentPage , sizePerPage: sizePerPageOrg, totalSize: totalPage , custom: true })}            
             >
              {({ paginationProps, paginationTableProps }) => (  <>
           <ToolkitProvider
             keyField="_id"
             data={bannertypedata}
             columns={columns}             
           >
             {
               props =>
                 <Row> 
                   <Col className='col-lg-12 d-flex mb-3'>
                          
                          <label className='mb-0 text-label'>Page Size</label>
                         
                           <select value={value} onChange={handleChange} className="form-select" style={{ width: "7%" }}>

                           <option value="10">10</option>

                           <option value="20">20</option>

                           <option value="50">50</option>

                           </select>
                          

                           <label className='mb-0 text-label ms-3'>Sort By:</label>
                           <select value={sort} onChange={handlesorting} className="form-select" style={{ width: "25%" }}>
                           <option value="first_name"> First Name</option>
                           <option value="last_name"> Last Name</option>
                           <option value="email">Email ID</option>

                           <option value="phone">Phone No.</option>
                           <option value="birth_date">DOB</option>
                           <option value="gender">Gender</option>

                           </select>
                         <div className='filter-item'>
                           <input type="search" placeholder='Search' value={searchText} class="form-control" onChange={ (e) => setSearchText(e.target.value) } style={{ width: "30%" }} />                            
                           <button onClick={ () => { storyManagment() }  } type='button' className='btn btn-primary mx-2'>Search</button>
                           <button type='button' onClick={handleReload} className='btn btn-danger'>Reset</button>
                           </div>
                       </Col>
                       
                  

                   <Col className='col-lg-12 text-end'>
                     <BootstrapTable  onTableChange={handleTableChange}
                                    remote={true} classes='table-striped'  {...props.baseProps} {...paginationTableProps}/>  
                   </Col>
                   
                 </Row>
             }
           </ToolkitProvider> 

           <Row>
             <Col className='col-lg-12 text-end'>

                         <div className="text-md-right">
                               <PaginationListStandalone
                                 {...paginationProps}
                               />
                             </div>
             </Col>

           </Row>
           </>
       )}
       </PaginationProvider>


                }
                
              
              </CardBody>
              }
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}           
            onCloseRequest={() => setOpenLightBox(false)}            
          />
        )}
      </React.Fragment>
    </>

  )
}

export default BannerTypeManagementlist;