import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import FileUpload from "../components/Common/FileUpload.js";

import { postRequestForm } from "../components/Common/Utils.js";

function FileDrop(props) {
  const [files, setFiles] = useState([]);
  const [startLoader, setStartLoader] = useState(false);  
  
  const onDrop = useCallback(async acceptedFiles => {
    setStartLoader(true);
    
    // Process all files
    const uploadedFiles = await Promise.all(acceptedFiles.map(async (file) => {
      const formData = new FormData();
      formData.append("image", file);

      const res = await postRequestForm("comman/img/upload", formData);
      return res.data.url;
    }));

    setStartLoader(false);
    setFiles([...files, ...uploadedFiles]);
    uploadedFiles.forEach(url => props.onFileUpload(url));
   
  }, [files, props]);

  useEffect(() => {
    if (props.files?.length) {
      setFiles(props.files);
    }
  }, [props.files]);

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop, 
    accept: props.accept,
    multiple: true // Allow multiple files
  });

  return (
    <div>
      <div {...getRootProps()} style={{ border: "1px dashed black", padding: "10px", marginTop: "20px" }}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
        {files.map((file, index) => (
          <div key={index} style={{ margin: "10px" }}>
            <img src={file} alt={`Preview ${index}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
          </div>
        ))}
      </div>
      <FileUpload message="Image Uploading" status={startLoader} />
    </div>
  );
}

export default FileDrop;
