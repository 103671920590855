import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequestForm,customValidator, putRequest, postRequestForm,postRequest } from "../../components/Common/Utils.js";
import CustomCard from "./CustomCard";
import Lightbox from "react-image-lightbox";

import FileUpload from "../../components/Common/FileUpload"; 

export default function UpdateCustomSubscribe() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    frequency: "",
    startColor: "",
    endColor: "",
    image: "",
    buttonText: "",
    packageId: "",
    backgroundImage: "",
    description: "",
    backgroundType: "color",
    packageName : '',
    packagePrice : '',
    packageDuration : '',
  });

  const [previewImage, setPreviewImage] = useState("");
  const [previewBackgroundImage, setPreviewBackgroundImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [openLightbox3, setOpenLightBox3] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [packagedata, setpackagedata] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (state) {

      delete state.__v;
      delete state._id;
      delete state.createdAt;
      delete state.updatedAt;    
      
      delete state.status; 

      setCategoryData(state); 

      setImageIcon(state.image);
    }
  }, [state]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewBackgroundImage) => {
    setPreviewBackgroundImage(previewBackgroundImage);
    setOpenLightBox2(true);
  };
  const showLightBox3 = (imageIcon) => {
    setImageIcon(imageIcon);
    setOpenLightBox3(true);
  };

  
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.image = reader.result
      setCategoryData(categoryTemp);
    };
   
    reader.readAsDataURL(selectedImage);
    
  }, [selectedImage]);   

  useEffect(() => {
    const packageData = packagedata.find(item => item._id == categoryData.packageId);
    if(packageData) {
      const categoryTemp = { ...categoryData };
      categoryTemp.packageName = packageData.title;
      categoryTemp.packagePrice = packageData.discountedPrice;
      categoryTemp.packageDuration = packageData.time;
      setCategoryData(categoryTemp);
    }
  }, [categoryData.packageId]);

  useEffect(() => {
    if (!selectedBackgroundImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewBackgroundImage(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.backgroundImage = reader.result
      setCategoryData(categoryTemp);
    };
   
    
    reader.readAsDataURL(selectedBackgroundImage);
  }, [selectedBackgroundImage]);

  useEffect(() => {
    packageManagment();
  }, []);

  const Updatecategory = async (e) => {
    e.preventDefault();
    categoryData.selectedImage = selectedImage;
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
        {
          field: "subTitle",
          errorMsg: "Please enter subtitle",
        },
        {
          field: "startColor",
          errorMsg: "Please enter start color",
        },
        {
          field: "endColor",
          errorMsg: "Please enter end color",
        },
      ],
      categoryData
    );
    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    

    const formData = new FormData();
    const selectedImageForm = new FormData();
    setStartLoader(true);
    selectedImageForm.append("image", selectedImage);
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
    let selectedBackgroundImageRes = null;
    if(categoryData.backgroundType == "image") {
      const selectedBackgroundImageForm = new FormData();
      selectedBackgroundImageForm.append("image", selectedBackgroundImage);
       selectedBackgroundImageRes = await postRequestForm("comman/img/upload", selectedBackgroundImageForm);
    }
    setStartLoader(false);   
    if (selectedImageRes?.data?.url) {
      categoryData.image = selectedImageRes.data.url;     
    } else {      
      console.error("Image URL not found in the response.");
    } 

    if(categoryData.backgroundType == "image") {
      if (selectedBackgroundImageRes?.data?.url) {
        categoryData.backgroundImage = selectedBackgroundImageRes.data.url;       
      } else {       
        console.error("Image URL not found in the response.");
      }
    } else {
      categoryData.backgroundImage = "";    
    }

    categoryData.subId = id;
    setLoading(true);
    const response = await putRequest("subscription/custom/update", categoryData);
    setLoading(false);

    if (response.status == 1) {
      toast.success("Custom Subscription Updated");
      setTimeout(() => {
        history.push("/customsubscribe");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };
  const packageManagment = async (filterArgs = []) => {   
    setLoading(true);
    const payload = {
      limit: 100000,
      page: 1     
    };
  
   try{
    const response = await postRequest(`subscription/membership/list`, payload);
    const data = response.data;    
    setpackagedata(data.list);
   }catch(error){
    console.error("Failed to fetch data:", error);
   }finally{
    setLoading(false);
   }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Custom Subscription Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Custom Subscription Management</li>:{" "}
              <li className="breadcrumb-item">Update Custom Subscription</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Custom Subscription </h4>
                <Form onSubmit={Updatecategory}>
                  <Row>
                    <Col lg={8} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={categoryData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              value={categoryData?.subTitle}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              value={categoryData?.buttonText}
                              name="buttonText"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={categoryData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div> 

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                             {imageIcon && (
                              <img
                                src={imageIcon}
                                onClick={() => showLightBox3(imageIcon)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Package
                          </label>
                          <div className="col-sm-9">
                            <select                              
                              onChange={(e) =>
                                updateValue("packageId", e.target.value)
                              }
                              value={categoryData?.packageId}
                              name="packageId"
                              className="form-select"
                            >                            
                              <option value="image">Select Package</option>
                              {packagedata.map((item, index) => (
                                <option value={item._id}>{item.title}</option>
                              ))}                            
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Background Type
                          </label>
                          <div className="col-sm-9">
                            <select                              
                              onChange={(e) =>
                                updateValue("backgroundType", e.target.value)
                              }
                              value={categoryData?.backgroundType}

                              name="backgroundType"
                              className="form-select"
                            >                            
                              <option value="image">Image</option>
                              <option value="color">Color</option>
                            </select>
                          </div>
                        </div>
                        { categoryData?.backgroundType == "image" ? (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-password-input"
                              className="col-sm-3 col-form-label"
                            >
                              Background Image
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="file"
                                onChange={(e) =>
                                  setSelectedBackgroundImage(e.target.files[0])
                                }
                                name="backgroundImage"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                              <br />
                              {previewBackgroundImage && (
                                <img
                                  src={previewBackgroundImage}
                                  onClick={() => showLightBox2(previewBackgroundImage)}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                            </div>
                          </div>                          
                        ) : (
                          <><div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Color
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("startColor", e.target.value)
                              }
                              value={categoryData?.startColor}
                              name="startColor"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Color
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("endColor", e.target.value)
                              }
                              value={categoryData?.endColor}
                              name="endColor"
                              className="form-control"
                            />
                          </div>
                        </div></>
                        ) }

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Frequency
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("frequency", e.target.value)
                              }
                              value={categoryData?.frequency}
                              name="frequency"
                              className="form-control"
                            />
                          </div>
                        </div>
                        
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </Col>
                    <Col lg={4} className="ms-lg-auto">
                      <CustomCard subscriptionDialog={categoryData}/>
                    </Col> 
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        {openLightbox2 && (
          <Lightbox
            mainSrc={previewBackgroundImage}
            onCloseRequest={() => setOpenLightBox2(false)}
          />
        )}
        {openLightbox3 && (
          <Lightbox
            mainSrc={imageIcon}
            onCloseRequest={() => setOpenLightBox3(false)}
          />
        )}
         <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
