// CalendarWithStats.js
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card, Row, Col, Container } from 'react-bootstrap';
import CustomEvent from './CustomEvent';
import LabelSelector from './LabelSelector';
import './CalendarWithStats12.css?v=1.0.1'; // Import the scoped CSS

const localizer = momentLocalizer(moment);

const CalendarWithStats = ({ events }) => {
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState(['User Registration']);
    const labels = [
        'User Registration',
        'Venue Add',
        'Business Add',
        'Offer Create',
        'Discount Add',
        'Discount Claimed',
        // 'Deal Created'
    ];

    useEffect(() => {
        const filterEvents = (events) => {
            return events?.map((event) => {
                const eventData = event.title.split(', ').map((item) => {
                    const [label, value] = item.split(': ');
                    return { label, value };
                });

                const filteredEventData = eventData.filter((item) =>
                    selectedLabels.includes(item.label)
                );

                return {
                    ...event,
                    title: filteredEventData.map((item) => `${item.label}: ${item.value}`).join(', '),
                };
            });
        };

        setFilteredEvents(filterEvents(events));
    }, [events, selectedLabels]);

    return (
        <Container className="my-4">
            <Row>
                <Col md={3}>
                    <Card className="shadow-sm border-0 mb-4">
                        <Card.Header as="h5" className="bg-primary text-white">Select Labels</Card.Header>
                        <Card.Body>
                            <LabelSelector labels={labels} selectedLabels={selectedLabels} onChange={setSelectedLabels} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={9}>
                    <Card className="shadow-sm border-0 calendar-card">
                        <Card.Header as="h5" className="bg-primary text-white">Calendar with Daily Stats</Card.Header>
                        <Card.Body>
                            <Calendar
                                localizer={localizer}
                                events={filteredEvents}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 700 }}
                                views={['month', 'day']} // Specify available views
                                components={{
                                    event: CustomEvent,
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CalendarWithStats;
