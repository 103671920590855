import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import queryString from 'query-string';

const Authmiddleware = ({
  component: Component,
  permission,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // Check if the user is authenticated
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }

      // Get account_type from local storage
      const accountType = localStorage.getItem("acc_type");

      // Get permissions from local storage
      const permissions = JSON.parse(localStorage.getItem("permissions")) || {};

      // Default permissions for bypass cases
      const defaultPermissions = { create: true, update: true, delete: true, show: true };

      // Determine permissions based on account type
      let routePermissions = defaultPermissions;

      if (accountType === "super-admin" || accountType === "venue" || accountType === "business") {
        routePermissions = defaultPermissions;
      } else if (!permission || permissions[permission] === undefined) {
        routePermissions = defaultPermissions;
      } else {
        const perm = permissions[permission];

        if(!perm.show){
          if(perm === false){
            return (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                  search: props.location.search // Preserve query parameters
                }}
              />
            );
          }
        }

        if (perm.show === false) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: props.location },
                search: props.location.search // Preserve query parameters
              }}
            />
          );
        }
        routePermissions = {
          create: perm.create || false,
          update: perm.update || false,
          delete: perm.delete || false,
          show: perm.show || false
        };
      }

      // Check if the account type is business and the current URL is not under the business account section
      if (accountType === "business" && !props.location.pathname.startsWith("/business-account")) {
        // Redirect to business account specific dashboard or page
        return (
          <Redirect
            to={{
              pathname: `/business-account${props.location.pathname}`,
              state: { from: props.location },
              search: props.location.search // Preserve query parameters
            }}
          />
        );
      }

      // Check if the account type is venue and the current URL is not under the venue account section
      if (accountType === "venue" && !props.location.pathname.startsWith("/venue-account")) {
        // Redirect to venue account specific dashboard or page
        return (
          <Redirect
            to={{
              pathname: `/venue-account${props.location.pathname}`,
              state: { from: props.location },
              search: props.location.search // Preserve query parameters
            }}
          />
        );
      }

      // Render the layout and component if the user has proper access
      return (
        <Layout>
          <Component {...props} permissions={routePermissions} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  permission: PropTypes.string, // Added permission prop type
};

export default Authmiddleware;
