import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequestForm,postRequestForm } from "../../components/Common/Utils.js";
import ReactCrop from "react-image-crop";
import Lightbox from "react-image-lightbox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import FileUpload from "../../components/Common/FileUpload"; 

import { canvasPreview } from "../VenueManagement/canvasPreview.js";
import { useDebounceEffect } from "../VenueManagement/useDebounceEffect.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function UpdatePackage() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [packageData, setPackageData] = useState();
  const [previewLogo, setPreviewLogo] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [isPopular, setIsPopular] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);

  const [remoteLogo, setRemoteLogo] = useState(null);

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    setPackageData(state);
    setPreviewLogo(state?.backgroundImage ? state?.backgroundImage : "");
    setIsPopular(state?.isPopular);
  }, [state]);

  const showLightBox = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox(true);
  };

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleIsPopularChange = (event) => {
    setIsPopular(event.target.checked);
  };

  const updateSquareCrop = (crop) => {    
    const size = Math.max(crop.width, crop.height);
    setCrop({ ...crop, width: size, height: size });
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {        
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);      

      const file = new File([blob], "bk-image.jpg", { type: "image/jpeg" });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const Updatepackage = async (e) => {
    e.preventDefault();
    if (!packageData.title) {
      toast.error("Title is required");
      return;
    }

    if (!packageData.subTitle) {
      toast.error("Please enter subtitle");
      return;
    }

    if (!packageData.discountText) {
      toast.error("Please enter discount text");
      return;
    }

    if (!packageData.time) {
      toast.error("Please enter time");
      return;
    }

    if (!packageData.actualPrice) {
      toast.error("Please enter actual price");
      return;
    }

    if (!packageData.discountedPrice) {
      toast.error("Please enter discount price");
      return;
    }

    if(parseInt(packageData?.actualPrice) < 1){
      toast.error("Please enter actual price greater than 0");
      return; 
    }

    if(parseInt(packageData?.discountedPrice)<1){
      toast.error("Please enter discounted price greater than 0");
      return; 

    }

    if (!packageData.feature) {
      toast.error("Please enter feature");
      return;
    }

    

    const formData = new FormData();
    
    const selectedImageForm = new FormData();
    selectedImageForm.append("backgroundImage", remoteLogo);
    setStartLoader(true);
    const selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
    setStartLoader(false);
    formData.append("packageId", id);
    formData.append("title", packageData.title);
    formData.append("subTitle", packageData.subTitle);
    formData.append("discountText", packageData.discountText);
    formData.append("time", packageData.time);
    formData.append("package_key", packageData.package_key);
    formData.append("actualPrice", packageData.actualPrice);
    formData.append("discountedPrice", packageData.discountedPrice);
    formData.append("feature", packageData.feature);
    formData.append("isPopular", isPopular ? "true" : "false");
    formData.append("status", packageData.status == "true" ? true : false);    
    if (selectedImageRes?.data?.url) {
      formData.append("backgroundImage", selectedImageRes.data.url);
    } else {      
      console.error("Image URL not found in the response.");
    } 

    setLoading(true);

    const response = await putRequestForm(
      "subscription/membership/update",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/package");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Package Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Package Management</li>:{" "}
              <li className="breadcrumb-item">Update Package</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>     
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Package </h4>
                <Form onSubmit={Updatepackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              value={packageData?.subTitle}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discountText", e.target.value)
                              }
                              value={packageData?.discountText}
                              name="subTitle"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Time
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("time", e.target.value)
                              }
                              value={packageData?.time}
                              name="time"
                              class="form-select"
                            >
                              <option value="monthly">Monthly</option>
                              <option value="annually">Annually</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              value={packageData?.actualPrice}
                              name="actualPrice"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              value={packageData?.discountedPrice}
                              name="discountPrice"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Package Key
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("package_key", e.target.value)
                              }
                              value={packageData?.package_key}
                              name="package_key"
                              class="form-select"
                            >
                              <option value="silver_package_key">Silver</option>
                              <option value="gold_package_key">Gold</option>
                              <option value="platinum_package_key">
                                Platinum
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature
                          </label>
                          <div className="col-sm-6">
                            <ReactQuill
                              value={packageData?.feature}
                              onChange={(value) =>
                                updateValue("feature", value)
                              }
                              style={{ height: "250px" }}
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, false] }],
                                  [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                  ],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image", "video"],
                                  ["clean"],
                                ],
                              }}
                              formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "video",
                              ]}
                            />
                          </div>
                        </div><br></br><br></br>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={packageData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"                             
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) =>
                                  updateSquareCrop(newCrop)
                                }
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "600px",
                                  maxHeight: "400px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox(previewLogo)}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Popular
                          </label>
                          <div className="col-sm-6">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isPopular}
                                  onChange={handleIsPopularChange}
                                  color="primary"
                                  value={isPopular.toString()}
                                  name="isPopular"
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!isPopular}
                                  onChange={(event) =>
                                    setIsPopular(!event.target.checked)
                                  }
                                  color="primary"
                                  value={!isPopular.toString()}
                                  name="isPopular"
                                />
                              }
                              label="No"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewLogo}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
        <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
