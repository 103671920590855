import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({ components }) => {
  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="">
                <Form>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {components?.map((component) => component)}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const FilterModal = ({ show, closeModal, components }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Apply Filter
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <FormComponent components={components} />
      </div>
    </Modal>
  );
};

export default FilterModal;
