import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import moment from "moment";
import Avatar from "react-avatar";

const TableComponent = ({ selectRow }) => {
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("name");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    userManagment();
  }, [currentPage]);

  useEffect(() => {
    userManagment();
  }, [sizePerPageOrg, sort]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];
  const initials =
    userdata?.first_name?.charAt(0).toUpperCase() +
    userdata?.last_name?.charAt(0).toUpperCase();

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img src={row.logo} alt="logo img" width={"50px"} />
      </>
    );
  }

  function BusinessFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.business_id?.name}</p>
      </div>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        {row.image ? (
          <img
            src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="img"
            width={"50px"}
          />
        ) : (
          <Avatar
            name={`${row?.first_name} ${row?.last_name}`}
            src={row?.image}
            size="45"
            textSizeRatio={2}
            // className="profile-image2"
          >
            {initials}
          </Avatar>
        )}
      </>
    );
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.enable == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.enable = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.enable = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "item",
      text: "Item",
      //   formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "amount",
      text: "Amount",
      sort: false,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: false,
    },
    {
      dataField: "qty",
      text: "Quantity",
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() =>
              selectRow(row._id, row.first_name + " " + row.last_name, row)
            }
          >
            <i class="fal fa-plus  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const userManagment = async (page = 0) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`user/list/admin`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setuserdata(selectRow.brunch);
    setLoader(false);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="">
                  <>
                    <ToolkitProvider
                      keyField="_id"
                      data={userdata}
                      columns={columns}
                    >
                      {(props) => (
                        <Row>
                          {/* <Col className="col-lg-12 d-flex mb-3">
                            <div className="w-50 d-flex">
                              <label className="mb-0 text-label">
                                Page Size
                              </label>

                              <select
                                value={sizePerPageOrg}
                                onChange={handleChange}
                                className="form-select"
                                style={{ width: "25%" }}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                              </select>
                            </div>
                            <div className="w-50 text-end">
                              <div className="d-flex g-3 justify-content-end">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  value={searchText}
                                  class="form-control"
                                  onChange={(e) =>
                                    setSearchText(e.target.value)
                                  }
                                  style={{ width: "30%" }}
                                />
                                <button
                                  onClick={() => {
                                    userManagment();
                                  }}
                                  type="button"
                                  className="btn btn-primary mx-2"
                                >
                                  Search
                                </button>
                                <Link to="/createVenue">
                                  <button class="btn btn-secondary">
                                    {" "}
                                    <i class="fas fa-plus"></i> Create
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </Col> */}

                          <Col className="col-lg-12 text-end">
                            <BootstrapTable
                              onTableChange={handleTableChange}
                              remote={true}
                              classes="table-striped"
                              {...props.baseProps}
                              //   {...paginationTableProps}
                            />
                          </Col>
                        </Row>
                      )}
                    </ToolkitProvider>

                    <Row>
                      <Col className="col-lg-12 text-end">
                        <div className="text-md-right">
                          {/* <PaginationListStandalone {...paginationProps} /> */}
                        </div>
                      </Col>
                    </Row>
                  </>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const ClaimBrunchModal = ({ show, closeModal, selectRow }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Brunch List
        </h5>

        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent selectRow={selectRow} />
      </div>
    </Modal>
  );
};

export default ClaimBrunchModal;
