import React, {useState, useEffect} from 'react';
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import BootstrapTable from 'react-bootstrap-table-next';
import { Link, NavLink } from 'react-router-dom';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Row, Col, Card, CardBody, CardTitle , Spinner,Badge, UncontrolledTooltip, Modal, Button  } from "reactstrap";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone   
  } from "react-bootstrap-table2-paginator";

const TableComponent = ({selectRow}) => {   

   const [activitydata, setactivitydata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);
  
  const [sort, setsort] = React.useState("name");
   const [currentFPage, setCurrentFPage] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState('');


   useEffect(() => {  
     activityManagment();    
   }, [currentPage])

   useEffect(() => {
    activityManagment();
  }, [sizePerPageOrg, sort]);

 

  const toggleModel = () => {
    setModel(!model);
  };

   const handleTableChange = (type, { page, sizePerPage, searchText }) => {

     if (type === "pagination") {

       if(page != currentPage){
         setCurrentPage(page); 
       }
     }
   };

   const defaultSorted = [
     {
       dataField: "_id",
       order: "asc",
     },
   ];

   function ImgLogoFormatter(cellContent, row) {
     return (
       <><img src={row.logo}  alt="logo img" width={"50px"} /></>
     );
   }

   function BusinessFormatter(cellContent, row) {
     return (
       <div style={ { display: 'flex' } }>        
         <p>
           {row?.business_id?.name}                           
         </p>
       </div>
     );
   }

   function SNoFormatter(cellContent, row, i) {
     return (
       <>{i + 1}</>
      );
    }

    function Eventtime(cellContent, row) {
      let event_time = row.event_time;
      return (
        <React.Fragment>
          <div>
            <p>{moment(event_time).format("DD-MM-YYYY")} </p>
          </div>
        </React.Fragment>
      );
    }
    function Reservationtime(cellContent, row) {
      let reservation_time = row.reservation_time;
      return (
        <React.Fragment>
          <div>
            <p>{moment(reservation_time).format("DD-MM-YYYY")} </p>
          </div>
        </React.Fragment>
      );
    }
  
    function CreatedBy(cellContent, row) {
      let created_by = row.userName;
      if (row.user_type === "org"){
        created_by = row.orgName;
      } 
      return (
        <React.Fragment>
          <div>
            <p>{created_by} </p>
          </div>
        </React.Fragment>
      );
    }
  
    function ImgFormatter(cellContent, row) {
      return (
        <>
          <img
            src={row.image}
            onClick={() => showLightBox(row.image)}
            alt="cover img"
            width={"50px"}
          />
        </>
      );
    }


    const columns = [
      {
        text: "S.NO",
        formatter: SNoFormatter,
        sort: false,
      },
      {
        dataField: "title",
        text: "Title",
        sort: false,
      },
      // {
      //   dataField: "description",
      //   text: "Description",
      //   sort: false,
      // },
  
      {
        
        text: "Created By",
        formatter: CreatedBy,
        sort: false,
      },
      // {
      //   dataField: "user_type",
      //   text: "User Type",      
      //   sort: false,
      // },
  
      // {
      //   dataField: "type",
      //   text: "Type",
      //   sort: false,
      // },
      {
        dataField: "image",
        text: "Image",
        formatter: ImgFormatter,
        sort: false,
      },
     
  
      {
        dataField: "event_time",
        text: "Event Time",  
        formatter: Eventtime,    
        sort: false,
      },
      {
        dataField: "reservation_time",
        text: "Reservation Time", 
        formatter: Reservationtime,
        sort: false,
      },
      
      // {
      //   dataField: "venue_type",
      //   text: "Venue Type",      
      //   sort: false,
      // },
      // {
      //   dataField: "custom_venue",
      //   text: "Custom Venue",    
      //   sort: false,
      // },   
  
      {
        dataField: "venueName",
        text: "Venue",    
        sort: false,
      },   
  
      // {
      //   dataField: "createdAt",
      //   text: "Created At",
      //   sort: false,
      // },
  
      {
        dataField: "actions",
        text: "Actions",
        formatter: iconFormatter,
        sort: false,
      },
    ];


  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  }
  const handlesorting = (event) => {
    setsort(event.target.value);
  }
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className='d-flex'>           
          <button type='button' className="btn btn-danger" onClick={() => selectRow(row._id, row.title, row)}><i class="fal fa-plus  fs-5"></i></button>
        </div>
      </React.Fragment>
    );
  }


  const limit = 10;

  const activityManagment = async (page = 0) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`event/list/`, payload);
    const data = response.data;
    setTotalPage(data.count);    
    setCurrentFPage(data.page);
    setactivitydata(data.list);   
    setLoader(false);    
  }





  return (
    <>
       <React.Fragment>     
        <Row> 
          <Col className="col-12">
            <Card>
            { loader ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :
              <CardBody className="">
               { 
               
               <PaginationProvider
               pagination={paginationFactory({ page: currentFPage,
                sizePerPage: sizePerPageOrg,
                totalSize: totalPage,
                custom: true, })}            
             >
              {({ paginationProps, paginationTableProps }) => (  <>
           <ToolkitProvider
             keyField="_id"
             data={activitydata}
             columns={columns}             
           >
             {
               props =>
                 <Row> 
                   <Col className="col-lg-12 d-flex mb-3">
                                <div className='w-10 d-flex'>
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ marginRight: "90px" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>
                                </div>

                                <div className='d-flex g-3 justify-content-end'>
                           <input type="search" placeholder='Search' value={searchText} class="form-control" onChange={ (e) => setSearchText(e.target.value) } style={{ width: "350px" }} />                            
                           <button onClick={ () => { activityManagment() }  } type='button' className='btn btn-primary mx-2'>Search</button>
                          
                           </div>

                                 
                                </Col>
                  

                   <Col className='col-lg-12 text-end'>
                     <BootstrapTable  onTableChange={handleTableChange}
                                    remote={true} classes='table-striped'  {...props.baseProps} {...paginationTableProps}/>  
                   </Col>
                   
                 </Row>
             }
           </ToolkitProvider> 

           <Row>
             <Col className='col-lg-12 text-end'>

                         <div className="text-md-right">
                               <PaginationListStandalone
                                 {...paginationProps}
                               />
                             </div>
             </Col>

           </Row>
           </>
       )}
       </PaginationProvider>


                }
                
              
              </CardBody>
              }
            </Card>
          </Col>
        </Row>       
      </React.Fragment>
    </>
  );
};



const EventModal = ({show, closeEventModal, selectRow}) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
     <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                      >
                        Event List
                            </h5>
                      <button
                        onClick={() => {
                            closeEventModal()
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
         <div className="modal-body">    
        <TableComponent selectRow={selectRow} />
        </div>
      
    </Modal>    
  );
};

export default EventModal;
