import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, Form,  Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  putRequest,customValidator } from "../../../components/Common/Utils.js";

export default function UpdateCuisine() {

  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;   
  const [cuisineData, setCuisineData] = useState( { title: "" });  
  const [loading, setLoading] = useState(false);  
  const { id } = useParams();

  useEffect(() => {
    setCuisineData(state);
  }, [state]) 

  const Updatecuisine = async (e) => {
    e.preventDefault();
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
      ],
      cuisineData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }
   
   setLoading(true);  
    
    const dataObj = {
      cuisineId: cuisineData._id,
      title: cuisineData.title,
    };   
    const response = await putRequest("venue/cuisine/update", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/cuisines");
      }, 3000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...cuisineData}
    list[index] = value
    setCuisineData(list)
  }     

  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Cuisine Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Cuisine Management</li>:{" "}
              <li className="breadcrumb-item">
                Update Cuisine
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>    
    
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Update Cuisine </h4>
              <Form onSubmit={Updatecuisine}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } value={cuisineData?.title} name='title'  className="form-control"  />
                            </div>
                        </div>          
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
