import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
// import rButton from "reactstrap/button";

import {
  deleteRequest,
  putRequest,
  postRequest,
  putRequestForm,
  RBButton,
  deleteConfirmationWords,
} from "components/Common/Utils";
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
import StarComponent from "./StarComponent";

import Lightbox from "react-image-lightbox";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import LocationPicker from "react-location-picker";
import DayTimePicker from "./DayTimePicker";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import TagSelection from "./TagSelection";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./CustomCard.css";
import VenueOfferListTable from "./VenueOfferTable";
import VenueSpecialOfferListTable from "./VenueSpecialOfferList";
import VenueHomeBlockListTable from "./VenueHomeblockList";
import VenueClaimsListTable from "./VenueClaimsTable";
import VenueNotificationListTable from "./VenueNotificationTable";
import VenueReviewListTable from "./VenueReviewTable";
import VenueFollowListTable from "./VenueFollowingTable";
import VenueActiveStoryListTable from "./VenueActiveStoryTable";
import VenueStoryListTable from "./VenueStoryTable";
import VenueAdListTable from "./VenueAdsTable";
import AnalyticsComponets from "./AnalyticsComponets";
import OrderManagementListTable from "./OrderManagmentListTable";
import { useLocation } from "react-router-dom";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function VenueDetails({ venueId, venueName = "" }) {
  const [venueData, setData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [Subdetails, setSubdetails] = React.useState(null);
  const { id } = useParams();

  const VenueSubscriptionApi = async () => {
    postRequest(`subscription/fetch-venue-subscription`, {
      venueId: id,
    }).then((data) => {
      let Counts = data?.data;
    

      setSubdetails(Counts);
    });
  };

  const DashboardStat = async () => {
    postRequest(`subscription/venue-analytics`, {
      venueId: id,
      type: "click",
    }).then((data) => {
      let Counts = data?.data;
      

      setAnalyticsData(Counts);
    });
  };

  useEffect(() => {
    DashboardStat();
    VenueSubscriptionApi();
  }, []);

  const [textReply, setTextReply] = React.useState("");
  const location = useLocation();
  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
    if (newSelectedMusic.length > oldMusic.length) {
      const addedMusic = newSelectedMusic.filter(
        (newItem) => !oldMusic.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: addedMusic[0]._id,
        });
      }
    } else if (newSelectedMusic.length < oldMusic.length) {
      const removedMusic = oldMusic.filter(
        (oldItem) =>
          !newSelectedMusic.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: removedMusic[0]._id,
        });
      }
    }
  };

  const search = location.search;
  const activeQ = new URLSearchParams(search).get("active");

  const handleThemeSelectionChange = (newSelectedTheme) => {
    const oldTheme = [...selectedTheme];
    if (newSelectedTheme.length > oldTheme.length) {
      const addedTheme = newSelectedTheme.filter(
        (newItem) => !oldTheme.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: addedTheme[0]._id,
        });
      }
    } else if (newSelectedTheme.length < oldTheme.length) {
      const removedTheme = oldTheme.filter(
        (oldItem) =>
          !newSelectedTheme.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: removedTheme[0]._id,
        });
      }
    }
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {
    const oldCuisine = [...selectedCuisine];
    if (newSelectedCuisine.length > oldCuisine.length) {
      const addedCuisine = newSelectedCuisine.filter(
        (newItem) => !oldCuisine.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: addedCuisine[0]._id,
        });
      }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
      const removedCuisine = oldCuisine.filter(
        (oldItem) =>
          !newSelectedCuisine.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: removedCuisine[0]._id,
        });
      }
    }
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeatureSelectionChange = (newSelectedFeature) => {
    const oldFeature = [...selectedFeature];
    if (newSelectedFeature.length > oldFeature.length) {
      const addedFeature = newSelectedFeature.filter(
        (newItem) => !oldFeature.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: addedFeature[0]._id,
        });
      }
    } else if (newSelectedFeature.length < oldFeature.length) {
      const removedFeature = oldFeature.filter(
        (oldItem) =>
          !newSelectedFeature.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: removedFeature[0]._id,
        });
      }
    }
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#e22a2a", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },

    // galleryImage: {
    //   width: "100%",
    // },
  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const VenueFetchDetail = async () => {
    setLoader(false);
    postRequest(`venue/detail/admin`, { venueId: `${id}` }).then((data) => {
      let venueData = data.data;
      //let status = data.status;
      setData(venueData);
      setCurrentLocation({
        lat: parseFloat(venueData?.location?.coordinates[1]),
        lng: parseFloat(venueData?.location?.coordinates[0]),
      });

      if (venueData?.timings && venueData?.timings.length > 0) {
        // fetch the index from days

        const newTimeOptions = [...timeOptions];
        venueData?.timings.map((item, index) => {
          const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
          newTimeOptions[daysIndex].checked = true;
          newTimeOptions[daysIndex].open = item.openingTime;
          newTimeOptions[daysIndex].close = item.closingTime;
        });

        setTimeOptions(newTimeOptions);
      }

      if (venueData?.music && venueData?.music.length > 0) {
        setSelectedMusic(venueData.music);
      }

      if (venueData?.themes && venueData?.themes.length > 0) {
        setSelectedTheme(venueData.themes);
      }

      if (venueData?.cuisines && venueData?.cuisines.length > 0) {
        setSelectedCuisine(venueData.cuisines);
      }

      if (venueData?.features && venueData?.features.length > 0) {
        setSelectedFeature(venueData.features);
      }

      setLoader(false);
    });
  };

  const Updatevenue = async (e) => {
    const formData = new FormData();
    formData.append("venueId", id);

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        }
      });
    }

    const response = await putRequestForm("venue/update", formData);

    if (response.status == 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    VenueFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`venue/gallery/delete`, {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      VenueFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const {
    business_id,
    name,
    about,
    address,
    logo,
    cover,
    phone,
    email,
    website,
    booking_url,
    menu_url,
    dress_code,
    claimCode,
    discountText,
    createdAt,
    updatedAt,
  } = venueData;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Venue details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundImage: `url(${cover})` }}
                onClick={() => showLightBox(cover)}
              ></div>
              <div className="profile-image2">
                <img
                  src={logo}
                  onClick={() => showLightBox(logo)}
                  alt="Image Not Available"
                />
              </div>
              <br></br>

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12 "
                  style={{ paddingLeft: "30px", marginTop: "30px" }}
                >
                  <div className="fst">
                    {" "}
                    <h3> {name} </h3>{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Business Name: <span> {name}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Phone: <span> {phone}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Email: <span> {email}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Address: <span> {address}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Website:{" "}
                        <span>
                          <a
                            href={website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Visit Website
                          </a>
                        </span>{" "}
                      </p>{" "}
                    </div>
                    {booking_url && (
                      <div className="col-md-4 fst py-1">
                        <p>
                          {" "}
                          Booking URL:{" "}
                          <span>
                            <a
                              href={booking_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Book Now
                            </a>
                          </span>{" "}
                        </p>{" "}
                      </div>
                    )}
                    {menu_url && (
                      <div className="col-md-4 fst py-1">
                        <p>
                          {" "}
                          Menu URL:{" "}
                          <span>
                            <a
                              href={menu_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Menu
                            </a>
                          </span>
                        </p>
                      </div>
                    )}

                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Dress Code: <span> {dress_code}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Claim Code: <span> {claimCode}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Discount Tag: <span> {discountText}</span>{" "}
                      </p>{" "}
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Created At: <span>{formatDate(createdAt)}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        {" "}
                        Updated At: <span>{formatDate(updatedAt)}</span>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <p>
                        {" "}
                        About: <span> {about}</span>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Col md="12" style={{ marginLeft: "inherit" }}>
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{
                  background: "#ffff",
                  marginLeft: "25px",
                  overflowX: "auto",
                }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li
                    className={
                      !activeQ || activeQ === "Location" ? "active" : ""
                    }
                  >
                    <a href="#location" data-toggle="tab">
                      {" "}
                      Location{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#timings" data-toggle="tab">
                      {" "}
                      Timings{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#music" data-toggle="tab">
                      {" "}
                      Music{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#features" data-toggle="tab">
                      {" "}
                      Features{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#themes" data-toggle="tab">
                      {" "}
                      Themes{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#cuisine" data-toggle="tab">
                      {" "}
                      Cuisine{" "}
                    </a>
                  </li>

                  <li className={activeQ === "Offers" ? "active" : ""}>
                    <a href="#offers" data-toggle="tab">
                      {" "}
                      Offers
                    </a>
                  </li>

                  <li>
                    <a href="#special-offers" data-toggle="tab">
                      Discounts
                    </a>
                  </li>

                  <li>
                    <a href="#cliams" data-toggle="tab">
                      Claims
                    </a>
                  </li>

                  <li className={activeQ === "Gallery" ? "active" : ""}>
                    <a href="#gallery" data-toggle="tab">
                      {" "}
                      Gallery{" "}
                    </a>
                  </li>

                  {/* <li>
                    <a href="#homeblocks" data-toggle="tab">
                      Home Blocks Position
                    </a>
                  </li>

                  <li>
                    <a href="#notifications" data-toggle="tab">
                      {" "}
                      Notifications{" "}
                    </a>
                  </li> */}

                  <li>
                    <a href="#reviews" data-toggle="tab">
                      {" "}
                      Reviews{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#following" data-toggle="tab">
                      {" "}
                      Following
                    </a>
                  </li>

                  <li>
                    <a href="#orders" data-toggle="tab">
                      {" "}
                      Orders
                    </a>
                  </li>

                  {/* <li>
                    <a href="#activeStories" data-toggle="tab">
                      {" "}
                      Active Stories{" "}
                    </a>
                  </li> */}
                  <li>
                    <a href="#stories" data-toggle="tab">
                      {" "}
                      Stories{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#ads" data-toggle="tab">
                      {" "}
                      Ads{" "}
                    </a>
                  </li>
                  {Subdetails?.subscription?.haveAnalytics && (
                    <li>
                      <a href="#analytics" data-toggle="tab">
                        {" "}
                        Analytics{" "}
                      </a>
                    </li>
                  )}
                  </li> */}
                  <li>
                    <a href="#analytics" data-toggle="tab">
                      {" "}
                      Analytics{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">
                <div
                  className={
                    !activeQ || activeQ === "Location"
                      ? "tab-pane active padded-tab"
                      : "tab-pane padded-tab"
                  }
                  id="location"
                >
                  <div className="row">
                    <div className="map-container">
                      <LocationPicker
                        containerElement={<div style={{ height: "100%" }} />}
                        mapElement={<div style={{ height: "350px" }} />}
                        defaultPosition={currentLocation}
                      />
                    </div>
                  </div>
                </div>
                <div className="tab-pane padded-tab" id="timings">
                  <div className="row mb-4">
                    <label className="col-sm-3 col-form-label">
                      Update Time
                    </label>
                    <div className="col-sm-9">
                      {days.map((day, index) => (
                        <DayTimePicker
                          updateTiming={updateTiming}
                          currentValue={timeOptions[index]}
                          index={index}
                          key={index}
                          day={day}
                        />
                      ))}
                    </div>

                    <div
                      className="col-sm-6 offset-6"
                      style={{ marginTop: "12px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={Updatevenue}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tab-pane padded-tab" id="music">
                  <div className="row"></div>

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedMusic?.length && (
                        <TagSelection
                          itemList={venueData?.music_list}
                          selectedItemList={selectedMusic}
                          onSelectionChange={handleMusicSelectionChange}
                          addButton={{
                            path: "/createVenueAssignMusic",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="tab-pane padded-tab" id="features">
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <Link
                        to={{
                          pathname: `/createVenueAssignMusic`,
                          state: {
                            row: {
                              venueId: venueData?._id,
                              venueName: venueData?.name,
                            },
                          },
                        }}
                      >
                        <i className="fal fa-eye fs-5 text-light"></i>
                      </Link>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedFeature?.length && (
                        <TagSelection
                          itemList={venueData?.feature_list}
                          selectedItemList={selectedFeature}
                          onSelectionChange={handleFeatureSelectionChange}
                          addButton={{
                            path: "/createVenueAssignFeature",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="tab-pane padded-tab" id="themes">
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedTheme?.length && (
                        <TagSelection
                          itemList={venueData?.theme_list}
                          selectedItemList={selectedTheme}
                          onSelectionChange={handleThemeSelectionChange}
                          addButton={{
                            path: "/createVenueAssignTheme",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="tab-pane padded-tab" id="cuisine">
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedCuisine?.length && (
                        <TagSelection
                          itemList={venueData?.cuisine_list}
                          selectedItemList={selectedCuisine}
                          onSelectionChange={handleCuisineSelectionChange}
                          addButton={{
                            path: "/createVenueAssignCuisine",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={
                    activeQ === "Offers"
                      ? "active tab-pane padded-tab"
                      : "tab-pane padded-tab"
                  }
                  id="offers"
                >
                  {venueData.length !== 0 && (
                    <VenueOfferListTable
                      userData={venueData?.offers}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="special-offers">
                  {venueData.length !== 0 && (
                    <VenueSpecialOfferListTable
                      userData={venueData?.offers_specials}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="cliams">
                  {venueData.length !== 0 && (
                    <VenueClaimsListTable
                      userData={venueData?.claims}
                      venueId={venueData?._id}
                    />
                  )}
                </div>
                <div
                  className={
                    activeQ === "Gallery"
                      ? "active tab-pane padded-tab"
                      : "tab-pane padded-tab"
                  }
                  id="gallery"
                >
                  <Link
                    to={{
                      pathname: `/createGallery`,
                      state: {
                        row: {
                          venueId: venueData?._id,
                          venueName: venueData?.name,
                          venueLogo: venueData?.logo,
                        },
                      },
                      search: "?from=venue",
                    }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="btn btn-info mx-2"
                      style={{ margin: "15px" }}
                    >
                      {" "}
                      <i class="fas fa-plus"></i> Create
                    </button>{" "}
                  </Link>
                  <br></br>
                  <Row>
                    {venueData?.galleries?.map((gallery, index) => (
                      <Col key={gallery} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                          <CardBody>
                            <img
                              src={gallery}
                              alt="gallery image "
                              className="mb-3"
                              style={{
                                height: "200px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              onClick={() => showLightBox(gallery)}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <RBButton
                                color="danger"
                                onClick={() => handlerdelete2(id, gallery)}
                              >
                                <i className="fal fa-trash fs-5"></i>
                              </RBButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>

                <div className="tab-pane padded-tab" id="homeblocks">
                  {venueData.length !== 0 && (
                    <VenueHomeBlockListTable
                      userData={venueData?.offers_specials}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="notifications">
                  {venueData.length !== 0 && (
                    <VenueNotificationListTable
                      userData={venueData?.notifications}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="reviews">
                  {venueData.length !== 0 && (
                    <VenueReviewListTable
                      userData={venueData?.reviews}
                      venueId={venueData?._id}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="following">
                  {venueData.length !== 0 && (
                    <VenueFollowListTable
                      userData={venueData?.follows}
                      venueId={venueData?._id}
                    />
                  )}
                </div>
                <div className="tab-pane padded-tab" id="orders">
                  {venueData.length !== 0 && (
                    <OrderManagementListTable
                      userData={venueData?.follows}
                      venueId={venueData?._id}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="activeStories">
                  {venueData.length !== 0 && (
                    <VenueActiveStoryListTable
                      userData={venueData?.active_stories}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>

                <div className="tab-pane padded-tab" id="stories">
                  {venueData.length !== 0 && (
                    <VenueStoryListTable
                      userData={venueData?.stories}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>
                <div className="tab-pane padded-tab" id="ads">
                  {venueData.length !== 0 && (
                    <VenueAdListTable
                      adData={venueData?.ads}
                      itemId={venueData?._id}
                      venueName={venueData?.name}
                    />
                  )}
                </div>
                <div className="tab-pane padded-tab" id="analytics">
                  {venueData.length !== 0 && (
                    <AnalyticsComponets data={analyticsData} />
                  )}
                </div>
              </div>
            </Col>
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </Row>
      <ToastContainer />{" "}
    </>
  );
}
