import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";

//import filter modal
import FilterModal from "../../../components/Modal/FilterModal";
import Dashboard from "./index.js";

import {  
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,  
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone  
} from "react-bootstrap-table2-paginator";


import {
  Row,
  Col,
  Card,
  CardBody, 
  Spinner, 
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


const filterObj = {
  venueName: "",
  contenType: "",
  mediaType: "",
  buttonText: "",
  status: "",
  createdAt: "",
};

const StoryManagementlist = (props) => { 
  const [storydata, setstorydata] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [storyNotAvilable, setStoryNotAvilable] = useState(true);

  const [show, setShow] = useState(false);

  const handleShow = (video) => {
    setShow(true);
    setSelectedVideo(video);
  };
  const handleClose = () => {
    setSelectedVideo("")
    setShow(false)
  }

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  const modalStyle = {
    display: show ? "block" : "none",
    position: "fixed",
    zIndex: 1,
    left: "50%",
    top: "50%",
    width: "80%",
    height: "80%",
    overflow: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
  };

  const closeButtonStyle = {
    color: "#aaaaaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
  };

  // useEffect(() => {
  //   storyManagment();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (currentPage == 1) {
  //     storyManagment();
  //     return;
  //   }
  //   setCurrentPage(1);
  // }, [sizePerPageOrg, sort, sortOrder]);

  useEffect(() => {
    const fetchData = () => {
      storyManagment();
    };

    fetchData();
  }, [currentPage, sort, sortOrder, sizePerPageOrg]);

  useEffect(() => {
    if (sort || sortOrder || sizePerPageOrg) {
      setCurrentPage(1);
    }
  }, [sort, sortOrder, sizePerPageOrg]);

  const storyManagmentSearch = async () => {
    if (currentPage == 1) {
      storyManagment();
      return;
    }
    setCurrentPage(1);
  };

  

  const GetValueOnChange = async (value, _id) => {    

    let actId = _id;
    let status = value == 1 ? false : true;

    const formData = new FormData();
    formData.append("id", actId);
    formData.append("status", status);

    const res = await putRequestForm("homeblock/story/update/promote", formData);

    if (res.status == 1) {
      toast.success("Story isPromote updated Successfully");
      storyManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
    
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "venueName" ||
          key === "contentType" ||
          key === "mediaType" ||
          key === "buttonText"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }
        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };

  const applyFilter = () => {
    setFilterActive(true);
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (
          key === "venueName" ||
          key === "contentType" ||
          key === "mediaType" ||
          key === "buttonText"
        ) {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    storyManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false);
    setFilter({ ...filterObj });
    toggleModel();
    storyManagment(null, true);
  };

  function MediaFormatter(cellContent, row) {
    if (row.mediaType === "video") {
      return (
        <>
          <button onClick={() => handleShow(row.mediaUrl)} class="btn btn-info">
            Play
          </button>
        </>
      );
    }
    return (
      <>
        <img
          src={row.mediaUrl}
          onClick={() => showLightBox(row.mediaUrl)}
          alt="thumbnail img"
          width={"50px"}
        />
      </>
    );
  }
  function MediaTypeFormatter(cell, row) {
    const capitalizedMediaType =
      row.mediaType.charAt(0).toUpperCase() +
      row.mediaType.slice(1).toLowerCase();
    return <>{capitalizedMediaType}</>;
  }

  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function VenueFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venue?.name}</p>
      </div>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  

  const GetValueOnChange2 = async (value, _id) => {    

    let actId = _id;
    let status = value == 1 ? false : true;

    const formData = new FormData();
    formData.append("id", actId);
    formData.append("status", status);
    formData.append("venueId", localStorage.getItem("stored_venueId"));

    const res = await putRequestForm("homeblock/story/update/status-venue", formData);

    if (res.status == 1) {
      toast.success("Story isPromote updated Successfully");
      storyManagment();
    } else {
      toast.error(res.message);
      storyManagment();
      setLoader(false);
    }
  };

  function statusFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.status == true ? true : false; // Invert the status
      GetValueOnChange2(newStatus, row?._id);
    };

    return (
      <div>
      
        <Toggle
          defaultChecked={row.status === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br/>
        <div> 
        {row.status === true ?  "Published" : "Unpublished"}
        </div>
      
      </div>
    );
  }

 

  const components = [
    // <div className="row mb-4" key="title">
    //   <label className="col-sm-3 col-form-label">Venue</label>
    //   <div className="col-sm-6">
    //     <input
    //       type="text"
    //       value={filter.venueName}
    //       onChange={(e) => updateFilter("venueName", e.target.value)}
    //       name="venue"
    //       className="form-control"
    //     />
    //   </div>
    // </div>,

    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">Content Type</label>
      <div className="col-sm-6">
        <select
          value={filter.contentType}
          onChange={(e) => updateFilter("contentType", e.target.value)}
          name="contentType"
          className="form-control"
        >
          <option value="">Select Content Type</option>
          <option value="web">Web</option>
          <option value="business">Business</option>
          <option value="offer">Offer</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">Media Type</label>
      <div className="col-sm-6">
        <select
          value={filter.mediaType}
          onChange={(e) => updateFilter("mediaType", e.target.value)}
          name="mediaType"
          className="form-control"
        >
          <option value="">Select Media Type</option>
          <option value="photo">Photo</option>
          <option value="video">Video</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Button Text</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.buttonText}
          onChange={(e) => updateFilter("buttonText", e.target.value)}
          name="buttonText"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venue",
      sort: false,
    },
    {
      dataField: "contentType",
      text: "Content Type",
      sort: false,
    },
    {
      dataField: "mediaType",
      text: "Media Type",
      formatter: MediaTypeFormatter,
      sort: false,
    },
    {
      dataField: "mediaUrl",
      text: "File",
      formatter: MediaFormatter,
      sort: false,
    },

    {
      dataField: "buttonText",
      text: "Button Text",
      sort: false,
    },

    
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");    
  };
  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      storyManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <Tippy content="Delete">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.isPromoted == true ? true : false; // Invert the status
      GetValueOnChange(newStatus, row?._id);
    };

    return (
      <div>      
        <Toggle
          defaultChecked={row.isPromoted === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br/>
        <div> 
        {row.isPromoted === true ?  "Yes" : "No"}
        </div>      
      </div>
    );
  }

  const storyManagment = async (filterArgs = [], resetFilter = false) => {
    setLoader(true);
    const bid = localStorage.getItem("stored_bussinessId");
    const vid = localStorage.getItem("stored_venueId");
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      bid: bid,
      venueId: vid
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    if (filterActive && !filterArgs?.length && !resetFilter) {
      payload.filterArgs = fetchFilter();
    }
    try {
      const response = await postRequest(`homeblock/story/list`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setstorydata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id) => {
    const response = await deleteRequest("homeblock/story/delete", {
      storyId: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      storyManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const promoteAll = async (ids) => {
    const response = await putRequest("homeblock/story/update/promote/all", {
      ids: ids,
    });
    if (response.status) {
      Swal.fire("Updated!", "Updated successfully");
      storyManagment();
      setselectedItems([]);
      
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const promoteNone = async (ids) => {
    const response = await putRequest("homeblock/story/update/promote/none", {
      ids: ids,
    });
    if (response.status) {
      Swal.fire("Updated!", "Updated successfully");
      storyManagment();   
      setselectedItems([]);   
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };



  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const handlerPromoteAll = (ids) => {
    Swal.fire({
      title: "Are you sure promote all?",      
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Promote All!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        promoteAll(ids);  
             
      }
    });
  };
  
  const handlerPromoteNone = (ids) => {
    Swal.fire({
      title: "Are you sure promote none?",     
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Promote None!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        promoteNone(ids);
        
      }
    });
  };
  
  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("homeblock/story/delete", {
      ids: ids,
      model: "story",
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      storyManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Story Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Story Management</li>:{" "}
                <li className="breadcrumb-item">Story List</li>
              </ol>
            </div>
            <Dashboard setStoryNotAvilable={setStoryNotAvilable}/>
          </Col>

          { storyNotAvilable ? null : <div className="col-md-2 text-end">
            <Link to="/createStory">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> }


          
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={storydata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>

                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="venueName">Venue</option>
                                    <option value="contentType">
                                      Content Type
                                    </option>
                                    <option value="mediaType">
                                      Media Type
                                    </option>

                                    <option value="button_text">
                                      Button Text
                                    </option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}

                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-info mx-2"
                                        onClick={() => {
                                          handlerPromoteAll(selectedItems);
                                        }}
                                      >
                                        Promote All
                                      </button>
                                    )}

                                     {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-success mx-2"
                                        onClick={() => {
                                          handlerPromoteNone(selectedItems);
                                        }}
                                      >
                                        Promote None
                                      </button>
                                    )}

                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        storyManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => (
                                      <div>No data available</div>
                                    )}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <div style={modalStyle}>
        <span style={closeButtonStyle} onClick={handleClose}>
          &times;
        </span>
        {selectedVideo && (
          <video
            key={selectedVideo}
            width="100%"
            height="100%"
            loop
            autoPlay
            controls
          >
            <source src={selectedVideo} type="video/mp4" />
          </video>
        )}
      </div>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default StoryManagementlist;
