// LabelSelector.js
import React from 'react';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LabelSelector = ({ labels, selectedLabels, onChange }) => {
    const handleButtonClick = (label) => {
        if (selectedLabels.includes(label)) {
            onChange(selectedLabels.filter((item) => item !== label));
        } else {
            if (selectedLabels.length < 5) {
                onChange([...selectedLabels, label]);
            } else {
                toast.error('You can select a maximum of 5 items.');
            }
        }
    };

    const getButtonVariant = (label) => {
        switch (label) {           
            case 'Venue Add':
                return 'success';
            case 'Business Add':
                return 'warning';
            case 'Offer Create':
                return 'info';
            case 'Discount Add':
                return 'danger';
            case 'Discount Claimed':
                return 'secondary';
            case 'Deal Created':
                return 'dark';
            default:
                return 'light';
        }
    };

    return (
        <div>
            <ToastContainer />
            {labels.map((label) => (
                <Button
                    key={label}
                    variant={selectedLabels.includes(label) ? getButtonVariant(label) : `outline-${getButtonVariant(label)}`}
                    className="mb-2 w-100"
                    onClick={() => handleButtonClick(label)}
                >
                    {label}
                </Button>
            ))}
        </div>
    );
};

export default LabelSelector;
