import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import { saveAs } from 'file-saver';
import MultiSelect from "../../components/Multiselect/MultiselectCommon2.js";
import Tippy from "@tippy.js/react";
import Toggle from "react-toggle";
import "tippy.js/dist/tippy.css";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  postRequest,
  putRequest,
  callCSVRequest,
  deleteRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
  pushUpdates,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const filterObj = {
  name: "",
  businessName: "",
  email: "",
  dress_code: "",
  open: "",
  cuisines: [],
  themes: [],
  music: [],
  features: [],
  planName: "",
  isPublished: "",
  homeblockAdded: "",
  startCreatedAt: "",
  endCreatedAt: "",
};

const VenueManagementlist = (props) => {
  const  permissions  = props.permissions || { permissions: { create: false, update: false, delete: false, show: false } };

  //console.log("permissions-1", permissions)

  const [resetSearch, setResetSearch] = useState(false); 
  const [venuedata, setvenuedata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedItems, setselectedItems] = useState([]);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [countries, setCountries] = useState([]);  
  const [regions, setRegions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);

  // useEffect(() => {
  //   venueManagment();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (currentPage == 1) {
  //     venueManagment();
  //     return;
  //   }
  //   setCurrentPage(1);
  // }, [sort, sortOrder, sizePerPageOrg]);

  // useEffect(() => {  
  //   if(!resetSearch) return;
  //   if (currentPage == 1) {
  //     venueManagment([], true);
  //     return;
  //   }
  //   setCurrentPage(1);    
  // }, [resetSearch]);

  useEffect(() => {
    const fetchData = () => {
      if (resetSearch) {
        venueManagment([], true);
      } else {
        venueManagment();
      }
    };

    fetchData();
  }, [currentPage, sort, sortOrder, sizePerPageOrg, resetSearch]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [sort, sortOrder, sizePerPageOrg]);

  const venueManagmentSearch = async () => {
    if(currentPage == 1){
      venueManagment();
      return;
    }
    setCurrentPage(1);
  };




  const handleDownload = async () => {
    try {
      const response = await callCSVRequest("venue/get-csv")

      //console.log('response', response)

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response], { type: 'text/csv' });

      // Use FileSaver to save the file
      saveAs(blob, 'venue-data.csv');
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;   

    const res = await putRequest("venue/update-by-field", {
      venueId: actId,
      isPublished: status ? false : true,
    });

    if (res.status == 1) {
      toast.success("Venue Status updated Successfully");
      venueManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const GetValueOnChange2 = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;   

    const res = await putRequest("venue/update-by-field", {
      venueId: actId,
      loginAllowed: status ? false : true,
    });

    if (res.status == 1) {
      toast.success("Venue Status updated Successfully");
      venueManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(selectedList);
    const filterObj = { ...filter };
    filterObj.themes = ids;
    setFilter(filterObj);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(selectedList);
    const filterObj = { ...filter };
    filterObj.music = ids;
    setFilter(filterObj);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(selectedList);
    const filterObj = { ...filter };
    filterObj.features = ids;
    setFilter(filterObj);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(selectedList);
    const filterObj = { ...filter };
    filterObj.cuisines = ids;
    setFilter(filterObj);
  };

  useEffect(() => {
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
    fetchCountry();
    fetchRegion();
    fetchPlan();
  }, []);

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };


  const fetchCountry = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/country/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title, code : item.code};
      });
      setCountries(options);
    }
  };
  const fetchRegion = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/region/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.name };
      });
      setRegions(options);
    }
  };

  const fetchPlan = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("subscription/venue/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, type: item.type };
      });
      setPlans(options);
    }
  };
  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
    
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const processFilter = (filter) => {
    var filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "businessName") {
          var filterObject = {
            businessName: filter["businessName"].trim(),
          };
        } else {
          var filterObject = {
            [key]: filter[key],
          };
        }

        if (
          key === "name" ||
          key === "businessName" ||
          key === "email" ||
          key === "dress_code"
        ) {
          filterObject.type = "regex";
        } else if (key === "isPublished" || key === "homeblockAdded") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        } else if (key === "open") {
          filterObject.type = "is-open";
        } else if (
          key === "themes" ||
          key === "music" ||
          key === "features" ||
          key === "cuisines"
        ) {
          filterObject.type = "in";
        } else if (key === "startCreatedAt") {
          filterObject.type = "date-range-start";
          filterObject.name = "createdAt";
        } else if (key === "endCreatedAt") {
          filterObject.type = "date-range-end";
          filterObject.name = "createdAt";
        } else if (key === "country" || key === "region" || key === "plan") {
          filterObject.type = "eq";
        } 
        filterArgs.push(filterObject);
      }
    });

    return filterArgs;
  };

  const applyFilter = () => {
    let filterArgs = [];
    filterArgs = processFilter(filter);
    toggleModel();
    venueManagment(filterArgs);
  };

  const resetFilter = () => {
    setSelectedTheme([]);
    setSelectedMusic([]);
    setSelectedFeature([]);
    setSelectedCuisine([]);
    setFilter({ ...filterObj });
    toggleModel();
    venueManagment([], true);
  };   
  

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.venueLogo}
          onClick={() => showLightBox(row.venueLogo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function HomeblockFormatter(cellContent, row) {
    // Assuming row.homeblockDetails is the object containing the homeblockDetails array
    let { homeblockDetails } = row?.homeblockDetails || {};

    if(!homeblockDetails) return null;
  
    return (
      <div>
        {homeblockDetails?.map((detail, index) => (
          <div key={index} style={{ margin: "5px 0" }}>
            {`${detail.title} - ${detail.currentOrder}`}
          </div>
        ))}
      </div>
    );
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };
  

  function venueFormatter(cellContent, row) {
    return (
      <div style={{}}>
        <p>{row?.name}</p>
        {row?.homeblock && (
          <div
            class="badge"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
            }}
          >
            HomeBlock
          </div>
        )}
      </div>
    );
  }

  function dateFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        {convertTimeToFormattedString(row.createdAt)}
      </div>
    );
  }

  function SNoFormatter(cellContent, row, rowIndex) {
    let firstItemIndex;

      //console.log("totalPage", sortOrder)
  
      // if (sortOrder === 'desc') {
      //   // Calculate the first item index on the current page in descending order

      //   //console.log("totalPage", totalPage)

      //   firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      // } else {
      //   // Calculate the first item index on the current page in ascending order
      //   firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
        
      // }

      if (sortOrder === 'desc') {
        // Calculate the first item index on the current page in descending order
        firstItemIndex = totalPage - ((currentPage - 1) * sizePerPageOrg);
      } else {
        // Calculate the first item index on the current page in ascending order
        firstItemIndex = ((currentPage - 1) * sizePerPageOrg) + 1;
      }

      // Adjust the rowIndex to calculate the actual item index
      const itemIndex = sortOrder === 'desc' ? firstItemIndex - rowIndex : firstItemIndex + rowIndex;


      return <>{itemIndex}</>;
  }

  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.isPublished == true ? true : false; // Invert the status
      GetValueOnChange(newStatus, row?._id);
    };

    return (
      <label>
        <Toggle
          defaultChecked={row.isPublished === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br></br>
        {row.isPublished === true ? "Published" : "Unpublished"}
      </label>
    );
  }

  function activeFormatterLogin(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.loginAllowed == true ? true : false; // Invert the status
      GetValueOnChange2(newStatus, row?._id);
    };

    return (
      <label>
        <Toggle
          defaultChecked={row.loginAllowed === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br></br>
        {row.loginAllowed === true ? "Active" : "Deactive"}
      </label>
    );
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.name}
          onChange={(e) => updateFilter("name", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

   


    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Start Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.startCreatedAt}
          onChange={(e) => updateFilter("startCreatedAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

  <div className="row mb-4" key="title">
  <label className="col-sm-3 col-form-label">End Date</label>
  <div className="col-sm-6">
    <input
      type="date"
      value={filter.endCreatedAt}
      onChange={(e) => updateFilter("endCreatedAt", e.target.value)}
      name="createdAt"
      className="form-control"
    />
  </div>
  </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
  
    {
      dataField: "name",
      formatter: venueFormatter,
      text: "Venue Name",
      sort: false,
    },
   
    
    {
      dataField: "venueLogo",
      text: "Logo",
      formatter: ImgLogoFormatter,
      sort: false,
    },   

    {
      dataField: "totalImpressions",
      text: "Total Impressions",
      sort: false,
    },
    {
      dataField: "totalViews",
      text: "Total Views",
      sort: false,
    }, 
   
    {
      dataField: "totalClicks",
      text: "Total Clicks",
      sort: false,
    }, 
    {
      dataField: "totalSearches",
      text: "Total Searches",
      sort: false,
    }, 
    {
      dataField: "uniqueViews",
      text: "Unique Views",
      sort: false,
    }, 
    {
      dataField: "viewClickRatio",
      text: "View Click Ratio",
      sort: false,
    }, 

  
  
   
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText(""); 
  };
 
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >
        <Tippy content="Venue Analytic">
            <button type="button" className=" btn btn-warning mx-1">
              <Link
                to={{
                  //  pathname: `/venue-analytic/${row._id}`,
                   pathname: `/venue-analytic`,
                   state: { row },
                }}
              >
                <i className="fal fa-analytics fs-5 text-light"></i>
                
              </Link>
            </button>
          </Tippy>
          &nbsp;&nbsp;
          
       
          {permissions.update && (
          <Tippy content="Venue Account">
            <button type="button" className=" btn btn-primary  m-1">
              {" "}
              <Link
                to={{
                  pathname: `/updateCredential/${row._id}`,
                  state: { row },
                }}
              >
                {" "}
                <i class="fal fa-retweet fs-5 text-light"></i>
              </Link>{" "}
            </button>
          </Tippy>
          )}
          {permissions.update && (
          <Tippy content="Edit">
            <button type="button" className=" btn btn-info  m-1">
              {" "}
              <Link
                to={{
                  pathname: `/updateVenue/${row._id}`,
                  state: { row },
                }}
              >
                {" "}
                <i class="fal fa-pencil fs-5 text-light"></i>
              </Link>{" "}
            </button>
          </Tippy>
          )}
          {permissions.delete && (
          <Tippy content="Delete">
            <button
              type="button"
              className="btn m-1 btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          )}
        </div>
      </React.Fragment>
    );
  }

  const venueManagment = async (filterArgs = [], reset = false) => {    
    setLoader(true);

    let mainFilter = { ...filter };

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    //

    if (filterArgs.length) {
      mainFilter = filterArgs;
    } else {
      mainFilter = processFilter(mainFilter);
    }

    if (reset) {
      mainFilter = [];
    }

    mainFilter = mainFilter?.filter((arg) => {
      const key = Object.keys(arg)[0];
      return arg[key].length !== 0;
    });

    if (mainFilter?.length) {
      payload.filterArgs = mainFilter;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }
    try {
      const response = await postRequest(`log/list-venue`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setvenuedata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this venue",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id) => {
    const response = await deleteRequest(`venue/delete`, { venueID: id });
    if (response.status) {
      // If the update is successful, call the pushUpdates function
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "venue",
          id: id,
          receivers: [],
        });
        // Check if the pushUpdates call was successful, handle the response as needed        
        Swal.fire("Deleted!", "Deleted successfully");
        venueManagment();
      } catch (pushUpdatesError) {
        console.error("Error in pushUpdates:", pushUpdatesError);
        toast.error("Error in pushUpdates");
      }
    } else {

      Swal.fire({
        title: "Venue Contains Order",
        text: "This venue may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction2(id);
          
        }
      });
    }
  };

  const deleteFunction2 = async (id) => {
    const response = await deleteRequest(`venue/delete`, { venueID: id, isForced: true });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  };  

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You want to delete this venue",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("venue/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      venueManagment();
      setselectedItems([]);
    } else {
      Swal.fire({
        title: "Venue Contains Order",
        text: "This venue may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction3(ids);
          
        }
      });
    }
  };

  const deleteFunction3 = async (ids) => {
    const response = await deleteRequest(`venue/delete`, { ids: ids, isForced: true });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  }; 

  const selectRow = {
    // mode: "checkbox", // or 'radio' for single selection
    // clickToSelect: false, // enable click to select
    // style: { background: "#c8e6c9" }, // background color for selected rows
    // onSelect: (row, isSelect, rowIndex, e) => {
    //   if (isSelect) {
    //     setselectedItems([...selectedItems, row._id]);
    //   } else {
    //     setselectedItems(selectedItems.filter((x) => x !== row._id));
    //   }
    // },
    // onSelectAll: (isSelect, rows, e) => {
    //   if (!isSelect) {
    //     setselectedItems([]);
    //     return;
    //   }

    //   const ids = rows.map((r) => r._id);
    //   setselectedItems(ids);
    // },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Venues List</h4>

             
            </div>
          </Col>
          <div className="col-md-2 text-end">
          {/* <Link to="/venueDelete">
              {" "}
              <Button style={{ backgroundColor: '#ec536c' }}>
                {" "}
                <i class="fas fa-trash"></i> Deleted List
              </Button>{" "}
            </Link> */}
            &nbsp;&nbsp;&nbsp;
            {permissions.create && 
              (
                <Link to="/createVenue">
                  {" "}
                  <Button>
                    {" "}
                    <i class="fas fa-plus"></i> Create
                  </Button>{" "}
                </Link>
                  )
                }
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={venuedata}
                            columns={columns}
                            exportCSV={{
                              fileName: 'venue-data.csv',
                              separator: ',',
                              ignoreHeader: false,
                              noAutoBOM: false,
                            }}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                 
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        venueManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>

                                  
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">                                 
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => (
                                      <div>No data available</div>
                                    )}
                                    remote={true}
                                    // selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>

                                { totalPage > sizePerPageOrg ? (
                                   <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ sizePerPageOrg }</b> From <b>{totalPage}</b> </p> 
                                ) : (
                                  <p style={{ marginTop: "12px", fontSize: "1rem" }}> Showing <b>{ totalPage }</b> From <b>{totalPage}</b> </p>
                                ) }
                               
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default VenueManagementlist;
