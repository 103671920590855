import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class SalesAnalytics extends Component {
    render() {
        const { active, inactive } = this.props.data || {};

        //console.log("this.props.data", active, inactive);

        const series = [active, inactive];
        const total = active + inactive;
        const activePercentage = ((active / total) * 100).toFixed(2);
        const inactivePercentage = ((inactive / total) * 100).toFixed(2);

        const options = {
            labels: ["Active", "Inactive"],
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#3DDC84', '#5664d2'],
        };

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Active & Inactive Subscriptions</h4>

                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={options} series={series} type="donut" height="250" />
                        </div>

                        <Row>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 me-1"></i>Active</p>
                                    <h5>{activePercentage} %</h5>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-4">
                                    <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 me-1"></i>Inactive</p>
                                    <h5>{inactivePercentage} %</h5>
                                </div>
                            </Col>                            
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;
