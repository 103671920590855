import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { postRequest,customValidator } from "../../components/Common/Utils.js";

export default function CreateMusic() {
  const [musicData, setMusicData] = useState({ title: "" });
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const updateMusicData = async (e) => {
    e.preventDefault();

    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
      ],
      musicData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }
       

    setLoading(true);

    const dataObj = {
      id: musicData._id,
      title: musicData.title,
    };

    const response = await postRequest("venue/music/add", dataObj);

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/music");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...musicData };
    list[index] = value;
    setMusicData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Music Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Music Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Music create</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>   

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Music Create </h4>
                <Form onSubmit={updateMusicData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              defaultValue={musicData?.title}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
