import React, { Component } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.css";



export class Vectormap extends Component {
  render() {
    return (
      <div id="asia-vectormap" style={{ height: "400px", width: "90%" }}>
      <VectorMap
          map={'asia_mill'}
          backgroundColor="#ffffff"
          containerStyle={{
              width: '100%',
              height: '100%',
          }}
          regionStyle={{
              initial: {
                  fill: '#e8ecf4',
              },
              hover: {
                  fill: "#c9dfaf"
              },
              selected: {
                  fill: '#F4A582'
              },
              selectedHover: {
                  fill: '#8E8E38'
              },
          }}
          series={{
              regions: [
                  {
                      values: {
                          AE: '#FFCC00', // UAE
                          SA: '#FF6600', // KSA
                      },
                      attribute: 'fill',
                  },
              ],
          }}
      />
  </div>
    );
  }
}

export default Vectormap;
