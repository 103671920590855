import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import ReactCrop from "react-image-crop";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { postRequestForm } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload";

import { canvasPreview } from "../VenueManagement/canvasPreview.js";
import { useDebounceEffect } from "../VenueManagement/useDebounceEffect.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const defaultPackageData = {
    type: "pay-as-you-go",
    isMessangingAllowed: false,
    isBookingAllowed: false,
    offerAddCharge: 0,
    offerAddFree: 0,
    commissionOnOfferPurchase: 0,
    isAddStoryAllowed: false,
    addStoryCharge: 0,
    addStoryFree: 0,
    isPromoteTop5: false,
    isManageBy7n: false,
    haveAnalytics: false,
    amount: 0,
    validity: "monthly",
    isPublished: true,
    };

export default function CreatePackage() {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  const [isPopular, setIsPopular] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [packageData, setPackageData] = useState(defaultPackageData);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const blobUrlRef = useRef("");

  let history = useHistory();

  const showLightBox = (previewLogo) => {
    setPreviewlogo(previewLogo);
    setOpenLightBox(true);
  };

  const Createpackage = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    if (!packageData?.type) {
      toast.error("Type is required");
      return;
    }
    if (typeof packageData?.isMessangingAllowed === 'undefined') {
      toast.error("Messaging Allowed field is required");
      return;
    }
    if (typeof packageData?.isBookingAllowed === 'undefined') {
      toast.error("Booking Allowed field is required");
      return;
    }
    if (typeof packageData?.isAddStoryAllowed === 'undefined') {
      toast.error("Add Story Allowed field is required");
      return;
    }
    if (typeof packageData?.isPromoteTop5 === 'undefined') {
      toast.error("Promote Top 5 field is required");
      return;
    }
    if (typeof packageData?.isManageBy7n === 'undefined') {
      toast.error("Managed by 7N field is required");
      return;
    }
    if (typeof packageData?.haveAnalytics === 'undefined') {
      toast.error("Analytics field is required");
      return;
    }
    if (!packageData?.amount) {
      toast.error("Please enter amount");
      return;
    }
    if (!packageData?.validity) {
      toast.error("Please enter validity");
      return;
    }
  
    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("backgroundImage", remoteLogo);
  
    setStartLoader(true);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    setStartLoader(false);
  
    formData.append("type", packageData.type);
    formData.append("isMessangingAllowed", packageData.isMessangingAllowed);
    formData.append("isBookingAllowed", packageData.isBookingAllowed);
    formData.append("offerAddCharge", packageData.offerAddCharge);
    formData.append("offerAddFree", packageData.offerAddFree);
    formData.append("commissionOnOfferPurchase", packageData.commissionOnOfferPurchase);
    formData.append("isAddStoryAllowed", packageData.isAddStoryAllowed);
    formData.append("addStoryCharge", packageData.addStoryCharge);
    formData.append("addStoryFree", packageData.addStoryFree);
    formData.append("isPromoteTop5", packageData.isPromoteTop5);
    formData.append("isManageBy7n", packageData.isManageBy7n);
    formData.append("haveAnalytics", packageData.haveAnalytics);
    formData.append("amount", packageData.amount);
    formData.append("validity", packageData.validity ? packageData.validity : "monthly");
    formData.append("isPublished", packageData.isPublished);
  
    if (selectedImageRes?.data?.url) {
      formData.append("backgroundImage", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
  
    setLoading(true);
    const response = await postRequestForm(
      "subscription/venue/create",
      formData
    );
    setLoading(false);
  
    if (response.status === 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/venue/package");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };
  
  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], "bk-image.jpg", { type: "image/jpeg" });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleIsPopularChange = (event) => {
    setIsPopular(event.target.checked);
  };

  const updateSquareCrop = (crop) => {
    const size = Math.max(crop.width, crop.height);
    setCrop({ ...crop, width: size, height: size });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Package Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Package Management</li>:{" "}
              <li className="breadcrumb-item">Create Package</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Package </h4>

                <Form onSubmit={Createpackage}>
                    <Row>
                        <Col lg={12} className="ms-lg-auto">
                        <div className="mt-5 mt-lg-4">
                            {/* Type */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Type</label>
                            <div className="col-sm-6">
                                <select
                                className="form-control"
                                onChange={(e) => updateValue("type", e.target.value)}
                                name="type"
                                class="form-select"
                                required
                                >
                                <option value="pay-as-you-go">Pay as you go</option>
                                <option value="basic">Basic</option>
                                <option value="vip">VIP</option>
                                <option value="platinum">Platinum</option>
                                </select>
                            </div>
                            </div>
                            {/* isMessagingAllowed */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Messaging Allowed</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isMessangingAllowed}
                                    onChange={(e) => updateValue("isMessangingAllowed", e.target.checked)}
                                    color="primary"
                                    name="isMessangingAllowed"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* isBookingAllowed */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Booking Allowed</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isBookingAllowed}
                                    onChange={(e) => updateValue("isBookingAllowed", e.target.checked)}
                                    color="primary"
                                    name="isBookingAllowed"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* offerAddCharge */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Offer Add Charge</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("offerAddCharge", e.target.value)}
                                name="offerAddCharge"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* offerAddFree */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Offer Add Free</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("offerAddFree", e.target.value)}
                                name="offerAddFree"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* commissionOnOfferPurchase */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Commission on Offer Purchase</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("commissionOnOfferPurchase", e.target.value)}
                                name="commissionOnOfferPurchase"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* isAddStoryAllowed */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Add Story Allowed</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isAddStoryAllowed}
                                    onChange={(e) => updateValue("isAddStoryAllowed", e.target.checked)}
                                    color="primary"
                                    name="isAddStoryAllowed"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* addStoryCharge */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Add Story Charge</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("addStoryCharge", e.target.value)}
                                name="addStoryCharge"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* addStoryFree */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Add Story Free</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("addStoryFree", e.target.value)}
                                name="addStoryFree"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* isPromoteTop5 */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Promote Top 5</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isPromoteTop5}
                                    onChange={(e) => updateValue("isPromoteTop5", e.target.checked)}
                                    color="primary"
                                    name="isPromoteTop5"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* isManageBy7n */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Managed by 7N</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isManageBy7n}
                                    onChange={(e) => updateValue("isManageBy7n", e.target.checked)}
                                    color="primary"
                                    name="isManageBy7n"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* haveAnalytics */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Analytics</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.haveAnalytics}
                                    onChange={(e) => updateValue("haveAnalytics", e.target.checked)}
                                    color="primary"
                                    name="haveAnalytics"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>
                            {/* amount */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Amount</label>
                            <div className="col-sm-6">
                                <input
                                type="number"
                                min={0}
                                onChange={(e) => updateValue("amount", e.target.value)}
                                name="amount"
                                className="form-control"
                                />
                            </div>
                            </div>
                            {/* validity */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Validity</label>
                            <div className="col-sm-6">
                                <select
                                className="form-control"
                                onChange={(e) => updateValue("validity", e.target.value)}
                                name="validity"
                                class="form-select"
                                >
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="yearly">Yearly</option>
                                </select>
                            </div>
                            </div>
                            {/* isPublished */}
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Published</label>
                            <div className="col-sm-6">
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={packageData?.isPublished }
                                    defaultChecked={true}
                                    onChange={(e) => updateValue("isPublished", e.target.checked)}
                                    color="primary"
                                    name="isPublished"
                                    />
                                }
                                label="Yes"
                                />
                            </div>
                            </div>                         

                            {/* Submit button */}
                            <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                                <div className="col-sm-6">
                                <div>
                                    <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                    >
                                    Submit
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </Col>  
                    </Row>  
                </Form>                
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
