import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function CreateBannerType() {
  const location = useLocation();
  const state = location.state?.row; 

  const [bannertypeData, setBannertypeData] = useState(null);   
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  const { id } = useParams();       

  const Createbannertype = async (e) => {
    e.preventDefault();
   setLoading(true);
  
    const formData = new FormData();   
    formData.append("title", bannertypeData.title);   
   
    
    const response = await postRequestForm("banner/type/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/bannerType");
      }, 3000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...bannertypeData}
    list[index] = value
    setBannertypeData(list)
    
  }    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Banner Type Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Banner Type Management</li>:{" "}
              <li className="breadcrumb-item">
              Create Banner Type
              </li>
            </ol>
          </div>
        </div>
      </div>     
      
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Create Banner Type</h4>
              <Form onSubmit={Createbannertype}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                    <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text"  onChange={ (e) => updateValue('title', e.target.value) } value={bannertypeData?.title} name='title'  className="form-control"  />
                            </div>
                        </div>              
             
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>     

    </React.Fragment>

  );
}
