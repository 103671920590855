import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import noImage from "../../assets/images/No_Image_Available.jpg";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "styled-components";

import Story from "./Story";

const useStyles = makeStyles((theme) => ({
  appBar: {
    maxWidth: "700px",
    margin: "0 auto",
    borderRadius: "10px",
    backgroundColor: "#F9FBFC", // Customize the background color of AppBar
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ffffff", // Customize the indicator color
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ overflow: "hidden" }} // Set overflow to hidden
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const PreviewAd = ({ currentForm, onTabChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("large" || currentForm?.size);

  const [value, setValue] = React.useState(0); // Use value to track the active tab

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Update the active tab based on the selected index
    if (newValue === 0) {
      setActiveTab("large");
      onTabChange("large");
    } else if (newValue === 1) {
      setActiveTab("medium");
      onTabChange("medium");
    } else if (newValue === 2) {
      setActiveTab("small");
      onTabChange("small");
    }

    // Call the callback to pass the activeTab value to the parent component
  };

  const size = currentForm?.size ? currentForm.size : "large";

  const getSizeStyles2 = () => {
    if (value === 0) return { width: "260px", height: "245px" };
    else return { width: "205px", height: "200px" };
  };
  const getSizeStyles3 = () => {
    if (value === 1) return { width: "260px", height: "207px" };
    else return { width: "205px", height: "200px" };
  };
  const getSizeStyles4 = () => {
    if (value === 2) return { width: "460px", height: "200px" };
    else return { width: "405px", height: "400px" };
  };

  const getSizeStyles = () => {
    if (size === "large") return { width: "360px", height: "350px" };
    else return { width: "320px", height: "300px" };
  };

  return (
    <div className="notification-preview-container">
      <AppBar
        style={{
          maxWidth: "700px",
          margin: "auto",
          borderRadius: "10px",
          background: "#1A76D2",
        }}
        position="static"
        className={classes.appBar + " mb-3"}
      >
       
      </AppBar>
      {value === 0 && (
        // Render the content for the "Classic" tab here
        <TabPanel value={value} index={0} dir={theme?.direction}>
          <div
            style={{
              height: "700px",
              background: "linear-gradient(180deg, rgba(44, 9, 74, 1) 0%, rgba(8, 11, 4, 1) 90%, #B9158B 100%)",
              margin: "0 auto",
              padding: "16px 0px",
            }}
          >
            <div className="col-lg-12">
              <div style={{width: "auto"}}><Story /> </div>
              
            
              {/* <div
                className={`box-custom ${
                  currentForm.background ? "bgcard" : ""
                }`}
                style={{
                  ...getSizeStyles2(),
                  border: "1px solid #ccc",
                  backgroundImage: `url(${currentForm.background}), linear-gradient(to right, #e66465, #9198e5)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                
               
                <div style={{ marginTop: "24px" }}>
                  {currentForm.badgeText && (
                    <div className="badge-deal">{currentForm.badgeText}</div>
                  )}

                  

                  <h5
                    className="title"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "36px",
                    }}
                  >
                    {currentForm.title ? currentForm.title : "Title"}
                  </h5>
                  <h4
                    className="subtitle"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "24px",
                      lineHeight: "36px",
                      fontWeight: "bold",
                    }}
                  >
                    {currentForm.description
                      ? currentForm.description
                      : "Description"}
                  </h4>
                </div>

                <div className="bottom-footer">
                  <div
                    className="d-flex align-items-center deal-footer"
                    style={{ padding: "12px 15px" }}
                  >
                    <div
                      className="logo"
                      style={{
                        background: "inherit",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <img
                        src={currentForm.logo || noImage}
                        alt="logo"
                        className="rounded-circle"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <div
                      style={{ width: "60%", padding: "0px", margin: "0px" }}
                    >
                      <h3
                        style={{
                          fontSize: "15px",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subHeading
                          ? currentForm.subHeading
                          : "Select"}
                      </h3>
                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subText ? currentForm.subText : "Address"}
                      </p>
                    </div>
                    <button
                      style={{
                        background:
                          "linear-gradient(98.93deg, #F30092 20.72%, #9026E3 151.56%, #9026E3 151.56%)",
                        width: "51.63px",
                        height: "24.35px",
                        marginLeft: "8%",
                        borderRadius: "8.77px",
                        border: "none",
                        cursor: "pointer",
                      }}
                      className="custom-button"
                    >
                      {currentForm.buttonText?.length > 4
                        ? currentForm.buttonText.substring(0, 4)
                        : currentForm.buttonText}
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </TabPanel>
      )}
      {value === 1 && (
        // Render the content for the "Feed" tab here
        <TabPanel value={value} index={1} dir={theme?.direction}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
              background: "#ccc",
            }}
          >
            <div className="col-lg-12">
              <div
                className={`box-custom ${
                  currentForm.background ? "bgcard" : ""
                }`}
                style={{
                  ...getSizeStyles3(),
                  border: "1px solid #ccc",
                  backgroundImage: `url(${currentForm.background}), linear-gradient(to right, #e66465, #9198e5)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <div style={{ marginTop: "24px" }}>
                  {/* {currentForm.badgeText && (
                    <div className="badge-deal">{currentForm.badgeText}</div>
                  )} */}

                  <h5
                    className="title"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "36px",
                    }}
                  >
                    {currentForm.title ? currentForm.title : "Title"}
                  </h5>
                  {/* <h4
                  className="subtitle"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    lineHeight: "36px",
                    fontWeight: "bold",
                  }}
                >
                  {currentForm.description
                    ? currentForm.description
                    : "Description"}
                </h4> */}
                </div>

                <div
                  className="bottom-footer"
                  style={{ position: "absolute", bottom: 0, width: "93%" }}
                >
                  <div
                    className="d-flex align-items-center deal-footer"
                    style={{ padding: "12px 15px" }}
                  >
                    <div
                      className="logo"
                      style={{
                        background: "inherit",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <img
                        src={currentForm.logo || noImage}
                        alt="logo"
                        className="rounded-circle"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <div
                      style={{ width: "60%", padding: "0px", margin: "0px" }}
                    >
                      <h3
                        style={{
                          fontSize: "15px",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subHeading
                          ? currentForm.subHeading
                          : "Select"}
                      </h3>
                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subText ? currentForm.subText : "Address"}
                      </p>
                    </div>
                    {/* <button
                    style={{
                      background:
                        "linear-gradient(98.93deg, #F30092 20.72%, #9026E3 151.56%, #9026E3 151.56%)",
                      width: "51.63px",
                      height: "24.35px",
                      marginLeft: "8%",
                      borderRadius: "8.77px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    className="custom-button"
                  >
                    {currentForm.buttonText?.length > 4
                      ? currentForm.buttonText.substring(0, 4)
                      : currentForm.buttonText}
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      )}
      {value === 2 && (
        // Render the content for the "Feed" tab here

        <TabPanel value={value} index={2} dir={theme?.direction}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "700px",
              background: "#ccc",
            }}
          >
            <div className="col-lg-12">
              <div
                className={`box-custom ${
                  currentForm.background ? "bgcard" : ""
                }`}
                style={{
                  ...getSizeStyles4(),
                  border: "1px solid #ccc",
                  background: currentForm.background || "#ffffff",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <div className="mt-0">
                  {/* {currentForm.badgeText && (
                  <div className="badge-deal">{currentForm.badgeText}</div>
                )} */}
                  <div
                    className="bottom-footer"
                    style={{ position: "absolute", bottom: 0, width: "93%" }}
                  >
                    {" "}
                    <h5
                      className="title"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "36px",
                      }}
                    >
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>
                    <h4
                      className="subtitle"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        lineHeight: "36px",
                        fontWeight: "bold",
                      }}
                    >
                      {currentForm.description
                        ? currentForm.description
                        : "Description"}
                    </h4>
                  </div>

                  <div
                    className="d-flex align-items-center deal-footer"
                    style={{ padding: "12px 15px" }}
                  >
                    <div
                      className="logo"
                      style={{
                        background: "inherit",
                        padding: "0px",
                        margin: "0px 5px 0 0",
                      }}
                    >
                      <img
                        src={currentForm.logo || noImage}
                        alt="logo"
                        className="rounded-circle"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <div
                      style={{ width: "60%", padding: "0px", margin: "0px" }}
                    >
                      <h3
                        style={{
                          fontSize: "15px",
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subHeading
                          ? currentForm.subHeading
                          : "Select"}
                      </h3>
                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {currentForm.subText ? currentForm.subText : "Address"}
                      </p>
                    </div>
                    {/* <button
                    style={{
                      background:
                        "linear-gradient(98.93deg, #F30092 20.72%, #9026E3 151.56%, #9026E3 151.56%)",
                      width: "51.63px",
                      height: "24.35px",
                      marginLeft: "8%",
                      borderRadius: "8.77px",
                      border: "none",
                      cursor: "pointer",
                    }}
                    className="custom-button"
                  >
                    {currentForm.buttonText?.length > 4
                      ? currentForm.buttonText.substring(0, 4)
                      : currentForm.buttonText}
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      )}
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        background: "#ccc",
      }}
    >
      <div className="col-lg-12">
        <div
          className={`box-custom ${currentForm.background ? "bgcard" : ""}`}
          style={{
            ...getSizeStyles(),
            border: "1px solid #ccc",
            backgroundImage: `url(${currentForm.background}), linear-gradient(to right, #e66465, #9198e5)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div className="badge-deal">
            <span>
              {currentForm.badgeText ? currentForm.badgeText : "Badge"}
            </span>
          </div>
          <div className="bottom-footer" style={{ marginTop: "2rem" }}>
            <div className="d-flex align-items-center mb-3">
              <div className="logo">
                <img src={currentForm.logo || noImage} alt="logo" />
              </div>
              <div className="me-3 mx-3">
                <h3
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "400px",
                  }}
                >
                  {currentForm.subHeading ? currentForm.subHeading : "Select"}
                </h3>
                <p
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: "400px",
                  }}
                >
                  {currentForm?.subText ? currentForm?.subText : "Address"}
                </p>
              </div>
            </div>
          </div>

          <div className="bottom-footer">
            <div className="mb-3">
              <h5
                className="title"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "400px",
                }}
              >
                {currentForm.title ? currentForm.title : "Title"}
              </h5>
              <h6
                className="fnt-18"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "bold",
                  size: "20px",
                  lineHeight: "28px",
                  marginTop: "10px",
                }}
              >
                {currentForm.description
                  ? currentForm.description
                  : "Description"}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAd;
