import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { putRequest, customValidator } from "../../components/Common/Utils.js";

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

export default function UpdateFeature() {
  const [featureData, setFeatureData] = useState({ title: "" });
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  useEffect(() => {
    fetchState();
  }, []);

  const fetchState = async () => {
    const data = { ...state };
    setFeatureData(data);
    setLoading(false);
  };

  let search = state._id;
  let finalsearch = search.toString();

  const updateFeatureData = async (e) => {
    e.preventDefault();
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
      ],
      featureData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    setLoading(true);

    const dataObj = {
      featureId: featureData._id,
      title: featureData.title,
    };
    const response = await putRequest("venue/feature/update", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/feature");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...featureData };
    list[index] = value;
    setFeatureData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Feature Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Feature Management</li>:{" "}
              <li className="breadcrumb-item">Feature update</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.push('/feature')}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </Row>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Feature Update </h4>
                <Form onSubmit={updateFeatureData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              defaultValue={featureData.title}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
