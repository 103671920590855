import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  customValidator,
  putRequest,
} from "../../components/Common/Utils.js";

// import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import Lightbox from "react-image-lightbox";
import FileDrop from "components/FileMultipleDrop.js";
import FileUpload from "../../components/Common/FileUpload"; 

export default function CreateEventGallery() {
  const location = useLocation();
  const state = location.state?.row;
  const [eventgalleryData, setEventGalleryData] = useState({ title: "" });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [orgList, setOrgList] = useState([]);

  const [orgId, setOrgId] = useState("");
  const [orgText, setOrgText] = useState("");
  const [orgImage, setOrgImage] = useState("");
  const [model, setModel] = useState(false);

  let history = useHistory();
  const { id } = useParams();

  const handleFileUpload = (url) => {
    setSelectedImage((prevState) => [...prevState, url]);

    //console.log(selectedImage, "selectedImage")

  };

  // useEffect(() => {
  //   if (!selectedImage) return;
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setPreviewImage(reader.result);
  //   };
  //   reader.readAsDataURL(selectedImage);
  // }, [selectedImage]);

  useEffect(() => {    
    if (!state) return;
    setOrgId(state?.organizerId);
    setOrgText(state?.organizerName);
    setOrgImage(state?.organizerLogo);
  }, [state]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };


  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("event/org/list", { limit: 10000000 });
    if (response.status == 1) {
      setOrgList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name,logo) => {
    setOrgId(id);
    setOrgText(name);
    setOrgImage(logo);
    closeModal();
  };

  const Creategallery = async (e) => {
    e.preventDefault();

    if(!orgId){
      toast.error("Please Select Event Organizer");
      return;
    }

    if (!selectedImage) {
      toast.error("Please select image");
      return;
    }

    //console.log(selectedImage, "selectedImage")

    //return;


    

    const x = new FormData();
    // setStartLoader(true);
    x.append("image", selectedImage);
    
    const imgRes = await postRequestForm("comman/img/upload", x);
    // setStartLoader(false);

    // const formData = new FormData();
    // formData.append("eventOrgId", orgId);
    // formData.append("image", imgRes?.data.url);
    // // formData.append("title", eventgalleryData.title);

    const dataObj = {
      eventOrgId: orgId,
      files: selectedImage
    };
    setLoading(true);
    const response = await postRequest("event/org/gallery/create", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success("Event Gallery Created Successfully");
      setTimeout(() => {
        // history.push("/eventGallery");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventgalleryData };
    list[index] = value;
    setEventGalleryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Event Gallery Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Event Gallery Management
              </li>
              : <li className="breadcrumb-item">Create Event Gallery</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Event Gallery </h4>
                <Form onSubmit={Creategallery}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Event Organizer
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {orgText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {orgText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //   <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button class="btn" style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff"   }} type="button" id="chooseOrganizerBtn" onclick={() => toggleModel()}>Choose Event Organizer</button>
                                <div style={{ border: "1px solid #ced4da",width:"16px"}}></div><img src={orgImage} style={{ width: "40px", height: "40px", border: "1px solid #ced4da",borderRight:"none",borderLeft:"none" }}></img>
                                <input type="text" style={{borderLeft:"none"}} class="form-control" readonly value={orgText} id="selectedOrganizer" placeholder="No Event Organizer chosen"/> 
                                </div>

                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Event Organizer
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              <div class="input-group">
                              <button class="btn" style={{ border: "1px solid #ced4da", background: "#E22A2A", color: "#ffff"  }} type="button" id="chooseOrganizerBtn" onclick={() => toggleModel()}>Choose Event Organizer</button>
                              <input type="text" class="form-control" readonly id="selectedOrganizer" placeholder="No Event Organizer chosen"/>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={eventgalleryData?.title}
                              name="buttonText"
                              className="form-control"
                            />
                          </div>
                        </div> */}

                        {/* <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Upload Gallery Image
                          </label>
                          <div className="col-sm-6">
                            <FileDrop
                              accept="image/*"
                              onFileUpload={handleFileUpload}
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      <OrganizerlistModal
        show={model}
        closeModal={closeModal}
        selectRow={selectRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
