import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Button, Spinner } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { postRequest } from "./Utils.js";
import { Link } from "react-router-dom";
import moment from 'moment';
import './ActiveSubscription.css'; // Import custom CSS for overlay effect

const ActiveSubscription = () => {
  const [activePlans, setActivePlans] = useState([]);
  const [loader, setLoader] = useState(false);

  const bId = localStorage.getItem("stored_bussinessId");

  const fetchActivePlans = async () => {
    setLoader(true);
    try {
      const response = await postRequest(`subscription/fetch-venue-subscription`, { bId });
      const data = response?.data;
      if (data != null) {
        setActivePlans(data);
      } else {
        setActivePlans([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchActivePlans();
  }, [bId]);

  const handleRedirect = () => {
    window.location.href = '/business-account/get-subscription'; // Update this URL to your subscription page
  };

  const getRemainingDays = (validTill) => {
    const now = moment();
    const end = moment(validTill);
    return end.diff(now, 'days');
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {loader ? (
        <CardBody style={{ height: "100px" }}>
          <Spinner
            color="info"
            style={{
              height: "4rem",
              width: "4rem",
              position: "absolute",
              left: "50%",
            }}
          >
            Loading...
          </Spinner>
        </CardBody>
      ) : (
        <Row>
          {activePlans.length > 0 ? (
            activePlans.map((plan, index) => (
              <Col md="3" key={index} className="mb-4">
                <Card className="" style={{ fontFamily: 'Arial, sans-serif', borderRadius: '0.5rem' }}>
                  <div className="custom-card-background" style={{ backgroundImage: `url(${plan.venue.cover})`, borderRadius: '0.5rem' }}>
                    <div className="custom-card-overlay"></div>
                    <CardBody className="custom-card-body" style={{ color: "#fff"}}>
                      <h4 className="card-title mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{plan.venue.name}</h4>
                      <h5 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Plan: {plan.subscription.type.toUpperCase()}</h5>
                      <p className="" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        Next Payment Date: {moment(plan.validTill).format('MMMM Do, YYYY')}
                      </p>
                      <p className="" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        Days Remaining: {getRemainingDays(plan.validTill)} Days
                      </p>
                      <p className="" style={{ fontSize: '1rem', margin: '10px 0' }}>
                        Total Cost: {plan.amount} / {plan.validity}
                      </p>
                      <Link to="/business-account/my-subscription" className="text-primary learn-more-link" style={{ fontSize: '1rem' }}>
                        Learn more <i className="mdi mdi-chevron-double-right"></i>
                      </Link>
                    </CardBody>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <Col md="3">
              <Card className="" style={{ fontFamily: 'Arial, sans-serif', borderRadius: '0.5rem' }}>
                <CardBody className="custom-card-body" style={{ color: "#fff"}}>
                  <h4 className="card-title mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: "#fff" }}>No Active Subscription</h4>
                  <div className="card-statistics">
                    <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>No Plan</h3>
                    <p className="text-muted" style={{ fontSize: '1rem', margin: '10px 0' }}>
                      You currently do not have an active subscription plan. Please subscribe to a plan to enjoy our services.
                    </p>
                    <Button color="primary" onClick={handleRedirect} style={{ fontSize: '1rem' }}>
                      Go to Subscription Page
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}
    </React.Fragment>
  );
};

export default ActiveSubscription;
