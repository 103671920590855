import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm  
} from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload";
import IconPackModal from "components/Modal/IconPackModal.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import DayTimePicker from "./DayTimePicker";

import LocationPicker from "react-location-picker";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

import BusinessModal from "components/Modal/BusinessModal";
export default function UpdateVenue() {
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [venueData, setVenueData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loading, setLoading] = useState(false);
  const [verifyId, setVerifyId] = useState("");
  const [verifyList, setVerifyList] = useState([]);

  const [countries, setCountries] = useState([]);  
  const [regions, setRegions] = useState([]);

  const [startLoader, setStartLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [lockSubmit, setLockSubmit] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultBadge, setDefaultBadge] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [completedCrop, setCompletedCrop] = useState(null);

  const [selectedLogo, setSelectedLogo] = useState("");

  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);


  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);

  const [rectangleSelected, setrectangleSelected] = useState(0);
  const [squareSelected, setSquareSelected] = useState(0);
  const [storySelected, setStorySelected] = useState(0);

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });


  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });


  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1; // Desired aspect ratio (1:1)

    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }

    setCropSquare(crop);
  };

  const [selectedCover, setSelectedCover] = useState("");
  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);

  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);

  const [rectangleImagePreview, setRectangleImagePreview] = useState(state?.rectangleImage || null);
  const [squareImagePreview, setSquareImagePreview] = useState(state?.squareImage || null);
  const [storyImagePreview, setStoryImagePreview] = useState(state?.storyImage || null);


  const [remoteLogo, setRemoteLogo] = useState(null);
  const [businessOptions, setBusinessOptions] = useState([
    { value: "", label: "None" },
  ]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinessData, setSelectedBusinessData] = useState([]);

  const [iconModal, setIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(0);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const fetchCountry = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/country/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title, code : item.code};
      });
      setCountries(options);
    }
  };

  const fetchRegion = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("comman/region/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.name };
      });
      setRegions(options);
    }
  };
  const fetchVerifyList = async () => {
    setLoading(true);
    const response = await postRequest("venue/verify/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setVerifyList(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCountry();
    fetchRegion();
    fetchVerifyList();
  }, []);


  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };
 
  const msUpdateBadge = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedBadge(ids);
  };

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTheme(ids);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCuisine(ids);
  };

  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };


  let history = useHistory();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const locationArray = state?.location?.coordinates;
    state.latitude = locationArray[1];
    state.longitude = locationArray[0];
    state.business_id = state.business._id;
    setCurrentLocation({
      lat: parseFloat(state.latitude),
      lng: parseFloat(state.longitude),
    });

    //console.log("state", state)

    setVenueData(state);
    setBusinessId(state?.business?._id)
    setBusinessText(state?.business?.name)
    setBusinessImage(state?.business?.logo)
    setPreviewCover(state?.cover ? state?.cover : "");
    setPreviewLogo(state?.logo ? state?.logo : "");
    // setRectangleImagePreview(state?.rectangleImage ? state?.rectangleImage : "");
    // setSquareImagePreview(state?.squareImage ? state?.squareImage : "");
    // setStoryImagePreview(state?.storyImage ? state?.storyImage : "");

    if (state?.promo?.length) {
      const features = state?.promo?.map((item) => {
        return {
          image: item.image,
          title: item.title,
          discountTag: item.discountTag,
          saved: true,
        };
      });
      setPackages([...features]);
    }

    if (state?.timings && state?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      state?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));

        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });

      setTimeOptions(newTimeOptions);
    }

    setSelectedBadge(state?.badges ? state?.badges : []);
    setSelectedTheme(state?.themes ? state?.themes : []);
    setSelectedMusic(state?.music ? state?.music : []);
    setSelectedFeature(state?.features ? state?.features : []);
    setSelectedCuisine(state?.cuisines ? state?.cuisines : []);

  }, [state]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

    const selectRow = (id, name, logo) => {

    setBusinessId(id?._id);
    setBusinessText(id?.name);
    setBusinessImage(id?.logo);
    setSelectedBusinessData(id);
    closeModal();
  };

  useEffect(() => {
    fetchTheme();
  }, [selectedTheme]);

  useEffect(() => {
    fetchBadge();
  }, [selectedBadge]);

  useEffect(() => {
    fetchMusic();
  }, [selectedMusic]);

  useEffect(() => {
    fetchFeature();
  }, [selectedFeature]);

  useEffect(() => {
    fetchCuisine();
  }, [selectedCuisine]);

  

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };



  const handleRectangleImageChange = (e) => {
    const file = e.target.files[0];
    setRectangleImage(file);
    setRectangleImagePreview(URL.createObjectURL(file));
  };
  
  const handleSquareImageChange = (e) => {
    const file = e.target.files[0];
    setSquareImage(file);
    setSquareImagePreview(URL.createObjectURL(file));
  };
  
  const handleStoryImageChange = (e) => {
    const file = e.target.files[0];
    setStoryImage(file);
    setStoryImagePreview(URL.createObjectURL(file));
  };

  

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], venueData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
      
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;    
    const venueDt = { ...venueData, latitude: lat, longitude: lng };
    setVenueData(venueDt);
  };

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setThemeList(options);

      if (selectedTheme.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedTheme.includes(item.id);
        });        
        setDefaultTheme(defaultItems);
      }
    }
  };

  const fetchBadge = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("venue/badge/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setBadgeList(options);

      if (selectedBadge.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedBadge.includes(item.id);
        });        
        setDefaultBadge(defaultItems);
      }
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("venue/music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);

      if (selectedMusic.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedMusic.includes(item.id);
        });
        setDefaultMusic(defaultItems);
      }
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);
      if (selectedFeature.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedFeature.includes(item.id);
        });
        
        setDefaultFeature(defaultItems);
      }
    }
  };

  function handleFileUpload(e) {
    const file = e.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    // Check the file type

    if (file.type === "application/pdf") {
      // Handle PDF file
      handleUpload(file, "pdf");
    } else if (file.type.startsWith("image/")) {
      // Handle image file
      handleUpload(file, "image");
    } else {
      // Invalid file type
      alert("Unsupported file type. Please select a PDF or an image.");
    }
  }

  async function handleUpload(file, type) {
    setLockSubmit(true);
    setStartLoader(true);
    let url = "comman/doc/upload";
   
    if(type === "image") {
      url = "comman/img/upload";
    }

    const pdfFile = file;
    const formData = new FormData();
    formData.append("file", pdfFile);

    const selectedImageRes = await postRequestForm(url, formData);

    const venData = { ...venueData };
    if (selectedImageRes?.data) {      
      venData["menu_url"] = selectedImageRes.data.url;
      setVenueData(venData);
    } 
    setStartLoader(false);
    setLockSubmit(false);
  }
  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("venue/cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCuisineList(options);
      if (selectedCuisine.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCuisine.includes(item.id);
        });
        setDefaultCuisine(defaultItems);
      }
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);

  

  // const Updatevenue = async (e) => {
  //   e.preventDefault();

  //   if (!businessId) {
  //     toast.error("Please select business");
  //     return;
  //   }

  //   if (!venueData.name) {
  //     toast.error("Please enter venue name");
  //     return;
  //   }
  //   if (!venueData.about) {
  //     toast.error("Please enter venue about");
  //     return;
  //   }
  //   if (!venueData.address) {
  //     toast.error("Please enter venue address");
  //     return;
  //   }
  //   if (!venueData.phone) {
  //     toast.error("Please enter venue phone");
  //     return;
  //   }
    

  //   if (!venueData.latitude) {
  //     toast.error("Please enter venue latitude");
  //     return;
  //   }

  //   if (!venueData.longitude) {
  //     toast.error("Please enter venue longitude");
  //     return;
  //   }
  //    // claim code must be 4 digit
  //   //  if (!venueData.claimCode) {
  //   //   toast.error("Please enter venue claim code (4 digit)");
  //   //   return;
  //   // }

  //   if (!selectedTheme.length) {
  //     toast.error("Please select venue theme");
  //     return;
  //   }

  //   if (!selectedFeature.length) {
  //     toast.error("Please select venue feature");
  //     return;
  //   }

  //   if (!selectedCuisine.length) {
  //     toast.error("Please select venue cuisine");
  //     return;
  //   }

  //   let atLeastOneChecked = false;

  //   timeOptions.forEach((item, index) => {
  //     if (item.checked) {
  //       atLeastOneChecked = true;
  //       return; // Break out of the loop since at least one is checked
  //     }
  //   });

  //   if (!atLeastOneChecked) {
  //     toast.error("Please select at least one time");
  //     return;
  //   }



  //   // let bussinessId = venueData.business_id?.value;

  //   // if (typeof venueData.business_id === "string") {
  //   //   bussinessId = venueData.business_id;
  //   // }

  //   const formData = new FormData();

    
  //   const selectedImageForm = new FormData();
  //   let selectedImageRes = null;
  //   if (remoteLogo) {
  //   selectedImageForm.append("image", remoteLogo);
  //   setStartLoader(true);
  //   selectedImageRes = await postRequestForm(
  //     "comman/logo/upload",
  //     selectedImageForm
  //   );
  //   setStartLoader(false);
  //   }

  //   const selectedImageForm2 = new FormData();
  //   let selectedImageRes2 = null;
  //   if (selectedCover) {
  //   selectedImageForm2.append("image", selectedCover);
  //   setStartLoader(true);
  //   selectedImageRes2 = await postRequestForm(
  //     "comman/img/upload",
  //     selectedImageForm2
  //   );
  //   setStartLoader(false);
  //   }

  //   // check if venueData.country_code not in phone then append

  //   let phone = "";
  //   if(!venueData.phone.includes(venueData.country_code)) {
  //      phone = `${venueData.country_code} ${venueData.phone}`;
  //   } else {
  //      phone = venueData.phone;
  //   }
    

  //   formData.append("venueId", id);
  //   // formData.append("business_id", businessId);
  //   formData.append("businessId", businessId);
  //   formData.append("name", venueData.name);
  //   formData.append("about", venueData.about ? venueData.about : "");
  //   formData.append("address", venueData.address ? venueData.address : "");
  //   formData.append("phone", phone);
  //   formData.append("email", venueData.email ? venueData.email : "");
  //   formData.append("website", venueData.website ? venueData.website : "");
  //   formData.append("latitude", venueData.latitude);
  //   formData.append("longitude", venueData.longitude);
  //   formData.append("booking_url", venueData.booking_url ? venueData.booking_url : "");
  //   formData.append("menu_url", venueData.menu_url ? venueData.menu_url : "");
  //   formData.append("dress_code", venueData.dress_code ? venueData.dress_code : "");
  //   formData.append("claimCode", venueData.claimCode ? venueData.claimCode : "");
  //   formData.append("discountText", venueData.discountText ? venueData.discountText : "");
  //   formData.append("isAllowReview", venueData.isAllowReview);
  //   formData.append("isAllowRatting", venueData.isAllowRatting);
  //   formData.append("promo", JSON.stringify(packages));
  //   formData.append("country", venueData.country ? venueData.country : "United Arab Emirates");
  //   formData.append("region", venueData.region);
  //   formData.append("isAlcohol", venueData.isAlcohol ? venueData.isAlcohol : "true");
    

  //   if (selectedImageRes?.data?.url) {
  //     formData.append("logo", selectedImageRes.data.url);
  //   } else {
  //     console.error("Image URL not found in the response.");
  //   }
  //   if (selectedImageRes2?.data?.url) {
  //     formData.append("cover", selectedImageRes2.data.url);
  //   } else {
  //     console.error("Image URL not found in the response.");
  //   }

  //   if (selectedTheme && selectedTheme.length > 0) {
  //     selectedTheme.forEach((item) => {
  //       formData.append("themes[]", item);
  //     });
  //   }

  //   if (selectedMusic && selectedMusic.length > 0) {
  //     selectedMusic.forEach((item) => {
  //       formData.append("music[]", item);
  //     });
  //   }

  //   if (selectedFeature && selectedFeature.length > 0) {
  //     selectedFeature.forEach((item) => {
  //       formData.append("features[]", item);
  //     });
  //   }

  //   if (selectedCuisine && selectedCuisine.length > 0) {
  //     selectedCuisine.forEach((item) => {
  //       formData.append("cuisines[]", item);
  //     });
  //   }

  //   if (timeOptions && timeOptions.length > 0) {
  //     timeOptions.forEach((item, index) => {
  //       if (item.checked) {
  //         formData.append(`days[]`, days[index].toString().toLowerCase());
  //         formData.append(`open_time[]`, item.open);
  //         formData.append(`close_time[]`, item.close);
  //       }
  //     });
  //   }
  //   setLoading(true);
  //   const response = await putRequestForm("venue/update", formData);
  //   setLoading(false);

  //   if (response.status === 1) {
  //     // If the update is successful, call the pushUpdates function
  //     try {
  //       const pushUpdatesResponse = await pushUpdates({
  //         type: "venue",
  //         id: id,
  //         receivers: [],
  //       });
  //       // Check if the pushUpdates call was successful, handle the response as needed       
  //       toast.success(response.message);
  //       setTimeout(() => {
  //         history.push("/venues");
  //       }, 3000);
  //     } catch (pushUpdatesError) {
  //       toast.error("Error in pushUpdates");
  //     }
  //   } else {
  //     toast.error(response.message);
  //   }
  // };


  const Updatevenue = async (e) => {
      e.preventDefault();

      if (!validateFormFields()) return; // Validates form fields before proceeding

      const formData = new FormData();
      setStartLoader(true);

      // Prepare image uploads concurrently
      try {
          const uploadPromises = [
              remoteLogo ? postRequestForm("comman/logo/upload", createFormData("image", remoteLogo)) : Promise.resolve({ data: { url: null } }),
              selectedCover ? postRequestForm("comman/img/upload", createFormData("image", selectedCover)) : Promise.resolve({ data: { url: null } }),
              remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve({ data: { url: null } }),
              remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve({ data: { url: null } }),
              remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve({ data: { url: null } }),
          ];

          const [logoResponse, coverResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);

          appendFormData(formData, {
              venueId: id,
              businessId,
              is_verified: venueData.is_verified,              
              name: venueData.name.trim(),
              about: venueData.about,
              address: venueData.address,
              phone: formatPhoneNumber(),
              email: venueData.email,
              website: venueData.website,
              country: venueData.country || "United Arab Emirates",
              region: venueData.region || "Dubai",
              isAlcohol: venueData.isAlcohol || "true",
              isManagedBy7N: venueData.isManagedBy7N || "true",
              latitude: venueData.latitude,
              longitude: venueData.longitude,
              booking_url: venueData.booking_url || "",
              menu_url: venueData.menu_url || "",
              dress_code: venueData.dress_code || "",
              claimCode: venueData.claimCode || "",
              reservationFee : venueData.reservationFee || 0,
              isOrderConfirmationRequired: venueData.isOrderConfirmationRequired || false,
              discountText: venueData.discountText || "",            
              isAllowReview: venueData.isAllowReview,
              isAllowRatting: venueData.isAllowRatting,
              promo: JSON.stringify(packages),
              badges: selectedBadge,
              themes: selectedTheme,
              music: selectedMusic,
              features: selectedFeature,
              cuisines: selectedCuisine,
              timeOptions: formatTimeOptions()
          });

          if(venueData?.is_verified && venueData.verificationType) {
            formData.append("verificationType", venueData.verificationType);
          }

          // Append images if upload was successful
          if (logoResponse?.data?.url) {
              formData.append("logo", logoResponse.data.url);
          }
          if (coverResponse?.data?.url) {
              formData.append("cover", coverResponse.data.url);
          }
          if (rectangleResponse?.data?.url) {
              formData.append("rectangleImage", rectangleResponse.data.url);
          }
          if (squareResponse?.data?.url) {
              formData.append("squareImage", squareResponse.data.url);
          }
          if (storyResponse?.data?.url) {
              formData.append("storyImage", storyResponse.data.url);
          }
          
          

          const response = await putRequestForm("venue/update", formData);
          handleResponse(response);
      } catch (error) {
          console.error("Failed to upload images or update venue:", error);
          toast.error("Failed to process the request.");
      } finally {
          setStartLoader(false);
      }
  };

  function createFormData(key, file) {
    const formData = new FormData();
    formData.append(key, file);
    return formData;
}

  function handleResponse(response) {
    if (response.status === 1) {
        toast.success(response.message);
        setTimeout(() => {
            history.push("/venues");
        }, 3000);
    } else {
        toast.error(response.message);
    }
}

  function formatPhoneNumber() {
    return venueData.phone.includes(venueData.country_code) ? venueData.phone : venueData.country_code ? `${venueData.country_code} ${venueData.phone}` : venueData.phone ;
  }

// Other auxiliary functions remain unchanged

  function validateFormFields() {
    // Validate business ID
    if (!businessId) {
        toast.error("Please select a business.");
        return false;
    }

    // Validate venue name
    if (!venueData.name.trim()) {
        toast.error("Please enter a venue name.");
        return false;
    }

    // Validate about section
    if (!venueData.about.trim()) {
        toast.error("Please enter details about the venue.");
        return false;
    }

    // Validate address
    if (!venueData.address.trim()) {
        toast.error("Please enter the venue address.");
        return false;
    }

    // Validate phone number
    if (!venueData.phone.trim()) {

        

        toast.error("Please enter a phone number.");
        return false;
    } else {

        // Normalize the phone number by removing spaces and dashes
        const normalizedPhone = venueData.phone.trim().replace(/[\s\-]/g, '');

        // Define a regex that matches UAE and KSA phone number formats
        // UAE numbers start with +971 followed by 9 digits
        // KSA numbers start with +966 followed by 9 digits
        // The regex also allows for numbers starting directly with a digit sequence typical for local use without country code
        const regexUAEKSA = /^(\+971|971|0)?[0-9]{9}$|^(\+966|966|0)?[0-9]{9}$/;

        //console.log("Normalized Phone", normalizedPhone);

        // if (!regexUAEKSA.test(normalizedPhone)) {
        //     toast.error("Please enter a valid phone number. Include the country code if applicable (e.g., +971 or 971 for UAE, +966 or 966 for KSA).");
        //     return false;
        // }
      
    }

    // Validate email if provided
    if (venueData.email && !/^\S+@\S+\.\S+$/.test(venueData.email)) {
        toast.error("Please enter a valid email address.");
        return false;
    }

    // Validate website URL if provided
    // if (venueData.website && !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(venueData.website)) {
    //     toast.error("Please enter a valid website URL.");
    //     return false;
    // }

    // Validate geographical coordinates
    if (!venueData.latitude || !venueData.longitude) {
        toast.error("Please specify the venue's geographical coordinates.");
        return false;
    } else {
        if (isNaN(venueData.latitude) || isNaN(venueData.longitude)) {
            toast.error("Latitude and longitude must be valid numbers.");
            return false;
        }
    }

    // Validate selected themes, if applicable
    if (selectedTheme.length === 0) {
        toast.error("Please select at least one theme.");
        return false;
    }

    // Validate selected music options, if applicable
    if (selectedMusic.length === 0) {
        toast.error("Please select at least one music genre.");
        return false;
    }

    // Validate selected features, if applicable
    if (selectedFeature.length === 0) {
        toast.error("Please select at least one feature.");
        return false;
    }

    // Validate selected cuisines, if applicable
    if (selectedCuisine.length === 0) {
        toast.error("Please select at least one cuisine.");
        return false;
    }

    // Check if at least one time option is selected
    let atLeastOneTimeChecked = timeOptions.some(option => option.checked);
    if (!atLeastOneTimeChecked) {
        toast.error("Please provide opening times for at least one day.");
        return false;
    }

    // All validations passed
    return true;
  }

  function formatTimeOptions() {
    const formattedOptions = [];
    timeOptions.forEach((item, index) => {
        if (item.checked) {
            formattedOptions.push({day: days[index].toLowerCase(), open_time: item.open, close_time: item.close});
        }
    });
    return formattedOptions;
  }

  function appendFormData(formData, data) {
    Object.keys(data).forEach(key => {
      if (key === 'timeOptions') {
          data[key].forEach(option => {
              formData.append('days[]', option.day);
              formData.append('open_time[]', option.open_time);
              formData.append('close_time[]', option.close_time);
          });
      } else if (Array.isArray(data[key])) {
          data[key].forEach(item => formData.append(`${key}[]`, item));
      } else {
          formData.append(key, data[key]);
      }
  });
}

  
  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);
    //console.log("venueDt", index, value)

    if (index == "business_id") {
      const Bdata = businessData.find((x) => x._id === list.business_id);
      setSelectedBusinessData(Bdata);
    }

    if(index == "country") {      
      // fetch country code

      const country = countries.find((x) => x.id === value);
      const code = country.code;
      const list = { ...venueData };
      list["country_code"] = code;
      list[index] = value;
      setVenueData(list);
    }

  };



  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }
    setCurrentLocation(newLocation);
    setVenueData({
      ...venueData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };
  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    //console.log("selectedIcon", icon)
    packagesList[selectedIcon].image = icon.image;    
    packagesList[selectedIcon].title = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };
  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  
  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const addRow = () => {
    setPackages([...packages, { image: "", title: "", discountTag: "" }]);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };


  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-square.jpg", {
        type: "image/jpeg",
      });

      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-story.jpg", {
        type: "image/jpeg",
      });

      setRemoteStory(file);
    });

  }, [storySelected]);

  const handleCropComplete2 = (crop) => {
    setCompletedCrop2(crop);
  };

  const handleCropComplete3 = (crop) => {
    setCompletedCrop3(crop);
  };

  const handleCropComplete4 = (crop) => {
    setCropImage(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );

        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        {/* <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Update Venue</li>
            </ol>
          </div>
        </div> */}
        {/* <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div> */}
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Venue </h4>
                <Form onSubmit={Updatevenue}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                       
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          Business Name
                          </label>

                            <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            
                            {businessText ? (
                             
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Select Business Name
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={businessImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={businessText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Select Business Name
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Business chosen"
                                />
                              </div>
                            )}
                          </div>   
                          </div> 


                          <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                         Country
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("country", e.target.value)
                            }                            
                            className="form-select"
                            value={venueData?.country}
                          >
                            <option value="">Select Country</option>
                            {countries.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                            
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                         Region
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("region", e.target.value)
                            }            
                            value={venueData?.region}                
                            className="form-select"
                          >
                            <option value="">Select Region</option>
                            {regions.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                            
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Is Alchohol Served
                        </label>
                        <div className="col-sm-6">
                          <select
                            defaultValue={
                              venueData?.isAlcohol ? "true" : "false"
                            }
                            onChange={(e) =>
                              updateValue("isAlcohol", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Is Venue Verified
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("is_verified", e.target.value)
                            }
                            value={venueData?.is_verified}
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      { (venueData?.is_verified === "true" || venueData?.is_verified === true) && (
                                              <div className="row mb-4">
                                              <label className="col-sm-3 col-form-label">
                                                Select Venue Verify
                                              </label>
                                              <div className="col-sm-6">
                                                <select
                                                  onChange={(e) =>
                                                    updateValue("verificationType", e.target.value)
                                                  }
                                                  value={venueData?.verificationType}
                                                  name="categoryId"
                                                  class="form-select" 
                                                >
                                                  <option value="">Select Venue Verify</option>
                                                  {verifyList.map((item, index) => (
                                                    <option key={index} value={item.id}>
                                                      {item.name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>

                      )}

                       
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Managed by 7NIGHTS
                        </label>
                        <div className="col-sm-6">
                          <select
                            defaultValue={
                              venueData?.isManagedBy7N ? "true" : "false"
                            }
                            onChange={(e) =>
                              updateValue("isManagedBy7N", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>



                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={venueData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              value={venueData?.about}
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              value={venueData?.address}
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Country Code
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="tel"
                            defaultValue={selectedBusinessData?.country_code}
                            onChange={(e) => updateValue(e.target.value)}
                            name="phone"
                            className="form-control"
                            value={venueData?.country_code}
                          />
                        </div>
                      </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              defaultValue={venueData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={venueData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              value={venueData?.website}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Url
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("booking_url", e.target.value)
                              }
                              value={venueData?.booking_url}
                              name="booking_url"
                              className="form-control"
                            />
                          </div>
                        </div>
                        
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Menu PDF or Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              accept=".pdf, image/*"
                              onChange={(e) => handleFileUpload(e)}
                              name="menu_url"
                              className="form-control"
                            />
                            <br></br>
                            {venueData?.menu_url && (
                              <a
                                href={venueData.menu_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                Download
                              </a>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Dress Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("dress_code", e.target.value)
                              }
                              value={venueData?.dress_code}
                              name="dress_code"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Claim Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              maxLength={4} // Set maximum length to 4
                              onChange={(e) =>                                
                                  updateValue("claimCode", e.target.value)
                                }
                              
                              value={venueData?.claimCode}
                              name="claimCode"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Reservation Fee (AED) [If Booking Allowed]
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            defaultValue={0}      
                            value={ venueData?.reservationFee}                      
                            onChange={(e) => {                               
                                updateValue("reservationFee", e.target.value);                             
                            }}
                            name="claimCode"
                            className="form-control"
                          />
                        </div>
                      </div>


                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Order Confirmation Required [If Booking Allowed]
                        </label>
                        <div className="col-sm-6">
                          <select
                            value={venueData?.isOrderConfirmationRequired}
                            onChange={(e) =>
                              updateValue("isOrderConfirmationRequired", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true">
                              Yes
                            </option>
                            <option value="false" selected>No</option>
                          </select>
                        </div>
                      </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Tag
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>                                
                                  updateValue("discountText", e.target.value)
                                }
                              
                              value={venueData?.discountText}
                              name="discountText"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Reviews
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowReview ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowReview", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Rattings
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowRatting ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowRatting", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Rectangle Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleRectangleImageChange} name="rectangleImage" className="form-control" />
                          <br />                         

                          {rectangleImagePreview && rectangleImage ? (
                             <ReactCrop
                             crop={cropRectangle}
                             src={rectangleImagePreview}
                             onChange={(newCrop) => updateCropRectangle(newCrop)}
                             onComplete={handleCropComplete2}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={rectangleImagePreview} />
                            </ReactCrop>
                         
                          ) : rectangleImagePreview ? (
                            <img
                              style={{ width: "250px", height: "100px" }}
                              src={rectangleImagePreview}
                              onClick={() => showLightBox2(rectangleImagePreview)}
                            />
                          ) : null}

                          {completedCrop2 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop2.width,
                                  height: completedCrop2.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Square Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleSquareImageChange} name="squareImage" className="form-control" />
                          <br />
                          {squareImagePreview && squareImage ? (
                             <ReactCrop
                             crop={cropSquare}
                             src={squareImagePreview}
                             onChange={(newCrop) => updateCropSquare(newCrop)}
                             onComplete={handleCropComplete3}
                             style={{
                               maxWidth: "720px",
                               maxHeight: "600px",
                             }}
                             >
                             
                             <img ref={imgRef} src={squareImagePreview} />
                            </ReactCrop>
                            
                          ): squareImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={squareImagePreview}
                              onClick={() => showLightBox2(squareImagePreview)}
                            />
                          ) : null}

                          {completedCrop3 && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: completedCrop3.width,
                                  height: completedCrop3.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Story Image</label>
                        <div className="col-sm-6">
                          <input type="file" onChange={handleStoryImageChange} name="storyImage" className="form-control" />
                          <br />
                          {storyImagePreview && storyImage ? (
                            <ReactCrop
                            crop={crop}
                            src={storyImagePreview}
                            onChange={(newCrop) => setCrop(newCrop)}
                            onComplete={handleCropComplete4}
                            style={{
                              maxWidth: "720px",
                              maxHeight: "600px",
                            }}
                            >
                            
                            <img ref={imgRef} src={storyImagePreview} />
                           </ReactCrop>
                           
                          ): storyImagePreview ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              src={storyImagePreview}
                              onClick={() => showLightBox2(storyImagePreview)}
                            />
                          ) : null}

                          {cropImage && (
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "contain",
                                  width: cropImage.width,
                                  height: cropImage.height,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Select Badge
                        </label>
                        <div className="col-sm-6">
                          <MultiSelect
                            options={badgeList}
                            onSelect={msUpdateBadge}
                            onRemove={msUpdateBadge}
                            selectedValues={defaultBadge}
                            placeholder="Select Badge"
                          />
                        </div>
                      </div>
  

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Theme
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={themeList}
                              onSelect={msUpdateTheme}
                              onRemove={msUpdateTheme}
                              selectedValues={defaultTheme}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Cuisine
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={cuisineList}
                              onSelect={msUpdateCuisine}
                              onRemove={msUpdateCuisine}
                              selectedValues={defaultCuisine}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Music
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={musicList}
                              onSelect={msUpdateMusic}
                              onRemove={msUpdateMusic}
                              selectedValues={defaultMusic}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={featureList}
                              onSelect={msUpdateFeature}
                              onRemove={msUpdateFeature}
                              selectedValues={defaultFeature}
                              placeholder="Search Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-6">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Promo
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Title</th>
                                  <th>Discount tag</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages?.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.image && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.image}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.image && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.title}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "title",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.discountTag}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "discountTag",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={venueData?.latitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lat")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={venueData?.longitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lng")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                  disabled={lockSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <BusinessModal show={model} closeModal={closeModal} selectRow={selectRow} />
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />


      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
