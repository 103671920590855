import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import 'tippy.js/dist/tippy.css';

import {
  postRequest,
  deleteRequest, 
  deleteConfirmationWords,
} from "../../../components/Common/Utils.js";



import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import React, { useState, useEffect } from "react";
import VenueModal from "components/Modal/VenueModal.js";
import GalleryModal from "components/Modal/GalleryModal.js";


const GalleryManagementlist = (props) => { 
  const [gallerydata, setgallerydata] = useState([]);
  const [lbData, setLBData] = React.useState([]); 

  const [photoIndex, setPhotoIndex] = React.useState(0);

  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(12);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [galleryMdl, setGalleryMdl] = useState(false);

  
  const closeModal = () => {
    setModel(false);
  };

  const closeGalleryModal = () => {
    setGalleryMdl(false);
  };



  const toggleModel = () => {
    setModel(!model);
  };

  useEffect(() => {
    if(!venueId) return;
    galleryManagment(true);
  }, [venueId]);



  const selectRow = (id, name, venue) => {
    setVenueId(id?._id);
    setVenueText(id?.name);
    setVenueImage(id?.logo);
    closeModal();
  }; 

  

  const showLightBox = (galleryData, initialIndex = 0) => {
    setLBData(galleryData);
    setPhotoIndex(initialIndex);
    setOpenLightBox(true);
  };
  


  const galleryManagment = async () => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder    
    };   
      
      payload.venueId = venueId;
      payload.page = 1;
      payload.limit = 10000;

      

    try {
      const response = await postRequest(`venue/gallery/list/admin`, payload);
      const data = response.data;
      setTotalPage(data.count);

      if (data?.list?.length) {  
        setgallerydata([...data.list]);       
      }else{
        setgallerydata([]);        
      }     

    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id, file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this gallery image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryImage(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryImage = async (id, file) => {
    const response = await deleteRequest("venue/gallery/delete", {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      galleryManagment(true);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };  
 

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Gallery Management</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Gallery Management</li>:{" "}
                <li className="breadcrumb-item">Gallery List</li>
              </ol>
            </div>
          </Col>         

          <div className="col-md-12 text-end" style={{ marginBottom: "20px" }}>
          <Col  className="col-lg-12 d-flex mb-3 justify-content-end">                                  
                                

                          <div
                            className="col-sm-3"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                             
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#E22A2A",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Venue
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No Venue chosen"
                                />
                              </div>
                            )}
                          </div>                             
                                  
                                



                                  
              {" "}

              { venueId && (
                 <Button className="mx-2" onClick={ () => setGalleryMdl(true)}>
                 {" "}
                 <i class="fas fa-plus"></i> Upload
                  </Button>
              )}


             
            
                                  
                              </Col>
            
           
          </div>

          {loader ? (
            <Col md={12} style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </Col>
          ) : (
            <>

              {
                gallerydata?.length == 0 && (
                  <div className="col-md-12 text-center">
                    <h4>{ venueId == "" ? 'Plaese Select Venue' : 'No Image' }</h4>
                  </div>
                )
              }


              {gallerydata.map((item, index) => (
                <Col key={item._id} xs={12} sm={6} md={6} lg={6} xl={4} xxl={3} className="col-xxxl-3">
                  <Card>
                    <CardBody>
                      <img
                        src={item.gallery}
                        onClick={() => showLightBox(gallerydata.map(item => item.gallery), index)}
                        alt="img"
                        className="mb-3"
                        style={{
                          cursor: "pointer",
                          height: "200px",
                          width: "100%",
                          objectFit: "cover"
                        }}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        
                        <h5 className="text-truncate font-size-14">
                          {item.venueName}
                        </h5>
                        
                        <Button
                          color="danger"
                          onClick={() => handlerdelete(item._id, item.gallery)}
                        >
                          <i className="fal fa-trash fs-5"></i>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>       

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <GalleryModal show={galleryMdl} closeModal={closeGalleryModal} venueId={venueId} reloadImage={galleryManagment} />      

      </React.Fragment>
      {openLightbox && (
        <Lightbox
          mainSrc={lbData[photoIndex]}
          onCloseRequest={() => setOpenLightBox(false)}
          nextSrc={lbData[(photoIndex + 1) % lbData.length]}
          prevSrc={lbData[(photoIndex + lbData.length - 1) % lbData.length]}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lbData.length - 1) % lbData.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lbData.length)
          }
        />
      )}
    </>
  );
};

export default GalleryManagementlist;
