import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser, apiError } from "../../store/actions";
import { postRequest } from "components/Common/Utils";

const Login = (props) => {
  let history = useHistory();

  const login = async () => {
    const token = new URLSearchParams(window.location.search).get("token");

    //console.log(token);

    if (!token) {
      toast.error("Token is missing");
      return;
    }

    const data = { venueToken: token };

    // Remove token from local storage
    localStorage.removeItem("authUser");
    localStorage.removeItem("access");
    localStorage.removeItem("token");
    localStorage.removeItem("acc_type");
    localStorage.removeItem("stored_bussinessId");
    localStorage.removeItem("stored_venueId");
    localStorage.removeItem("stored_temp_bId");
    localStorage.removeItem("stored_temp_vId");

    try {
      const log = await postRequest("venue/onboarding/venue/login", data);

        //console.log(log);   

      if (log.status) {
        toast.success(log.message);
        localStorage.setItem("authUser", JSON.stringify(log.data.profile));
        localStorage.setItem("access", "admin");
        localStorage.setItem("token", log.data.token);
        localStorage.setItem("acc_type", log.data.type);
        localStorage.setItem("stored_venueId", log.data?.profile?._id);
        localStorage.setItem("stored_temp_bId", log.data?.profile?.businessId);

        setTimeout(() => {
          history.push("/venue-account/dashboard");
        }, 1000);


      } else {
        toast.error(log.message);
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
    }
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(connect(mapStateToProps, { loginUser, apiError })(Login));

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
