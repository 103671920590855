import { Link } from "@material-ui/core";
import React, {useEffect,useState} from "react"
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap"
import { getRequest, postRequest,deleteRequest } from "../../components/Common/Utils.js";
import DonutChart from '../AllCharts/DonutChart';
import CountUp from "react-countup";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ChartComponets from "./ChartData.js";

const MonthlyEarnings = props => {
    const [userdata, setuserdata] = useState({});
    const [loader, setLoader] = useState(false);
    useEffect(() => {   
        dsManagment();
    }, [])

    const dsManagment = async (page = 0) => {
        setLoader(true);
        const response = await postRequest(`user/admin/dashboard-stat`);
        const data = response;
       
        // setTotalPage(parseInt(data.total));    
        // setCurrentPage(parseInt(currentPage));
        
      
        
        // wait for 2 seconds
    
        //await new Promise((resolve) => setTimeout(resolve, 2000));
        
        setuserdata(data);   
        // setSearchText('');
       
    
        setLoader(false);    
      }
    

    return (
        <React.Fragment>
            <Row>
            <Col xl={4}>
            <Card>              
                

                
                <CardBody >
                    <CardTitle className="h4 mb-4">Users</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                       
                        <a href="/users">
                        <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.totalUser || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Users</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>
               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Business</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/business">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.businessCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Business</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Venues</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/venues">                           
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.venuesCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Venues</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Cuisines</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/cuisines">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.cuisineCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Cuisines</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Themes</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/themes">
                          
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.themeCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Themes</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
           
            </Row>
            <Row>
           
             <Col xl={2}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">HomeBlock</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/homeblock">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.homeBlockCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">HomeBlock</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>

            <Col xl={2}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Ad Components</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/AdsList">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.adCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Ad Components</p>
                        </a>   
                        </div>                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>


               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Music</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/music">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.musicCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Music</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Features</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/feature">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.featureCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Features</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Story</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/stories">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.storyCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Story</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Activity</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/activityManagement">
                           
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.activity || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Activity</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
           
           
            </Row>
            <Row>
              <Col xl={2}>
            <Card>   
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Offers</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/offer">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.offerCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Offers</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>  
            <Col xl={2}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Activity Provider</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/activityProvider">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.activityProvider || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Activity Provider</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>
               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Categories</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/category">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.categoriesCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Categories</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Claims</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/specialOfferclaim">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.cliamsCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Claims</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Vouchers</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/vouchers">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.voucherCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Vouchers</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Events</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/events">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.eventCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Events</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            
           
            </Row>
            <Row>
            <Col xl={2}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Notification</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/notification">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.notificationCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Notification</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>
            <Col xl={2}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Check In</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/venuecheckin">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.CheckInCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Check In</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>
               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Invites</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="#">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.inviteCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Invites</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Organizers</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/eventOrganizer">                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.orgCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Organizers</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Special Offer</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/specialOffer">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.specialofferCount || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Special Offer</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Subscriptions</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/subscribe">
                            
                            <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.subscriptions || 0}
                            duration={1.5}
                        />
                        </h2>
                            <p className="text-muted">Subscriptions</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
           
           
            </Row>           
            {/* <ChartComponets /> */}
        </React.Fragment>
    )

}

export default MonthlyEarnings
