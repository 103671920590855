import React , {useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";

import {
  Row,
  Col
} from "reactstrap"




import DashCard from "./DashCard";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions";


const Dashboard = (props) => {
   


  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  },)

  


  return (
    <React.Fragment>

    
<Row>
{/* <Col className='12'>
            <div className="page-title-box">
              <h4>Dashboard </h4>
             
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Dashboard</li>
                : <li className="breadcrumb-item">Dashboard Counter</li>
              </ol>
            </div>
          </Col> */}

          {/* <div className="col-md-2 text-end">
            <Link to="/graph">
              {" "}
              <Button>
                
                {" "}
                <i class="fal fa-chart-line fs-5 text-light"></i> View Graph
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div> */}


<Row>
<Col className='3' xl="3">
{/* <ActiveVenueSubscription /> */}
  </Col>
  <Col className='9' xl="9">
    <DashCard values={props?.summary} />
    
  </Col>
 

</Row>
</Row>


   

      

   
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);