import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";
import Lightbox from "react-image-lightbox";
import {
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "../../components/Common/Utils.js";

import { ToastContainer, toast } from "react-toastify";
import CategoryModal from "components/Modal/CategoryModal.js";
import OfferModal from "components/Modal/OfferModalOrg.js";
import VenueModal from "components/Modal/VenueModalOrg.js";
import SelectedUsers from "./SelectedUsers.js";
import FileUpload from "components/Common/FileUpload.js";

export default function CloneNotification() {
  const nav = useHistory();
  const [notificationData, setNotificationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userModel, setUserModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [imageIcon, setImageIcon] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  // let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const msUpdateTarget = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTarget(ids);
  };
  const today = new Date().toISOString().split("T")[0];
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState();
  const [categoryImage, setCategoryImage] = useState("");
  const [scheduled, setScheduled] = useState(notificationData?.scheduled);
  const [model, setModel] = useState(false);
  const [categoryModel, setCategoryModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    // Extract date and time from scheduledDateTime
    const defaultDate = notificationData?.scheduledDateTime
      ? new Date(notificationData.scheduledDateTime).toISOString().split("T")[0]
      : "";
    const defaultTime = notificationData?.scheduledDateTime
      ? new Date(notificationData.scheduledDateTime)
          .toISOString()
          .split("T")[1]
          .slice(0, 5)
      : "";

    setDate(defaultDate);
    setTime(defaultTime);
  }, [notificationData]);

  useEffect(() => {
    fetchState();
  }, []);

  // const fetchTarget = async () => {
  //   const param = { page: 1, limit: 100000 };
  //   const theme = await postRequest("theme/list", param);
  //   if (theme.status == 1) {
  //     const options = theme.data.list.map((item) => {
  //       return { id: item._id, name: item.title };
  //     });
  //     setUserList(options);
  //   }
  // };

  const fetchState = async () => {
    const data = { ...state };
    setNotificationData(data);
    setLoading(false);
    setVenueImage(data?.venue?.logo);
    setOfferImage(data?.offer?.image);
    setCategoryImage(data?.category?.image);
    setSelectedUserIds(data?.users ? data?.users : []);
  };

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setNotificationData({ ...notificationData, image: reader.result });
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);
  useEffect(() => {
    setScheduled(notificationData?.scheduled);
    // other logic to handle other state variables...
  }, [notificationData]);

  //   let search = state._id;
  //   let finalsearch = search.toString();

  const updateNotificationData = async (e) => {
    e.preventDefault();
    if (!notificationData.title) {
      toast.error("Title is required");
      return;
    }
    if (notificationData.scheduled === "") {
      toast.error("Please select Scheduled");
      return;
    }
    setStartLoader(true);
    setLoading(true);
    const x = new FormData();

    x.append("image", selectedImage);

    const imgRes = await postRequestForm("comman/img/upload", x);
    setStartLoader(false);
    const dataObj = {
      title: notificationData.title,
      description: notificationData.description,
      scheduled: notificationData.scheduled,
      platform: notificationData.platform,
      type: notificationData.type,
      targetUser: notificationData.targetUser,
      status: notificationData.status,
      image: imgRes?.data?.url || notificationData?.image,
    };

    if (notificationData.scheduled === true) {
      const scheduledDateTime = `${notificationData.date}T${notificationData.time}:00.000Z`;
      dataObj.scheduledDateTime = scheduledDateTime;
    }

    if (notificationData.type === "link") {
      dataObj.typeId = notificationData.link;
    }

    if (notificationData.targetUser === "all") {
      dataObj.users = [];
    }

    if (notificationData.targetUser === "specific") {
      const users = [];

      selectedUserIds.forEach((item) => {
        users.push(item?._id);
      });
      dataObj.users = users;
    }

    if (notificationData.type === "venue") {
      dataObj.typeId = venueId;
    }

    if (notificationData.type === "category") {
      dataObj.typeId = categoryId;
    }

    if (notificationData.type === "offer") {
      dataObj.typeId = offerId;
    }

    const response = await postRequest("user/notification/add", dataObj);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      // redirect to list page
      setTimeout(() => {
        nav.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  //   const fetchIndex = (items, search) => {
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].value === search) {
  //         return i;
  //       }
  //     }
  //     return -1;
  //   };

  const fetchUsers = async () => {
    const param = { page: 1, limit: 100000 };
    const user = await postRequest("user/list/admin", param);
    if (user.status == 1) {
      // const selected = user.data.list.map((item) => {

      // });
      // setSelectedTarget(selected);
      const options = user.data.list.map((item) => {
        return {
          id: item._id,
          phone: item.phone,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
        };
      });
      setUserList(options);
    }
  };

  useEffect(() => {
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchUsers();
    setCategoryId(state.typeId);
    setVenueId(state.typeId);
    setOfferId(state.typeId);
    if (state.type === "venue") fetchVenueList();
    if (state.type === "offer") fetchOfferList();
    if (state.type === "category") fetchCategoryList();
  }, []);

  const fetchVenueList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
      setVenueText(
        response.data.list?.find((x) => x._id === state.typeId)?.name
      );
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
      setOfferText(
        response.data.list?.find((x) => x._id === state.typeId)?.title
      );
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setCategoryList(response.data.list);
      setCategoryText(
        response.data.list?.find((x) => x._id === state.typeId)?.title
      );
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };
  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (imageIcon) => {
    setImageIcon(imageIcon);
    setOpenLightBox2(true);
  };
  const selectRow = (id, name, row) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(row.logo);
    closeModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };
  const selectOfferRow = (id, name, row) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(row.image);
    closeOfferModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };
  const closeCategoryModal = () => {
    setCategoryModel(false);
  };
  const selectCategoryRow = (id, name, row) => {
    setCategoryId(id);
    setCategoryText(name);
    setCategoryImage(row.image);
    closeCategoryModal();
  };

  const popSelectedUser = (id) => {
    const newList = selectedUserIds.filter((item) => item._id !== id);
    setSelectedUserIds(newList);
  };

  const onSelectedUser = (selectedList) => {
    setSelectedUserIds(selectedList);
  };
  const closeUserModal = () => {
    setUserModel(false);
  };

  const selectUserRow = (id, name) => {
    setUserId(id);
    setUserText(name);
    closeUserModal();
  };

  const toggleUserModel = () => {
    setUserModel(!userModel);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Notifications</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Notifications</li>:{" "}
              <li className="breadcrumb-item">Clone Notification</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </Row>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Clone Notification</h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={notificationData?.title}
                              name="title"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={notificationData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}

                            {imageIcon && (
                              <img
                                src={imageIcon}
                                onClick={() => showLightBox2(imageIcon)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Scheduled
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={String(scheduled) || ""}
                              onChange={(e) => {
                                let v = "";
                                if (e.target.value === "true") v = true;
                                if (e.target.value === "false") v = false;
                                setScheduled(v);
                                updateValue("scheduled", v);
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              {/* <option value="now">Now</option> */}
                              <option value={"true"}>Yes</option>
                              <option value={"false"}>No</option>
                            </select>
                          </div>
                        </div>
                        {scheduled === true && (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  defaultValue={date}
                                  type="date"
                                  onChange={(e) =>
                                    updateValue("date", e.target.value)
                                  }
                                  name="date"
                                  min={today}
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Time
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateValue("time", e.target.value)
                                  }
                                  defaultValue={time}
                                  name="time"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {/* {notificationData?.scheduled === "true" && (
                        <>
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Push Date
                            </label>
                            <div className="col-sm-9">
                              <DatePicker
                                type="text"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                name="pushDate"
                                className="form-control"
                                id="horizontal-firstname-input"
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Push Time
                            </label>
                            <div className="col-sm-9">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  type="text"
                                  onChange={(e) =>
                                    updateValue("pushTime", e.target.value)
                                  }
                                  name="pushTime"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </>
                      )} */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("platform", e.target.value)
                              }
                              value={notificationData?.platform}
                              className="form-select"
                            >
                              <option value="">Select Platform</option>
                              <option value="sendtoall">Android & iOS</option>
                              <option value="sendtoandroid">All Android User</option>
                              <option value="sendtoios">All iOS User</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={notificationData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="general">General</option>
                              <option value="link">Link</option>
                              <option value="category">Category</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                            </select>
                          </div>
                        </div>

                        {notificationData?.type === "link" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Link
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                defaultValue={notificationData?.typeId}
                                name="link"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText !== "" ? (
                                // <div
                                //   defaultValue={notificationData?.venueId}
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {venueText}
                                //   </a>
                                // </div>
                                // <div></div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     Select Venue
                                //   </a>
                                // </div>
                                // <div>
                                //   <a
                                //     onClick={() => toggleModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                //   </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleOfferModel()}
                              defaultValue={notificationData?.offerId}
                            >
                              {offerText !== "" ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {offerText}
                                //   </a>
                                // </div>
                                // <div></div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     Select Offer
                                //   </a>
                                // </div>
                                // <div>
                                //   <a
                                //     onClick={() => toggleOfferModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                //   </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {notificationData?.type === "category" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Category
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleCategoryModel()}
                            >
                              {categoryText !== "" ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     defaultValue={
                                //       notificationData?.categoryText
                                //     }
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {categoryText}
                                //   </a>
                                // </div>
                                // <div>
                                //   <a
                                //     onClick={() => toggleCategoryModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                //   </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >
                                    Choose Category
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={categoryImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              ) : (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding:
                                //       " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     Select Category
                                //   </a>
                                // </div>
                                // <div>
                                //   <a
                                //     onClick={() => toggleCategoryModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                //   </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#E22A2A",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseCategoryBtn"
                                    onclick={() => toggleCategoryModel()}
                                  >
                                    Choose Category
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={categoryText}
                                    id="selectedCategory"
                                    placeholder="No category chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Target User
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("targetUser", e.target.value)
                              }
                              value={notificationData?.targetUser}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="all">All User</option>
                              <option value="specific">Specific User</option>
                            </select>
                          </div>
                        </div>

                        {notificationData?.targetUser === "specific" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Users
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleUserModel()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select User
                                  </a>
                                </div>
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <SelectUserList
                              users={userList}
                              onSelect={msUpdateTarget}
                              selectedTarget={selectedTarget}
                              setSelectedTarget={setSelectedTarget}
                              placeholder="Select Target"
                            /> */}
                          </div>
                        )}

                        {selectedUserIds?.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Selected Users
                            </label>
                            <div className="col-sm-6">
                              <SelectedUsers
                                selectedUser={selectedUserIds}
                                deleteHandler={popSelectedUser}
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={notificationData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="true">Published</option>
                              <option value="false">Unpublished</option>
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={imageIcon}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <CategoryModal
        show={categoryModel}
        closeCategoryModal={closeCategoryModal}
        selectRow={selectCategoryRow}
      />
      <MultipleSelectUserList
        showUser={userModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeUserModal}
        selectUserRow={selectUserRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
