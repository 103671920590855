import React, { Component } from 'react';
import { Card, CardBody, Row, Col, ButtonGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { postRequest } from "../../components/Common/Utils";
import "./dashboard.scss";

class SalesAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'overall',
            modal: false,
            selectedYear: moment().year(),
            selectedMonth: '',
            startDate: '2023-01-01',
            endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            data: {
                device: { ios: 0, android: 0 },
                country: {},
                country_oth: 0,
                type: { view: 0, search: 0, click: 0 },
                users: { unique: 0, nonUnique: 0 }
            }
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async fetchData() {
        const { startDate, endDate } = this.state;
        const { offerId } = this.props;

        try {
            const response = await postRequest(`log/offer-pie-stat-by-category`, { offerId, startDate, endDate });
            if (response.status === 1) {
                this.setState({ data: response.data });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleCustomApply = () => {
        const { selectedYear, selectedMonth } = this.state;
        const startDate = selectedMonth ? `${selectedYear}-${selectedMonth + 1}-01` : `${selectedYear}-01-01`;
        const endDate = selectedMonth ? moment(`${selectedYear}-${selectedMonth + 1}-01`).endOf('month').format('YYYY-MM-DD') : `${selectedYear}-12-31`;

        this.setState({ startDate, endDate }, () => {
            this.fetchData();
            this.toggleModal();
        });
    }

    handleYearChange = (e) => {
        this.setState({ selectedYear: e.target.value });
    }

    handleMonthChange = (e) => {
        this.setState({ selectedMonth: e.target.value });
    }

    updateView = (view) => {
        let startDate, endDate;
        if (view === 'overall') {
            startDate = '2023-01-01';
            endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        } else if (view === 'annual') {
            startDate = moment().startOf('year').format('YYYY-MM-DD');
            endDate = moment().endOf('year').format('YYYY-MM-DD');
        } else if (view === 'monthly') {
            startDate = moment().startOf('month').format('YYYY-MM-DD');
            endDate = moment().endOf('month').format('YYYY-MM-DD');
        }
        this.setState({ view, startDate, endDate }, () => {
            this.fetchData();
        });
    }

    renderPieChart(data, labels, title, colors) {
        const series = Object.values(data);
        const total = series.reduce((acc, val) => acc + val, 0);
        const percentages = series.map(value => ((value / total) * 100).toFixed(2));

        const options = {
            labels: labels,
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: colors,
        };

        return (
            <Col md={12} lg={6}> {/* Double the size */}
                <Card className="flat-card"> {/* Apply flat style */}
                    <CardBody>
                        <h4 className="card-title mb-4">{title}</h4>
                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={options} series={series} type="donut" height="250" />
                        </div>
                        <Row className="text-center mt-4">
                            {labels.map((label, index) => (
                                <Col xs={labels.length > 2 ? 4 : 6} key={index}> {/* Apply condition here 6 / 4 */}
                                    <div>
                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle" style={{ color: colors[index], fontSize: '10px' }}></i> {label}</p>
                                        <h5>{percentages[index]} %</h5>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }

    render() {
        const { data, modal, selectedYear, selectedMonth, view } = this.state;
        const deviceLabels = ["iOS", "Android", ];
        const deviceColors = ['#5664d2', '#3DDC84'];
        const countryLabels = [...Object.keys(data.country), "Other"];
        const countryColors = ['#FF4560', '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3DDC84', '#FFA800', '#5664d2', '#FF4560'];
        const typeLabels = ["View", "Search", "Click"];
        const typeColors = ['#3DDC84', '#5664d2', '#775DD0'];
        const userLabels = ["Unique", "Non-Unique"];
        const userColors = ['#FF4560', '#008FFB'];

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="float-end d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" active={view === 'monthly'} onClick={() => this.updateView('monthly')}>Monthly</Button>
                                <Button size="sm" color="light" active={view === 'annual'} onClick={() => this.updateView('annual')}>Annual</Button>
                                <Button size="sm" color="light" active={view === 'custom'} onClick={this.toggleModal}>Custom</Button>
                                <Button size="sm" color="light" active={view === 'overall'} onClick={() => this.updateView('overall')}>Overall</Button>
                            </ButtonGroup>
                        </div>
                        <h4 className="card-title mb-4">Analytics by Platform</h4>
                        <Row>
                            {this.renderPieChart(data.device, deviceLabels, "Device", deviceColors)}
                            {this.renderPieChart({ ...data.country, Other: data.country_oth }, countryLabels, "Country", countryColors)}
                            {this.renderPieChart(data.type, typeLabels, "View / Search / Click", typeColors)}
                            {this.renderPieChart(data.users, userLabels, "Users", userColors)}
                        </Row>
                    </CardBody>
                </Card>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Select Date</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="yearSelect">Year</Label>
                            <Input type="select" id="yearSelect" value={selectedYear} onChange={this.handleYearChange}>
                                {Array.from({ length: moment().year() - 2023 + 1 }, (_, i) => 2023 + i).map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Month (optional)</Label>
                            <Input type="select" id="monthSelect" value={selectedMonth} onChange={this.handleMonthChange}>
                                <option value="">All</option>
                                {moment.months().map((month, index) => (
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCustomApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;
