import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const TableComponent = ({selectVoucherRow }) => {
  const [offerdata, setofferdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("name");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    voucherManagment();
  }, [currentPage]);

  useEffect(() => {
    voucherManagment();
  }, [sizePerPageOrg, sort]);

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img src={row.image} alt="img" width={"50px"} />
      </>
    );
  }

  function BusinessFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.business_id?.name}</p>
      </div>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function titleFormatter(cellContent, row) {
    return (
      <div style={{}}>
        <p>{row?.title}</p>
        {row?.homeblock && (
          <div
            class="badge"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
            }}
          >
            HomeBlock
          </div>
        )}
      </div>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "title",
      formatter: titleFormatter,
      text: "Title",
      sort: true,
    },
    // {
    //   dataField: "description",
    //   text: "Description",
    //   sort: true,
    // },

    {
      dataField: "venueName",
      text: "Venue",
      sort: true,
    },
   
    //  {
    //    text: "Bussiness Name",
    //    formatter: BusinessFormatter,
    //  },

    //  {
    //    dataField: "email",
    //    text: "Email",
    //    sort: false,
    //  },

    {
      dataField: "image",
      text: "Image",
      formatter: ImgLogoFormatter,
      sort: false,
    },

    {
      dataField: "select",
      text: "Select",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className='d-flex'>           
          <button type='button' className="btn btn-danger" onClick={() => selectVoucherRow(row)}><i class="fal fa-plus  fs-5"></i></button>
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const voucherManagment = async (page = 0) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
      expired: false
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`homeblock/deal-package/list`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setofferdata(data.list);
    setLoader(false);
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={offerdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                <div className='w-10 d-flex'>
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ marginRight: "90px" }}
                                     >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>
                                  </div>

                                  <div className='d-flex g-3 justify-content-end'>
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "300px" }}
                                    />
                                    <button
                                      onClick={() => {
                                        voucherManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <Link to="/createvoucher">
                                      <button class="btn btn-secondary"> <i class="fas fa-plus"></i> Create</button> 
                                    </Link>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const VoucherModal = ({ show, closeModal, selectRow }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Voucher List
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent selectVoucherRow={selectRow} />
      </div>
    </Modal>
  );
};

export default VoucherModal;
