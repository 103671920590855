import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { canvasPreview } from "pages/VenueManagement/canvasPreview";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect";

import { ToastContainer, toast } from "react-toastify";
import { postRequest, putRequestForm } from "../../components/Common/Utils.js";

export default function UpdateOrganizerProfileData() {
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [eventData, setEventData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [completedCrop, setCompletedCrop] = useState(null);

  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);

  const [remoteLogo, setRemoteLogo] = useState(null);

  let history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    setEventData(state);
    setPreviewCover(state?.cover ? state?.cover : "");
    setPreviewLogo(state?.logo ? state?.logo : "");

    // wait for 1 sec
  }, [state]);

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], eventData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);

  const Updateeventorganizer = async (e) => {
    e.preventDefault();

    if (!eventData.name) {
      toast.error("Please enter event name");
      return;
    }

    if (!eventData.password) {
      toast.error("Please enter event email");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("eventOrgId", id);
    formData.append("name", eventData.name);
    formData.append("phone", eventData.phone);
    formData.append("email", eventData.email);
    formData.append("password", eventData.password);
    formData.append("website", eventData.website);

    if (remoteLogo) {
      formData.append("logo", remoteLogo);
    }
    if (selectedCover) {
      formData.append("cover", selectedCover);
    }

    const response = await putRequestForm("event-organizer/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/events");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Event Organizer</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Event Organizer</li>:{" "}
              <li className="breadcrumb-item">Update Event</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Event </h4>
                <Form onSubmit={Updateeventorganizer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={eventData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              value={eventData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={eventData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="password"
                              onChange={(e) =>
                                updateValue("password", e.target.value)
                              }
                              value={eventData?.password}
                              name="password"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              value={eventData?.website}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              // onChange={(e) =>
                              //   setSelectedLogo(e.target.files[0])
                              // }
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
