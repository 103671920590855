import { Modal } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import DraggableList from 'react-draggable-list';

const Item = ({ item, dragHandleProps, index }) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const [isDragging, setIsDragging] = useState(false);

  return (
    <>
      <div
        className={`disable-select ${isDragging ? 'dragging' : ''}`}
        style={{
          border: "1px solid #e4dfdf",
          borderRadius: '5px',
          margin: "4px",
          padding: "15px",
          display: "flex",
          justifyContent: "space-around",
          background: "white",
          alignItems: "center",
          userSelect: "none"
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          //console.log("touchStart");
          // Change background color when touched
          e.target.style.backgroundColor = "blue";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          //console.log("mouseDown");
          setIsDragging(true); // Set dragging state to true
          onMouseDown(e);
        }}
        onTouchEnd={(e) => {
          // Reset background color when touch ends
          e.target.style.backgroundColor = "black";
          setIsDragging(false); // Set dragging state to false
        }}
        onMouseUp={() => {
          // Reset background color when mouse up
          setIsDragging(false); // Set dragging state to false
        }}
      >
        <span class="fas fa-grip-vertical" style={{ fontSize: "1.5rem", width: '20%' }}></span>
        <div style={{ width: '50%' }}>
          {item.title || item.name || item.label}
        </div>
        <div style={{ width: '30%' }}>
          <span className='text-center align-middle mb-0'>{item.type}</span>
          <span className='text-center align-middle mb-0'>{item?.sizeName}</span>
        </div>
      </div>
    </>
  );
};

const DragDropHomeblock = ({ data, show, closeModal, setData }) => {
  // State for the list
  const [list, setList] = useState(data.map((item, index) => ({ ...item, index: index })));

  // Ref for the container
  const containerRef = useRef();

  // Handle list change
  const handleListChange = (newList) => {
    setList(newList);
  };

  // Callback function when dragging ends
  const handleDragEnd = (newList) => {
    setList(newList);
    // Reset dragging state for all items
    newList.forEach(item => item.isDragging = false);
  };

  return (
    <>
      <div className="" style={{ maxHeight: '500px', overflowY: 'auto' }} ref={containerRef}>
        <DraggableList
          itemKey="index"
          template={Item}
          list={list}
          onMoveEnd={handleDragEnd} // Use handleDragEnd for onMoveEnd callback
          container={() => containerRef.current}
        />
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <button
          onClick={() =>
            setData(list)
          } /* Set onClick to call setData(items) */
          className="btn btn-primary"
        >
          Save Order
        </button>
      </div>
    </>
  );
};

export default DragDropHomeblock;
